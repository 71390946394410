import LoginService from "../../Login/LoginService";

interface UserPreferences {
    department: string;
    departments: string[];
    userName?: string;
    preferredColour: string;
    lineManager: string;
    jobTitle: string;
}

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class OnboardingService {
    async saveOnboarding(userPreferences: UserPreferences, kpis: string[], userDescription: string): Promise<Response> {
        const url = `${BASE_URL}/api/user/on-board`
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                ...userPreferences,
                userName: userPreferences.userName ?? "",
                kpis: kpis,
                description: userDescription
            })
        });
    }
}

export default new OnboardingService();