import React from "react";
import './ThirdStep.scss';
import { QuestionContext, QuestionContextProps } from "../QuestionProvider";
import { UserContext, UserContextProps } from "../../../../components/UserProvider";

const ThirdStep: React.FC = () => {
  const context = React.useContext<QuestionContextProps>(QuestionContext);
  const userContext = React.useContext<UserContextProps>(UserContext);

  const date = new Date();
  const day = date.getDate();
  const month = date.toLocaleDateString('en-US', { month: "short" });
  const year = date.getFullYear();

  return (
    <>
      <div className="question-preview">
        <div className="question-header">
          <div>
            <span className="datetime">{date.toLocaleTimeString()} · {day} {month} {year}</span>
            <span className="question-comments-info">Be the first to comment on this question</span>
          </div>

          <div className="tags-list">
            {context.state.kpis.map((kpi) => (
              <span className="tags-item">{kpi}</span>
            ))}
          </div>
        </div>

        <div className="question-container">
          <div className="question-container-header">
            <img className="container-header-avatar" src={userContext.state.profileUrl} alt="" />

            <div className="container-header-name">
              <span className="name">{userContext.state.fullName}</span>
              <span className="role">
                {userContext.state.jobTitle} {userContext.state.department ? `· ${userContext.state.department}` : ''}
              </span>
            </div>
          </div>

          {/* <span className="question-menu-button">
            <img src={DotsMenuIcon} alt="" />
          </span> */}

          <div className="question-container-title">
            <h5 className="card-type">Question</h5>
            {/* <img src={userContext.state.profileUrl} className="cover" alt="" /> */}
            <h3 className="title">
              {context.state.text}
            </h3>
          </div>

          <div className="question-container-footer">
            <span className="data-actions">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 14.928 14.165" className="data-actions-icon data-actions-like">
                <g id="Like" transform="translate(0.5 0.699)">
                  <path id="Caminho_21" data-name="Caminho 21"
                    d="M12.569,17.465h5.97a1.273,1.273,0,0,0,1.187-.79L21.707,12.1a1.261,1.261,0,0,0,.09-.473v-1.3a1.5,1.5,0,0,0-1.312-1.506H16.348l.625-2.752.021-.2a.965.965,0,0,0-.288-.685L16,4.5,11.632,8.8a1.282,1.282,0,0,0-.382.915V16.2A1.292,1.292,0,0,0,12.569,17.465Z"
                    transform="translate(-7.869 -4.5)" strokeWidth="1" />
                  <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.307v7.979H3.375Z"
                    transform="translate(-3.375 -10.764)" strokeLinejoin="round" strokeWidth="1" />
                </g>
              </svg>
            </span>
            <span className="data-actions">
              00 Likes
            </span>
            <span className="data-actions">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 13.625 13.347" className="data-actions-icon">
                <g id="Comment" transform="translate(-4.5 -1.8)">
                  <g id="Comment_Icon" data-name="Comment Icon" transform="translate(5 2.3)">
                    <path d="M-521.2,544.632h-8.884a1.957,1.957,0,0,0-1.871,2.033v5.884a1.957,1.957,0,0,0,1.871,2.033h1.406V556.8a.154.154,0,0,0,.256.126l2.472-2.345h4.75a1.957,1.957,0,0,0,1.87-2.033v-5.884A1.957,1.957,0,0,0-521.2,544.632Z"
                      transform="translate(531.954 -544.632)" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                </g>
              </svg>
              00 Comments
            </span>
            {/* <span className="data-actions">
              <img src={SimilarICon} className="data-actions-icon" alt="" />
              00 Similar
            </span>
            <span className="data-actions">
              <img src={DataStoryIcon} className="data-actions-icon" alt="" />
              00 Data Stories
            </span>
            <span className="data-actions">
              <img src={ArticlesICon} className="data-actions-icon" alt="" />
              00 Articles
            </span> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ThirdStep;