import React from 'react';
import './Password.scss';
import {
  Container,
  Grid,
  InputLabel,
  Checkbox,
  Hidden,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  CircularProgress
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import passwordImage from '../../../assets/images/password-image.svg';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RegisterState } from '../Register';

export interface PasswordProps {
  state: RegisterState;
  setState: CallableFunction;
  register: CallableFunction;
}

export interface PasswordState {
  acceptAgreement: boolean;
  passwordRequired: boolean;
  passwordsDontMatch: boolean;
  registerFailedError: string;
  showFirstPassword: boolean;
  showConfirmPassword: boolean;
}

const Password: React.FC<PasswordProps> = (props) => {
  const [state, setState] = React.useState<PasswordState>({
    acceptAgreement: false,
    passwordRequired: false,
    passwordsDontMatch: false,
    registerFailedError: "",
    showFirstPassword: false,
    showConfirmPassword: false
  })

  const [isRegistering, setIsRegistering] = React.useState<boolean>(false)

  const doRegister = (props: PasswordProps, state: PasswordState, setState: CallableFunction, loadingDone: CallableFunction) => {
    setState({...state, registerFailedError: ""});
  
    if (props.state.step !== 2) return;
  
    if (props.state.password.length === 0) {
        setState({...state, passwordRequired: true});
        return;
    }
    setState({...state, passwordRequired: false});
  
    if (!state.acceptAgreement) {
        toast.warning("You must accept terms & conditions to continue.");
        return;
    }
  
    setIsRegistering(true);
    props.register((err: string) => {
        toast.warning(err);
        loadingDone();
        setState({...state, registerFailedError: err });
    }, () => {
        loadingDone();
    });
  }
  
  const doPasswordsMatch = (props: PasswordProps) => props.state.password === props.state.passwordConfirmation;
  
  const passwordHelperError = (field: number, state: PasswordState) => {
    if (field === 1) {
        if (state.passwordRequired) return "Password cannot be empty. Please type a password.";
        return "";
    }
  
  }
  
  return (
    <Container className="register-card-content">
      <Grid container className="password-align-content">
        <Grid item md={8} sm={12} xs={12} className="password-grid-content">
          <h1 className="register-content-title">Nice to meet you {props.state.name}</h1>
          <h2 className="register-content-subtitle password-content-subtitle">We’ve got a few more steps and then you’ll be set</h2>
          <p className="password-content-text">Make sure it’s at least 6 characters long and uses a mix of upper and lowercase letters, numbers and at least one non alphanumeric symbol. </p>

          {/* Passwords inputs */}
          <Grid item className="register-inputs-container">
            <div className="register-inputs-container-margin password-inputs-container">
              <InputLabel className="register-input-label" htmlFor="register-password">
                Password
              </InputLabel>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <OutlinedInput
                  id="register-password"
                  placeholder="password"
                  type={state.showFirstPassword ? 'text' : 'password'}
                  value={props.state.password}
                  required
                  onChange={(e) => props.setState({ ...props.state, password: e.target.value })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setState({ ...state, showFirstPassword: !state.showFirstPassword })}
                        onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                        edge="end"
                      >
                        {state.showFirstPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormHelperText
                error={state.passwordRequired || (state.passwordsDontMatch && !doPasswordsMatch(props)) || state.registerFailedError.length > 0}
                style={{ paddingLeft: "15px", paddingRight: "15px", marginTop: "-5px" }}
              >
                {passwordHelperError(1, state)}
              </FormHelperText>
            </div>
          </Grid>

          {/* Terms and conditions checkbox */}
          <Grid item className="password-terms-link">
            <Checkbox
              name="termsAgreement"
              color="primary"
              value={state.acceptAgreement}
              onClick={() => setState({ ...state, acceptAgreement: !state.acceptAgreement })}
              id="accept-agreement"
            />
            <label htmlFor="accept-agreement">
              Agree to&#160;
              <a href="https://www.decidable.com/legal/Decidable-TermsOfUse.pdf" target="_blank" rel="noreferrer">
                <span className="link">terms & conditions</span>
              </a>
            </label>
          </Grid>

          {/* Verify button */}
          <Grid item className="password-buttons">
            <button className="btn-outlined-wipe" onClick={() => props.setState({ ...props.state, step: 1 })}>
              Back
            </button>
            <button className="btn-contained-wipe" onClick={() => {              
              doRegister(props, state, setState, () => setIsRegistering(false))
            }}>
              Verify
            </button>
          </Grid>
        </Grid>

        {/* Image */}
        <Grid item md={4}>
          <Hidden smDown>
            <img src={passwordImage} alt="" />
          </Hidden>
        </Grid>
      </Grid>

      <ToastContainer />

      {isRegistering &&
          <div className="overlay-loading">
            <CircularProgress style={{ 'color': 'white' }} />
          </div>
      }
    </Container>
  );
}



export default Password;