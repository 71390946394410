import { Redirect, Route, RouteProps } from 'react-router';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthenticated, ...routeProps }) => {
  if (isAuthenticated) {
    return <Route {...routeProps} />;
  }
  const callbackUrl = window.location.pathname + window.location.search;
  return <Redirect to={"/login?callbackUrl=" + encodeURIComponent(callbackUrl)} />;
};

export default ProtectedRoute;