import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.table))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const trackingId = process.env.REACT_APP_GA_TRACKING_ID; // Replace with your Google Analytics tracking ID
if (trackingId !== undefined) {
  ReactGA.initialize(trackingId)
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.set({
    // userId: auth.currentUserId(),
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  })
}