import React from 'react';

import Joyride from 'react-joyride';

export interface JoyrideStep {
    target: string;
    content: string;
    disableBeacon: boolean;
  }
  
  export class JoyrideBuilder {
    private steps: JoyrideStep[] = [];
    private isRunning: boolean = false;
    private showingProgress: boolean = false;
    private isContinuous: boolean = false;
    private callback: CallableFunction = () => null;
    private showSkip: boolean = false;
  
    constructor(steps: JoyrideStep[]) {
      this.steps = steps;
    }
  
    getSteps(): JoyrideStep[] {
      return this.steps;
    }
  
    start(): JoyrideBuilder {
      this.isRunning = true;
      return this;
    }
  
    stop(): JoyrideBuilder {
      this.isRunning = false;
      return this;
    }
  
    continuous(): JoyrideBuilder {
      this.isContinuous = true;
      return this;
    }
  
    showProgress(): JoyrideBuilder {
      this.showingProgress = true;
      return this;
    }
  
    hideProgress(): JoyrideBuilder {
      this.showingProgress = false;
      return this;
    }
  
    setCallback(callback: CallableFunction): JoyrideBuilder {
      this.callback = callback;
      return this;
    }
  
    showSkipButton(): JoyrideBuilder {
      this.showSkip = true;
      return this;
    }
  
    build(): JSX.Element {
      return (
        <Joyride
          steps={this.steps}
          run={this.isRunning}
          showProgress={this.showingProgress}
          continuous={this.isContinuous}
          callback={(data: any) => this.callback(data) }
          showSkipButton={this.showSkip}
        />
      )
    }
  }
  
  export const runJoyride = (context: any, steps: JoyrideStep[], finishCallback?: CallableFunction) => {
    const joyride =   new JoyrideBuilder(steps)
      .showProgress()
      .continuous()
      .showSkipButton()
      .start()
  
    if(finishCallback) {
      joyride.setCallback((data: any) => finishCallback(data));
    }
  
    context.state.setJoyride(
        joyride.build()
    )
  }