import LoginService from "../../../../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class CommentService {
    async likeComment(CommentId: string) {
        const url = `${BASE_URL}/api/Comment/${CommentId}/like`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: ""
        });
    }
}

export default new CommentService();