import './Decision.scss';
import { Button, Menu, MenuItem} from "@material-ui/core";

import DotsMenuIcon from '../../../../../assets/images/dotsMenu-icon.svg';
import React from "react";
import { StoryItemContext, StoryItemContextProps } from "../StoryItemProvider";
import { StoryItemImageProps} from "../StoryItemContracts";
import { DialogContext, DialogContextProps } from "../../QuestionItem/DialogProvider";
import { toast } from "react-toastify";


import Editor from "rich-markdown-editor";

import QuestionService from "../../QuestionItem/QuestionService";
import { handleError } from "../../QuestionItem/Comments/Comment";
import CommentService from "../../QuestionItem/Comments/CommentService";
import { LikeWhiteButton } from '../../../../../components/Likes/LikeButton';


const Decision: React.FC<StoryItemImageProps> = (props) => {
    const context = React.useContext<StoryItemContextProps>(StoryItemContext);
    const dialogContext = React.useContext<DialogContextProps>(DialogContext);

    const likeDecision = () => {
        (async function () {
            await CommentService.likeComment(props.story.id);
            if (context.state.likeDecision) {
                context.setState({ ...context.state, numberLikesDecision: context.state.numberLikesDecision - 1, likeDecision: !context.state.likeDecision });
                return;
            }
            context.setState({ ...context.state, numberLikesDecision: context.state.numberLikesDecision + 1, likeDecision: !context.state.likeDecision });
        })()
    }

    const unselectDecision = async (id: string) => {
        const response = await QuestionService.unselectDecision(props.story.id);
        
        if (response.status !== 200) {
          handleError(response);
          return;
        }
    
        toast.success("Comment unselected as decision.");
        
        context.setState({...context.state, decisionMenu: null});
        dialogContext.setState({...dialogContext.state, decision: null});
        dialogContext.state.updateFeed();
    }

    const hasOutcome = () => {
        return props.story.outcome;
    }

    return (
        <div key={props.story.id} className="feed-decision-item">
            <div className="decision-container"
                style={hasOutcome() ? { background: "#53cb83", borderRadius: "0px" } : { background: "#53cb83", borderRadius: "0px 0px 15px 15px" }}
            >
                <div className="decision-container-header">
                    <div className="decision-container-contents">
                        <img className="container-header-avatar" src={props.story.author.profileUrl} alt="" />

                        <div className="container-header-name container-header-name-white">
                            <span className="name">{props.story.author.fullName}</span>
                            <span className="role">
                                {props.story.author.jobTitle} {props.story.author.department === '' ? '' : `· ${props.story.author.department}`}
                            </span>
                        </div>
                    </div>

                    <div className="decision-container-contents">
                        <img src={props.story.chartImageUrl} className="container-header-chart" alt="" />
                                   
                        <Button className="decision-menu-button" onClick={(e) => context.setState({ ...context.state, decisionMenu: e.currentTarget })}>
                            <img src={DotsMenuIcon} alt="" />
                        </Button>
                        <Menu
                            anchorEl={context.state.decisionMenu}
                            open={Boolean(context.state.decisionMenu)}
                            onClose={() => context.setState({ ...context.state, decisionMenu: null })}
                            PopoverClasses={{ paper: 'popover-menu' }}
                        >
                            <MenuItem onClick={() => unselectDecision(props.story.id)} className="popover-menu-item">Remove Decision</MenuItem>
                        </Menu>
                    </div>
                </div>

                <div className="decision-container-title">
                    <div className="texts-contents">
                        <h5 className="card-type" style={{color: "#fff"}}>Marked as Decision</h5>
                        <Editor className="text-intro decidable-markdown-feed white-decision"
                        value={[
                            props.story.insight,
                            props.story.anomalyReason].join("\n")
                        } readOnly disableExtensions={[]} />
                    </div>
                </div>

                <div className="decision-container-footer">
                    <LikeWhiteButton liked={context.state.likeDecision} onClick={() => likeDecision() } />     
                    <span className="like-counter-decision">{context.state.numberLikesDecision.toString().length === 1 ? "0" : ""}{context.state.numberLikesDecision}</span> 
                </div>

            </div>
        </div>
    )
}

export default Decision;

