import { DataStoryState } from "../DataStoryContracts";
import DataStoryService from "../DataStoryService";
import { toast } from "react-toastify";

export const saveDraft = async (state: DataStoryState): Promise<void> => {
  // noinspection DuplicatedCode
  const response = await DataStoryService.saveStory({
    id: state.id,
    addressedUserId: state.addressedUserId || 0,
    taggedUsersIds: state.tagged.map((user: any) => user.id),
    kpis: state.kpis,
    title: state.title,
    pivotState: state.selectedData,
    storyContent: {
      insight: state.insight,
      anomalyReason: state.anomalyReason,
      impactExplanation: state.impactExplanation,
      suggestions: state.suggestions
    }
  });

  if (response.status !== 200) {
    toast.error("Error while saving draft.");
    return;
  }
  toast.success("Data Story saved as Draft!");
}

export const postStory = async (id: string, state: any, history: any): Promise<void> => {
  let pivotState = JSON.stringify(state.pivotTable);

  const storyResponse = await DataStoryService.saveStory({
    id: state.id,
    addressedUserId: state.addressedUserId || 0,
    taggedUsersIds: state.tagged.map((user: any) => user.id),
    kpis: state.kpis,
    title: state.title,
    pivotState: pivotState,
    storyContent: {
      insight: state.insight,
      anomalyReason: state.anomalyReason,
      impactExplanation: state.impactExplanation,
      suggestions: state.suggestions
    }
  });

  // noinspection DuplicatedCode
  if (storyResponse.status !== 200) {
    toast.error("Error while posting.");
    return;
  }

  const response = await DataStoryService.publishStory(id);

  if (response.status !== 200) {
    toast.error("Error while posting.");
    return;
  }

  toast.success("Data Story posted successfully!");
  setTimeout(() => {
    history.push("/dashboard");
  }, 100);}