import DataStoryService from "../../../../../v1/DataStoryService";
import { DataStoryProps } from "../../../../DataStoryProvider";

export const generateText = async (props: any) => {
  const response = await DataStoryService.generateText(props.state.id, {
    file: props.state.filename,
    cols: props.state.cols,
    rows: props.state.rows,
    columns: JSON.stringify(props.state.columns),
    data: "",
    pivotState: "",
    graphType: props.state.graphType,
    aggregatorName: props.state.aggregatorName,
    dictionaryGraphData: JSON.stringify(props.state.dictionaryGraphData)
  });

  if (response.status !== 200) {    
    return null;
  }

  const responseData = await response.json();
  return responseData;
}

export const saveStory = async (props: DataStoryProps) => {
  const response = await DataStoryService.saveStory({
    id: props.state.id,
    addressedUserId: props.state.draftStory.addressedUserId,
    kpis: [props.state.context.kpi],
    title: props.state.draftStory.title,
    taggedUsersIds: props.state.draftStory.tagged.map((u: any) => u.id),
    pivotState: JSON.stringify(null),
    storyContent: {
      anomalyReason: props.state.draftStory.anomalyReason,
      impactExplanation: props.state.draftStory.impactExplanation,
      insight: props.state.draftStory.insight,
      suggestions: props.state.draftStory.suggestions
    }
  });

  return response.status === 200;
}
