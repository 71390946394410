import React, { useEffect } from "react";
import './QuestionItem.scss';
import DotsMenuIcon from '../../../../assets/images/dotsMenu-icon.svg';
import { Button, Collapse, Menu, MenuItem} from "@material-ui/core";
import QuestionService from "./QuestionService";
import { PostType } from "./Comments/Comments";
import { Comment as IComment, Like } from "../../../DataStory/v1/DataStoryContracts";
import { QuestionItemContext, QuestionItemContextProps } from "./QuestionItemProvider";
import QuestionAnswerComponent from "./QuestionAnswerComponent";
import { CommentAnswer, User } from "../../Dashboard";
import CommentService from "./Comments/CommentService";
import CommentsLikesDialog from "../StoryItem/CommentsLikesDialog";
import { DialogContext, DialogContextProps } from "./DialogProvider";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {handleError} from "./Comments/Comment";
import {getKpiColor, getKpiLineColor, getKpiStyle} from "../StoryItem/StoryItem";

interface QuestionItemProps {
  id: string;
  author: string;
  jobTitle: string;
  department: string;
  title: string;
  image: string | null;
  extraContent: string | null;
  answers: Array<any>;
  date: Date;
  isFirst: boolean;
  profileUrl: string;
  tags: string[];
  likedByCurrentUser: boolean;
  likesCount: number;
  commentsCount: number;
  commentAnswerId: string | null;
  commentAnswer: CommentAnswer | null;
  answerSelectedBy: User | null;
  peopleCommentsCount: number;
  isOpen?: boolean;
  questionRemoved: CallableFunction;
  openEditModal?: CallableFunction;
}

interface QuestionState {
  liked: boolean;
  tabValue: number;
  open: boolean;
  like: boolean;
  storyMenu: HTMLElement | null;
  openStoryContent: boolean;
  comments: IComment[];
  comment: string;
  commentSelected: boolean;
  likes: Like[];
  numberLikes: number;
  commentId: string;
  commentsMenu: HTMLElement | null;
  commentsCount: number,
  peopleCommentsCount: number;
}

const getCommentInfo = (answerNumber: number) => {
  if (answerNumber === 1) return 'Someone commented on this question';
  return `${answerNumber} people commented on this question`
  // if (answerNumber - 1 < 9) return ` & 0${answerNumber - 1} more commented`;
  // return ` & ${(answerNumber - 1)} more commented`;
}

const QuestionItem: React.FC<QuestionItemProps> = (props) => {
  const context = React.useContext<QuestionItemContextProps>(QuestionItemContext);
  const dialogContext = React.useContext<DialogContextProps>(DialogContext);

  const kpis = [
    { name: 'Acquisition', description: 'Casting your net to capture as many customers as possible.' },
    { name: 'Conversion', description: 'Transforming prospective customers into actual customers.' },
    { name: 'Engagement', description: "Maximise the visitor's experience of your site" },
    { name: 'Revenue', description: 'Maximise the amount that your site earns' },
    { name: 'Payoff', description: 'Reaping the rewards of landing your customers.' },
    { name: 'Referral', description: 'The customers you’ve landed spreading the word.' }
  ];

  const day = props.date.getDate();
  const month = props.date.toLocaleDateString('en-US', { month: "short" });
  const year = props.date.getFullYear();
  const [openContent, setOpenContent] = React.useState(props.isOpen ?? false);

  const handleOpenContent = () => {
    setOpenContent(!openContent);
  };

  const [state, setState] = React.useState<QuestionState>({
    liked: props.likedByCurrentUser,
    commentSelected: false,
    tabValue: 0,
    open: false,
    like: props.likedByCurrentUser,
    storyMenu: null,
    openStoryContent: false,
    comment: "",
    comments: [],
    likes: [],
    numberLikes: props.likesCount,
    commentId: '',
    commentsMenu: null,
    commentsCount: props.commentsCount,
    peopleCommentsCount: props.peopleCommentsCount
  })

  const likeQuestion = async (questionId: string) => {
    const response = await QuestionService.likeQuestion(questionId);

    if (response.status !== 200) {
      return;
    }
    if (state.liked) {
      context.setState({ ...context.state, numberLikes: context.state.numberLikes - 1 });
    } else {
      context.setState({ ...context.state, numberLikes: context.state.numberLikes + 1 });
    }

    setState({ ...state, liked: !state.liked });
  }

  const likeAnswerComment = async () => {
    const answer = context.state.commentAnswer;
    if (!answer)
      return;
    const response = await CommentService.likeComment(answer.id);

    if (response.status !== 200) {
      return;
    }

    if (answer.likedByCurrentUser) {
      context.setState({
        ...context.state,
        commentAnswer: {
          ...answer,
          likesCount: answer.likesCount - 1,
          likedByCurrentUser: false
        }
      });
    } else {
      context.setState({
        ...context.state,
        commentAnswer: {
          ...answer,
          likesCount: answer.likesCount + 1,
          likedByCurrentUser: true
        }
      });
    }
  }

  const openLikes = () => {
    let id = props.id;

    dialogContext.setState({
      ...dialogContext.state,
      tabValue: 0,
      id: id,
      open: true
    })
    setState({ ...state, open: true })
  }

  const openComments = () => {
    let id = props.id;

    dialogContext.setState({
      ...dialogContext.state,
      tabValue: 1,
      id: id,
      open: true
    })
  }
  const removeAnswer = async () => {
    const response = await QuestionService.removeAnswer(props.id, props.commentAnswerId || context.state.commentAnswer!.id)

    if (response.status !== 200) {
      await handleError(response);
      return;
    }

    context.setState({ ...context.state, commentAnswer: null })
    setState({ ...state, commentsMenu: null })
  }

  useEffect(() => {
    context.setState({
      ...context.state,
      numberLikes: props.likesCount,
      commentAnswer: props.commentAnswer,
      questionId: props.id,
      answerSelectedBy: props.answerSelectedBy
    })
  /* eslint-disable */
  }, [])
  /* eslint-enable */

  const updateQuestion = async () => {
    const refreshResult = await QuestionService.refreshQuestion(props.id);

    setState({
      ...state,
      liked: refreshResult.likedByCurrentUser,
      like: refreshResult.likedByCurrentUser,
      numberLikes: refreshResult.likesCount,
      commentsCount: refreshResult.commentsCount,
      peopleCommentsCount: refreshResult.peopleCommentsCount
    });
  }

  const editQuestion = () => {
    if(props.openEditModal) {
      props.openEditModal(() => context.setState({...context.state, questionMenu: null}));
    }
  }

  const removeQuestion = (): void => {
    swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this question!",
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
    })
    .then(async (willDelete: boolean) => {
        if (willDelete) {
            const response = await QuestionService.deleteQuestion(props.id);
            if (response.status === 200) {
                 props.questionRemoved();
                context.setState({...context.state, questionMenu: null})
                toast.success("Poof! Your question has been deleted");
                return;
            }
            toast.error("Something went wrong while deleting the question");
        }
    });
}

  return (
    <div key={props.id}>
      <div className="question-item">
        <div className="question-header">
          <div>
            <span className="datetime">{props.date.toLocaleTimeString()} · {day} {month} {year}</span>
            {state.peopleCommentsCount > 0
              ? (
                <>
                  {getCommentInfo(props.peopleCommentsCount)}
                </>
              )
              : (
                <span className="question-comments-info">Be the first to comment on this question</span>
              )
            }

          </div>

          <div className="tags-list">
            {props.tags.map(item => (
              <span className="tags-item"  key={item} style={ getKpiStyle(item) }>
                <span className={ getKpiLineColor(item) }>{item}</span>
                <div className="tags-item-hover">
                  <h5 className="tags-hover-title">{item}</h5>
                  <p className="tags-hover-description">
                    {kpis.map(kpi => kpi.name === item ? kpi.description : "")}
                  </p>
                </div>
              </span>
            ))}
          </div>
        </div>

        <div className={context.state.commentAnswer ? 'question-container with-answer' : 'question-container'}>
          <div className={`question-container-header ${ getKpiLineColor(props.tags[0]) }`}>
            <div className="story-container-contents">
              <img className="container-header-avatar" src={props.profileUrl} alt="" />

              <div className="container-header-name">
                <span className="name">{props.author}</span>
                <span className="role">
                  {props.jobTitle} {props.department === '' ? '' : `· ${props.department}`}
                </span>
              </div>
            </div>

            <Button className="question-menu-button" data-label="question-context-menu" onClick={(e) => context.setState({ ...context.state, questionMenu: e.currentTarget })}>
                <img src={DotsMenuIcon} alt="" />
            </Button>
            <Menu
                anchorEl={context.state.questionMenu}
                open={Boolean(context.state.questionMenu)}
                onClose={() => context.setState({ ...context.state, questionMenu: null })}
                PopoverClasses={{ paper: 'popover-menu' }}
            >
                <MenuItem onClick={() => editQuestion()} className="popover-menu-item">Edit Question</MenuItem>
                <MenuItem onClick={() => removeQuestion()} className="popover-menu-item">Remove Question</MenuItem>
            </Menu>
          </div>

          <div className="question-container-title" onClick={handleOpenContent}>
            <h5 className="card-type" style={{ color: getKpiColor(props.tags[0]) }}>Question</h5>
            <h3 className="title" data-label="question-feed-item">
              {props.title}
            </h3>

            {props.image || props.extraContent ? (
              <span className="read-more" style={{ color: getKpiColor(props.tags[0]) }}>
                {openContent ? 'Hide content...' : 'Read more...'}
              </span>
            ) : ''}
          </div>

          {props.image || props.extraContent ? (
            <Collapse in={openContent} timeout="auto" className="collapse-content">
              <h5 className="collapse-title-content">Additional Information</h5>
              <p className="collapse-content-text">{props.extraContent ?? ""}</p>
              {props.image && <img src={props.image} className="collapse-content-image" alt="" />}
            </Collapse>
          ) : ''}

          <div className="question-container-footer">
            <Button className="data-actions" onClick={() => likeQuestion(props.id)}>
              {
                state.liked ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 18.537 17.524" className="data-actions-icon data-actions-like liked">
                    <g id="Like" transform="translate(0.5 0.699)">
                      <g id="Icon_ionic-md-thumbs-up" data-name="Icon ionic-md-thumbs-up">
                        <path id="Caminho_21" data-name="Caminho 21" d="M12.911,20.825h7.516a1.6,1.6,0,0,0,1.495-.995l2.5-5.756a1.588,1.588,0,0,0,.114-.6V11.838a1.892,1.892,0,0,0-1.652-1.9H17.669l.787-3.465.026-.255a1.215,1.215,0,0,0-.363-.863L17.237,4.5,11.731,9.912a1.614,1.614,0,0,0-.481,1.152v8.162A1.626,1.626,0,0,0,12.911,20.825Z" transform="translate(-6.993 -4.5)" stroke-width="1" />
                        <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.808V25.8H3.375Z" transform="translate(-3.375 -9.472)" strokeLinejoin="round" strokeWidth="1" />
                      </g>
                    </g>
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 14.928 14.165" className="data-actions-icon data-actions-like">
                    <g id="Like" transform="translate(0.5 0.699)">
                      <path id="Caminho_21" data-name="Caminho 21"
                        d="M12.569,17.465h5.97a1.273,1.273,0,0,0,1.187-.79L21.707,12.1a1.261,1.261,0,0,0,.09-.473v-1.3a1.5,1.5,0,0,0-1.312-1.506H16.348l.625-2.752.021-.2a.965.965,0,0,0-.288-.685L16,4.5,11.632,8.8a1.282,1.282,0,0,0-.382.915V16.2A1.292,1.292,0,0,0,12.569,17.465Z"
                        transform="translate(-7.869 -4.5)" strokeWidth="1" />
                      <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.307v7.979H3.375Z"
                        transform="translate(-3.375 -10.764)" strokeLinejoin="round" strokeWidth="1" />
                    </g>
                  </svg>
              }
            </Button>
            <Button className="data-actions" onClick={() => openLikes()}>
              {state.numberLikes && state.numberLikes.toString().length <= 1
                ? `0${state.numberLikes}`
                : (state.numberLikes === 0
                  ? `0${state.numberLikes}`
                  : state.numberLikes)}
            </Button>
            <Button className="data-actions" onClick={() => openComments()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 13.625 13.347" className="data-actions-icon">
                <g id="Comment" transform="translate(-4.5 -1.8)">
                  <g id="Comment_Icon" data-name="Comment Icon" transform="translate(5 2.3)">
                    <path d="M-521.2,544.632h-8.884a1.957,1.957,0,0,0-1.871,2.033v5.884a1.957,1.957,0,0,0,1.871,2.033h1.406V556.8a.154.154,0,0,0,.256.126l2.472-2.345h4.75a1.957,1.957,0,0,0,1.87-2.033v-5.884A1.957,1.957,0,0,0-521.2,544.632Z"
                      transform="translate(531.954 -544.632)" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                </g>
              </svg>
              {state.commentsCount.toString().length === 1 ? `0${state.commentsCount}` : state.commentsCount}
            </Button>


            {/* <span className="data-actions">
              <img src={SimilarICon} className="data-actions-icon" alt="" />
              00 Similar
            </span>
            <span className="data-actions">
              <IncandescentOutlinedIcon className="data-actions-icon dataStory" />
              00 Data Stories
            </span>
            <span className="data-actions">
              <img src={ArticlesICon} className="data-actions-icon" alt="" />
              00 Articles
            </span> */}
          </div>

          <CommentsLikesDialog author={props.author}
            likes={state.likes}
            liked={state.liked}
            comments={state.comments}
            type={PostType.QUESTION}
            key={props.id}
            onClose={updateQuestion} />
        </div>

        {/* FIXED ANSWER */}
        {context.state.commentAnswer != null && (
          <QuestionAnswerComponent
            answer={context.state.commentAnswer}
            removeAnswer={removeAnswer}
            likeAnswerComment={likeAnswerComment}
            answerSelectedBy={context.state.answerSelectedBy}
          />
        )}
      </div>

      {/* <div className="story-add-link">
        <span style={{ color: 'inherit', textDecoration: "none" }}>
          Add a data story
        </span>
      </div> */}
    </div >
  )
}

export default QuestionItem;