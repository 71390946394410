import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  overrides: {
    // Button
    MuiButton: {
      root: {
        borderRadius: '20px',
        textTransform: 'capitalize'
      }
    },
    // Switch
    MuiSwitch: {
      root: {
        padding: '0',
        height: '27px',
        width: '61px',
      },
      track: {
        borderRadius: '20px',
        height: '27px',
        width: '43px',
        "$checked$colorPrimary + &": {
          opacity: 1.0,
          backgroundColor: "#fff"
        },
      },
      switchBase: {
        padding: '4px 3px',
      },
      thumb: {
        height: '18px',
        width: '18px',
      }
    },
  },
});
