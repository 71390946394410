import React, { useEffect } from "react";
import './FirstStep.scss';
import FileIcon from '../../../../assets/images/uploadFile-icon.svg';
import { QuestionContext, QuestionContextProps } from "../QuestionProvider";
import { FormHelperText } from "@material-ui/core";

const FirstStep: React.FC = () => {
    const context = React.useContext<QuestionContextProps>(QuestionContext);
    const [showingImage, setShowingImage] = React.useState<boolean>(false);

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : "";
        const image: any = document.getElementById('question-image');
        loadImage(file, image);
    }

    const loadImage = (file: any, elImage: any) => {
        if(typeof file === 'string') {
            elImage.src = file;
            context.setState({...context.state, imageKeep: true});
            return;    
        }

        const reader = new FileReader();
    
        reader.addEventListener("load", function () {
            elImage.src = reader.result;
        });
        if (file) {
            reader.readAsDataURL(file);
            context.setState({...context.state, text: context.state.text, extraContent: context.state.extraContent, image: file, imageKeep: true });
            setShowingImage(true);
        }
    }

    useEffect(() => {
        if(context.state.image) {
            loadImage(context.state.image, document.getElementById('question-image'));
            setShowingImage(true);
        }
    /* eslint-disable */
    }, []);
    /* eslint-enable */

    return (
        <>
            <div className="question-form-group">
                <label className="question-form-label">What would you like to know?</label>
                <textarea
                className={ `question-form-textarea ${context.state.inputError && context.state.text.length === 0 && "error"}` }
                name="question-title"
                id="question-title"
                placeholder="Type what would you like to know"
                maxLength={140}
                rows={3}
                value={context.state.text}
                onChange={(e) => context.setState({...context.state, text: e.target.value, inputError: false })}
                >
                </textarea>
                { 
                context.state.inputError && context.state.text.length === 0 && (
                    <FormHelperText style={{color: "#ff0000"}}>
                        You should provide a question to continue...
                    </FormHelperText>
                )}
            </div>

            <div className="question-form-group">
                <label className="question-form-label">Anything else you’d like to add?</label>
                <textarea
                className="question-form-textarea"
                name="question-subtitle"
                id="question-subtitle"
                placeholder="Type anything else you’d like to add"
                maxLength={140}
                rows={3}
                value={context.state.extraContent}
                onChange={(e) => context.setState({...context.state, extraContent: e.target.value })}
                >
                </textarea>
            </div>

            {/* Upload image */}
            <div className="question-form-group">
                <div className="question-upload-button">
                <label htmlFor="file-input">
                    Drop files here or click to add an images
                    <img src={FileIcon} alt="" />
                </label>
                <input
                    id="file-input"
                    type="file"
                    multiple={false}
                    accept="image/*"
                    onChange={handleFile}
                    name="file-input"
                    className="question-upload-input"
                />
                </div>

                <img src="" alt="" className="question-image-preview" id="question-image" />
                <hr style={{visibility: "hidden"}}/>
                {showingImage && (
                    <button className="btn-contained-wipe" onClick={() => {
                        context.setState({...context.state, imageKeep: false, image: null});
                        const el: any = document.getElementById('question-image');
                        el.src = "";
                        setShowingImage(false);
                    }}>Remove image</button>
                )}
                
            </div>
        </>
    )
}

export default FirstStep;