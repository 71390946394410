import React, { useEffect } from 'react';
import './FreeTrial.scss';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, OutlinedInput } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AdminFreeTrialService from '../AdminFreeTrialService';
import { OptionsMenu } from './OptionsMenu';
import { UserRegisterStatus } from '../../DataStory/v1/DataStoryContracts';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { UserContextProps, UserContext } from '../../../components/UserProvider';

export interface Row {
  id: number;
  email: string;
  name: string;
  role: string;
  userType: string;
  userRegisterStatus: UserRegisterStatus;
}

const FreeTrial: React.FC = () => {
  const [rows, setRows] = React.useState<Row[]>([]);
 
  const history = useHistory();
  const context = React.useContext<UserContextProps>(UserContext);
  useEffect(() => {
    try {
      const freeTrialEmails: string[] = JSON.parse(process.env.REACT_APP_FREE_TRIAL_EMAILS!);
      
      if(!freeTrialEmails.includes(context.state.email)) {
        throw new Error();
      }
    } catch(err) {
      history.push("/dashboard");
    }

    updateUsers()
  /* eslint-disable */
  }, [])
  /* eslint-enable */
  const updateUsers = () => {
    AdminFreeTrialService.trialUsers().then(response => {
      if(response.status !== 200) {
        toast.error("Error while listing the users.");
        return;
      }

      response.json().then((emails: string[]) => {
        const rows = emails.map((email: string) => {
          return {
            email: email,
          } as Row;
        });

        setRows(rows)
      })
    });
  }

  function updateRow(row: Row, newStatus: UserRegisterStatus) {
    const index = rows.indexOf(row);
    const copy = [...rows];
    copy[index] = {...row, userRegisterStatus: newStatus };

    setRows(copy);
  }

  const inviteTrial = async () => {
    const email = await swal({
      text: 'Enter the email you want to invite for a trial:',
      content: { element: "input"},
      buttons: ["Cancel", "Invite"]
    });

    if(!email) return;

    const shouldInvite = await swal(`Are you sure you want to invite "${email}" for a trial?`, {
      buttons: ["Cancel", "Confirm"]
    });
    
    if(!shouldInvite) return;

    const response = await AdminFreeTrialService.inviteTrial(email);
  
    if(response.status !== 200) {
      toast.error("Error while inviting for a trial.");
      return;
    }

    toast.success(`A free trial invitation was sent to "${email}".`);

    updateUsers();
  }

  return (
    <>
      <div className="admin-selected-content-header">
        <h1 className="admin-selected-content-title">Free trial</h1>

        <span 
          className="btn-contained-wipe admin-users-button"
          onClick={(e) => inviteTrial()}
        >
          Invite for trial
        </span>
      </div>

      <div className="admin-users-subheader">
        <span className="admin-users-subheader-text">Number of Users: {rows.length}</span>
        <OutlinedInput
          id="search-input"
          className="admin-users-search-input"
          type="text"
          placeholder="Search Users"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon className="admin-users-search-input-icon" />
            </InputAdornment>
          }
        />
      </div>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="admin-users-table-head">
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="admin-users-table-body">
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <OptionsMenu row={row} updateRow={updateRow} updateUsers={() => updateUsers()} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}

export default FreeTrial;