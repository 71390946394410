import React from "react";
import { StepBuilder, Step } from "../Step";
import Context from "./Context";

export const ContextStep = (): Step => {
    return new StepBuilder()
                .component(<Context />)
                .step("Context is king")
                .stepListName("Context")
                .help(
                    <ul className="step-help-text">
                        <li>Always tailor your insights to the reader.</li>
                        <li>It’s about what they need to hear.</li>
                        <li>Not what you want to say.</li>
                    </ul>)
                .build();
}