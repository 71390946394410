import React from 'react';
import './Admin.scss';
import { useLocation } from "react-router-dom";
import Header from '../../components/Header/Header';
import { Container, Grid } from '@material-ui/core';
import Users from './Users/Users';
import { UserContext, UserContextProps } from '../../components/UserProvider';
import { useEffect } from 'react';
import AppInsights, { loadAppContext } from '../../components/AppInsights';
import AdminMenu from '../../components/AdminMenu/AdminMenu';

const Admin: React.FC = () => {
  const location = useLocation();
  const userContext = React.useContext<UserContextProps>(UserContext);

  useEffect(() => {
    loadAppContext(location, userContext);
  /* eslint-disable */
  }, [])
  /* eslint-enable */

  return (
    <div className="admin-container">
      <Header />

      <Container className="admin-container-content">
        <AdminMenu path={"/company/users"} />

        <Grid item md={10} className="admin-selected-content">
          <Users />
        </Grid>
      </Container>
    </div>
  );
}

export default AppInsights(Admin);