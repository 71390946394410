import React from "react";
import FirstStep from "./FirstStep/FirstStep";
import SecondStep from './SecondStep/SecondStep';
import ThirdStep from './ThirdStep/ThirdStep';

export type ObjectComponent = {
    title: string;
    helpList: JSX.Element;
    component: JSX.Element;
} & object;

export const getStep = (step: number): ObjectComponent => {
    const steps: any = {
        1: {
            title: 'Ask a question',
            helpList: (
                <ul className="question-help-list">
                    <li className="question-help-item">You don’t ask, you don’t get.</li>
                    <li className="question-help-item">So let us know what’s on your mind?</li>
                </ul>
            ),
            component: <FirstStep />
        },
        2: {
            title: 'Who’d be interested in your question?',
            helpList: (
                <ul className="question-help-list">
                    <li className="question-help-item">It’s your question.</li>
                    <li className="question-help-item">But the answer may be useful to lots of people.</li>
                    <li className="question-help-item">Add anyone you think would be interested.</li>
                    <li className="question-help-item">Tag a department and a KPI too.</li>
                </ul>
            ),
            component: <SecondStep />
        },
        3: {
            title: 'Preview',
            component: <ThirdStep />
        }
    }

    return steps[step];
}