import React from "react";
import FirstStep from "./Steps/FirstStep";
import {OnboardingContext, OnboardingContextProps} from "../OnboardingProvider";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import FourthStep from "./Steps/FourthStep";
import Targets from "./Steps/Targets";
import TargetsPreview from "./Steps/TargetsPreview";

const Analyst = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);

    switch (context.state.step) {
        case 0:
            return <FirstStep />;
        case 1:
            return <SecondStep />;
        case 2:
            return <ThirdStep />;
        case 3:
            return <FourthStep />;
        case 4:
            return <Targets />;
        case 5:
            return <TargetsPreview />;
        default:
            return <></>;
    }
}

export default Analyst;