import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import AppInsights, { loadAppContext } from "../../components/AppInsights";
import './Checkout.scss';
import {toast} from "react-toastify";

const Success: React.FC = () => {
    const history = useHistory();
    const location: any = useLocation();

    useEffect(() => {
        loadAppContext(location);
        const message = "Payment done successfully. Enjoy your subscription!";
        toast.success(message);
        history.push("/billing", { message: message });
        /* eslint-disable */
    }, []);
    /* eslint-enable */

    return <></>;
}

export default AppInsights(Success);