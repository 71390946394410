import React, { useEffect } from "react";
import Header from "../../../../../components/Header/Header";
import { UserContext, UserContextProps } from "../../../../../components/UserProvider";
import { finishOnboarding } from "../../Onboard";
import { OnboardingContext, OnboardingContextProps } from "../../OnboardingProvider";
import LoginService from "../../../../Login/LoginService";
import { CircularProgress, Grid } from "@material-ui/core";
import GoogleSignIn from "../../../../../components/GoogleSignIn/GoogleSIgnIn";

interface ShowButtonState {
    showConnect: boolean;
    showContinue: boolean;
}

const SecondStep = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);
    const userContext = React.useContext<UserContextProps>(UserContext);

    const [state, setState] = React.useState<ShowButtonState>({
        showConnect: false,
        showContinue: false
    })

    const getCredentials = () => {
        const token = LoginService.getToken();

        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/google-analytics/credentials`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
            },
        }).then(async (tokenResponse: any) => {

            const body = await tokenResponse.json();

            if (body.loggedIn) {
                context.setState({
                    ...context.state, GAPayload: {
                        accounts: body.accounts ?? [],
                        loggedIn: body.loggedIn,
                        scope: body.scope
                    }
                });
                setState({ ...state, showContinue: true });
                return;
            }
            setState({ ...state, showConnect: true });
        }).catch(() => {
            setState({ ...state, showConnect: true });
        });
    }

    useEffect(() => {
        getCredentials();
        /* eslint-disable */
    }, [])
    /* eslint-enable */

    const authorise = () => {
        localStorage.setItem("isOnboarding", "true");
        window.location.href = process.env.REACT_APP_API_ENDPOINT + '/api/google-analytics/onboarding-authorize'
    };

    return (
        <div className="onboarding-container">
            <Header />
            <div className="onboarding-container-content">
                <div className="onboarding-step-container">
                    <h1 className="onboarding-step-first-text">
                        Connect with Google Analytics.<br /><br />
                        This is really simple. All you will need to do is have your google analytics details to hand and follow the process.
                    </h1>


                    <Grid container style={{ marginTop: '40px' }}>
                        <Grid item md={12} style={{ textAlign: "center" }}>
                            {!state.showConnect && !state.showContinue && (
                                <CircularProgress style={{ color: 'white' }} />
                            )}
                        </Grid>
                        <Grid item md={12}>
                            <div className="onboarding-content-footer">
                                {(state.showConnect || state.showContinue) && (
                                    <button className="btn-outlined-wipe"
                                        onClick={() => finishOnboarding(userContext, context)}
                                    >
                                        Skip
                                    </button>
                                )}

                                {state.showConnect && (
                                    <GoogleSignIn onClick={authorise} />
                                )}

                                {state.showContinue && (
                                    <button className="btn-contained-wipe btn-continue" onClick={() => {
                                        context.setState({
                                            ...context.state,
                                            step: 2
                                        })
                                    }}>
                                        Continue
                                    </button>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </div>
        </div>
    )
}

export default SecondStep;