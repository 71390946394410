import "./Dashboard.scss";
import { Container, Grid, Input, InputAdornment, Select, MenuItem, Dialog, Hidden } from "@material-ui/core";
import { MultiSelect } from "react-multi-select-component";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import SearchIcon from '@material-ui/icons/Search';
import CloseWhiteIcon from '../../assets/images/close-white-icon.svg';
import { useHistory } from "react-router";
import StoryItem from "./Feed/StoryItem/StoryItem";
import Question, { QuestionData } from './Question/Question';
import DataStoryService from "../DataStory/v1/DataStoryService";
import { QuestionProvider } from "./Question/QuestionProvider";
import { StoryItemProvider } from "./Feed/StoryItem/StoryItemProvider";
import QuestionItem from "./Feed/QuestionItem/QuestionItem";
import { QuestionItemProvider } from "./Feed/QuestionItem/QuestionItemProvider";
import { UserContext, UserContextProps } from "../../components/UserProvider";
import { DialogContextProvider } from "./Feed/QuestionItem/DialogProvider";
import AppInsights, { loadAppContext } from "../../components/AppInsights";
import StoryQuotes from "./Feed/StoryQuotes/StoryQuotes";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import LoginService from "../Login/LoginService";

export interface User {
  id: number;
  fullName: string;
  department?: string;
  jobTitle?: string;
  profileUrl?: string;
}

export interface CommentAnswer {
  id: string;
  text: string;
  user: User;
  likedByCurrentUser: boolean;
  likesCount: number;
}

export interface FeedItem {
  __typename: string;
  id: string;
  questionKpis?: Array<any>;
  dataStoryKpis?: Array<any>;
  // DataStory
  title?: string;
  subtitle?: string;
  insight?: string;
  anomalyReason?: string;
  impactExplanation?: string;
  suggestions?: string;
  author?: User;
  commentsCount?: number;
  peopleCommentsCount?: number;
  likedByCurrentUser?: boolean;
  likesCount?: number;
  taggedUsers?: number[];
  publishedDate: Date;
  chartImageUrl: string;
  dataChartImageUrl: string;
  addressedUserId: number;
  hasDownloadableData: boolean;
  decision?: CommentAnswer;
  outcome?: CommentAnswer;
  // Question
  text?: string;
  image?: string;
  extraContent?: string | null;
  commentAnswerId?: string | null;
  commentAnswer?: CommentAnswer | null;
  answerSelectedBy?: User | null;
  user?: User;
}

const dateToString = (date: Date) => {
  return date.toISOString().split('T')[0]
}


const updateFeed = async (selectedFilter: Array<any>, setFeed: CallableFunction, from?: Date, to?: Date) => {
  if (from && to) {
    const feedPayload: any = await DataStoryService.getFeed(selectedFilter,
      dateToString(from),
      dateToString(to)
    );
    setFeed(feedPayload.data.feedV2);
    return;
  }

  const feedPayload: any = await DataStoryService.getFeed(selectedFilter);
  setFeed(feedPayload.data.feedV2);
}

const scheduledStories = process.env.REACT_APP_SCHEDULED_STORIES === "true";

const Dashboard: React.FC = () => {
  const context = React.useContext<UserContextProps>(UserContext);
  const location = useLocation();
  const [showConnect, setShowConnect] = React.useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = React.useState<Array<any>>([]);
  const [openQuestionModal, setOpenQuestionModal] = React.useState(false);
  const options = [
    { label: "Acquisition", value: "Acquisition" },
    { label: "Conversion", value: "Conversion" },
    { label: "Engagement", value: "Engagement" },
    { label: "Revenue", value: "Revenue" },
    // { label: "Retention", value: "Retention" },
    // { label: "Payoff", value: "Payoff" },
    // { label: "Referral", value: "Referral" },
  ];

  const [dateValue, onDateValueChange] = React.useState<Date[] | null>(null);

  const [feed, setFeed] = React.useState<FeedItem[]>([])
  const [editQuestion, setEditQuestion] = React.useState<QuestionData>({
    text: "",
    extra: "",
    image: "",
    kpis: [],
    id: ""
  })


  const [closeCallback, setCloseCallback] = React.useState<CallableFunction | null>(null);

  React.useEffect(() => {
    loadAppContext(location, context);
    (async function () {
      await updateFeed(selectedFilter, setFeed)
    })()
  }, [selectedFilter, context, location]);

  React.useEffect(() => {
    getCredentials();
    if (dateValue) {
      updateFeed(selectedFilter, setFeed, dateValue[0], dateValue[1]);
      return;
    }
    updateFeed(selectedFilter, setFeed);
    /* eslint-disable */
  }, [dateValue])
  /* eslint-enable */

  const handleOpenQuestionModal = (callback?: CallableFunction) => {
    if (callback) {
      setCloseCallback(callback);
    }
    setOpenQuestionModal(true);
  };
  const handleCloseQuestionModal = () => {
    setOpenQuestionModal(false);
    closeCallback && closeCallback() && setCloseCallback(null);
  };

  const history = useHistory();

  const navigateToPage = (value: string) => {
    if (value === "dataStory") {
      history.push("/v2/data-story")
    } else if (value === "question") {
      handleOpenQuestionModal()
    }
  }

  const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

  const removeFromFeed = (index: number) => {
    const array = [...feed];
    array.splice(index, 1);
    setFeed(array);
  }

  const getCredentials = () => {
    const token = LoginService.getToken();

    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/google-analytics/credentials`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': token ? 'Bearer ' + token : "",
      },
    }).then(async (tokenResponse: any) => {

      const body = await tokenResponse.json();

      if (!body.loggedIn) {
        setShowConnect(true)
        return;
      }
      setShowConnect(false)
    }).catch(() => {
      setShowConnect(true);
    });
  }

  return (
    <div className="dashboard-container">
      <Header />

      <div className="feed-subheader">
        <Container>
          <Grid item md={9} sm={12} xs={12} className="subheader-title">
            <span className="subheader-title-content">
              Have a question or something to share ?&nbsp;
              <Select
                id="feed-select"
                defaultValue="question"
                className="subheader-title-select"
                onChange={() => null}
              >
                <MenuItem value="question" onClick={() => navigateToPage('question')}>Ask a Question</MenuItem>
                <MenuItem value="dataStory" onClick={() => navigateToPage('dataStory')}>Add a Data Story</MenuItem>
              </Select>
            </span>
          </Grid>

          <Hidden smDown>
            {scheduledStories && showConnect && <Grid item md={2}>
              <Link to="/google-analytics" >Connect with GA</Link>
            </Grid>}
            <Grid item md={3} className="subheader-search">
              <Input
                id="search"
                placeholder="Search functionality coming soon"
                fullWidth
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end" disablePointerEvents>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
          </Hidden>
        </Container>
      </div>

      {/* Add a question Modal */}
      <Dialog className="question-modal" onClose={handleCloseQuestionModal} open={openQuestionModal} maxWidth="md" aria-describedby="Add a question modal" aria-labelledby="Add a question modal">
        <QuestionProvider>
          <Question handleClose={handleCloseQuestionModal} updateFeed={() => updateFeed(selectedFilter, setFeed)} questionData={editQuestion} />
        </QuestionProvider>

        <span onClick={handleCloseQuestionModal}>
          <img src={CloseWhiteIcon} className="question-modal-close" alt="" />
        </span>
      </Dialog>

      <Container className="dashboard-container-content">
        <Grid container>
          {/* content menu */}
          <Hidden mdUp>
            <Grid item md={12} sm={12} xs={12} className="feed-sidemenu">
              <div className="sidemenu-item">
                <div className="text-icon">
                  <svg id="Filter" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19.898">
                    <line className="icon-stroke" x2="17.996" transform="translate(0.504 19.398)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <g id="Grupo_287" data-name="Grupo 287" transform="translate(-345.961 -286.65)">
                      <path id="Caminho_805" data-name="Caminho 805" d="M357.246,286.932a2.065,2.065,0,0,0-2.824.741l-.736,1.259,3.565,2.083.736-1.259A2.065,2.065,0,0,0,357.246,286.932Z" />
                      <path id="Caminho_806" data-name="Caminho 806" d="M346.146,301.839a1.086,1.086,0,0,0-.148.536l-.037,3.741a.066.066,0,0,0,.1.058l3.251-1.86a1.1,1.1,0,0,0,.4-.4l7.015-12.009-3.565-2.082Zm7.222-9.279a.5.5,0,1,1,.863.5l-4.921,8.425a.5.5,0,0,1-.864-.505Z" />
                    </g>
                  </svg>
                  Filter
                </div>
              </div>

              <MultiSelect
                options={options}
                value={selectedFilter}
                onChange={setSelectedFilter}
                labelledBy="Sort by"
                hasSelectAll={false}
                disableSearch={true}
                className="sidemenu-item-filter"
                overrideStrings={{
                  selectSomeItems: "Filter by",
                }}
              />

            </Grid>
          </Hidden>

          {/* main content */}
          <Grid item md={9} sm={12} xs={12}>
            {context.state.type === "ANALYST" &&
              <StoryQuotes />}

            {
              feed.map((feedItem, index) => {
                if (feedItem.__typename === "DataStory") {
                  let kpis = feedItem.dataStoryKpis!.map(kpi => capitalize(kpi.kpi.toLowerCase()));

                  return (
                    <StoryItemProvider
                      key={feedItem.id}
                      likedByCurrentUser={feedItem.likedByCurrentUser!}
                      likedByCurrentUserDecision={feedItem.decision && feedItem.decision.likedByCurrentUser}
                      likedByCurrentUserOutcome={feedItem.outcome && feedItem.outcome.likedByCurrentUser}
                      likesCount={feedItem.likesCount!}
                      likesCountDecision={feedItem.decision && feedItem.decision.likesCount!}
                      likesCountOutcome={feedItem.outcome && feedItem.outcome.likesCount!}
                    >
                      <DialogContextProvider
                        id={feedItem.id}
                        key={feedItem.id}
                        decision={feedItem.decision}
                        outcome={feedItem.outcome}
                        updateFeed={() => updateFeed(selectedFilter, setFeed)}
                      >
                        <div style={{ margin: "0 auto 4em" }}>
                          {
                            <StoryItem
                              story={{
                                ...feedItem,
                                userId: context.state.id,
                                tags: kpis,
                                date: new Date(feedItem.publishedDate),
                                storyRemoved: () => removeFromFeed(index),
                                isFirst: index === 0
                              }}
                              preview={false}
                            />
                          }
                        </div>
                      </DialogContextProvider>
                    </StoryItemProvider>
                  );
                }

                if (feedItem.__typename === "Question") {
                  let kpis = feedItem.questionKpis!.map(kpi => capitalize(kpi.kpi.toLowerCase()));
                  return (
                    <QuestionItemProvider key={feedItem.id}>
                      <DialogContextProvider id={feedItem.id} key={feedItem.id}>
                        <QuestionItem
                          id={feedItem.id}
                          author={feedItem.user!.fullName}
                          jobTitle={feedItem.user!.jobTitle!}
                          department={feedItem.user!.department!}
                          title={feedItem.text!}
                          image={feedItem.image!}
                          extraContent={feedItem.extraContent!}
                          answers={[]}
                          date={new Date(feedItem.publishedDate)}
                          isFirst={index === 0}
                          profileUrl={feedItem.user!.profileUrl!}
                          tags={kpis}
                          key={feedItem.id}
                          commentAnswerId={feedItem.commentAnswerId!}
                          commentAnswer={feedItem.commentAnswer as any}
                          likesCount={feedItem.likesCount!}
                          commentsCount={feedItem.commentsCount!}
                          peopleCommentsCount={feedItem.peopleCommentsCount!}
                          likedByCurrentUser={feedItem.likedByCurrentUser!}
                          answerSelectedBy={feedItem.answerSelectedBy!}
                          questionRemoved={() => removeFromFeed(index)}
                          openEditModal={(callback: CallableFunction) => {
                            setEditQuestion({
                              text: feedItem.text!,
                              extra: feedItem.extraContent!,
                              image: feedItem.image!,
                              kpis: kpis,
                              id: feedItem.id!
                            })
                            handleOpenQuestionModal(callback)
                          }}
                        />
                      </DialogContextProvider>
                    </QuestionItemProvider>
                  );
                }

                return <></>;
              })
            }

          </Grid>

          {/* content menu */}
          <Hidden smDown>
            <Grid item md={3} className="feed-sidemenu">
              {/* <div className="sidemenu-item border">
              <div className="text-icon">
                <img src={NotificationIcon} alt="" className="icon" />
                Notifications
              </div>
              <span className="text-link">
                View
              </span>
            </div>
            <div className="sidemenu-item border">
              <div className="text-icon">
                <img src={SavedIcon} alt="" className="icon" />
                Saved
              </div>
              <span className="text-link">
                View
              </span>
            </div> */}
              <div className="sidemenu-item">
                <div className="text-icon">
                  <svg id="Filter" className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19.898">
                    <line className="icon-stroke" x2="17.996" transform="translate(0.504 19.398)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <g id="Grupo_287" data-name="Grupo 287" transform="translate(-345.961 -286.65)">
                      <path id="Caminho_805" data-name="Caminho 805" d="M357.246,286.932a2.065,2.065,0,0,0-2.824.741l-.736,1.259,3.565,2.083.736-1.259A2.065,2.065,0,0,0,357.246,286.932Z" />
                      <path id="Caminho_806" data-name="Caminho 806" d="M346.146,301.839a1.086,1.086,0,0,0-.148.536l-.037,3.741a.066.066,0,0,0,.1.058l3.251-1.86a1.1,1.1,0,0,0,.4-.4l7.015-12.009-3.565-2.082Zm7.222-9.279a.5.5,0,1,1,.863.5l-4.921,8.425a.5.5,0,0,1-.864-.505Z" />
                    </g>
                  </svg>
                  Filter
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <DateRangePicker
                  tileDisabled={tileDisabled}
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date"
                  onChange={(value: any) => {
                    console.log(value)
                    onDateValueChange(value)
                  }}
                  value={dateValue}
                  yearAriaLabel="Year"
                />
              </div>

              <MultiSelect
                options={options}
                value={selectedFilter}
                onChange={setSelectedFilter}
                labelledBy="Sort by"
                hasSelectAll={false}
                disableSearch={true}
                className="sidemenu-item-filter"
                overrideStrings={{
                  selectSomeItems: "Filter by",
                }}
              />

            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  )

  function tileDisabled(parameters: any): boolean {
    return parameters.date.getDay() !== 1;
  }
}

export default AppInsights(Dashboard);