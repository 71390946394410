
import { toast } from "react-toastify";
import DataStoryService from "../../v1/DataStoryService";
import { DataStoryProps } from "../DataStoryProvider";

export const saveDraft = async (state: any): Promise<void> => {
  const response = await DataStoryService.saveStory({
    id: state.id,
    addressedUserId: state.addressedUserId || 0,
    taggedUsersIds: state.tagged.map((user: any) => user.id),
    kpis: state.kpis,
    title: state.title,
    pivotState: state.selectedData,
    storyContent: {
      insight: state.insight,
      anomalyReason: state.anomalyReason,
      impactExplanation: state.impactExplanation,
      suggestions: state.suggestions
    }
  });

  if (response.status !== 200) {
    toast.error("Error while saving draft.");
    return;
  }
  toast.success("Data Story saved as Draft!");
}

export const postStory = async (id: string, context: DataStoryProps, history: any): Promise<void> => {
  let pivotState = JSON.stringify(null);//JSON.stringify(state.pivotTable);

  const storyResponse = await DataStoryService.saveStory({
    id: context.state.id,
    addressedUserId: context.state.draftStory.addressedUserId,
    taggedUsersIds: context.state.draftStory.tagged.map((user: any) => user.id),
    kpis: [context.state.context.kpi],
    title: context.state.draftStory.title,
    pivotState: pivotState,
    storyContent: {
      insight: context.state.draftStory.insight,
      anomalyReason: context.state.draftStory.anomalyReason,
      impactExplanation: context.state.draftStory.impactExplanation,
      suggestions: context.state.draftStory.suggestions
    }
  });

  if (storyResponse.status !== 200) {
    toast.error("Error while posting.");
    return;
  }

  const response = await DataStoryService.publishStory(id);

  if (response.status !== 200) {
    toast.error("Error while posting.");
    return;
  }

  toast.success("Data Story posted successfully!");
  setTimeout(() => {
    history.push("/dashboard");
  }, 100);
}