interface SideMenuItemProps {
    text: any;
    action: CallableFunction;
}

const SideMenuItem: React.FC<SideMenuItemProps> = (props) => {
    return (
        <div className="sidemenu-list-item" onClick={() => props.action() }>
            { props.text }
        </div>
    );
}

export default SideMenuItem;