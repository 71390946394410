import React from 'react';
import { ContextStep } from "./Context/ContextStep";
import { PreviewStep } from './Preview/PreviewStep';
import { DataStoryState } from './DataStoryContracts';
import { DraftNarrativeStep } from './DraftNarrative/DraftNarrativeStep';
import { AddVisualStep } from './AddVisual/AddVisualStep';
export class Step {
  component: JSX.Element;
  step: string;
  stepListName: string;
  help: JSX.Element;

  constructor(component: JSX.Element, step: string, stepListName: string, help: JSX.Element) {
    this.component = component;
    this.step = step;
    this.stepListName = stepListName;
    this.help = help;
  }
}

export class StepBuilder {
  _component: JSX.Element;
  _step: string;
  _stepListName: string;
  _help: JSX.Element;

  constructor() {
    this._component = <></>;
    this._step = "";
    this._stepListName = "";
    this._help = <></>;
  }

  component(component: JSX.Element) {
    this._component = component;
    return this;
  }

  step(step: string) {
    this._step = step;
    return this;
  }

  stepListName(stepListName: string) {
    this._stepListName = stepListName;
    return this;
  }

  help(help: JSX.Element) {
    this._help = help;
    return this;
  }

  build(): Step {
    return new Step(
      this._component,
      this._step,
      this._stepListName,
      this._help
    );
  }
}

export const getSteps = (state: DataStoryState, setState: CallableFunction, freedom: boolean = false) => {
  // if (freedom) {
  //   return {
  //     1: ContextStep(),
  //     2: DataSourceStep(),
  //     3: PickGrapStep(state, setState),
  //     4: YourStoryStep(state),
  //     '5a': WizardStep(state),
  //     '5b': StoryStep(state),
  //     6: PreviewStep(),
  //   }
  // }
  // return {
  //   1: ContextStep(),
  //   2: DataSourceStep(),
  //   3: CheckDataStep(),
  //   4: PickGrapStep(state, setState),
  //   5: YourStoryStep(state),
  //   '5a': WizardStep(state),
  //   '5b': StoryStep(state),
  //   6: PreviewStep(),
  // }
  return {
    1: ContextStep(),
    2: DraftNarrativeStep(),
    3: AddVisualStep(),
    4: PreviewStep(),
  }
}