import React, { useEffect } from "react";
import "./Focused.scss";

import { Container, Dialog, Grid, Input, InputAdornment, MenuItem, Select } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import Header from "../../components/Header/Header";
import { DialogContextProvider } from "../Dashboard/Feed/QuestionItem/DialogProvider";
import QuestionItem from "../Dashboard/Feed/QuestionItem/QuestionItem";
import { QuestionItemProvider } from "../Dashboard/Feed/QuestionItem/QuestionItemProvider";

import { default as QuestionComponent } from '../Dashboard/Question/Question';
import CloseWhiteIcon from '../../assets/images/close-white-icon.svg';

import { QuestionProvider } from "../Dashboard/Question/QuestionProvider";
import { FeedItem } from "../Dashboard/Dashboard";
import QuestionService from "../Dashboard/Feed/QuestionItem/QuestionService";

type Params = {
    id: string;
}

const Question: React.FC = () => {
    // noinspection DuplicatedCode
    const [openQuestionModal, setOpenQuestionModal] = React.useState(false);
    const [feedItem, setFeedItem] = React.useState<FeedItem | null>(null)
    const [kpis, setKpis] = React.useState<string[]>([])
    let { id }: Params  = useParams();

    const history = useHistory();

    const handleOpenQuestionModal = () => {
        setOpenQuestionModal(true);
    };
    
    const handleCloseQuestionModal = () => {
        setOpenQuestionModal(false);
    };
        
    const navigateToPage = (value: string) => {
        if (value === "dataStory") {
          history.push("/data-story")
        } else if (value === "question") {
          handleOpenQuestionModal();
        }
    }

    const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

    useEffect(() => {
        QuestionService.refreshQuestion(id).then(question => {
            setKpis(question.questionKpis!.map((kpi: any) => capitalize(kpi.kpi.toLowerCase())));
            setFeedItem(question);
        });
    /* eslint-disable */
    }, []);
    /* eslint-enable */

    return (
        <div className="focused-container">
            <Header />

            <div className="feed-subheader">
                <Container>
                    <Grid item md={9} className="subheader-title">
                        <span className="subheader-title-content">
                        Have a question or something to share ?&nbsp;
                        <Select
                            id="feed-select"
                            defaultValue="question"
                            className="subheader-title-select"
                            onChange={(e) => null}
                        >
                            <MenuItem value="question" onClick={(e) => navigateToPage('question')}>Ask a Question</MenuItem>
                            <MenuItem value="dataStory" onClick={(e) => navigateToPage('dataStory')}>Add a Data Story</MenuItem>
                        </Select>
                        </span>
                    </Grid>
                    <Grid item md={3} className="subheader-search" style={{visibility: "hidden"}}>
                        <Input
                        id="search"
                        placeholder="Search functionality coming soon"
                        fullWidth
                        autoComplete="off"
                        endAdornment={
                            <InputAdornment position="end" disablePointerEvents>
                                {/* <SearchIcon /> */}
                            </InputAdornment>
                        }
                        />
                    </Grid>
                </Container>
            </div>

            <Dialog className="question-modal" onClose={handleCloseQuestionModal} open={openQuestionModal} maxWidth="md" aria-describedby="Add a question modal" aria-labelledby="Add a question modal">
                <QuestionProvider>
                    <QuestionComponent handleClose={handleCloseQuestionModal} updateFeed={() => null} />
                </QuestionProvider>

                <span onClick={handleCloseQuestionModal}>
                <img src={CloseWhiteIcon} className="question-modal-close" alt="" />
                </span>
            </Dialog>

            <Container className="focused-container-content">
                <Grid container>
                    <Grid item md={9}>
                        {feedItem && (
                            <QuestionItemProvider key={feedItem.id}>
                                <DialogContextProvider id={feedItem.id} key={feedItem.id}>
                                    <QuestionItem
                                        id={feedItem.id}
                                        author={feedItem.user!.fullName}
                                        jobTitle={feedItem.user!.jobTitle!}
                                        department={feedItem.user!.department!}
                                        title={feedItem.text!}
                                        image={feedItem.image!}
                                        extraContent={feedItem.extraContent!}
                                        answers={[]}
                                        date={new Date(feedItem.publishedDate)}
                                        isFirst={true}
                                        profileUrl={feedItem.user!.profileUrl!}
                                        tags={kpis}
                                        key={feedItem.id}
                                        commentAnswerId={feedItem.commentAnswerId!}
                                        commentAnswer={feedItem.commentAnswer as any}
                                        likesCount={feedItem.likesCount!}
                                        commentsCount={feedItem.commentsCount!}
                                        peopleCommentsCount={feedItem.peopleCommentsCount!}
                                        likedByCurrentUser={feedItem.likedByCurrentUser!}
                                        answerSelectedBy={feedItem.answerSelectedBy!}
                                        isOpen
                                        questionRemoved={() => null}
                                    />
                                </DialogContextProvider>
                            </QuestionItemProvider>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Question;