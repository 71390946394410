import React from "react";

import HelperContainer from "../HelperContainer";

import { DataStoryContext, DataStoryProps } from "../../DataStoryProvider";
import { Grid } from "@material-ui/core";

const StoryHelper = () => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);

    return (
        <>
            <Grid container style={{padding: "25px 10px 5px 20px"}}>
                <Grid item md={2} style={{fontWeight: 300, fontSize: "20px"}}>
                    <span>Context:</span>
                </Grid>
                <Grid item md={2}>
                    <span style={{color: "#bebebe"}}>KPI</span>
                    <button className="btn-outlined-wipe" style={{marginBottom: "10px", marginLeft: "15px"}}>{ context.state.context.kpi}</button>
                </Grid>
                <Grid item md={4}>
                    <span style={{color: "#bebebe"}}>Target metric</span><button className="btn-outlined-wipe" style={{marginBottom: "10px", marginLeft: "15px"}}>{context.state.context.targetMetric}</button>
                </Grid>
                <Grid item md={4}>
                    <span style={{color: "#bebebe"}}>Target value</span><button className="btn-outlined-wipe" style={{marginBottom: "10px", marginLeft: "15px"}}>{context.state.context.targetValue}</button>
                </Grid>
            </Grid>

            <HelperContainer showHelpText={context.state.showQuestions} 
                toggleHelpText={() => context.setState({...context.state, showQuestions: !context.state.showQuestions })}
                question={"What are the questions they often ask?"}
                helpText={
                    context.state.context.questions
                }
            />
            <HelperContainer showHelpText={context.state.showActions} 
                toggleHelpText={() => context.setState({...context.state, showActions: !context.state.showActions })}
                question={"What are typical actions taken to improve acquisition?"}
                helpText={
                    context.state.context.actions
                }
            />
        </>
    )
}

export default StoryHelper;