import React from 'react';
import { UserPreferences } from '../Onboarding';
import '../Onboarding.scss';
import UserStoryGenerator from '../UserStoryGenerator';

interface OnboardinSummaryState {
  userPreferences: UserPreferences;
  name: string;
  email: string;
  kpis: string[];
}

const FourthStep: React.FC<OnboardinSummaryState> = (props) => {
  const boldedIntentionList = props.kpis.map(x => (<span className="highlight">{x}</span>));

  return (
    <div className="onboarding-step-container">
      <h1 className="onboarding-step-title">
        So this is your story:
      </h1>

      <h2 className="onboarding-step-story">
        My name is <span className="highlight">{props.name}</span>, I work in <span className="highlight">{props.userPreferences.department}</span>
        {props.userPreferences.jobTitle === '' ? '' :
          <>
            &nbsp;as a <span className="highlight">{props.userPreferences.jobTitle}</span>
          </>
        }. You can contact me at <span className="highlight">{props.email}</span>.
        Some of the KPIs I’m interested in are {UserStoryGenerator.grammarJoinElements(boldedIntentionList)}.
      </h2>
    </div>
  );
}

export default FourthStep;