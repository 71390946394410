import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AppInsights, { loadAppContext } from "../../components/AppInsights";
import { UserRegisterStatus } from "../DataStory/v1/DataStoryContracts";
import EmailConfirmationService from "./EmailConfirmationService";

export interface UserRegisterResponse{
    userRegisterStatus?: UserRegisterStatus
}

const EmailConfirmation: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        loadAppContext(location);
        (async function() {
            await confirmEmail();
        })();
    /* eslint-disable */
    }, []);
    /* eslint-enable */

    async function confirmEmail() {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email') ?? '';
        const token = params.get('token') ?? '';
        const trial = params.get('trial') ? "trial=enabled" : '';

        if (email.length === 0 || token.length === 0) {
            history.push('/');
            return;
        }

        const response = await EmailConfirmationService.confirm(email, token);
        if (response.status !== 200) {
            toast.error("Something went wrong while confirming your e-mail")
            history.push('/');
            return;
        }

        var responseBody: UserRegisterResponse = await response.json();

        history.push(`/?${trial}`, { email: email, emailConfirmation: true, resetPassword: false, userRegisterStatus: responseBody.userRegisterStatus });
    }

    return <div></div>;
}

export default AppInsights(EmailConfirmation);