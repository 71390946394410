import React, { useState } from "react";
import Header from "../../../../../components/Header/Header";
import { OnboardingContext, OnboardingContextProps } from "../../OnboardingProvider";
import { useHistory } from "react-router";
import LoginService from "../../../../Login/LoginService";
import { toast } from "react-toastify";
import { CircularProgress, Grid } from "@material-ui/core";
import { StoryItemProvider } from "../../../../Dashboard/Feed/StoryItem/StoryItemProvider";
import { DialogContextProvider } from "../../../../Dashboard/Feed/QuestionItem/DialogProvider";
import StoryItem from "../../../../Dashboard/Feed/StoryItem/StoryItem";
import "./TargetsPreview.scss";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const averageDifference = (current: number, target: number) => {
    current = current === 0 ? 1 : current;
    const percentage: number = +((100 * target) / current).toFixed(2);
    const removingPercentage = percentage === 0 ? 0 : 100;
    return (percentage - removingPercentage).toFixed(2);
}

const MD_CARRIAGE_RETURN = "\n\n\\\n";


export const storyText = (context: OnboardingContextProps) => {
    const CURRENT_YEAR = new Date().getFullYear();

    const GBPFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const revenueText1 = () => {
        if(revenuePercentage === 0) {
            return "";
        }
        if(revenuePercentage < 0 && context.state.revenue === 0) {
            return "a decrease of over 100%.";
        }
        if(revenuePercentage < 0 && context.state.revenue !== 0) {
            return `a decrease of ${Math.round(revenuePercentage) * -1}%.`;
        }
        if(revenuePercentage > 0 && context.state.revenue === 0) {
            return "a growth of over 100%.";
        }
        if(revenuePercentage > 0 && context.state.revenue !== 0) {
            if(revenuePercentage > benchmarkRevenueGrowth) {
                return `a significant growth of ${Math.round(revenuePercentage)}%.`;
            }
            return `a growth of ${Math.round(revenuePercentage)}%.`;
        }
        return "";
    }

    const percentageGrowthInRevenue = (targetRevenue: number, revenue: number) => {
        return ((targetRevenue / revenue) - 1) * 100;
    }

    const revenueText2 = () => {
        if(revenuePercentage === 0 || isNaN(revenuePercentage)) {
            return `most other online stores expect revenue to increase by ${benchmarkRevenueGrowth.toFixed(0)}% this year, it would be worth considering entering some targets so that you can build and underpin the strength of your brand.`
        }
        if (revenuePercentage < 0) {
            return ` Your company's ambitions run counter to those of most eCommerce companies. The majority of eCommerce companies expect their revenues to grow by at least ${benchmarkRevenueGrowth.toFixed(0)}%.`;
        }
        if(revenuePercentage > 0 && context.state.revenue === 0) {
            return ` This is extremely positive and we will help understand how to hit that target.  In your category, most other online stores expect revenue to increase by ${benchmarkRevenueGrowth.toFixed(0)}% this year, so we will use this year to gauge your growth.`
        }
        if(revenuePercentage > 0 && context.state.revenue !== 0) {
            if(revenuePercentage > benchmarkRevenueGrowth) {
                return ` This is extremely positive! At this growth rate, you will underpin the strength of your brand and most likely surpass your competitors, whose average growth rate is expected to be only ${benchmarkRevenueGrowth.toFixed(0)}%.`;
            }
            return `Although this is positive! The growth rate at which you are targeting to grow will make it difficult for you to establish the strength of your brand, especially since most of your competitors will grow faster than you at an expected rate of ${benchmarkRevenueGrowth.toFixed(0)}%.`;
        }
        if (revenuePercentage > benchmarkRevenueGrowth) {
            return " This is extremely positive! At this growth rate, you will underpin the strength of your brand and most likely surpass your competitors, whose average growth rate is expected to be only";
        }
        return "Although this is positive! The growth rate at which you are targeting to grow will make it difficult for you to establish the strength of your brand, especially since most of your competitors will grow faster than you at an expected rate of";
    }

    const aovText = () => {
        if(aovPercentage === 0 || isNaN(aovPercentage)) {
            if(revenuePercentage === 0) {
                return `continue as ${GBPFormatter.format(0)}`;
            }
            return `continue as ${GBPFormatter.format(context.state.targetAverageOrderValue)}`;
        }
        if(aovPercentage < 0 && context.state.averageOrderValue === 0) {
            return `decrease to ${GBPFormatter.format(context.state.targetAverageOrderValue)}`;
        }
        if(aovPercentage < 0 && context.state.averageOrderValue !== 0) {
            return `decrease by ${Math.round(aovPercentage) * -1}% to ${GBPFormatter.format(context.state.targetAverageOrderValue)}`;
        }
        if(aovPercentage > 0 && context.state.averageOrderValue === 0) {
            return `grow to ${GBPFormatter.format(context.state.targetAverageOrderValue)}`;
        }
        if(aovPercentage > 0 && context.state.averageOrderValue !== 0) {
            return `grow by ${Math.round(aovPercentage)}% to ${GBPFormatter.format(context.state.targetAverageOrderValue)}`
        }
        return "";
    }

    const conversionText = () => {
        if (conversionPercentage > 0 && trafficPercentage > 0) {
            return "";
        }
        else if (conversionPercentage > 0)
            return "increase ";

        return "decrease"
    }

    const percentageGrowInTraffic = (targetTraffic: number, traffic: number) => {
        return ((targetTraffic / traffic) - 1) * 100;
    }

    const percentageGrowthInConversion = (targetConversion: number, conversion: number) => {
        return ((targetConversion / conversion) - 1) * 100;
    }

    const percentageGrowthInAOV = (targetAOV: number, AOV: number) => {
        return ((targetAOV / AOV) - 1) * 100;
    }

    const revenuePercentage = percentageGrowthInRevenue(context.state.targetRevenue, context.state.revenue);
    const conversion = Number(context.state.conversion.toFixed(2));
    const targetConversion = context.state.targetConversion

    const trafficPercentage = percentageGrowInTraffic(context.state.targetTraffic, context.state.traffic === 0 ? 1 : context.state.traffic);
    const conversionPercentage = percentageGrowthInConversion(targetConversion, conversion === 0 ? 1 : conversion);
    const aovPercentage = percentageGrowthInAOV(context.state.targetAverageOrderValue, context.state.averageOrderValue);

    const benchmarkRevenueGrowth = 12;

    const growthTraffic = context.state.targetTraffic - context.state.traffic;
    const growthConversion = targetConversion - conversion;
    const targetConversionPercentage = 1.8;

    const conversionWillHaveTo = () => {
        if(Number(growthConversion.toFixed(2)) === 0) {
            if(conversion === Number(targetConversion.toFixed(2))) {
                return `remain as 0%`;
            }
            return `remain as ${Math.round(targetConversion)}%`;
        }
        if(growthConversion < 0 && conversion === 0) {
            return `go down from ${conversion.toFixed(2)}% to ${targetConversion.toFixed(2)}%`;
        }
        if(growthConversion < 0 && conversion !== 0) {
            return `go down by ${Math.round(conversionPercentage) * -1}% from ${conversion.toFixed(2)}% to ${targetConversion.toFixed(2)}%`;
        }
        if(growthConversion > 0 && conversion === 0) {
            return `go up from ${conversion.toFixed(2)}% to ${targetConversion.toFixed(2)}%`;
        }
        if(growthConversion > 0 && conversion !== 0) {
            return `go up by ${Math.round(conversionPercentage)}% from ${conversion.toFixed(2)}% to ${targetConversion.toFixed(2)}%`;
        }
        return "";
    }

    const toMeetYourGoals = () => {
        if(growthTraffic === 0) {
            return " keep traffic the same with no additional sessions, ";
        }
        if(growthTraffic < 0 && context.state.traffic === 0) {
            return ` decrease by ${growthTraffic.toLocaleString()} sessions, `;
        }
        if(growthTraffic < 0 && context.state.traffic !== 0) {
            return ` decrease by ${(Math.round(trafficPercentage) * -1).toLocaleString()}% (or ${(growthTraffic * -1).toLocaleString()} sessions), `;
        }
        if(growthTraffic > 0 && context.state.traffic === 0) {
            return ` increase by ${(growthTraffic).toLocaleString()} sessions, `;
        }
        if(growthTraffic > 0 && context.state.traffic !== 0) {
            return ` increase by ${Math.round(trafficPercentage).toLocaleString()}% (or ${(growthTraffic).toLocaleString()} sessions), `;
        }
        return "";
    }

    const dataStoriesOnDecidableWill = () => {
        if(context.state.targetRevenue === 0 || revenuePercentage === 0) {
            return "Data stories on Decidable will help you find the most significant hidden opportunities to help you grow your business, it is however much more helpful if you have some growth targets.";
        }
        return "Data stories on Decidable will help you find the most significant hidden opportunities to help meet these targets.";
    }

    const revenueShrunkBoost = () => {
        if(revenuePercentage < 0) {
            return "shrunk";
        }
        if(revenuePercentage > 0) {
            return "boosted";
        }
        return "no increase in";
    }

    return _storyText({
        currentYear: CURRENT_YEAR.toString(),
        revenueShrunkBoosted: revenueShrunkBoost(),
        revenue: GBPFormatter.format(revenuePercentage === 0 ? 0: context.state.revenue),
        targetRevenue: GBPFormatter.format(revenuePercentage === 0 ? 0: context.state.targetRevenue),
        revenueTexts: [
            revenueText1(),
            revenueText2(),
        ],
        benchmarkRevenueGrowth: benchmarkRevenueGrowth.toFixed(0),
        trafficIncreaseDecrease: trafficPercentage > 0 ? "increase" : "decrease",
        trafficPercentage: trafficPercentage.toFixed(0),
        growthTraffic: growthTraffic.toString(),
        conversionText: conversionText(),
        growthConversion: growthConversion.toFixed(2),
        targetConversionPercentage: targetConversionPercentage.toFixed(0),
        toMeetYourGoals: toMeetYourGoals(),
        AOVText: aovText(),
        AOVPercentage: aovPercentage.toFixed(0),
        targetAOV: GBPFormatter.format(context.state.targetAverageOrderValue),
        revenuePercentage: revenuePercentage.toFixed(0),
        conversionPercentage: conversionPercentage.toFixed(0),
        conversionWillHaveTo: conversionWillHaveTo(),
        datastoriesOnDecidableWill: dataStoriesOnDecidableWill()
    });
}

interface StoryParameters {
    currentYear: string;
    revenueShrunkBoosted: string;
    revenue: string;
    targetRevenue: string;
    revenueTexts: string[];
    revenuePercentage: string;
    benchmarkRevenueGrowth: string;
    trafficIncreaseDecrease: string;
    trafficPercentage: string;
    growthTraffic: string;
    conversionText: string;
    growthConversion: string;
    conversionPercentage: string;
    targetConversionPercentage: string;
    AOVText: string;
    AOVPercentage: string;
    targetAOV: string;
    conversionWillHaveTo: string;
    toMeetYourGoals: string;
    datastoriesOnDecidableWill: string;
}

const _storyText = (params: StoryParameters) => {
    return `By the end of ${params.currentYear} your business is looking to have ${params.revenueShrunkBoosted} its revenues from ${params.revenue} to ${params.targetRevenue}, ${params.revenueTexts[0]}`+
        `${params.revenueTexts[1]}${MD_CARRIAGE_RETURN}To meet your goals, your traffic will need to${params.toMeetYourGoals}` +
        `the conversion rate will have to ${params.conversionWillHaveTo} and AOV will need to ${params.AOVText}. ` +
        `${params.datastoriesOnDecidableWill}`;
}

const TargetsPreview = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);
    const history = useHistory();
    const [saving, setSaving] = useState(false);

    const publishTargetsStory = () => {
        const url = `${BASE_URL}/story/publish-as-decidable`
        const token = LoginService.getToken();

        setSaving(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title: getTitle(),
                addressedUserId: null,
                taggedUsersIds: null,
                kpis: [
                    "Engagement", "Acquisition", "Conversion"
                ],
                storyContent: {
                    insight: generateStory()
                },
                hasDownloadableData: true
            })
        }).then(response => {
            if (response.status === 200) {
                history.push("/");
                return;
            }
            setSaving(false);
            toast.error("Error while setting targets.");
        }).catch(() => {
            setSaving(false);
            toast.error("Error while setting targets.");
        });

    };

    const setupTargets = () => {
        const url = `${BASE_URL}/api/google-analytics/targets/setup`
        const token = LoginService.getToken();
        setSaving(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                targetTraffic: +context.state.targetTraffic,
                targetConversion: +context.state.targetConversion,
                targetAverageOrderValue: +context.state.targetAverageOrderValue,
                targetRevenue: +context.state.targetRevenue,
                viewId: context.state.viewId
            })
        }).then(response => {

            if (response.status === 200) {
                publishTargetsStory();
                return;
            }
            setSaving(false);
            toast.error("Error while setting targets.");
        }).catch(() => {
            setSaving(false);
            toast.error("Error while setting targets.");
        });


    }

    const getTitle = () => {
        const CURRENT_YEAR = new Date().getFullYear();
        return "eCommerce targets " + CURRENT_YEAR;
    };
    const generateStory = () => {
        return `${storyTable()} ${storyText(context)}`
    }

    const GBPFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const storyTable = () => {
        const CURRENT_YEAR: any = new Date().getFullYear();
        return [
            row(["KPI", `Last year (${CURRENT_YEAR - 1})`, `Current year (${CURRENT_YEAR})`], true),
            row(["Traffic (total sessions)", context.state.traffic.toLocaleString(), Math.round(context.state.targetTraffic).toLocaleString()]),
            row(["Average Conversion Rate", `${context.state.conversion.toFixed(2)}%`, `${context.state.targetConversion.toFixed(2)}%`]),
            row(["Average Order Value", GBPFormatter.format(context.state.averageOrderValue), GBPFormatter.format(context.state.targetAverageOrderValue)]),
            row(["Revenue", GBPFormatter.format(context.state.revenue), GBPFormatter.format(context.state.targetRevenue)]),
        ].join("") + MD_CARRIAGE_RETURN
    }

    const cell = (value: string | number) => `| ${value} `;
    const row = (values: string[], isHeader: boolean = false) => values.reduce((previous, next) => previous + `${cell(next)}`, "") + (isHeader ? "|\n|----|----|----|\n" : "|\n")

    const feedItem = {
        id: '',
        author: {
            profileUrl: "https://app.decidable.com/profile-images/decidable.png",
            fullName: "Decidable Global",
            jobTitle: "Storyteller",
            department: "All"
        },
        chartImageUrl: "/data-story-icons/bars-chart.png",
        commentsCount: 0,
        title: "eCommerce targets 2022",
        insight: generateStory()

    }
    return (
        <div className="onboarding-container onboarding-target-container">
            <Header />
            <div className="onboarding-container-content">
                <div className="onboarding-step-container target-preview">
                    <h3 className="onboarding-step-first-text target-preview">
                        Here is your first story, you will get a sense of what Decidable is all about.
                    </h3>
                    <div className="target-preview-story">
                        <StoryItemProvider
                            key={feedItem.id}
                            likedByCurrentUser={false}
                            likedByCurrentUserDecision={false}
                            likedByCurrentUserOutcome={false}
                            likesCount={0}
                            likesCountDecision={0}
                            likesCountOutcome={0}
                        >
                            <DialogContextProvider
                                id={feedItem.id}
                                key={feedItem.id}
                                decision={null}
                                outcome={null}
                                updateFeed={() => { }}
                            >
                                <div style={{ margin: "0 auto 4em" }}>
                                    {
                                        <StoryItem
                                            story={{
                                                ...feedItem,
                                                userId: 0,
                                                tags: [],
                                                date: new Date(),
                                                storyRemoved: () => { },
                                                isFirst: false
                                            }}
                                            preview={true}
                                        />
                                    }
                                </div>
                            </DialogContextProvider>
                        </StoryItemProvider>
                    </div>

                    <Grid container style={{ marginTop: '40px' }}>
                        <Grid item md={9} />
                        <Grid item md={3}>
                            <div className="onboarding-content-footer">
                                <div className="onboarding-buttons">
                                    <button className="btn-contained-wipe" onClick={() => context.setState({ ...context.state, step: context.state.step - 1 })} style={{ marginRight: "10px" }}>
                                        Make changes
                                    </button>
                                    {saving && <CircularProgress style={{ color: 'white' }} />}
                                    {!saving &&
                                        <button className="btn-contained-wipe" onClick={() => setupTargets()}>
                                            Go to feed
                                        </button>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default TargetsPreview;