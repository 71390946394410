import { Button, Menu, MenuItem } from "@material-ui/core"
import React from "react"
import DotsMenu from '../../../assets/images/dotsMenu-icon.svg';
import { UserRegisterStatus } from "../../DataStory/v1/DataStoryContracts";
import { Row } from "./FreeTrial";
import { toast } from 'react-toastify';
import swal from "sweetalert";
import AdminFreeTrialService from "../AdminFreeTrialService";

interface OptionsProps {
    row: Row;
    updateRow(row: Row, status: UserRegisterStatus): void
    updateUsers: CallableFunction;
}

export const OptionsMenu: React.FC<OptionsProps> = (props) => {
    const [dotsMenu, setDotsMenu] = React.useState<null | HTMLElement>(null);

    const handleCloseMenu = () => {
        setDotsMenu(null);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDotsMenu(event.currentTarget);
    };

    const removeTrial = async (email: string) => {
        const shouldRemove = await swal('Are you sure you want to remove the trial for ', {
          buttons: ["Cancel", "Remove"]
        });
        
        if(!shouldRemove) return;
    
        const response = await AdminFreeTrialService.removeFreeTrial(email);
      
        if(response.status !== 200) {
            toast.error("Error while removing user from trial.");
            return;
        }
    
        props.updateUsers();

        toast.success(`User "${email}" removed from free trial.`);
    }

    return (
        <>
            <Button className="story-menu-button" onClick={handleOpenMenu}>
                <img src={DotsMenu} alt="" />
            </Button>
            <Menu
                anchorEl={dotsMenu}
                open={Boolean(dotsMenu)}
                onClose={handleCloseMenu}
                PopoverClasses={{ paper: 'popover-menu admin-users' }}
                elevation={1}
            >
                <MenuItem 
                    className="popover-menu-item deactivate" 
                    onClick={(e) => removeTrial(props.row.email)}
                >
                    Remove trial
                </MenuItem>    
            </Menu>
        </>
    )
}