import React, { Fragment } from 'react';
import './AdminMenu.scss';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import UserIcon from '../../assets/images/user-icon.svg';

interface AdminMenuProps {
  path: string;
}

const AdminMenu: React.FC<AdminMenuProps> = (props) => {
  const prepareCurrentCheck = (path: string) => {
    return (current: string) => current === path ? "current" : "";
  }
  const scheduledStories = process.env.REACT_APP_SCHEDULED_STORIES === "true";

  const isCurrent = prepareCurrentCheck(props.path);

  return (
    <Grid item md={2} className="admin-sidemenu">
      <h2 className="admin-sidemenu-list-title">Account & profile</h2>
      <nav>
        <ul className="admin-sidemenu-list">
          <li className="admin-sidemenu-item decidable-back">
            <Link to="/" className="back-link">
              <SubdirectoryArrowLeftIcon className="admin-sidemenu-item-icon" />
              Back to Decidable
            </Link>
          </li>
          <li className={`admin-sidemenu-item ${isCurrent("/company/users")}`}>
            <Link to="/company/users"  className={ isCurrent("/company/users").length > 0 ? "" : "normal-link"}>
              <img src={UserIcon} className="admin-sidemenu-item-icon" alt="" />
              Users
            </Link>
          </li>
        </ul>
      </nav>

      <h2 className="admin-sidemenu-list-title">Billing</h2>
      <nav>
        <ul className="admin-sidemenu-list">
          <li className={`admin-sidemenu-item ${isCurrent("/billing")}`}>
            <Link to="/billing" className={ isCurrent("/billing").length > 0 ? "" : "normal-link"}>
              <img src={UserIcon} className="admin-sidemenu-item-icon" alt="" />
              Overview
            </Link>
          </li>
        </ul>
      </nav>
      {scheduledStories && <Fragment>
        <h2 className="admin-sidemenu-list-title">Scheduled stories</h2>
        <nav>
          <ul className="billing-sidemenu-list">
            <li className={`billing-sidemenu-item ${isCurrent("/google-analytics")}`}>
              <Link to="/google-analytics" className={ isCurrent("/google-analytics").length > 0 ? "" : "normal-link"}>
                <img src={UserIcon} className="billing-sidemenu-item-icon" alt="" />
                Google analytics
              </Link>
            </li>
          </ul>
        </nav>
      </Fragment>}

    </Grid>
  )
}

export default AdminMenu;