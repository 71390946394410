import React from 'react';
import { StepBuilder, Step } from "../Step";
import DraftNarrative from "./DraftNarrative";

export const DraftNarrativeStep = (): Step => {
  return new StepBuilder()
    .component(<DraftNarrative />)
    .step("Draft your narrative story")
    .stepListName("Draft story")
    .help(
      <ul className="step-help-text">
        <li>People want answers, not workings out.</li>
        <li>So just give the data that tells the story.</li>
      </ul>)
    .build();
}