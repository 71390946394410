
import React from "react";

import "./Comments.scss";
import { UserContext, UserContextProps } from '../../../../../components/UserProvider';
import LikeButton, { LikeWhiteButton } from '../../../../../components/Likes/LikeButton';
import CommentService from "./CommentService";
import { PostType } from "./Comments";

interface CommentLikeProps {
  likes: any;
  userId: number;
  commentId: string;
  type: PostType;
  isWhite?: boolean;
  setLikes(likes: any): void;
}

const CommentLikes: React.FC<CommentLikeProps> = (props) => {
  const userContext = React.useContext<UserContextProps>(UserContext);

  const likedByCurrentUser = props.likes.filter((l: any) => l.user.id === props.userId).length > 0;

  const likeComment = async (commentId: string) => {

    let response = await CommentService.likeComment(commentId);

    if (response.status !== 200) {
      return;
    }

    if (!likedByCurrentUser) {
      const likes = [...props.likes, { user: { id: userContext.state.id, fullName: userContext.state.fullName } }];
      props.setLikes(likes);
      return;
    } else {
      props.setLikes([...props.likes.filter((l: any) => l.user.id !== userContext.state.id)]);
    }
  }
  return (
    <div className="comments-like-comment">
      {props.isWhite && props.isWhite ? (
        <LikeWhiteButton
          className="like-comment-button"
          onClick={() => likeComment(props.commentId)}
          liked={likedByCurrentUser}
        />
      ) : (
        <LikeButton
          className="like-comment-button"
          onClick={() => likeComment(props.commentId)}
          liked={likedByCurrentUser}
        />
      )}

      <div className="comments-likes-list">
        <span className="comments-likes-count" style={props.isWhite ? { color: "#fff" } : {}}>
          {props.likes.length}
        </span>
        {props.likes.length > 0 ? (
          <ul className="likes-list">
            {props.likes.map((_like: any) => <li key={_like.user.id}>{_like.user.fullName}</li>)}
          </ul>
        )
          : ''
        }
      </div>
    </div>
  )
}

export default CommentLikes;