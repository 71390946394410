import React from 'react';
import './Sidemenu.scss';
import SignoutIcon from '../../assets/images/signout-icon.svg';
import AddUsersIcon from '../../assets/images/addUsers-icon.svg';
import UserIcon from '../../assets/images/user-icon.svg';
import AdminIcon from '../../assets/images/admin-icon.svg';
import AnalystLibraryIcon from '../../assets/images/analyst-library-icon.svg';
import ContactIcon from '../../assets/images/contact-icon.svg';
import OnboardingIcon from '../../assets/images/dataStory-icon.svg';
import LoginService from '../../pages/Login/LoginService'
import { useHistory } from 'react-router';
import SideMenuItem from './SideMenuItem';
import { UserContextProps, UserContext} from '../UserProvider';

interface SidemenuProps {
  openMenu: boolean
}

const Sidemenu: React.FC<SidemenuProps> = (props) => {
  const history = useHistory();
  const context = React.useContext<UserContextProps>(UserContext);
  
  const freeTrialEmails: string[] = JSON.parse(process.env.REACT_APP_FREE_TRIAL_EMAILS!);
      
  const navigateToPage = (value: string) => {
    history.push(value)
  }

  const sendEmail = (email: string) => {
    window.location.href = 'mailto:' + email;
  }
  const scheduledStories = process.env.REACT_APP_SCHEDULED_STORIES === "true";

  return (
    <div className={props.openMenu ? 'sidemenu-container active' : 'sidemenu-container'}>
      <span className="sidemenu-text">Something to share?</span>
      <SideMenuItem
        action={() => navigateToPage('/user/profile')}
        text={(
          <>
            <img src={UserIcon} className="sidemenu-item-icon" alt="" />
            Profile
          </>
        )}
      />
      <SideMenuItem
        action={() => navigateToPage('/library/analyst')}
        text={(
          <>
            <img src={AnalystLibraryIcon} className="sidemenu-item-icon" alt="" />
            Analyst Library
          </>
        )}
      />
      <SideMenuItem
        action={() => navigateToPage('/company/users')}
        text={(
          <>
            <img src={AdminIcon} className="sidemenu-item-icon" alt="" />
            Organization Admin
          </>
        )}
      />
      {freeTrialEmails.includes(context.state.email) && (
        <SideMenuItem
          action={() => navigateToPage('/free-trial')}
          text={(
            <>
              <img src={AddUsersIcon} className="sidemenu-item-icon" alt="" />
              Free trial
            </>
          )}
        />
      )}
      {scheduledStories &&
        <SideMenuItem
          action={() => navigateToPage('/google-analytics')}
          text={(
            <>
              <img src={AdminIcon} className="sidemenu-item-icon" alt="" />
              Schedule Stories
            </>
          )}
        />}
        {scheduledStories &&
        <SideMenuItem
          action={() => navigateToPage('/v2/onboarding')}
          text={(
            <>
              <img src={OnboardingIcon} className="sidemenu-item-icon" alt="" />
              Onboarding Process
            </>
          )}
        />}
      {scheduledStories &&
        <SideMenuItem
          action={() => navigateToPage('/story-test')}
          text={(
            <>
              <img src={AdminIcon} className="sidemenu-item-icon" alt="" />
              Test stories
            </>
          )}
        />}
      <SideMenuItem
        action={() => navigateToPage('/add-users')}
        text={(
          <>
            <img src={AddUsersIcon} className="sidemenu-item-icon" alt="" />
            Invite new users
          </>
        )}
      />

      <SideMenuItem
        action={() => sendEmail('tellmemore@decidable.com')}
        text={(
          <>
            <img src={ContactIcon} className="sidemenu-item-icon" alt="" />
            Contact Us
          </>
        )}
      />

      <SideMenuItem
        action={() => LoginService.logout()}
        text={(
          <>
            <img src={SignoutIcon} className="sidemenu-item-icon" alt="" />
            Sign out
          </>
        )} />
    </div>
  )
}

export default Sidemenu;