import React from 'react';
import './Billing.scss';
import { useLocation } from "react-router-dom";
import Header from '../../components/Header/Header';
import { Container, Grid } from '@material-ui/core';
import { UserContext, UserContextProps } from '../../components/UserProvider';
import { useEffect } from 'react';
import { loadAppContext } from '../../components/AppInsights';
import BillingDetails from './BillingDetails/BillingDetails';
import AdminMenu from '../../components/AdminMenu/AdminMenu';

const Billing: React.FC = () => {
  const location = useLocation();
  const userContext = React.useContext<UserContextProps>(UserContext);

  useEffect(() => {
    loadAppContext(location, userContext);
    /* eslint-disable */
  }, [])
  /* eslint-enable */

  return (
    <div className="billing-container">
      <Header />

      <Container className="billing-container-content">
        <AdminMenu path={"/billing"} />

        <Grid item md={10} className="billing-selected-content">
          {
            location.state && (
              <div className="payment-status">
                <span>{(location.state as any).message}</span>
              </div>
            )
          }
          <BillingDetails />
        </Grid>
      </Container>
    </div>
  );
}

export default Billing;