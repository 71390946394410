import { Grid } from "@material-ui/core";
import React from "react";

interface ContextQuestionProps {
    text: string;
}

const ContextQuestion: React.FC<ContextQuestionProps> = (props) => {
    return (
        <>
            <Grid item md={9}>
                <ul>
                    <li>{props.text}</li>
                </ul> 
            </Grid>
        </>
    )
}

export default ContextQuestion;