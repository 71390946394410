import React from "react";
import Header from "../../../../../components/Header/Header";
import {OnboardingContext, OnboardingContextProps} from "../../OnboardingProvider";
import {Grid} from "@material-ui/core";

const FirstStep = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);

    return (
        <div className="onboarding-container">
            <Header />
            <div className="onboarding-container-content">
                <div className="onboarding-step-container">
                    <h1 className="onboarding-step-first-text">
                        Your data has a story to tell. We help generate that story, enable you to discuss it with your colleagues, and make real decisions with it.<br /><br />
                        Only a few clicks away.
                    </h1>


                    <Grid container style={{marginTop: '40px'}}>
                        <Grid item md={10} />
                        <Grid item md={2}>
                            <div className="onboarding-content-footer">
                                <div className="onboarding-buttons">
                                    <button className="btn-contained-wipe" onClick={() => context.setState({ ...context.state, step: 1 })}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default FirstStep;