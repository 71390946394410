import React from "react";
import './SecondStep.scss';
import { Button, MenuItem, Grid, Select } from "@material-ui/core";
import { MultiSelect } from "react-multi-select-component";
import QuestionService, { ActiveUser } from "../QuestionService";
import { QuestionContext, QuestionContextProps } from "../QuestionProvider";

interface SecondStepState {
  kpis: string[];
  taggedUsers: number[];
  selected: OptionUser[];
}

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

type OptionUser = Option & ActiveUser

const SecondStep: React.FC = (props) => {
  const context = React.useContext<QuestionContextProps>(QuestionContext);

  const [state, setState] = React.useState<SecondStepState>({
    kpis: [],
    taggedUsers: [],
    selected: []
  });

  const [userOptions, setUserOptions] = React.useState<Option[]>([]);

  const handleKpiChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const kpis = event.target.value as string[];
    if(kpis.length === 3) {
      kpis.shift();
    }
    setState({...state, kpis: event.target.value as string[]})
    context.setState({...context.state, kpis: kpis})
  };

  React.useEffect(() => {
    (async function() {
      const activeUsers = await QuestionService.getUsers();
    
      setUserOptions(activeUsers.map((user) => {
        return {...user, label: user.fullName, value: user.id}
      }));

      const selected: any = activeUsers.filter((user) => {
        const _user: any = context.state.taggedUsers.find((id) => id === user.id);
        if(_user) {
          return {..._user, label: _user.fullName, value: _user.id};
        }
        return false;
      })

      setState({...state, selected: selected.map((user: any) => {
        return {...user, label: user.fullName, value: user.id}
      })});      
    })()
  /* eslint-disable */
  }, []);
  /* eslint-enable */

  return (
    <>
      <div className="question-user-selection">
        <MultiSelect
          options={userOptions}
          value={state.selected}
          onChange={(e: OptionUser[]) => {
            setState({...state, selected: e})
            context.setState({...context.state, taggedUsers: e.map(user => user.id)})
          }}
          labelledBy="Select user(s)"
          className="user-input"
          overrideStrings={{
            selectSomeItems: "Select user(s)",
            allItemsAreSelected: "All users are selected"
          }}
        />
        {/* SELECTED USER */}
        { state.selected.map((user) => (
          <div className="selected-user" key={user.value}>
            <span className="user-photo">
              <img src={user.profileUrl} alt="" style={{width: "100%"}}/>
            </span>
            <span className="user-name">{user.label}</span>

            <Button className="user-delete" variant="outlined" 
            onClick={(e: React.MouseEvent) => {
              const _selected = [...state.selected.filter(item => item.value !== user.value)]
              setState({...state, selected: _selected})
              context.setState({...context.state, taggedUsers: _selected.map(user => user.id)})
            }}
            >
              x
            </Button>
          </div>
        ))}
        
      </div>

      <Grid container className="question-tags-container">
        <Grid item xs={12} sm={5}>
          <h3 className="question-tags-title">Tags</h3>
        </Grid>

        {/* Importance Select */}
        <Grid item xs={12} sm={5} className="question-tag-select">
          <Select
            id="importance"
            variant="outlined"
            defaultValue="placeholder"
            fullWidth
            value={context.state.importance ? context.state.importance : "placeholder"}
            onChange={(e: any) => context.setState({...context.state, importance: e.target.value})}
          >
            <MenuItem value="placeholder" disabled>Importance</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Normal">Normal</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container className="question-tags-container">
        {/* Department Select */}
        <Grid item xs={12} sm={5} className="question-tag-select">
          <Select
            id="department"
            variant="outlined"
            defaultValue="placeholder"
            value={context.state.department ? context.state.department : "placeholder"}
            onChange={(e: any) => context.setState({...context.state, department: e.target.value})}
            fullWidth
          >
            <MenuItem value="placeholder" disabled>Department</MenuItem>
            <MenuItem value="Production">Production</MenuItem>
            <MenuItem value="Product">Product</MenuItem>
            <MenuItem value="Research and Development">Research and Development</MenuItem>
            <MenuItem value="Marketing">Marketing</MenuItem>
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="Humans Resources">Humans Resources</MenuItem>
            <MenuItem value="Accounting and Finance">Accounting and Finance</MenuItem>
            <MenuItem value="IT">IT</MenuItem>
            <MenuItem value="Technology">Technology</MenuItem>
            <MenuItem value="Data">Data</MenuItem>
            <MenuItem value="Software Development">Software Development</MenuItem>
            <MenuItem value="Ecommerce">Ecommerce</MenuItem>
            <MenuItem value="Design">Design</MenuItem>
            <MenuItem value="Fraud">Fraud</MenuItem>
            <MenuItem value="Legal">Legal</MenuItem>
            <MenuItem value="Public Relations">Public Relations</MenuItem>
            <MenuItem value="Social Media">Social Media</MenuItem>
          </Select>
        </Grid>

        {/* KPI Select */}
        <Grid item xs={12} sm={5} className="question-tag-select">
          <Select
            id="kpi"
            variant="outlined"
            multiple
            fullWidth
            onChange={handleKpiChange}
            value={context.state.kpis}
            displayEmpty
            renderValue={(kpiItems) => {
              if ((kpiItems as string[]).length === 0) {
                return <span>KPI</span>;
              }

              return (kpiItems as string[]).join(', ');
            }}
          >
            <MenuItem value="Acquisition">Acquisition</MenuItem>
            <MenuItem value="Payoff">Payoff</MenuItem>
            <MenuItem value="Retention">Retention</MenuItem>
            <MenuItem value="Conversion">Conversion</MenuItem>
            <MenuItem value="Referral">Referral</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  )
}

export default SecondStep;