import React from "react";
import Onboard from "./Onboard";

export interface Account {
  id: string;
  kind: string;
  name: string;
  webProperties: Property[];
}

interface Property {
  id: string;
  internalWebPropertyId: string;
  kind: string;
  level: string;
  name: string;
  profiles: Profile[];
  starred: boolean;
  websiteUrl: string;
}

interface Profile {
  id: string;
  kind: string;
  name: string;
  starred: boolean;
  type: string;
}

export interface IGAPayload {
  accounts: Account[];
  loggedIn?: string | boolean;
  scope: string;
}

export type OnboardingContextProps = {
  state: OnboardingState;
  setState: React.Dispatch<React.SetStateAction<OnboardingState>>;
}

export enum OnboardingUserType {
  USER = "USER",
  ANALYST = "ANALYST"
}

interface OnboardingState {
  step: number;
  type: OnboardingUserType;
  traffic: number;
  conversion: number;
  averageOrderValue: number;
  revenue: number;
  targetTraffic: number;
  targetConversion: number;
  targetAverageOrderValue: number;
  targetRevenue: number;
  viewId: string | null;
  GAPayload: IGAPayload | null;
}

export const OnboardingContext = React.createContext<OnboardingContextProps>({
  state: {
    step: 0,
    type: OnboardingUserType.ANALYST,
    traffic: 0,
    conversion: 0,
    averageOrderValue: 0,
    revenue: 0,
    targetTraffic: 0,
    targetConversion: 0,
    targetAverageOrderValue: 0,
    targetRevenue: 0,
    viewId: null,
    GAPayload: null
  },
  setState: () => null
})

export const OnboardingProvider = (props: any) => {
  const [state, setState] = React.useState<OnboardingState>({
    step: 0,
    type: OnboardingUserType.ANALYST,
    traffic: 0,
    conversion: 0,
    averageOrderValue: 0,
    revenue: 0,
    targetTraffic: 0,
    targetConversion: 0,
    targetAverageOrderValue: 0,
    targetRevenue: 0,
    viewId: null,
    GAPayload: null
  });

  return (
    <OnboardingContext.Provider value={{ state, setState: setState }}>
      {props.children}
    </OnboardingContext.Provider>
  )
}

export const OnboardingConnector = () => {
  return (
      <OnboardingProvider>
        <Onboard />
      </OnboardingProvider>
  );
}