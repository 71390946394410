import { WelcomeProps, WelcomeState } from "./Welcome";

export const invalidEmail = (email: string) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email) === false;

export const getEmailErrorHelper = (state: WelcomeState, props: WelcomeProps) => {
  if (requireError(state.emailRequired, props.state.email)) return "Please tell us your email.";
  if (state.emailInvalid && invalidEmail(props.state.email)) return "Invalid email, please enter a valid one.";
  return "";
}

export const nextScreen = (state: WelcomeState, props: WelcomeProps, setState: CallableFunction, history: any) => {
  const isEmpty = (item: string) => item.length === 0;

  const setRequired = (field: string, required: boolean) => {
    const _prop = `${field}Required`;
    setState({...state, [_prop]: required});
  }
  const required = [
    { field: "name"},
    { field: "company"},
    { field: "email"},
    { field: "jobTitle"}
  ];

  const _props: any = props.state;

  for (const field of required) {
    if (isEmpty(_props[field.field])) {
      setRequired(field.field, true);
      return;
    }
    setRequired(field.field, false);
  }

  if (invalidEmail(props.state.email)) {
    setState({...state, emailInvalid: true});
    return;
  }

  history.push('/register');
  props.setState({...props.state, step: 2});
}

export const requireError = (invalid: boolean, textValidation: string) => {
  return invalid && textValidation.length === 0;
}