import DataStory from "./DataStory";
import { DataStoryProvider } from "./DataStoryProvider";

const DataStoryConnector: React.FC = () => {
    return (
        <DataStoryProvider>
            <DataStory />
        </DataStoryProvider>
    )
}

export default DataStoryConnector;