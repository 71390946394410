import React, { useEffect, useState } from 'react'
import './Context.scss';
import { Grid, Button} from '@material-ui/core';
import { MultiSelect } from "react-multi-select-component";
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { configureKpis, getOptions, selectUser } from './ContextMethods';
import { DataStoryContext, DataStoryProps } from '../DataStoryProvider';
import { UserType } from '../../../../components/UserProvider';


const Context: React.FC = () => {
  const context = React.useContext<DataStoryProps>(DataStoryContext);
  const [selected, setSelected] = useState<any>([]);
  const [options, setOptions] = useState<Array<any>>([]);
  const kpis: string[] = ['Acquisition', 'Payoff', 'Retention', 'Conversion', 'Referral']
  const kpisDescription = [
    { name: 'Acquisition', description: 'Casting your net to capture as many customers as possible.' },
    { name: 'Conversion', description: 'Transforming prospective customers into actual customers.' },
    { name: 'Engagement', description: "Maximise the visitor's experience of your site" },
    { name: 'Retention', description: 'Keeping existing customers coming back for more.' },
    { name: 'Payoff', description: 'Reaping the rewards of landing your customers.' },
    { name: 'Referral', description: 'The customers you’ve landed spreading the word.' }
  ];
  const history = useHistory();

  const [lock, setLock] = React.useState<boolean>(false);

  /* eslint-disable */
  useEffect(() => {
    if(lock) return;
    setLock(true);

    configureKpis(context.state.kpis);
    loadKpis(context.state.currentSelected);
    getOptions(setOptions, (_options: any) => {
      if (context.state.addressedUserId) {
        const currentSelected = _options.filter((user: any) => user.id == context.state.addressedUserId)[0]

        const _state = {
          ...context.state,
          currentSelected: currentSelected
        };
        context.setState(_state)
        selectUser(_state, context.setState, [], setSelected);
      } else {
        selectUser(context.state, context.setState, selected, setSelected);
      }
    }).then(r => r)

     setLock(false);
  }, []);

  useEffect(() => {
    loadKpis();
  }, [selected[0], selected[1]]);
  /* eslint-enable */


  const loadKpis = (currentSelected?: any, reset: boolean = false) => {
    const _selected = selected[0] || currentSelected;

    if (reset) {
      const inputs: Array<any> = Array.from(document.querySelectorAll(".form-check-input")!);
      for (const input of inputs) {
        input.checked = false;
      }
      return;
    }

    if (_selected) {
      const inputs: Array<any> = Array.from(document.querySelectorAll(".form-check-input")!);
      let _kpis: string[] = [];
      for (const input of inputs) {
        let checked = false;
        const id = input.id[input.id.length - 1];

        if (_selected.type === UserType.ANALYST) {
          const kpi = kpis[id - 1];
          const _k = `${kpi[0].toUpperCase()}${kpi.slice(1).toLowerCase()}`
          input.checked = true;
          _kpis = newKpis(_k, _kpis);
          continue;
        }

        for (const kpi of _selected.kpis) {
            const _k = `${kpi[0].toUpperCase()}${kpi.slice(1).toLowerCase()}`
            _kpis = newKpis(_k, _kpis);
            if (_k === kpis[id - 1]) {
              checked = true;
            }
        }
        input.checked = checked;
      }

      if (_kpis.length > 0) {
        selectUser({ ...context.state, kpis: _kpis }, context.setState, selected, setSelected);
        return;
      }
      selectUser(context.state, context.setState, selected, setSelected);
    }
  }

  const newKpis = (kpi: string, _kpis: Array<string>) => {
    const found = _kpis.filter(_k => _k === kpi);
    if (found.length === 0) {
      return [..._kpis, kpi];
    }
    return [..._kpis.filter(_k => _k !== kpi)];
  }

  const nextPage = () => {
    if (!context.state.currentSelected || context.state.kpis.length === 0) {
      if (!context.state.currentSelected) {
        toast.warning("You should select who you are writing for.");
        return;
      }
      if (context.state.kpis.length === 0) {
        toast.warning("You should select a KPI for the story.");
        return;
      }
      return;
    }
    context.setState({ ...context.state, step: 2 });
  }

  return (
    <div className="data-context-container">
      {/* Who are you writing for? */}

      <div style={{ minHeight: '60vh' }}>
        <h3 className="data-container-title">It is always important to have one person in mind when writing a data story</h3>

        <div className="data-user-selection">
          <MultiSelect
            options={options}
            value={selected}
            hasSelectAll={false}
            onChange={setSelected}
            labelledBy="Select user"
            className="user-input"
            overrideStrings={{
              selectSomeItems: "Select user"
            }}
            data-label="select-user"
          />
          {/* SELECTED USER */}
          {context.state.currentSelected && (
            <div className="selected-user">
              <span className="user-photo">
                <img src={context.state.currentSelected.profileUrl} style={{ width: '100%' }} alt="" />
              </span>
              <span className="user-name">{context.state.currentSelected.label}</span>

              <Button className="user-delete" variant="outlined" onClick={() => {
                setSelected([]);
                context.setState({ ...context.state, currentSelected: null, name: "", jobTitle: "", department: "", description: "" });
              }}>
                x
              </Button>
            </div>
          )}

        </div>

        {/* What you need to know */}
        <h3 className="data-container-title">What you need to know.</h3>
        <Grid container className="data-user-infos" spacing={4}>
          <Grid item md={4} className="user-info-item">
            <div className="info-item-content">
              <span className="value">{context.state.name}</span>
              <span className="label">Name</span>
            </div>
          </Grid>
          <Grid item md={4} className="user-info-item">
            <div className="info-item-content">
              <span className="value">{context.state.jobTitle}</span>
              <span className="label">Job title</span>
            </div>
          </Grid>
          <Grid item md={4} className="user-info-item">
            <div className="info-item-content">
              <span className="value">{context.state.department}</span>
              <span className="label">Department</span>
            </div>
          </Grid>

          <Grid item md={12} className="user-info-description">
            <p className="info-description-text">
              {context.state.description}
            </p>
          </Grid>
        </Grid>

        {/* asks questions about */}
        <h3 className="data-container-title">{context.state.currentSelected ? context.state.currentSelected.label : ""} asks questions about</h3>

        {/* TAG SELECTION */}
        <div className="data-kpis-selection">
          <div className="kpis-list">
            {kpis.map((val, index) => (
              <div className="kpis-list-item" key={index}>
                <input className="form-check-input" disabled type="checkbox" id={`kpi-check${index + 1 !== 1 ? index + 1 : 1}`} />
                <label className="form-check-label" htmlFor={`kpi-check${index + 1 !== 1 ? index + 1 : 1}`}>
                  {val}
                </label>
                <div className="tags-item-hover">
                  <h5 className="tags-hover-title">{val}</h5>
                  <p className="tags-hover-description">
                    {kpisDescription.map(kpi => kpi.name === val ? kpi.description : "")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="data-story-buttons">
        <button className="btn-outlined-wipe" onClick={(e: React.MouseEvent<HTMLButtonElement>) => history.push("/dashboard")}>
          Cancel
        </button>

        <button className="btn-contained-wipe" onClick={nextPage}>
          Next
        </button>
      </div>
      <ToastContainer />
    </div >
  )
}

export default Context;