import { Button, Menu, MenuItem } from "@material-ui/core"
import React from "react"
import DotsMenu from '../../../assets/images/dotsMenu-icon.svg';
import { UserRegisterStatus } from "../../DataStory/v1/DataStoryContracts";
import AdminService from "../AdminService";
import { Row } from "./Users";
import { toast } from 'react-toastify';
interface OptionsProps {
    row: Row;
    updateRow(row: Row, status: UserRegisterStatus): void
}

export const OptionsMenu: React.FC<OptionsProps> = (props) => {
    const [dotsMenu, setDotsMenu] = React.useState<null | HTMLElement>(null);

    const handleCloseMenu = () => {
        setDotsMenu(null);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDotsMenu(event.currentTarget);
    };

    const approveAccount = async (id: number) => {
        const response = await AdminService.approveUser(id);

        if (response.status !== 200) {
            toast.error('Operation failed');
            return;
        }
        
        toast.success('User approved succesfully');
        props.updateRow(props.row, UserRegisterStatus.APPROVED);
    }

    const disapproveAccount = async (id: number) => {
        const response = await AdminService.disapproveUser(id);

        if (response.status !== 200) {
            toast.error('Operation failed');
            return;
        }
        toast.success('User disabled succesfully');
        props.updateRow(props.row, UserRegisterStatus.PENDING_ADMIN_APPROVAL);

    }
    return (
        <>
            <Button className="story-menu-button" onClick={handleOpenMenu}>
                <img src={DotsMenu} alt="" />
            </Button>
            <Menu
                anchorEl={dotsMenu}
                open={Boolean(dotsMenu)}
                onClose={handleCloseMenu}
                PopoverClasses={{ paper: 'popover-menu admin-users' }}
                elevation={1}
            >
                {
                    props.row.userRegisterStatus === UserRegisterStatus.PENDING_ADMIN_APPROVAL
                        ? (
                            <MenuItem className="popover-menu-item deactivate" onClick={(e) => approveAccount(props.row.id)}>
                                Approve account
                            </MenuItem>
                        ) : props.row.userRegisterStatus === UserRegisterStatus.APPROVED ? (
                            <MenuItem className="popover-menu-item deactivate" onClick={(e) => disapproveAccount(props.row.id)}>
                                Deactivate account
                            </MenuItem>
                        ) : 
                        <MenuItem className="popover-menu-item">
                            User pending email confirmation
                        </MenuItem>
                }
            </Menu>
        </>
    )
}