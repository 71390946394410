import { UserType } from "../../components/UserProvider";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

interface ErrorEntity {
    code: string
    info: string
  }
  
export interface ErrorResponse {
    errors: Array<ErrorEntity>
}

interface RegisterRequest {
    fullName: string;
    email: string;
    companyName: string;
    password: string;
    userType: string;
    freeTrialToken: string | null;
}

interface ValidateRequest{
    email: string;
}

export interface RegisterResponse {
    adminName: string;
    status: string;
}

const RegisterService = {
    async register(request: RegisterRequest): Promise<Response> {
        const url = `${BASE_URL}/api/user`
    
        const requestBody: any = {
            "fullName": request.fullName,
            "email": request.email,
            "companyName": request.companyName,
            "password": request.password,
            "userType": request.userType === "Storyteller" ? UserType.ANALYST : UserType.BUSINESS
        }

        if(request.freeTrialToken) {
            requestBody["freeTrialToken"] = request.freeTrialToken!;
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(requestBody)
        });
        return response;
    },

    async validate(request :ValidateRequest): Promise<Response>
    {
        const url = `${BASE_URL}/api/user/validate-email`

        const response = await fetch(url, 
            {
            method: 'PUT',
            headers: 
                {
                'Content-Type': 'application/json'
                },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify
                ({
                "email": request.email
                })
            });
        return response;
    }
};

export default RegisterService;