import React from "react";
export type DialogContextProps = {
    state: DialogContextState,
    setState: CallableFunction
}

interface DialogContextState {
    tabValue: number;
    open: boolean;
    commentSelected: boolean;
    commentId: string;
    id: string;
    comments: any[];
    decision: any | null;
    outcome: any | null;
    updateFeed: CallableFunction;
}

export const DialogContext = React.createContext<DialogContextProps>({
    state: {
        tabValue: 0,
        open: false,
        commentSelected: false,
        commentId: '',
        id: '',
        comments: [],
        decision: null,
        outcome: null,
        updateFeed: () => null,
    },
    setState: () => null
});

interface DialogContextInitialization {
    id: string;
    decision?: any;
    outcome?: any;
    hasDecision?: any;
    hasOutcome?: any;
    updateFeed?: CallableFunction;
}


export const DialogContextProvider: React.FC<DialogContextInitialization> = (props) => {
    const [state, setState] = React.useState<DialogContextState>({
        tabValue: 0,
        open: false,
        commentSelected: false,
        commentId: '',
        id: '',
        comments: [],
        updateFeed: props.updateFeed!,
        decision: props.decision ?? null,
        outcome: props.outcome ?? null
    })
    return (
        <DialogContext.Provider value={{ state, setState: setState }}>
            {props.children}
        </DialogContext.Provider>
    )
}