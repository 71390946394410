import { Container, Dialog, Grid, Input, InputAdornment, MenuItem, Select } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import Header from "../../components/Header/Header";
import { FeedItem } from "../Dashboard/Dashboard";
import { DialogContextProvider } from "../Dashboard/Feed/QuestionItem/DialogProvider";
import { QuestionProvider } from "../Dashboard/Question/QuestionProvider";

import { default as QuestionComponent } from '../Dashboard/Question/Question';
import CloseWhiteIcon from '../../assets/images/close-white-icon.svg';
import DataStoryService from "../DataStory/v1/DataStoryService";
import { StoryItemProvider } from "../Dashboard/Feed/StoryItem/StoryItemProvider";
import StoryItem from "../Dashboard/Feed/StoryItem/StoryItem";

type Params = {
    id: string;
}

const Story: React.FC = () => {
    // noinspection DuplicatedCode
    const [openQuestionModal, setOpenQuestionModal] = React.useState(false);
    const [feedItem, setFeedItem] = React.useState<FeedItem | null>(null)
    const [kpis, setKpis] = React.useState<string[]>([])
    let { id }: Params  = useParams();

    const history = useHistory();

    const handleOpenQuestionModal = () => {
        setOpenQuestionModal(true);
    };
    
    const handleCloseQuestionModal = () => {
        setOpenQuestionModal(false);
    };
        
    const navigateToPage = (value: string) => {
        if (value === "dataStory") {
          history.push("/data-story")
        } else if (value === "question") {
          handleOpenQuestionModal();
        }
    }

    const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

    useEffect(() => {
        DataStoryService.readMore(id).then((payload: any) => {
            setKpis(payload.data.story.dataStoryKpis!.map((kpi: any) => capitalize(kpi.kpi.toLowerCase())));
            setFeedItem(payload.data.story);
        })
    /* eslint-disable */
    }, []);
    /* eslint-enable */

    const removeFromFeed = (index: number) => null

    return (
        <div className="focused-container">
            <Header />

            <div className="feed-subheader">
                <Container>
                    <Grid item md={9} className="subheader-title">
                        <span className="subheader-title-content">
                        Have a question or something to share ?&nbsp;
                        <Select
                            id="feed-select"
                            defaultValue="question"
                            className="subheader-title-select"
                            onChange={(e) => null}
                        >
                            <MenuItem value="question" onClick={(e) => navigateToPage('question')}>Ask a Question</MenuItem>
                            <MenuItem value="dataStory" onClick={(e) => navigateToPage('dataStory')}>Add a Data Story</MenuItem>
                        </Select>
                        </span>
                    </Grid>
                    <Grid item md={3} className="subheader-search" style={{visibility: "hidden"}}>
                        <Input
                        id="search"
                        placeholder="Search functionality coming soon"
                        fullWidth
                        autoComplete="off"
                        endAdornment={
                            <InputAdornment position="end" disablePointerEvents>
                                {/* <SearchIcon /> */}
                            </InputAdornment>
                        }
                        />
                    </Grid>
                </Container>
            </div>

            <Dialog className="question-modal" onClose={handleCloseQuestionModal} open={openQuestionModal} maxWidth="md" aria-describedby="Add a question modal" aria-labelledby="Add a question modal">
                <QuestionProvider>
                    <QuestionComponent handleClose={handleCloseQuestionModal} updateFeed={() => null} />
                </QuestionProvider>

                <span onClick={handleCloseQuestionModal}>
                <img src={CloseWhiteIcon} className="question-modal-close" alt="" />
                </span>
            </Dialog>

            <Container className="focused-container-content">
                <Grid container>
                    <Grid item md={9}>
                        {feedItem && (
                            <StoryItemProvider
                                key={feedItem.id}
                                likedByCurrentUser={feedItem.likedByCurrentUser!}
                                likesCount={feedItem.likesCount!}>
                                <DialogContextProvider id={feedItem.id} key={feedItem.id}>
                                    <StoryItem
                                        story={{
                                        ...feedItem,
                                        tags: kpis,
                                        date: new Date(feedItem.publishedDate),
                                        storyRemoved: () => removeFromFeed(0)
                                        }}
                                        preview={false}
                                    />
                                </DialogContextProvider>
                            </StoryItemProvider>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Story;