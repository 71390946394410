import StoryHelper from "../StoryHelper";
import "./BasicGuide.scss";
import Story from "./Story/Story";

const BasicGuide = () => {
    return (
        <>
            <div>
                <StoryHelper />
                
                <div style={{marginTop: "35px"}}>
                    <Story />
                </div>            
            </div>
        </>
    );
}

export default BasicGuide;