import React from "react";
import "./Onboard.scss";
import Analyst from "./Analyst/Analyst";
import { IGAPayload, OnboardingContext, OnboardingContextProps, OnboardingUserType } from "./OnboardingProvider";
import BusinessUser from "./BusinessUser/BusinessUser";
import { useLocation } from "react-router-dom";
import { UserContext, UserContextProps, UserType } from "../../../components/UserProvider";
import { toast } from "react-toastify";
import OnboardingService from "./OnboardingService";
import { useHistory } from "react-router";
import SubscribedToGa from "../../../components/SubscribedToGa";

let history;

interface OnboardingGAState {
    GAPayload: IGAPayload;
    step: number;
    type: OnboardingUserType;
}

export const finishOnboarding = (userContext: UserContextProps, context: OnboardingContextProps, blockRedirect: boolean = false) => {
    OnboardingService.saveOnboarding({
        preferredColour: userContext.state.preferredColour,
        lineManager: "",
        userName: userContext.state.fullName,
        jobTitle: userContext.state.jobTitle,
        departments: [],
        department: userContext.state.department
    }, userContext.state.kpis, userContext.state.description)
        .then((response) => {
            if (response.status !== 200) {
                toast.error("Something went wrong while saving your onboard, please refresh the page to try again");
                return;
            }

            if(!blockRedirect){
                history.push("/");
            }

            context.setState({ ...context.state, step: context.state.step + 1 });
        })
}

const Onboard = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);
    const userContext = React.useContext<UserContextProps>(UserContext);
    const location = useLocation();
    history = useHistory();

    React.useEffect(() => {
        const state = location.state as OnboardingGAState;
        if (state) {
            context.setState({ ...context.state, type: state.type, step: state.step, GAPayload: state.GAPayload });
            return;
        }

        const hasGoogleAnalyticsAccess = SubscribedToGa(userContext);
        const type = userContext.state.type === UserType.ANALYST && hasGoogleAnalyticsAccess ? OnboardingUserType.ANALYST : OnboardingUserType.USER;
        context.setState({ ...context.state, type: type });

        
        /* eslint-disable */
    }, [userContext.state.type])
    /* eslint-enabled */

    switch (context.state.type) {
        case OnboardingUserType.USER:
            return <BusinessUser />;
        case OnboardingUserType.ANALYST:
            return <Analyst />;
        default:
            return <></>;
    }
};

export default Onboard;