import LoginService from "../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class AdminFreeTrialService {
    async inviteTrial(email: string) {
        const url = `${BASE_URL}/free-trial-token`;
        const token = LoginService.getToken();
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(email)
        });
    }

    async removeFreeTrial(email: string) {
        const url = `${BASE_URL}/free-trial-token`;
        const token = LoginService.getToken();
        return fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(email)
        });
    }

    async trialUsers(take: number = 100, skip: number = 0) {
        const token = LoginService.getToken();
        return fetch(`${BASE_URL}/free-trial-token?take=${take}&skip=${skip}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }
}

export default new AdminFreeTrialService();