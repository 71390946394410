import {gql} from "@apollo/client";
import {getApolloClient} from "../../../components/GraphQL/GraphQL";
import LoginService from "../../Login/LoginService";
import {ActiveUser, CommentStory, FeedResponse, GenerateText, QueryResult, SendStory} from "./DataStoryContracts";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const DataStoryService = {
    saveStory: async function (story: SendStory): Promise<Response> {
        const url = `${BASE_URL}/story`
        var token = LoginService.getToken();
        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: JSON.stringify(story)
        });
    },
    publishStory: async function (id: string): Promise<Response> {
        const url = `${BASE_URL}/story/${id}/publish`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    },
    downloadData: async function (id: string): Promise<Response> {
        const url = `${BASE_URL}/story/${id}/download-data`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    },
    unpublishStory: async function (id: string): Promise<Response> {
        const url = `${BASE_URL}/story/${id}/unpublish`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    },
    uploadStoryData: async function (id: string, dataCsv: string): Promise<Response> {
        const url = `${BASE_URL}/story/${id}/data-upload`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PUT',
            body: JSON.stringify({ csvData: dataCsv }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    },
    generateText: async function (id: string, text: GenerateText): Promise<Response> {
        const url = `${BASE_URL}/story/${id}/story-generator`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: JSON.stringify(text)
        });
    },
    getUsers: async (): Promise<ActiveUser[]> => {
        const client = getApolloClient(LoginService.getToken());

        const result: QueryResult = await client.query({
            query: gql`
            query Ping {
                me{
                    fullName
                    company{
                        name
                        activeUsers{
                            id
                            fullName
                            profileUrl
                            department
                            jobTitle
                            description
                            type
                            userKpis {
                                userId
                                kpi
                            }
                        }
                    }
                }
            }
            `
        });

        return result.data.me[0].company.activeUsers;
    },
    async getStories() {
        const client = getApolloClient(LoginService.getToken());

        const result: FeedResponse = await client.query({
            query: gql`
            query Ping {
                feed {
                    id
                    title
                    subtitle
                    kpis
                    insight
                    anomalyReason
                    authorId
                    commentsCount
                    likesCount
                    likedByCurrentUser
                    author {
                        fullName
                        profileUrl
                    }
                }
            }
            `
        });

        return result;
    },
    async commentStory(comment: CommentStory) {
        const url = `${BASE_URL}/story-comment`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: JSON.stringify(comment)
        });
    },
    async getComments(dataStoryId: string): Promise<any[]>{
        const client = getApolloClient(LoginService.getToken());

        const result = await client.query({
            query: gql`
            query Ping {
                story(id: "${dataStoryId}") {
                    likes{
                        user {
                            fullName
                            department
                            jobTitle
                            profileUrl
                        }
                    }
                    comments{
                        id
                        text 
                        parentCommentId
                        user{
                            fullName
                            department
                            jobTitle
                            profileUrl
                        }
                        likes{
                            user {
                                id
                                fullName
                            }
                        }
                    }
                }
            }
            `
        });

        return result.data.story.comments;
    },

    async getLikes(questionId: string) {
        const client = getApolloClient(LoginService.getToken());

        const result = await client.query({
            query: gql`
            query Ping {
                story(id: "${questionId}") {
                    id
                    likes {
                        user {
                            id
                            fullName
                            department
                            jobTitle
                            profileUrl
                        }
                    }
                }
            }
            `
        });

        return result.data.story.likes;
    },
    async likeStory(uuid: string) {
        const url = `${BASE_URL}/story/${uuid}/like`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: ""
        });
    },
    async saveGenericImage(id: string, image: File) {
        const url = `${BASE_URL}/story/${id}/image/upload`
        var token = LoginService.getToken();

        const data = new FormData();
        data.append('ImageFile', image, image.name);
        
        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: data
        });
    },
    async readMore(dataStoryId: string) {
        const client = getApolloClient(LoginService.getToken());

        return await client.query({
            query: gql`
            query Ping {
                story(id: "${dataStoryId}") {
                    taggedUsers{
                        userId
                    }
                    id
                    author {
                        id
                        fullName
                        jobTitle
                        department
                        profileUrl
                    }
                    title
                    subtitle
                    insight
                    anomalyReason
                    impactExplanation
                    suggestions
                    pivotState
                    commentsCount
                    peopleCommentsCount
                    likesCount
                    likedByCurrentUser
                    chartImageUrl
                    dataChartImageUrl
                    dataStoryKpis{
                        kpi
                    }
                    publishedDate
                }
            }
            `
        });
    },
    async getFeed(selectedKpis: any[], from?: string, to?: string) {
        const client = getApolloClient(LoginService.getToken());

        return await client.query({
            variables: {
                kpis: selectedKpis.map(x => x.value.toUpperCase()),
                from: from && to ? from : null,
                to: to && from ? to : null
            },
            query: gql`
                query Feed($kpis: [Kpi!], $from: DateTime, $to: DateTime) {
                    feedV2(kpis: $kpis, from: $from, to: $to){
                        __typename
                        ... on DataStory {
                            addressedUserId
                            taggedUsers{
                                userId
                            }
                            id
                            author {
                                id
                                fullName
                                jobTitle
                                department
                                profileUrl
                            }
                            outcome {
                                id
                                user {
                                  id
                                  fullName
                                  jobTitle
                                  department
                                  profileUrl
                                }
                                text
                                likesCount
                                likedByCurrentUser
                            }
                            decision {
                                id
                                user {
                                  id
                                  fullName
                                  jobTitle
                                  department
                                  profileUrl
                                }
                                text
                                likesCount
                                likedByCurrentUser
                            }
                            title
                            subtitle
                            insight
                            anomalyReason
                            impactExplanation
                            suggestions
                            commentsCount
                            peopleCommentsCount
                            likesCount
                            likedByCurrentUser
                            chartImageUrl
                            dataChartImageUrl
                            dataStoryKpis{
                                kpi
                            }
                            publishedDate
                            hasDownloadableData
                       }
                       ... on Question {
                            id
                            text
                            image
                            extraContent
                            commentAnswerId
                            answerSelectedBy {
                                id
                                fullName
                                department
                                jobTitle
                                profileUrl
                            }
                            commentAnswer {
                                id
                                text
                                user {
                                    id
                                    fullName
                                    department
                                    jobTitle
                                    profileUrl
                                }
                                likesCount
                                likedByCurrentUser
                            }
                            user {
                                id
                                fullName
                                jobTitle
                                department
                                profileUrl
                            }
                            questionKpis{
                                kpi
                            }  
                            publishedDate
                            likedByCurrentUser
                            commentsCount
                            peopleCommentsCount
                            likesCount
                       }
                    }
                }
        `
        });
    },
    async saveStoryImage(id: string, image: File) {
        const url = `${BASE_URL}/story/${id}/image-upload`
        var token = LoginService.getToken();

        const data = new FormData();
        data.append('ImageFile', image, image.name);
        
        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: data
        });
    },
    async refreshStory(storyId: string) {
        const client = getApolloClient(LoginService.getToken());

        const result = await client.query({
            query: gql`
            query Ping {
                story(id: "${storyId}") {
                    likedByCurrentUser
                    likesCount
                    commentsCount
                    peopleCommentsCount
                }
            }
            `
        });

        
        return result.data.story;
    }
};

export default DataStoryService;