import { gql } from "@apollo/client";
import { getApolloClient } from "../../components/GraphQL/GraphQL";
import { Company } from "../DataStory/v1/DataStoryContracts";
import LoginService from "../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class AdminService {
    async approveUser(userId: number) {
        const url = `${BASE_URL}/api/AdminUser/admin-approve-pending-user/${userId}`;
        const token = LoginService.getToken();
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: ""
        });
    }
    async disapproveUser(userId: number) {
        const url = `${BASE_URL}/api/AdminUser/admin-disapprove-user/${userId}`;
        const token = LoginService.getToken();
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: ""
        });
    }
    async getCompany(): Promise<Company> {
        const client = getApolloClient(LoginService.getToken());

        const result: any = await client.query({
            query: gql`
            query Ping {
                me {
                    id
                    company {
                        admin{
                            id
                        }
                        activeUsers {
                            id
                            fullName
                            type
                            email
                            jobTitle
                            userRegisterStatus
                            type
                        }
                    }
                }
            }
            `
        });

        return result.data.me[0].company;
    }
}

export default new AdminService();