import React from "react";
import { DataStoryState as _DataStoryState } from './DataStoryContracts';
import { v4 as uuid } from "uuid";
import { GraphSelection } from "./PickGraph/PickAGraphService";
import { DecidableChartProps } from "./PickGraph/DecidableChart";


export type DataStoryProps = {
    state: DataStoryState & {
        pivotTable?: DecidableChartProps
    };
    setState: CallableFunction;
}

export const DataStoryContext = React.createContext<DataStoryProps>({
    state: {
        showHelpText: false,
        step: 1,
        id: "",
        kpis: [],
        jobTitle: "",
        department: "",
        description: "",
        users: [],
        addressedUserId: null,
        sentiment: "",
        showPreviewTable: false,
        selectedData: "",
        disabled: false,
        filename: "",
        data: [],
        name: "",
        title: "",
        subtitle: "",
        insight: "",
        anomalyReason: "",
        impactExplanation: "",
        suggestions: "",
        choosenDepartment: "",
        choosenKpi: "",
        choosenPriority: "",
        currentSelected: null,
        tagged: [],
        graphType: "",
        rows: [],
        cols: [],
        aggregatorName: "",
        dictionaryGraphData: {},
        columns: {},
        freedomMode: false,
        freedomGraphImage: null,
        pivotTable: undefined,
        graphSelection: undefined,
        wizardAnswers: Array(5),
        AIAnswers: Array(3).fill(""),
        wizardFlow: false,
        editMode: false,
        dataChartImageUrl: "",
        storyMode: ""
    },
    setState: () => null
});

export type DataStoryState = _DataStoryState & {
    dataChartImageUrl: string
    pivotTable?: DecidableChartProps
    editMode: boolean
    graphSelection?: GraphSelection
    storyMode: string;
    wizardFlow: boolean;
    AIAnswers: string[];
    wizardAnswers: string[]
}

export const DataStoryProvider: React.FC = (props) => {
    const [state, setState] = React.useState<DataStoryState>({
        showHelpText: false,
        step: 1,
        id: uuid(),
        kpis: [],
        jobTitle: "",
        department: "",
        description: "",
        users: [],
        addressedUserId: null,
        sentiment: "",
        showPreviewTable: false,
        selectedData: "",
        disabled: false,
        filename: "",
        wizardFlow: false,
        AIAnswers: Array(3).fill(""),
        data: [],
        name: "",
        title: "",
        subtitle: "",
        insight: "",
        anomalyReason: "",
        impactExplanation: "",
        suggestions: "",
        choosenDepartment: "",
        choosenKpi: "",
        choosenPriority: "",
        currentSelected: null,
        tagged: [],
        graphType: "",
        rows: [],
        cols: [],
        aggregatorName: "",
        dictionaryGraphData: {},
        columns: {},
        freedomMode: false,
        freedomGraphImage: null,
        pivotTable: undefined,
        graphSelection: undefined,
        wizardAnswers: Array(5),
        editMode: false, 
        dataChartImageUrl: "",
        storyMode: ""
    });

    return (
        <DataStoryContext.Provider value={{ state, setState: setState }}>
            {props.children}
        </DataStoryContext.Provider>
    )
}
