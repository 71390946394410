import React, { useEffect } from "react";
import './Comments.scss';

import CommentsModalEmpty from '../../../../../assets/images/comments-modal-empty.svg';

import { Comment as IComment } from "../../../../DataStory/v1/DataStoryContracts";
import Comment from "./Comment";
import QuestionService from "../QuestionService";
import CommentsFooter from "./CommentsFooter";
import DataStoryService from "../../../../DataStory/v1/DataStoryService";
import { DialogContext, DialogContextProps } from "../DialogProvider";

export enum PostType {
  QUESTION = "QUESTION",
  DATA_STORY = "DATASTORY"
}

interface CommentsProps {
  dataStoryId: string;
  author: string;
  comments: IComment[];
  liked: boolean;
  type: PostType;
}

interface CommentsState {
  comments: IComment[];
  liked: boolean;
  comment: string;
}

const Comments: React.FC<CommentsProps> = (props) => {
  const context = React.useContext<DialogContextProps>(DialogContext);

  const [state, setState] = React.useState<CommentsState>({
    comments: props.comments,
    liked: props.liked,
    comment: ""
  });

  const updateCommentsList = (newCommentsList: IComment[]) => {
    setState({ ...state, comments: newCommentsList })
  }

  useEffect(() => {
    (async function () {
      let comments: any[];

      if (props.type === PostType.QUESTION)
        comments = await QuestionService.getComments(props.dataStoryId)
      else
        comments = await DataStoryService.getComments(props.dataStoryId);


      context.setState({ ...context.state, comments: comments });

      const _comments = comments.slice().sort((a: any, b: any) => b.likes.length - a.likes.length)

      setState({ ...state, comments: _comments, liked: props.liked })
    })()
  /* eslint-disable */
  }, [])
  /* eslint-enable */


  return (
    <>
      {/* comments list */}
      {state.comments.length > 0 ? (
        <ul className="comments-list">
          {state.comments
            .filter(comment => comment.parentCommentId === null)
            .map((_comment, index) => {
              return (
                <Comment comment={_comment}
                  index={index}
                  mostLikedShow={true}
                  key={_comment.id}
                  showReply={true}
                  type={props.type}
                  replies={
                    state.comments.filter((item) => {
                      return item.parentCommentId === _comment.id
                    }).sort((a, b) => b.likes.length - a.likes.length)
                  }
                  focusedComment={false}
                />
              );
            })}
        </ul>
      ) : (
        <div className="comments-empty">
          <img src={CommentsModalEmpty} className="comments-empty-icon" alt="" />
          <p className="comments-empty-text">
            Someone’s got to be first,<br />so say what’s on your mind.
          </p>
        </div>
      )}
      <CommentsFooter
        author={props.author}
        dataStoryId={props.dataStoryId}
        liked={state.liked}
        type={props.type}
        updateCommentsList={updateCommentsList} />
    </>
  )
}

export default Comments;