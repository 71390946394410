import React from 'react';
import './Onboarding.scss';
import Header from '../../../components/Header/Header';

import { default as BusinessFirstStep } from "./Business/FirstStep";
import { default as BusinessSecondStep } from './Business/SecondStep';
import { default as BusinessThirdStep } from './Business/ThirdStep';
import { default as BusinessFourthStep } from './Business/FourthStep';

import { default as AnalystFirstStep } from "./Analyst/FirstStep";
import { default as AnalystSecondStep } from './Analyst/SecondStep';
import { default as AnalystThirdStep } from './Analyst/ThirdStep';
import { default as AnalystFourthStep } from './Analyst/FourthStep';


import OnboardingNavigation from './OnboardingNavigation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OnboardingService from './OnboardingService';
import { useHistory } from 'react-router';
import { UserContext, UserContextProps, UserType } from '../../../components/UserProvider';
import UserStoryGenerator from './UserStoryGenerator';
import Colourway, { Colour } from '../../../components/Colourway/Colourway';

export interface OnboardingState {
  step: number;
  maxStep: number;
  kpis: string[];
  analystIntentions: string[];
  userPreferences: UserPreferences;
}

export interface UserPreferences {
  department: string;
  departments: string[];
  userName?: string; 
  preferredColour: string;
  lineManager: string;
  jobTitle: string;
}

export enum GuideStatus {
  NotApplicable = "NOT_APPLICABLE",
  UserGuided = "USER_GUIDED",
  UserRefused = "USER_REFUSED",
  PendingGuide = "PENDING_GUIDE"
}

export enum GuideType {
  FeedReadGuide = "FeedReadGuide",
  AskQuestionGuide = "AskQuestionGuide",
  WriteDataStoryGuide = "WriteDataStoryGuide"
}

export const mapGuideToApplicable = (status: GuideStatus): any => {
  const mapping = {
    NOT_APPLICABLE: "0",
    USER_GUIDED: "1",
    USER_REFUSED: "2",
    PENDING_GUIDE: "3"
  }

  return mapping[status]
}

const Onboarding: React.FC = () => {
  const history = useHistory();
  const context = React.useContext<UserContextProps>(UserContext);

  const [state, setState] = React.useState<OnboardingState>({
    step: 1,
    kpis: [],
    analystIntentions: [],
    maxStep: 1,
    userPreferences: {
      department: '',
      preferredColour: '',
      lineManager: '',
      jobTitle: '',
      departments: []
    }
  });

  const validateUserPreferences = (): boolean => {
    if (context.state.type === UserType.BUSINESS) {
      if (state.userPreferences.department !== "")
        return true;

      toast.warning("Please select your department");
      return false;
    }
    else {
      if (state.userPreferences.departments.length >= 1)
        return true;

      toast.warning("Please select at least one department");
      return false;
    }
  }

  const validateKpiOrIntentionSelection = (): boolean => {
    if (context.state.type === UserType.BUSINESS) {
      if (state.kpis.length >= 2)
        return true;

      toast.warning("Please select at least two KPIs");
      return false;
    } else {
      if (state.analystIntentions.length >= 1)
        return true;

      toast.warning("Please select at least one intention");
      return false;
    }
  }

  const changeStep = (step: number) => {
    setState({
      ...state,
      step: step,
      maxStep: step > state.maxStep ? step : state.maxStep
    })
  };

  const changeUserPreferences = (userPreferences: UserPreferences) => {
    const colour: Colour = userPreferences.preferredColour as any;
    if(colour) Colourway.change(colour); 
    setState({ ...state, userPreferences: userPreferences })
  }

  const getUserDescription = (): string => {
    if (context.state.type === UserType.ANALYST) {
      return UserStoryGenerator.generateAnalystStory(state.analystIntentions, context.state.fullName, state.userPreferences.departments);
    }

    return UserStoryGenerator.generateUserStory(state.userPreferences, context.state, state.kpis);
  }

  const saveOnboardDetails = async () => {
    const userDescription = getUserDescription();
    const response = await OnboardingService.saveOnboarding(state.userPreferences, state.kpis, userDescription);

    if (response.status === 200) {
      toast.success("Welcome!")

      history.push("/add-users");
    } else {
      toast.error("We are sorry, but something went wrong.")
    }
  }

  const kpiChanged = (value: string, isInputChecked: boolean) => {
    const array = [...state.kpis]; // make a separate copy of the array
    const index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);
      setState({ ...state, kpis: array });
    }

    if (isInputChecked) {
      setState({ ...state, kpis: [...state.kpis, value] })
    }
  }

  const intentionChanged = (value: string, isInputChecked: boolean) => {
    const array = [...state.analystIntentions]; // make a separate copy of the array
    const index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);
      setState({ ...state, analystIntentions: array });
    }

    if (isInputChecked) {
      setState({ ...state, analystIntentions: [...state.analystIntentions, value] })
    }
  }

  const getComponent = (step: number, type: UserType): any => {
    if (type === UserType.ANALYST) {
      switch (step) {
        case 1:
          return (<AnalystFirstStep />);
        case 2:
          return (<AnalystSecondStep
            preferences={state.userPreferences}
            name={context.state.fullName}
            changeUserPreferences={changeUserPreferences} />);
        case 3:
          return (<AnalystThirdStep
            intentionList={state.analystIntentions}
            intentionChanged={intentionChanged}
          />);
        default:
          return (<AnalystFourthStep
            preferences={state.userPreferences}
            name={context.state.fullName}
            intentionList={state.analystIntentions}
            changeUserPreferences={changeUserPreferences} />);
      }
    }

    if (type === UserType.BUSINESS) {
      switch (step) {
        case 1:
          return (<BusinessFirstStep />);
        case 2:
          return (<BusinessSecondStep
            preferences={state.userPreferences}
            name={context.state.fullName}
            changeUserPreferences={changeUserPreferences} />);
        case 3:
          return (<BusinessThirdStep
            kpis={state.kpis}
            kpiChanged={kpiChanged}
          />);
        default:
          return (<BusinessFourthStep
            email={context.state.email}
            name={context.state.fullName}
            kpis={state.kpis}
            userPreferences={state.userPreferences} />);
      }
    }
  }

  return (
    <div className="onboarding-container">
      <Header />
      <div className="onboarding-container-content">
        {
          context.state.type === UserType.ANALYST
            ? getComponent(state.step, UserType.ANALYST)
            : getComponent(state.step, UserType.BUSINESS)
        }
        <OnboardingNavigation
          type={context.state.type}
          step={state.step}
          changeStep={changeStep}
          validateKpiSelection={validateKpiOrIntentionSelection}
          validateUserPreferences={validateUserPreferences}
          saveOnboardDetails={saveOnboardDetails} />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Onboarding;