import React, { ChangeEvent, useEffect } from 'react'
import './Story.scss';

import Grid from '@material-ui/core/Grid';
import { InputBase, TextareaAutosize, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import Editor from "rich-markdown-editor";
import { saveStory } from './StoryMethods';
import { DataStoryContext, DataStoryProps } from '../DataStoryProvider';
import DataStoryService from '../DataStoryService';
import WordHighlighter from './WordHighlighter';

const tooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: "#fff",
    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
    color: '#000',
    fontSize: '12px',
    lineHeight: '20px'
  }
});

const Story: React.FC = () => {
  const context = React.useContext<DataStoryProps>(DataStoryContext);
  const [showDraftText, setShowDraftText] = React.useState(true);
  const [answerIndex, setAnswerIndex] = React.useState<number>(0);
  const classes = tooltipStyles();
  const [originalAnswers, setOriginalAnswers]: any = React.useState<string>('');

  const save = async () => {
    await saveStory(context, context.state.freedomMode);

    if (context.state.freedomGraphImage) {
      await DataStoryService.saveStoryImage(context.state.id, context.state.freedomGraphImage!);
    }
  }

  const isActive = (_index: number, current: number) => _index === current ? "active" : "";

  useEffect(() => {
    const words: string = context
      .state
      .wizardAnswers
      .filter((x) => x)
      .join(' ');

    setOriginalAnswers(words);
  /* eslint-disable */
  }, [])
  /* eslint-enable */

  return (
    <div className="data-story-container">

      {
        context.state.wizardFlow && (
          <h3 className="data-container-title">Scribey will take what you have drafted and transform it into an engaging data story</h3>
        )
      }

      <div style={{ minHeight: '60vh' }}>
        {context.state.wizardFlow && (
            <>
              <div className="data-story-drafted">
                <div className="data-drafted-header">
                  <h4>Here are the key points that you drafted</h4>

                  <button className="data-drafted-button" onClick={() => setShowDraftText(!showDraftText)}>
                    {showDraftText ? (
                      <VisibilityOutlinedIcon className="visibility-icon" />
                    ) : (
                      <VisibilityOffOutlinedIcon className="visibility-icon" />
                    )}
                  </button>
                </div>

                {showDraftText && (
                  <p className="data-drafted-text" dangerouslySetInnerHTML={{ __html: context.state.wizardAnswers.filter((x) => x).join(". <br>") }}>
                  </p>
                )}
              </div>

              <div className="data-story-scribey">
                <h4>Here are some options that Scribey has come up with</h4>
                <p className="data-scribey-text"
                  dangerouslySetInnerHTML={{
                    __html: WordHighlighter.getText(originalAnswers, context.state.AIAnswers[answerIndex])
                  }}
                >
                </p>
                <div className="data-scribey-footer">
                  <div className="data-scribey-footer-pagination">
                    {context.state.AIAnswers.map((value, index) => (
                      <span className={`footer-pagination ${isActive(index, answerIndex)}`}
                        onClick={() => { setAnswerIndex(index) }}
                      >{index + 1}</span>
                    ))}
                  </div>

                  <div className="data-scribey-footer-buttons">
                    {/* <button className="btn-outlined-wipe"
                        onClick={(e) => {
                          let newIndex = 0;
                          if(answerIndex + 1 < context.state.AIAnswers.length) {
                            newIndex = answerIndex + 1;
                          }
                          setAnswerIndex(newIndex);
                        }}
                      >
                        Load more
                      </button> */}

                    <button className="btn-contained-wipe"
                      onClick={() => {
                        context.setState({ ...context.state, anomalyReason: context.state.AIAnswers[answerIndex] + "\n" + context.state.anomalyReason })
                      }}
                    >
                      Add to Story
                    </button>
                  </div>
                </div>
              </div>
            </>
          )
        }

        <h3 className="data-container-title">Your story - click to edit any text</h3>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={3}>
            <label className="data-story-label">Title</label>
          </Grid>
          <Grid item xs={12} md={9}>
            <InputBase
              value={context.state.title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, title: e.target.value })}
              placeholder="Type here"
              inputProps={{ 'aria-label': 'naked', maxLength: 150 }}
              className="data-story-input"
            />
          </Grid>
        </Grid>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={3}>
            <label className="data-story-label">Subtitle</label>
          </Grid>
          <Grid item xs={12} md={9}>
            <InputBase
              value={context.state.subtitle}
              onChange={(e: ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, subtitle: e.target.value })}
              placeholder="Type here"
              inputProps={{ 'aria-label': 'naked', maxLength: 150 }}
              className="data-story-input"
            />
          </Grid>
        </Grid>

        {
          context.state.wizardFlow && (
            <Grid container className="data-story-topic">
              <Grid item xs={12} md={3}>
                <label className="data-story-label">
                  The narrative that you will publish
                </label>
              </Grid>
              <Grid item xs={12} md={9}>
                  <Editor
                    key={context.state.anomalyReason}
                    onChange={(newValue) => {
                      context.setState({...context.state, insight: "", anomalyReason: newValue(), impactExplanation: "", suggestions: "" })
                    }}
                    className="decidable-markdown data-story-textarea"
                    uploadImage={async (file: File) => {
                      const response = await DataStoryService.saveGenericImage(context.state.id, file)
                      if(response.status !== 200) return;
                      const payload = await response.json(); 
                      return payload.imageUrl;
                    }}
                    disableExtensions={[]}
                    placeholder="Type your story here"
                    defaultValue={[
                      context.state.insight, 
                      context.state.anomalyReason,
                      context.state.impactExplanation,
                      context.state.suggestions,
                    ].filter(x => x).join("\\")}
                  />
              </Grid>
            </Grid>
          )
        }

        {
          !context.state.wizardFlow && (
            <>
              <Grid container className="data-story-topic">
                <Grid item xs={12} md={5}>
                  <label className="data-story-label">
                    <b>The setting and your general discoveries</b><br />
                    Give some context. Not loads. Just enough background to set the stage for the data. Identify any key things you’ve looked at. Or discovered. But keep your powder dry. Don’t give your insight yet. Just tease the problem or opportunity.<br />
                    <Tooltip
                      title="As you can see, the total online sales follow a cyclical pattern each quarter. This year’s sales have been trending above the previous year’s results in every period until recently. For some reason, the current quarter’s results took an unexpected downward turn. Most product categories are performing as normal, however there are two product categories that are worth digging into."
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span className="data-story-label-example">Example</span>
                    </Tooltip>
                  </label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextareaAutosize placeholder="Type here" className="data-story-textarea" name="" id="freedom-mode"
                    value={context.state.anomalyReason}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => context.setState({ ...context.state, anomalyReason: e.target.value })}
                    maxLength={1000}
                  />
                </Grid>
              </Grid>

              <Grid container className="data-story-topic">
                <Grid item xs={12} md={5}>
                  <label className="data-story-label">
                    <b>The aha moment</b><br />
                    This is your big reveal. Where you share your main insight. Explain what it is. The impact that it has on your KPIs or goals. If you can, say why it happened. If you can’t, say why too. This could be a good follow up action.<br />
                    <Tooltip
                      title="Looking deeper into the two product categories that didn’t perform as well, we noticed that a cluster of products from brand X performed unusually poorly. Unless the brand X issue can be resolved, the e-commerce team will miss their quarterly sales target by 38%. However, it’s not clear why brand X has performed below expectations."
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span className="data-story-label-example">Example</span>
                    </Tooltip>
                  </label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextareaAutosize placeholder="Type here" className="data-story-textarea" name="" id="freedom-mode"
                    value={context.state.impactExplanation}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => context.setState({ ...context.state, impactExplanation: e.target.value })}
                    maxLength={1000}
                  />
                </Grid>
              </Grid>

              <Grid container className="data-story-topic">
                <Grid item xs={12} md={5}>
                  <label className="data-story-label">
                    <b>The solution and next steps</b><br />
                    Pitch your ideas for actions. Give some options. It’ll kickstart the discussion about how to act on your insight.<br />
                    <Tooltip
                      title="It’s worth taking a deeper look at those specific brands to try and work out what the issue was. Perhaps the tech team could see if there is an issue on the search or discovery of the brands? It’d be good to rule out any tech-related issues."
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span className="data-story-label-example">Example</span>
                    </Tooltip>
                  </label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextareaAutosize placeholder="Type here" className="data-story-textarea" name="" id="freedom-mode"
                    value={context.state.suggestions}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => context.setState({ ...context.state, suggestions: e.target.value })}
                    maxLength={1000}
                  />
                </Grid>
              </Grid>
            </>
          )
        }

      </div>

      <div className="data-story-buttons">
        <button className="btn-outlined-wipe" onClick={() => {
          context.setState({ ...context.state, storyMode: "", wizardFlow: false })
        }}>
          Cancel
        </button>

        <button className="btn-contained-wipe" onClick={() => save()}>
          Add Visual
        </button>
      </div>
    </div>
  );
}

export default Story;