import React from "react";

import { DataStoryContext, DataStoryProps } from "./DataStoryProvider";
import Step from "./Step";

interface StepMenuProps {
    steps: Step[];
}

const StepMenu: React.FC<StepMenuProps> = (props) => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);

    const isActive = (index: number) => context.state.step > index;
    const getActiveClass = (index: number) => isActive(index) ? "steps-list-item active" : "steps-list-item";
    const getIcon = (index: number) => isActive(index) ? <span>&#10003;</span> : <span></span>;
    
    return (
        <ul>
            {
                props.steps.map((step: Step, index: number) => (
                    <li className={getActiveClass(index)} key="" onClick={() => {
                        if(index + 1 === 1 && context.state.context.kpi.length > 0) {
                            context.setState({...context.state, step: index + 1, subStep: 2})
                            return;
                        }
                        context.setState({...context.state, step: index + 1, subStep: 1})
                    }}>
                        <span className="check-icon">{getIcon(index)}</span>
                        Step { index + 1 }:&nbsp;<span className="step-description">{step.getName()}</span>
                    </li>
                ))
            }
        </ul>
    )
} 

export default StepMenu;