import React from "react";

import { ToastContainer } from "react-toastify";
import { DataStoryContext, DataStoryProps } from "../../../DataStoryProvider";
import "./DraftMenu.scss";

const DraftMenu = () => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);

    return (
        <div className="data-draft-container">
            <div style={{ minHeight: '60vh' }}>
                <div className="data-draft-header">
                    <h3 className="data-container-title">
                        Decidable is here to help as much or as little as you want.<br />
                        Pick the approach that best suits your needs.
                    </h3>
                </div>

                <div className="data-draft-item">
                    <span className="data-draft-text">
                        <svg className="data-draft-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <g id="Elipse_82" data-name="Elipse 82" fill="none" stroke-width="1">
                            <circle cx="20" cy="20" r="20" stroke="none" />
                            <circle cx="20" cy="20" r="19.5" fill="none" />
                        </g>
                        <g id="Grupo_1011" data-name="Grupo 1011" transform="translate(-334.5 -349.758)">
                            <line id="Linha_67" data-name="Linha 67" x2="20" transform="translate(344.5 377.5)" fill="none" stroke-linecap="round" stroke-width="1" />
                            <path id="Caminho_1026" data-name="Caminho 1026" d="M1956.166,2807.621l7.348-6.6,3.029,2.786,9.623-8.7" transform="translate(-1611.666 -2434.342)" fill="none" stroke-linecap="round" stroke-width="1" />
                        </g>
                        </svg>

                        Scribey - we ask you 5 key questions and draft the story for you
                    </span>
                    <button className="data-story-button" type="button" onClick={() => {
                            context.setState({...context.state, subStep: 2})
                        }} data-label="wizard">
                        Go
                    </button>
                </div>

                <div className="data-draft-item">
                    <span className="data-draft-text">
                        <svg className="data-draft-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <g id="Elipse_84" data-name="Elipse 84" fill="none" stroke-width="1">
                            <circle cx="20" cy="20" r="20" stroke="none" />
                            <circle cx="20" cy="20" r="19.5" fill="none" />
                        </g>
                        <g id="Icon_feather-book-open" data-name="Icon feather-book-open" transform="translate(7.165 7.798)">
                            <path id="Caminho_1024" data-name="Caminho 1024" d="M3,4.5H8.828a3.885,3.885,0,0,1,3.885,3.885v13.6A2.914,2.914,0,0,0,9.8,19.07H3Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                            <path id="Caminho_1025" data-name="Caminho 1025" d="M27.714,4.5H21.885A3.885,3.885,0,0,0,18,8.385v13.6a2.914,2.914,0,0,1,2.914-2.914h6.8Z" transform="translate(-5.286)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                        </g>
                        </svg>

                        Basic guide - we use the three part story template to guide you
                    </span>
                    <button className="data-story-button" type="button" onClick={() => {
                            context.setState({...context.state, subStep: 3})
                        }} data-label="basic-guide">
                        Go
                    </button>
                </div>

                <div className="data-draft-item">
                    <span className="data-draft-text">
                        <svg className="data-draft-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <g id="Elipse_82" data-name="Elipse 82" fill="none" stroke-width="1">
                            <circle cx="20" cy="20" r="20" stroke="none" />
                            <circle cx="20" cy="20" r="19.5" fill="none" />
                        </g>
                        <g id="Grupo_1011" data-name="Grupo 1011" transform="translate(-334.5 -349.758)">
                            <line id="Linha_67" data-name="Linha 67" x2="20" transform="translate(344.5 377.5)" fill="none" stroke-linecap="round" stroke-width="1" />
                            <path id="Caminho_1026" data-name="Caminho 1026" d="M1956.166,2807.621l7.348-6.6,3.029,2.786,9.623-8.7" transform="translate(-1611.666 -2434.342)" fill="none" stroke-linecap="round" stroke-width="1" />
                        </g>
                        </svg>
                        Freedom mode - you get a text box and you write away
                    </span>
                    <button className="data-story-button" type="button" onClick={() => {
                            context.setState({...context.state, subStep: 4})
                        }} data-label="freedom-mode">
                        Go
                    </button>
                </div>
            </div>
            
            <ToastContainer />
        </div >
    );
}

export default DraftMenu;