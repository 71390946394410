import React from "react";
import Camera from '../../assets/images/camera.svg';
import { Container, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { UserContext, UserContextProps} from "../../components/UserProvider";
import { MultiSelect } from "react-multi-select-component";
import Colourway, { Colour } from "../../components/Colourway/Colourway";
import { Option } from "react-multi-select-component";

interface Kpi {
    kpis: Option[];
    setKpis: CallableFunction;
}

interface ProfileTabProps {
    kpis: Kpi;
}

export const ProfileTab: React.FC<ProfileTabProps> = (props) => {
    const context = React.useContext<UserContextProps>(UserContext);
    const kpis: string[] = ['Acquisition', 'Payoff', 'Retention', 'Conversion', 'Referral']
    const kpisDescription = [
        { name: 'Acquisition', description: 'Casting your net to capture as many customers as possible.' },
        { name: 'Conversion', description: 'Transforming prospective customers into actual customers.' },
        { name: 'Engagement', description: "Maximise the visitor's experience of your site" },
        { name: 'Revenue', description: "Maximise the amount that your site earns" },
        { name: 'Retention', description: 'Keeping existing customers coming back for more.' },
        { name: 'Payoff', description: 'Reaping the rewards of landing your customers.' },
        { name: 'Referral', description: 'The customers you’ve landed spreading the word.' }
      ];

    const enterFile = () => {
        document.getElementById("file-input")?.click()
    }

    const prepareFile = (e: any) => {
        const tgt = e.target,
            files = tgt.files;

        if (FileReader && files && files.length) {
            const fr = new FileReader();

            fr.onload = function () {
                (document.querySelector(".profile-image-preview")! as any).src = fr.result!;
            }
            
            fr.readAsDataURL(files[0]);

            context.setState({...context.state, profilePhoto: files[0]})
        }

    }

    const changeColourway = (e: any) => {
        if(!Object.values(Colour).includes(e.target.value)) return;
        Colourway.change(e.target.value);
        context.setState({...context.state, preferredColour: e.target.value});
    }

    const selectKpi = (selectedKpiValues: Option[]) => {
        const kpiLabels = Array.from(document.querySelectorAll(".form-check-label")!);

        // const kpiValues = selectedKpiValues.length > 2 ? selectedKpiValues.slice(1) : selectedKpiValues;

        for(const _label of kpiLabels) {
            const input = document.querySelector(`#kpi-check${_label.id.replace("kpi-label", "")}`) as HTMLInputElement;
            input.checked = false;
        }

        for(const value of selectedKpiValues) {
            for(const _label of kpiLabels) {
                if(_label.innerHTML === value.value) {
                    const input = document.querySelector(`#kpi-check${_label.id.replace("kpi-label", "")}`) as HTMLInputElement;
                    input.checked = true;
                }
            }
        }

        context.setState({...context.state, kpis: selectedKpiValues.map(kpi => kpi.value)})
        props.kpis.setKpis(selectedKpiValues)
    }

    return (
        <Container>
            <Grid container>
                <Grid item md={6} sm={6} xs={12} style={{padding: "10px 20px"}}>
                    <h2 className='profile-label'>About you</h2>
                    <p>Have a favorite selfie? Upload it now.</p>



                    <div className="profile-image-area"  onClick={enterFile}>
                        <div className="profile-image-edit">
                            <img className="profile-image-preview" src={context.state.profileUrl} alt="" />
                            <img className="profile-image-camera" src={Camera} alt=""/>
                        </div>
                    </div>

                    <input id="file-input" style={{display: "none"}} type="file" onChange={prepareFile}  accept=".png,.jfif,.jpeg,.jpg"/> 

                    <span>
                        <InputLabel className='profile-label-subtitle'>Name</InputLabel>
                        <TextField
                            fullWidth
                            margin="normal"
                            value={context.state.fullName}
                            onChange={(e) => context.setState({...context.state, fullName: e.target.value})}
                            variant="outlined"
                            required
                        />
                    </span>


                    <span>
                        <InputLabel className='profile-label-subtitle'>Job Title</InputLabel>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={context.state.jobTitle}
                            onChange={(e) => context.setState({...context.state, jobTitle: e.target.value})}
                            required
                        />
                    </span>

                    <span>
                        <InputLabel className='profile-label-subtitle'>Department</InputLabel>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={context.state.department}
                            onChange={(e) => context.setState({...context.state, department: e.target.value})}
                            required
                        />
                    </span>


                    <span>
                        <InputLabel className='profile-label-subtitle'>Email</InputLabel>
                        <TextField
                            fullWidth
                            type="email"
                            margin="normal"
                            variant="outlined"
                            value={context.state.email}
                            disabled
                            required
                        />
                        <InputLabel className='profile-label-subtitle'>Colourway</InputLabel>
                        <Select
                            variant="outlined"
                            defaultValue="placeholder"
                            fullWidth
                            required
                            style={{marginTop: "10px"}}
                            value={context.state.preferredColour}
                            onChange={(e) => changeColourway(e)}
                        >
                            <MenuItem value="placeholder" disabled>Colourway</MenuItem>
                            { Object.values(Colour).map((colour) => <MenuItem value={colour}>{colour[0].toUpperCase()}{colour.substring(1)}</MenuItem>)}
                        </Select>                
                    </span>

                    {/* <span>
                        <h2 className='profile-label' style={{marginTop: "50px"}}>Password</h2>
                        <TextField
                            fullWidth
                            type="password"
                            margin="normal"
                            variant="outlined"
                            // error={state.invalidCredentials}
                            // helperText={state.invalidCredentials && "Sorry we can't find an account with this email address. Do you want to check it and try again?"}
                            required
                        />
                    </span> */}
                </Grid>
                <Grid item md={6} sm={6} xs={12}  style={{padding: "10px 20px"}}>
                    <h2 className='profile-label'>Public Profile</h2>
                    
                    <InputLabel className='profile-label-subtitle'>Description</InputLabel>
                    <TextField
                      placeholder="Type here your description"
                      variant="outlined"
                      value={context.state.description}
                      onChange={(e) => context.setState({...context.state, description: e.target.value })}
                      multiline
                      rows={16}
                      rowsMax={16}   
                      style={{marginTop: "16px"}}    
                      fullWidth             
                    />
                    <h2 className='profile-label' style={{marginTop: "60px"}}>My Kpis</h2>
                    <MultiSelect
                        options={kpis.map((kpi) => {
                            return {value: kpi, label: kpi}
                        })}
                        value={props.kpis.kpis}
                        hasSelectAll={false}
                        onChange={selectKpi}
                        labelledBy="Select user"
                        className="user-input"
                        overrideStrings={{
                        selectSomeItems: "Select kpi"
                        }}
                        data-label="select-user"
                    />

                    <div className="data-kpis-selection" style={{marginTop: "50px", border: "none"}}>
                        <div className="kpis-list">
                            {kpis.map((val, index) => {
                                
                                if(props.kpis.kpis.length > 0) {
                                    for(const kpi of props.kpis.kpis) {
                                        if(val === kpi.value) {
                                            return (
                                                <div className="kpis-list-item" key={index}>
                                                    <input className="form-check-input" disabled type="checkbox" id={`kpi-check${index + 1 !== 1 ? index + 1 : 1}`} checked={true}/>
                                                    <label className="form-check-label" id={`kpi-label${index + 1 !== 1 ? index + 1 : 1}`} htmlFor={`kpi-check${index + 1 !== 1 ? index + 1 : 1}`}>
                                                    {val}
                                                    </label>
                                                    <div className="tags-item-hover">
                                                    <h5 className="tags-hover-title">{val}</h5>
                                                    <p className="tags-hover-description">
                                                        {kpisDescription.map(kpi => kpi.name === val ? kpi.description : "")}
                                                    </p>
                                                    </div>
                                                </div>
                                            )    
                                        }
                                    }
                                }

                                return (
                                    <div className="kpis-list-item" key={index}>
                                        <input className="form-check-input" disabled type="checkbox" id={`kpi-check${index + 1 !== 1 ? index + 1 : 1}`} />
                                        <label className="form-check-label" id={`kpi-label${index + 1 !== 1 ? index + 1 : 1}`} htmlFor={`kpi-check${index + 1 !== 1 ? index + 1 : 1}`}>
                                        {val}
                                        </label>
                                        <div className="tags-item-hover">
                                        <h5 className="tags-hover-title">{val}</h5>
                                        <p className="tags-hover-description">
                                            {kpisDescription.map(kpi => kpi.name === val ? kpi.description : "")}
                                        </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {/* <Select
                        variant="outlined"
                        defaultValue="placeholder"
                        fullWidth
                        required
                        style={{marginBottom: "20px"}}
                    >
                        <MenuItem value="placeholder" disabled>KPI</MenuItem>
                    </Select>    
                    <Select
                        variant="outlined"
                        defaultValue="placeholder"
                        fullWidth
                        required
                    >
                        <MenuItem value="placeholder" disabled>KPI</MenuItem>
                    </Select>     */}
                </Grid>
            </Grid>
        </Container>
    );
}