import React from 'react';
import '../Onboarding.scss';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

interface ThirdStepProps {
  kpis: string[];
  kpiChanged: CallableFunction;
}

const ThirdStep: React.FC<ThirdStepProps> = (props) => {
  const options = [
    { name: 'Acquisition', description: 'Casting your net to capture as many customers as possible.' },
    { name: 'Conversion', description: 'Transforming prospective customers into actual customers.' },
    { name: 'Engagement', description: "Maximise the visitor's experience of your site" },
    { name: 'Revenue', description: "Maximise the amount that your site earns" },
    { name: 'Retention', description: 'Keeping existing customers coming back for more.' },
    { name: 'Payoff', description: 'Reaping the rewards of landing your customers.' },
    { name: 'Referral', description: 'The customers you’ve landed spreading the word.' }
  ];

  return (
    <div className="onboarding-step-container">
      <h1 className="onboarding-step-title third-step">
        Which KPIs are most important to your work?
      </h1>
      <h2 className="onboarding-step-subtitle">
        Pick at least two as it helps us tailor your experience.<br />
        Don’t worry you can change them later.
      </h2>

      <FormControl className="onboarding-checkbox-group">
        <FormGroup>
          {options.map((kpi, index) => (
            <div className="onboarding-checkbox-item">
              <FormControlLabel
                className="onboarding-checkbox-label"
                control={<Checkbox name={kpi.name}
                  onChange={(e, isInputChecked) => props.kpiChanged(e.target.name, isInputChecked)}
                  icon={<UncheckedIcon />}
                  checked={props.kpis.indexOf(kpi.name) >= 0}
                  checkedIcon={<CheckCircleIcon />} />}
                label={kpi.name}
                key={index}
              />
              <div className="onboarding-kpi-hover">
                <h5 className="kpi-hover-title">{kpi.name}</h5>
                <p className="kpi-hover-description">{kpi.description}</p>
              </div>
            </div>
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default ThirdStep;