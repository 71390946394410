import React from 'react';
import { OnboardingProvider } from './OnboardingProvider';
import Onboarding from './Onboarding';
import { useEffect } from 'react';
import AppInsights, { loadAppContext } from '../../../components/AppInsights';
import { useLocation } from 'react-router-dom';
import { UserContext, UserContextProps } from '../../../components/UserProvider';

const _OnboardingConnector: React.FC = () => {
  const userContext = React.useContext<UserContextProps>(UserContext)
  const location = useLocation();

  useEffect(() => {
    loadAppContext(location, userContext);
  /* eslint-disable */
  }, []);
  /* eslint-enable */

  return (
    <OnboardingProvider>
      <Onboarding />
    </OnboardingProvider>
  )
}

export const OnboardingConnector = AppInsights(_OnboardingConnector);