import React from "react";
import { StoryComponentState } from "./StoryItemContracts";

export type StoryItemContextProps = {
    state: StoryItemContextState & StoryComponentState,
    setState: CallableFunction;
}

export const StoryItemContext = React.createContext<StoryItemContextProps>({
    state: {
        commentSelected: false,
        tabValue: 0,
        open: false,
        like: false,
        likeDecision: false,
        likeOutcome: false,
        storyMenu: null,
        decisionMenu: null,
        outcomeMenu: null,
        openStoryContent: false,
        comments: [],
        comment: "",
        likes: [],        
        numberLikes: 0,
        numberLikesDecision: 0,
        numberLikesOutcome: 0,
        commentId: '',
        commentsCount: 0
    },
    setState: () => null
});
interface StoryItemContextState {
    commentSelected: boolean;
    comment: string;
    commentId: string;
    commentsCount: number;
}

interface StoryItemProviderProps {
    likedByCurrentUser: boolean;
    likedByCurrentUserDecision?: boolean;
    likedByCurrentUserOutcome?: boolean;
    likesCount: number;
    likesCountDecision?: number;
    likesCountOutcome?: number;
}

export const StoryItemProvider: React.FC<StoryItemProviderProps> = (props) => {
    const [state, setState] = React.useState<StoryItemContextState & StoryComponentState>({
        commentSelected: false,
        tabValue: 0,
        open: false,
        like: props.likedByCurrentUser,
        likeDecision: props.likedByCurrentUserDecision ?? false,
        likeOutcome: props.likedByCurrentUserOutcome ?? false,
        storyMenu: null,
        decisionMenu: null,
        outcomeMenu: null,
        openStoryContent: false,
        comment: "",
        comments: [],
        likes: [],        
        numberLikes: props.likesCount,
        numberLikesDecision: props.likesCountDecision ?? 0,
        numberLikesOutcome: props.likesCountOutcome ?? 0,
        commentId: '',
        commentsCount: 0
    })
    return (
        <StoryItemContext.Provider value={{ state, setState: setState }}>
            {props.children}
        </StoryItemContext.Provider>
    )
}