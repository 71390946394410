import React, { Component } from 'react';
import './ForgottenPassword.scss';
import { Grid, Card, CardContent, InputLabel, TextField } from '@material-ui/core';
import ForgottenPasswordService from './ForgottenPasswordService';
import ResendEmail from './ResendEmail';

type RecoveryProps = {
  setParentState: (o: any) => void;
}

interface State {
  email: string;
  emailSent: boolean;
  disableSendAgain: boolean;
}

export default class ForgottenPassword extends Component<RecoveryProps, State> {
  constructor(props: RecoveryProps) {
    super(props);

    this.state = {
      email: '',
      emailSent: false,
      disableSendAgain: false
    }
  }

  getCurrentComponent() {
    return (
      !this.state.emailSent
        ? (
          <CardContent>
            <h1 className="login-container-title">Don't worry</h1>
            <form onSubmit={async (e) => await this.handleSubmit(e)}>
              <Grid item>
                <InputLabel className="login-input-label" htmlFor="recovery-email">
                  We'll send a recovery link to
              </InputLabel>
                <TextField
                  id="recovery-email"
                  fullWidth
                  type="email"
                  margin="normal"
                  placeholder="E-mail"
                  variant="outlined"
                  required
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Grid>

              <Grid item className="login-buttons">
                <button className="btn-outlined-wipe" onClick={() => this.props.setParentState({ mode: 'login' })}>
                  Back
                </button>
                <button className="btn-contained-wipe" type="submit">
                  Send e-mail
                </button>
              </Grid>
            </form>
        </CardContent>
      )
      : (
        <ResendEmail 
          email={this.state.email} 
          disableSendAgain={this.state.disableSendAgain}
          submit={async () => await this.doSubmit(true) }
          setParentState={() => this.props.setParentState({ mode: 'login' })}/>
      )
    ); 
  }

  async doSubmit(sendAgain: boolean = false) {
    const response = await ForgottenPasswordService.sendMailLink(this.state.email);

    if (response.status !== 200) {
      return;
    }

    let state = { ...this.state };
    state = { ...state, emailSent: true };

    if (sendAgain) {
      state = { ...state, disableSendAgain: true };
    }

    this.setState(state);
  }

  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    await this.doSubmit();
  }

  render() {
    return (
      <Grid container>
        <Card className="recovery-card login-content-card">
          {this.getCurrentComponent()}
        </Card >
      </Grid >
    );
  }
}