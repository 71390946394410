import React from 'react'
import './Wizard.scss';
import { Dialog, DialogTitle, DialogActions, CircularProgress } from '@material-ui/core';
import { DataStoryContext, DataStoryProps } from '../../../../DataStoryProvider';
import LoginService from '../../../../../../Login/LoginService';
import ScribeyStory from './ScribeyStory/ScribeyStory';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const Wizard: React.FC = () => {
  const context = React.useContext<DataStoryProps>(DataStoryContext);
  const [generatingStory, setGeneratingStory] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = React.useState<number>(0);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  /* eslint-disable */
  const [displayStoryForm, setDisplayStoryForm] = React.useState(false);
  /* eslint-enable */
  const [currentAnswer, setCurrentAnswer] = React.useState(
    context.state.draftStory.wizardAnswers[0] ?? ''
  );

  // noinspection DuplicatedCode
  const wizardQuestions = [
    {
      question: 'The Main Character',
      title: `• What is the main character of your data?<br/>
              • What is this character typically like?<br/>
              • Any notable recent behaviours?`,
      text: 'Every data story needs a main character.  Character and plot are inseparable because a person is what happens to them. Without a clear sense of who a character is and how they usually behave, the reader will not appreciate the significance of your events. If there have been any recent changes in that behaviour, you should also add this.  Now that your audience understands what’s typical or expected, they can appreciate the impact of the changes.',
      example: 'Mondays sales and typically the lowest sales of the week. Recently has been performing lower than expected.'
    },
    {
      question: 'Hook',
      title: `• What happened to the character?<br/>
              • What was the magnitude of this occurrence?<br/>
              • Is it continuing a trend, breaking a trend, starting a new one or the same as before?`,
      text: 'The previous section armed the readers with crucial background information on the main character.  Now, something has happened. This is the inciting incident that is the turning point of the story.  What you write here will make your audience lean in.  It is the incident that starts the unveiling journey towards the problem or the opportunity.  Every story needs something noteworthy to happen to the main character, something that is worth digging into to understand why or what the impact of this incident will be!',
      example: 'Monday sales are up significantly against last week, about 30% up. Breaking the downward trend that we have recently seen.'
    },
    {
      question: 'Peel the onion',
      title: `• What can you eliminate as causes for this occurrence?`,
      text: 'Readers will always have suspects for the cause.  It is worth squashing those as soon as possible. This is where you do it.  The goal here is to peel back the layers of exploration as you move to eventually land on the climax.',
      example: 'New users have performed as normal, but returning users see a difference in behaviour.  All channels that returning users are coming from are performing as expected except for emails.'
    },
    {
      question: 'Climax',
      title: `• What was the cause of this incident?<br/>
              • Is this how this character typically behaves?<br/>
              • How far away is this from expectations?<br/>
              • What is the impact on our KPI's?`,
      text: 'Introduce the responsible character(s) in your significant discovery.  The one(s) responsible for this inciting incident and the business impact that they have caused.  As you did in the setting, it is worth talking about how this character typically behaves and how they have behaved recently to build an understanding of expectation and help create a connection between your audience and your discovery.  Here, you help the reader understand why they should care and get them to understand the significance of your discovery.',
      example: 'Last weeks email campaign is the main driver for the changes that we have seen, usually, emails only drive 4% of the sales on Mondays. However, this Monday, we have seen a 45% increase in sales from emails compared to what we would typically expect. Looking forward, this will have a reasonably large impact on the total expected sales for the week.'
    },
    {
      question: 'Solution',
      title: `• Is this an issue or an opportunity that we can influence?<br/>
              • What are some key actions to consider doing next?<br/>
              • Why should we consider them?`,
      text: 'Your data story must share how the audience should leverage the new insight.  Start by reminding the reader if this is an opportunity or an issue.  Then consider how you move them to action.  This step leans heavily on the context that you selected at the start of this story.  Using the actions that someone responsible for this KPI would consider, you can suggest different options, solutions or next steps.  Being proactive with offering the next steps helps deliver truly valuable insights.',
      example: 'This seems to be an untapped opportunity in email campaigns for returning users, we should look to increasing the number of emails sent on the weekend as it seems to have impacted the traffic and sales. Email users this week converted better than average, the traffic from the emails had lower bounce rates, and they stayed on the platform longer than anyone else.  They exhibited the behaviour we want to see in our users.'
    },
  ]

  const prepareParagraphs = (_responses: string[]): string[] => {
    return [
      _responses.map(x => x).join(". ").trim()
    ]
  }

  // noinspection DuplicatedCode
  const AIGenerateStory = async () => {
    const url = `${BASE_URL}/api/paraphraser`;

    const token = LoginService.getToken();

    setGeneratingStory(true);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? 'Bearer ' + token : "",
      },
      body: JSON.stringify({
        paragraphs: prepareParagraphs(
          context.state.draftStory.wizardAnswers
        )
      })
    });
    // noinspection DuplicatedCode
    setGeneratingStory(false);
    if (response.status !== 200) {
      return;
    }

    const responsePayload = await response.json();

    const _resp = responsePayload.paragraphSuggestions

    const AIAnswers: string[] = []

    for(const options of _resp) {
      for(const index in options) {
        options[index].forEach((item: string) => {
          AIAnswers.push(item.trim())
        })
      }
    }

    context.setState({
      ...context.state,
      draftStory: {
        ...context.state.draftStory,
        AIAnswers: AIAnswers,
        showStory: true
      }
    })
    setDisplayStoryForm(true);
  }

  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  if (context.state.draftStory.showStory) {
    return (<ScribeyStory />);
  }

  return (
    <div className="scribey-container">
      <div className="scribey-header">
        <h3 className="data-container-title">
          Scribey - we ask you 5 key questions and draft the story for you
        </h3>
      </div>

      <div className="scribey-steps-container">
        {wizardQuestions.map((item, i) => (
          <span
            className={`scribey-step-item ${i <= currentQuestion ? 'active' : ''}`}
            key={i}
            onClick={() => {
              if (i <= currentQuestion) {
                setCurrentQuestion(i)
                setCurrentAnswer(
                  context.state.draftStory.wizardAnswers[i] ?? ""
                )
              }
            }}
          >
            {i + 1}
          </span>
        ))}
      </div>

      <div className="data-scribey-question" style={{ minHeight: '60vh' }}>
        <h3 className="data-container-title data-scribey-title">
          {wizardQuestions[currentQuestion].question}
        </h3>

        <p className="data-scribey-text">{wizardQuestions[currentQuestion].text}</p>
        <h3 className="data-container-title" dangerouslySetInnerHTML={{__html: wizardQuestions[currentQuestion].title}} />

        <div className="data-scribey-example">
          <p className="data-scribey-example-title">Example</p>
          <p className="data-scribey-example-description">{wizardQuestions[currentQuestion].example}</p>
        </div>

        <textarea
          tabIndex={currentQuestion + 1}
          className="data-scribey-textarea"
          value={currentAnswer}
          placeholder="Type here" name="" id="" onChange={(e) => {
            setCurrentAnswer(e.target.value);
            const _newResponses = [...context.state.draftStory.wizardAnswers];
            _newResponses[currentQuestion] = e.target.value;
            context.setState({ ...context.state, draftStory: {
              ...context.state.draftStory,
              wizardAnswers: _newResponses
            }});
          }}>
        </textarea>
      </div>

      <Dialog
        className="data-scribey-modal"
        open={openCancelModal}
        onClose={handleCloseCancelModal}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to leave Wizard mode?
        </DialogTitle>

        <DialogActions>
          <button className="btn-outlined-wipe" onClick={(e: any) => context.setState({ ...context.state, 
            step: 2,
            subStep: 1
          })}>
            Yes, I'm sure
          </button>
          <button className="btn-contained-wipe" onClick={handleCloseCancelModal}>
            No, take me back
          </button>
        </DialogActions>
      </Dialog>

      <div className="data-story-buttons">
        <button className="btn-outlined-wipe" onClick={handleOpenCancelModal}>
          Cancel
        </button>

        <div className="data-scribey-buttons">
          {currentQuestion !== 0 && (
            <button className="btn-outlined-wipe" onClick={() => {
              setCurrentQuestion(currentQuestion - 1)
              setCurrentAnswer(
                context.state.draftStory.wizardAnswers[currentQuestion - 1] ?? ''
              )
            }}>
              Previous Question
            </button>
          )}

          {currentQuestion < 4 ? (
            <button className="btn-contained-wipe" onClick={() => {
              setCurrentQuestion(currentQuestion + 1)
              setCurrentAnswer(
                context.state.draftStory.wizardAnswers[currentQuestion + 1] ?? ''
              )
            }}>
              Next Question
            </button>
          )
            : (
              <button className="btn-contained-wipe" onClick={(e) => {
                (async function () {
                  await AIGenerateStory()
                })()
              }}>
                Generate
              </button>
            )}
        </div>
        {generatingStory &&
          <div className="overlay-loading">
            <CircularProgress style={{ 'color': 'white' }} />
            <br />
            <span className="overlay-text">
              Scribey is just generating your story
            </span>
          </div>
        }
      </div>
    </div >
  );
}

export default Wizard;