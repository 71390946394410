import React, { useEffect } from 'react';
import './Users.scss';
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, OutlinedInput } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AdminService from '../AdminService';
import { OptionsMenu } from './OptionsMenu';
import { ActiveUser, Company, UserRegisterStatus } from '../../DataStory/v1/DataStoryContracts';
import { UserType } from '../../../components/UserProvider';

export interface Row {
  id: number;
  email: string;
  name: string;
  role: string;
  userType: string;
  userRegisterStatus: UserRegisterStatus;
}

const Users: React.FC = () => {
  const [rows, setRows] = React.useState<Row[]>([]);

  useEffect(() => {
    AdminService.getCompany().then((company: Company) => {

      const adminId = company.admin.id;
      const rows = company.activeUsers.map((user: ActiveUser) => {
        const role = adminId === user.id ? "Admin" : "User";
        const type = user.type === UserType.ANALYST ? "Storyteller" : "Story Reader";

        return {
          id: user.id,
          email: user.email,
          name: user.fullName, role: role, userType: type,
          userRegisterStatus: user.userRegisterStatus
        } as Row;
      });

      setRows(rows)
    })
  }, [])

  function updateRow(row: Row, newStatus: UserRegisterStatus) {

    const index = rows.indexOf(row);
    const copy = [...rows];
    copy[index] = { ...row, userRegisterStatus: newStatus };

    setRows(copy);
  }

  return (
    <>
      <div className="admin-selected-content-header">
        <h1 className="admin-selected-content-title">Users</h1>

        <Link to="/add-users" className="btn-contained-wipe admin-users-button">
          Invite People
        </Link>
      </div>

      <div className="admin-users-subheader">
        <span className="admin-users-subheader-text">Number of Users: {rows.length}</span>
        <OutlinedInput
          id="search-input"
          className="admin-users-search-input"
          type="text"
          placeholder="Search Users"
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon className="admin-users-search-input-icon" />
            </InputAdornment>
          }
        />
      </div>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="admin-users-table-head">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Registration Status</TableCell>
              {/* <TableCell>Joined</TableCell> */}
              {/* <TableCell>Last Login</TableCell> */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="admin-users-table-body">
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>{row.userType}</TableCell>
                <TableCell>{renderRegisterStatus(row.userRegisterStatus)}</TableCell>
                <TableCell>
                  {row.userRegisterStatus !== UserRegisterStatus.CONFIRMATION_EMAIL &&
                    <OptionsMenu row={row} updateRow={updateRow} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}

const renderRegisterStatus = (status: UserRegisterStatus): string => {
  switch (status) {
    case UserRegisterStatus.APPROVED:
      return "Approved";
    case UserRegisterStatus.CONFIRMATION_EMAIL:
      return "Pending email confirmation";
    case UserRegisterStatus.PENDING_ADMIN_APPROVAL:
      return "Pending admin approval";
    default:
      throw new Error("Failed to get the right status on enum");
  }
}


export default Users;