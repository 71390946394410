import "../../Dashboard.scss";
import './StoryItem.scss';
import { Tab, Tabs, Dialog } from "@material-ui/core";

import CloseIcon from '../../../../assets/images/close-icon.svg';

import React  from "react";
import TabPanel from "./TabPanel";
import Comments, { PostType } from "../QuestionItem/Comments/Comments";
import { DialogContext, DialogContextProps } from "../QuestionItem/DialogProvider";
import FocusedComment from "../QuestionItem/Comments/FocusedComment";
import Likes from "../QuestionItem/Likes/Likes";

interface DialogProps {
    author: string,
    comments: any,
    likes: any[],
    liked: boolean
    type: PostType
    onClose?: CallableFunction
}

const CommentsLikesDialog: React.FC<DialogProps> = (props) => {
    const context = React.useContext<DialogContextProps>(DialogContext);

    const close = () => {
        context.setState({ ...context.state, open: false })
        if (props.onClose)
            props.onClose();
    }

    return (
        <Dialog key={context.state.id} onClose={close}
            open={context.state.open} className="story-modal" maxWidth="sm"
            aria-labelledby="Likes and Comments modal" aria-describedby="Likes and Comments modal">
            <span onClick={close} className="story-modal-close">
                <img src={CloseIcon} alt="" />
            </span>
            <div className="story-modal-container">
                <Tabs value={context.state.tabValue} onChange={(e: any, newValue) => {
                    context.setState({ ...context.state, tabValue: newValue });
                }} aria-label="Data Story Tabs">
                    {
                        !context.state.commentSelected ? (
                            [
                                <Tab label="Likes" className="story-nav-item" />,
                                <Tab label="Comments" className="story-nav-item" />,
                                <Tab label="Back" className="story-nav-item back" style={{ visibility: "hidden" }} />
                            ]
                        ) : (
                            [
                                <Tab label="Back" className="story-nav-item back"
                                    onClick={(e) => context.setState({ ...context.state, commentSelected: false, tabValue: 1 })}
                                />,
                                <Tab label="Likes" className="story-nav-item" style={{ visibility: "hidden" }} />,
                                <Tab label="Comments" className="story-nav-item" style={{ visibility: "hidden" }} />

                            ]
                        )
                    }

                </Tabs>
                <TabPanel value={context.state.tabValue} index={0}>
                    <Likes likes={props.likes} liked={props.liked} dataStoryId={context.state.id} type={props.type} />
                </TabPanel>
                <TabPanel value={context.state.tabValue} index={1}>
                    <Comments liked={props.liked}
                        comments={props.comments}
                        dataStoryId={context.state.id} author={props.author}
                        type={props.type} />
                </TabPanel>
                <TabPanel value={context.state.tabValue} index={2}>
                    <FocusedComment
                        comments={props.comments}
                        liked={props.liked}
                        dataStoryId={context.state.id}
                        author={props.author}
                        type={props.type} />
                </TabPanel>
            </div>
        </Dialog>
    )
}

export default CommentsLikesDialog;