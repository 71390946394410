import { UserType } from "../../../components/UserProvider";

export interface DataStoryState {
    step: number;
    name: string;
    id: string;
    kpis: string[];
    jobTitle: string;
    department: string;
    description: string;
    users: number[];
    addressedUserId: number | null;
    selectedData: string;
    sentiment: string;
    showHelpText : boolean;
    showPreviewTable : boolean;
    disabled: boolean;
    data: any;
    filename: string;
    title: string;
    subtitle: string;
    insight: string;
    anomalyReason: string;
    impactExplanation: string;
    suggestions: string;
    choosenDepartment: string;
    choosenKpi: string;
    choosenPriority: string;
    currentSelected: any,
    tagged: Array<any>,
    graphType: string,
    rows: string[],
    cols: string[],
    aggregatorName: string,
    dictionaryGraphData: any,
    columns: Object,
    freedomMode: boolean;
    freedomGraphImage: File | null;
}

export interface StoryResponse {
    data: StoryPayload;
}

export interface FeedResponse {
    data: FeedPayload
}

interface StoryPayload {
    story: Story;
}

interface FeedPayload {
    feed: Feed[];
}

interface Story {
    comments: Comment[];
}

interface Feed {
    id: string;
    title: string;
    subtitle: string;
    insight: string;
    pivotState: string;
    anomalyReason: string;
    impactExplanation: string;
    suggestions: string;
    commentsCount: number;
    likesCount: number;
    likedByCurrentUser: boolean;
    comments: Comment[];
    likes: Like[];
    author: Author;
    kpis: string[];
    publishedDate: Date;
}

export interface Comment {
    id: string;
    parentCommentId: string | null;
    text: string;
    user: UserInfo;
    liked: boolean;
    likes: Array<any>;
}

export interface Like {
    user: UserInfo
}

interface UserInfo {
    id: number;
    fullName: string;
    jobTitle: string;
    department: string;
    profileUrl: string;
}

interface Author {
    fullName: string;
    profileUrl: string;
}

export interface CommentStory {
    comment: string;
    dataStoryId: string;
    parentCommentId: string | null;
}

export interface QueryResult {
    data: ResponsePayload
}

interface ResponsePayload {
    me: User[]
}

interface User {
    id: string;
    fullName: string;
    company: Company;
}

export interface Company {
    activeUsers: ActiveUser[];
    name: string;
    domain: string;
    admin: User
}

export interface ActiveUser {
    id: any;
    email: string;
    fullName: string;
    profileUrl: string;
    department: string;
    jobTitle: string;
    type: UserType;
    description: string;
    userRegisterStatus: UserRegisterStatus;
    userKpis: Array<any>;
}

export enum UserRegisterStatus{
    CONFIRMATION_EMAIL = "CONFIRMATION_EMAIL",
    PENDING_ADMIN_APPROVAL = "PENDING_ADMIN_APPROVAL",
    APPROVED = "APPROVED",
}

interface StoryText {
    insight: string;
    anomalyReason: string;
    impactExplanation: string;
    suggestions: string;
}

export interface SendStory {
    id: string;
    addressedUserId: number | null;
    title: string;
    pivotState: string;
    storyContent: StoryText;
    taggedUsersIds: number[];
    kpis: string[];
}

export interface GenerateText {
    pivotState: string;
    columns: string;
    cols: string[];
    rows: string[];
    data: string;
    file: string;
    graphType: string;
    aggregatorName: string;
    dictionaryGraphData: any;
}

export interface GeneratedText {
    title: string;
    subtitle: string;
    storyContent: StoryText;
}