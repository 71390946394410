import React from 'react';
import './TermsConditions.scss';
import Header from '../../../components/Header/Header';
import { Container, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router';

interface TermsConditionsProps {
  setStep: CallableFunction;
  isRegistration: boolean;
}

const TermsConditions: React.FC<TermsConditionsProps> = (props) => {
  const history = useHistory();

  const exitTerms = () => {
    if (!props.isRegistration) {
      history.push("/");
      return;
    }
    props.setStep(2);
  }

  return (
    <div className="terms-container">
      { !props.isRegistration ? <Header /> : <></> }

      <Container className="terms-container-content">
        <Grid container className="terms-card-content">
          <Grid item className='terms-back-button'>
            <Button variant="outlined" onClick={() => exitTerms()}>
              {"x"}
            </Button>
          </Grid>
          <h1 className="terms-content-title">
            Terms & conditions
            </h1>

          <div className="terms-text-content">
            <h4>
              Article Title orem ipsum dolor sit amet, consectetur adipiscing trend lorem
              ipsum dolor sit amet, consectetur.
              </h4>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.
              </p>
            <h4>
              Article Title orem ipsum dolor sit amet, consectetur adipiscing trend lorem
              ipsum dolor sit amet, consectetur.
              </h4>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.
              </p>
            <h4>
              Article Title orem ipsum dolor sit amet, consectetur adipiscing trend lorem
              ipsum dolor sit amet, consectetur.
              </h4>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.
              </p>
          </div>
        </Grid>
      </Container>
    </div>

  );
}

export default TermsConditions;