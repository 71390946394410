import React, { useEffect } from 'react';
import './AddUsers.scss';
import Header from '../../components/Header/Header'
import { Container, Grid, InputLabel, TextField, Button } from '@material-ui/core';
import CloseIcon from '../../assets/images/close-icon.svg';
import { invalidEmail } from '../Register/Welcome/WelcomeMethods';
import AddUsersService from './AddUsersService';
import { useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginService from '../Login/LoginService';

import AppInsights, { loadAppContext } from '../../components/AppInsights';
import { UserContext, UserContextProps } from '../../components/UserProvider';

const getBaseUrl = () => window.location.protocol + "//" + window.location.host + "/register";

const AddUsers: React.FC = () => {
    const history = useHistory()
    const location = useLocation();

    const invitationCode = getBaseUrl();
    const [copiedCode, setCopiedCode] = React.useState<boolean>(false);
    const [messageSent, setMessageSent] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("")
    const [emails, setEmails] = React.useState<string[]>([])
    const [companyDomain, setCompanyDomain] = React.useState<string>("");

    const userContext = React.useContext<UserContextProps>(UserContext);

    const copyCode = () => {
        const textArea = document.createElement("textarea");
        textArea.value = invitationCode;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');

        try {
            document.execCommand('copy');
            setCopiedCode(true)
        } catch (err) {
        }
        document.body.removeChild(textArea);
    }

    const addEmail = () => {
        if (email.length === 0) return;
        if (invalidEmail(email)) {
            toast.warning("Invalid email.");
            return;
        }
        setEmails([...emails.filter(e => e !== email), email])
        setEmail("");
    }

    const removeEmail = (_email: string) => {
        setEmails([...emails.filter(email => email !== _email)])
    }

    useEffect(() => {
        AddUsersService.getCompany().then((company) => {
            setCompanyDomain(company.domain);
        });
    /* eslint-disable */
    }, [LoginService.getToken()])
    /* eslint-enable */

    useEffect(() => {
        loadAppContext(location, userContext);
    /* eslint-disable */
    }, [])
    /* eslint-enable */

    const invite = async () => {
        const response = await AddUsersService.invite(emails)

        if (response.status !== 200) {
            toast.warning("Error while inviting.");
            return;
        }
        setMessageSent(true)

        setTimeout(() => {
            history.push("/dashboard");
        }, 50)
    }

    const skip = async () => {
        history.push("/dashboard");
    }

    return (
        <div className="addusers-container">
            <Header />
            <Container className="addusers-content">
                <h1 className="addusers-content-title">
                    Decidable is designed to get people talking, so invite other users.
                </h1>

                <Link to="/" className="addusers-close">
                    <img src={CloseIcon} alt="" />
                </Link>

                <Grid container className="addusers-inputs-container-margin">
                    <InputLabel className="addusers-input-label">
                        (They must have a {companyDomain} address.)
                    </InputLabel>
                    <Grid item md={9} sm={9} className="addusers-code-span">
                        <span className="code">{invitationCode}</span>
                    </Grid>
                    <Grid item md={3} sm={3} className="addusers-copy-button">
                        <button type="button" onClick={copyCode}>Copy link</button>
                    </Grid>

                    <div className="addusers-status-message">
                        {copiedCode && <span>Copied to clipboard</span>}
                    </div>

                </Grid>

                <Grid container className="addusers-inputs-container-margin">
                    <InputLabel className="addusers-input-label">
                        Or invite them via email
                    </InputLabel>
                    <Grid item md={12} sm={12} className="addusers-email-selection">
                        <Grid container>
                            <Grid item md={9} sm={9}>
                                <TextField
                                    id="email-input"
                                    fullWidth
                                    className="addusers-email-input"
                                    placeholder="Type an email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyUp={(e) => e.key === "Enter" && addEmail()}
                                />
                            </Grid>
                            <Grid item md={3} sm={3} className="addusers-add-button">
                                <button type="button" onClick={(e) => addEmail()}>
                                    Add email
                                </button>
                            </Grid>
                        </Grid>
                        {/* SELECTED USER */}
                        {emails.map((email) => (
                            <>
                                <div className="selected-email" style={{ backgroundColor: "#ddd" }}>
                                    <span className="user-email">{email}</span>&nbsp;
                                    <Button className="user-delete" variant="outlined" onClick={(e) => removeEmail(email)}>x</Button>
                                </div>
                            </>
                        )
                        )}
                    </Grid>
                    <div className="addusers-status-message">
                        {messageSent && <span>Email(s) sent</span>}
                    </div>
                </Grid>

                <Grid container className="addusers-buttons">
                    <button className="btn-outlined-wipe" type="button" onClick={(e) => skip()}>
                        Skip
                    </button>
                    <button className="btn-contained-wipe" type="button" onClick={(e) => invite()}>
                        Send Invite(s)
                    </button>
                </Grid>
            </Container>
            <ToastContainer />
        </div>
    );
}

export default AppInsights(AddUsers);