import React, { useEffect } from 'react';
import './Register.scss';
import Header from '../../components/Header/Header'
import { Container } from '@material-ui/core';
import RegisterService, { ErrorResponse, RegisterResponse } from './RegisterService';
import { getStep } from './Steps';
import { useHistory } from 'react-router';
import LoginService from "../Login/LoginService";
import AppInsights, { loadAppContext } from '../../components/AppInsights';
import { useLocation } from 'react-router-dom';

export interface RegisterState {
  step: number;
  name: string;
  email: string;
  company: string;
  jobTitle: string;
  password: string;
  passwordConfirmation: string;
  adminName: string;
  status: string;
  freeTrialToken: string;
}

const Register: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState<RegisterState>({
    step: 1,
    name: "",
    email: "",
    company: "",
    jobTitle: "",
    password: "",
    passwordConfirmation: "",
    adminName: "",
    status: "",
    freeTrialToken: ""
  })

  const loadToken = (queryString: string[][]) => {
    queryString.forEach((item) => {
      if(item[0] === "freeTrialToken") {
        setState({...state, freeTrialToken: item[1]});
      }
    })
  }

  const getQueryString = () => location.search.replace("?", "").split("&").map((item: any) => item.split("="))

  const register = async (handleErrorCallback: CallableFunction, finishingCallback: CallableFunction) => {
    const response = await RegisterService.register({
      fullName: state.name.trim(),
      email: state.email.trim(),
      companyName: state.company.trim(),
      password: state.password.trim(),
      userType: state.jobTitle.trim(),
      freeTrialToken: state.freeTrialToken.length > 0 ? state.freeTrialToken : null
    });

    if(response.status !== 200) {
      const errors: ErrorResponse = await response.json();
      if(handleErrorCallback) {
        // noinspection JSUnusedAssignment
        handleErrorCallback(errors.errors.reduce((acc, e) => acc += e.info , ""))
      }
      return;
    }
    const success: RegisterResponse = await response.json();
    setState({...state, adminName: success.adminName, status: success.status, step: 3 });
    finishingCallback();
  }

  useEffect(() => {
    const queryString = getQueryString();
    loadToken(queryString);
    loadAppContext(location);
    if(LoginService.isUserAuthenticated()) {
      history.push("/dashboard");
    }
  /* eslint-disable */
  }, []);
  /* eslint-disable */

  return (
    <div className="register-container">
      <Header />

      <Container className="register-container-content">
        { getStep(state.step, state, setState, register) }
      </Container>
    </div>
  );
}

export default AppInsights(Register);