import DataStoryService from "../../../../../../v1/DataStoryService";
import { DataStoryProps } from "../../../../../DataStoryProvider";


export const generateText = async (props: any) => {
  const response = await DataStoryService.generateText(props.state.id, {
    file: props.state.filename,
    cols: props.state.cols,
    rows: props.state.rows,
    columns: JSON.stringify(props.state.columns),
    data: "",
    pivotState: "",
    graphType: props.state.graphType,
    aggregatorName: props.state.aggregatorName,
    dictionaryGraphData: JSON.stringify(props.state.dictionaryGraphData)
  });

  if (response.status !== 200) {    
    return null;
  }

  const responseData = await response.json();
  return responseData;
}

export const saveStory = async (context: DataStoryProps) => {
  const response = await DataStoryService.saveStory({
    id: context.state.id,
    // addressedUserId: context.state.addressedUserId || 0,
    addressedUserId: context.state.draftStory.addressedUserId,
    kpis: [context.state.context.kpi],
    title: context.state.draftStory.title ?? "",
    // taggedUsersIds: context.state.tagged.map((u: any) => u.id),
    taggedUsersIds: [],
    pivotState: JSON.stringify(null),
    storyContent: {
      anomalyReason: context.state.draftStory.anomalyReason ?? "",
      impactExplanation: context.state.draftStory.impactExplanation ?? "",
      insight: context.state.draftStory.insight ?? "",
      suggestions: context.state.draftStory.suggestions ?? ""
    }
  });

  return response.status === 200;
}
