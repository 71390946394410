import React from "react";
import Header from "../../../../../components/Header/Header";
import {OnboardingContext, OnboardingContextProps} from "../../OnboardingProvider";
import LoginService from "../../../../Login/LoginService";
import {CircularProgress} from "@material-ui/core";
import {finishOnboarding} from "../../Onboard";
import {UserContext, UserContextProps} from "../../../../../components/UserProvider";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const FourthStep = () => {
    const userContext = React.useContext<UserContextProps>(UserContext);
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);

    const [generatingStories, setGeneratingStories] = React.useState<boolean>(true);

    React.useEffect(() => {
        const viewId = context.state.viewId;
        if(viewId) {
            const url = `${BASE_URL}/api/scheduled-story/schedule-default-stories?viewId=${viewId}`;
            const token = LoginService.getToken();

            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': token ? 'Bearer ' + token : "",
                },
            }).catch((e) => {
                console.log(e);
            });

            finishOnboarding(userContext, context, true);
            setGeneratingStories(false);
        }
    /* eslint-disable */
    }, [])
    /* eslint-enable */

    return (
        <div className="onboarding-container">
            <Header />
            <div className="onboarding-container-content">
                <div className="onboarding-step-container" style={{textAlign: "center"}}>
                    <h1 className="onboarding-step-first-text">
                        Do not worry, we are generating stories for you. You are going to be redirected in a couple of seconds.
                    </h1>

                    {generatingStories && <CircularProgress style={{ color: 'white' }} /> }

                </div>
            </div>
        </div>
    )
}

export default FourthStep;