import React from "react";
import Header from "../../../../../components/Header/Header";
import {Account, OnboardingContext, OnboardingContextProps} from "../../OnboardingProvider";
import {Checkbox, FormControl, FormControlLabel, Grid} from "@material-ui/core";
import UncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const generateAccountsList = (accounts: Account[], context: OnboardingContextProps) => {
    return accounts.map((account) => (
            account.webProperties.map(webProperty => (
                webProperty.profiles.map(profile => (
                    <FormControlLabel
                        className="onboarding-checkbox-label"
                        control={
                            <Checkbox name={profile.name}
                                      onChange={() => {
                                          context.setState({...context.state, viewId: profile.id});
                                      }}
                                      icon={<UncheckedIcon />}
                                      checked={profile.id === context.state.viewId}
                                      checkedIcon={<CheckCircleIcon />} />
                        }
                        label={` ${account.name} - ${profile.name}`}
                        key={`${account.id}_${profile.id}`}
                    />
                ))
            ))
    ));
}

const ThirdStep = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);

    return (
        <div className="onboarding-container">
            <Header />
            <div className="onboarding-container-content">
                <div className="onboarding-step-container">
                    <h1 className="onboarding-step-first-text">
                        Select the account that you want stories from:
                    </h1>
                    <FormControl className="onboarding-checkbox-group">
                        {
                            generateAccountsList(context.state.GAPayload!.accounts, context)
                        }
                    </FormControl>


                    <Grid container style={{marginTop: '40px'}}>
                        <Grid item md={9} />
                        <Grid item md={3}>
                            <div className="onboarding-content-footer">
                                <div className="onboarding-buttons">
                                    <button className="btn-contained-wipe" onClick={() => {
                                        context.setState({
                                            ...context.state,
                                            step: 3
                                        });
                                    }}>
                                        Generate Stories
                                    </button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </div>
        </div>
    )
}

export default ThirdStep;