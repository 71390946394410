
const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const ForgottenPasswordService = {
    sendMailLink: async function (email: string): Promise<Response> {
        const url = `${BASE_URL}/api/user/reset-password`
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: '"' + email.trim() + '"'
        });
    },

    changePassword: async function(changePassword: any): Promise<Response>{
        const url = `${BASE_URL}/api/user/change-password`
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(changePassword)
        });
    }
};

export default ForgottenPasswordService