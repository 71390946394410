import React from "react";

import { Grid } from "@material-ui/core";

import plusIcon from "../../../../assets/images/plus-icon.svg";
import minusIcon from "../../../../assets/images/minus-icon.svg";

const HelperContainer: React.FC<any> = (props) => {
    return (            
        <div style={{border: "1px solid #ccc"}}>
            <Grid container style={{backgroundColor: "#d8d8d8", paddingTop: "30px", paddingBottom: "10px"}}>
                <Grid item md={2}/>
                <Grid item md={8}>
                    <strong>{ props.question ?? ""}</strong>
                </Grid>

                <Grid item md={2}>
                    <div style={{marginTop: "-12px"}}>
                        { props.showHelpText ? (
                            <img 
                                src={minusIcon} 
                                className="toggle-icon" 
                                style={{width: "20%"}}
                                onClick={(e) => props.toggleHelpText()}
                                alt=""
                            />
                        ) : (
                            <img 
                                src={plusIcon} 
                                className="toggle-icon" 
                                style={{width: "20%"}}
                                onClick={(e) => props.toggleHelpText()}
                                alt=""
                            />
                        ) }
                    </div>
                    {/* HELP TEXT */}
                    
                </Grid>
            </Grid>
                
            { props.showHelpText && (
                <Grid container style={{paddingTop: "5px", paddingBottom: "5px"}}>
                    <Grid item md={2} />
                    <Grid item md={10} >
                            <ul>
                                {props.helpText.map((item: string) => <li>{item}</li>)}
                            </ul>
                    </Grid>
            </Grid>
            )}
        </div>
    )
}

export default HelperContainer;