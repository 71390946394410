import React, { useEffect } from "react";
import './UserProfile.scss';
import Header from '../../components/Header/Header';
import { Container, Grid, Tabs, Tab } from "@material-ui/core";

import { ProfileTab } from "./ProfileTab";
import { UserContext, UserContextProps } from "../../components/UserProvider";
import LoginService from "../Login/LoginService";
import OnboardingService from "../Onboarding/v1/OnboardingService";
import { toast } from "react-toastify";
import { Option } from "react-multi-select-component";

const UserProfile: React.FC = () => {
  const context = React.useContext<UserContextProps>(UserContext);
  const [value, setValue] = React.useState(0);

  const [selectedKpis, setSelectedKpis] = React.useState<Option[]>([])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    LoginService.getUserName(() => null)
      .then(data => {
        const _kpis: any = data.userKpis.map(kpi => `${kpi.kpi[0]}${kpi.kpi.slice(1).toLowerCase()}`)

        context.setState({
          ...context.state,
          fullName: data.fullName,
          jobTitle: data.jobTitle,
          department: data.department,
          email: data.email,
          profileUrl: data.profileUrl,
          preferredColour: data.preferredColour,
          description: data.description,
          kpis: _kpis
        })

        setSelectedKpis(_kpis.map((kpi: any) => {
          return {value: kpi, label: kpi}
        }));
      })
  /* eslint-disable */
  }, [])
  /* eslint-enable */

  const saveProfile = async () => {
    const response = await OnboardingService.saveOnboarding({
      preferredColour: context.state.preferredColour,
      lineManager: "",
      userName: context.state.fullName,
      jobTitle: context.state.jobTitle,
      departments: [],
      department: context.state.department
    }, context.state.kpis, context.state.description)
  
    if(response.status !== 200) {
      toast.error("Something went wrong while updating your profile...")
      return;
    }

    if(context.state.profilePhoto) {
      const response = await OnboardingService.changeUserPhoto(context.state.profilePhoto);

      if(response.status !== 200) {
        toast.error("Error updating the profile picture...");
        return;
      }
    }

    toast.success("Success while saving your profile.");
  }

  return (
    <div className="profile-container">
      
      <Header />

      <div className="profile-subheader">
        <Container >
          <Grid item md={11} sm={12} xs={12}>
            <Tabs className="profile-header-tabs" value={value} onChange={handleChange}>
                <Tab label="My Profile" className="profile-nav-item" />,
                {/* <Tab label="Notifications" className="profile-nav-item" />,
                <Tab label="Linked Accounts" className="profile-nav-item" />, */}
            </Tabs>
            </Grid>
        </Container>
      </div>

      <div className="profile-content-header" style={{minHeight: "83vh"}}>
        {
            value === 0 && (
                <ProfileTab  kpis={{
                  kpis: selectedKpis,
                  setKpis: setSelectedKpis
                }} />
            )
        }

        <Container style={{marginBottom: "100px"}}>
            <Grid container>
                <Grid item md={6} >
                  {/* <div className="profile-buttons" style={{display: "flex", justifyContent: "flex-end"}}>
                    <button className="btn-outlined-wipe">
                        Change Password
                    </button>
                  </div> */}
                </Grid>
                <Grid item md={4}/>
                <Grid item>
                  <div className="profile-buttons">
                    <button className="btn-outlined-wipe" style={{marginRight: "10px"}}>
                      Cancel
                    </button>

                    <button className="btn-contained-wipe" onClick={saveProfile}>
                      Save
                    </button>
                  </div>
                </Grid>
            </Grid>
        </Container>
      </div>

    </div>
  );
}

export default UserProfile;