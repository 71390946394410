
import React from "react";

import { Grid, TextField } from "@material-ui/core";
import { DataStoryContext, DataStoryProps } from "../../DataStoryProvider";
import ContextQuestion from "./ContextQuestion";
import ContextAction from "./ContextAction";

const Second: React.FC = () => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);
    return (
        <div className="second">
            <button className="btn-outlined-wipe" style={{ marginBottom: "10px" }}>{context.state.context.kpi}</button>
            <p>{context.state.context.kpiDescription}</p>

            <h2 className="section-title">We need to know a little more about why, see if the below works for you</h2>
            <section className="context-section">
                <b>Considerations for why your audience should they care:</b>
                <Grid container alignItems="center">
                    <Grid item md={6}>
                        <ul>
                            <li>Key metrics often used when measuring <strong>{context.state.context.kpi}</strong> are</li>
                        </ul>
                    </Grid>
                    <Grid item md={5}>
                        <TextField
                            fullWidth
                            margin="normal"
                            value={context.state.context.keyMetrics}
                            variant="outlined"
                            disabled
                        />
                    </Grid>
                    <Grid item md={1} style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", height: "50px" }}>
                    </Grid>
                    
                    <Grid item md={6}>
                        <ul>
                            <li>Target metric for the year</li>
                        </ul>
                    </Grid>
                    <Grid item md={5}>
                        <TextField
                            fullWidth
                            margin="normal"
                            value={context.state.context.targetMetric}
                            variant="outlined"
                            disabled
                        />
                    </Grid>
                    <Grid item md={1} style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", height: "50px" }}>
                    </Grid>

                    <Grid item md={6}>
                        <ul>
                            <li>Target value for the year</li>
                        </ul>
                    </Grid>
                    <Grid item md={5}>
                        <TextField
                            id="target-value-input"
                            fullWidth
                            margin="normal"
                            onChange={(e) => context.setState({ ...context.state, context: { ...context.state.context, targetValue: +e.target.value } })}
                            value={context.state.context.targetValue}
                            variant="outlined"
                            required
                            type="number"
                        />
                    </Grid>
                    <Grid item md={1} style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", height: "50px" }}>
                        <span className="check-icon" />
                    </Grid>
                </Grid>
            </section>
            <section className="context-section">
                <b>What are the questions you often get asked?</b>
                <Grid container className="input-margin list">
                    {context.state.context.questions.map(question => (
                        <ContextQuestion text={question} />
                    ))}
                </Grid>
            </section>

            <section className="context-section">
                <b>What are typical actions taken to improve acquisition?</b>
                <Grid container className="input-margin list">
                    {context.state.context.actions.map(action => (
                        <ContextAction text={action} />
                    ))}
                </Grid>
            </section>
        </div >
    );
}

export default Second;