import { Button, Menu, MenuItem } from "@material-ui/core";
import DotsMenuIcon from '../../../../../assets/images/dotsMenu-icon.svg';
import React from "react";
import "./Comments.scss";
import { QuestionItemContext, QuestionItemContextProps } from '../QuestionItemProvider';
import QuestionService from '../QuestionService';
import { UserContext, UserContextProps } from '../../../../../components/UserProvider';
import RepliesContent from './RepliesContent';
import CommentLike from './CommentLike';
import { PostType } from './Comments';
import { DialogContext, DialogContextProps } from '../DialogProvider';
import { toast } from 'react-toastify';
import { ErrorResponse } from '../../../../Register/RegisterService';

interface CommentProps {
  comment: any;
  story?: any;
  index: number;
  mostLikedShow: boolean;
  showReply: boolean;
  replies?: Array<any>;
  focusedComment: boolean;
  type: PostType;
}

interface CommentState {
  commentsMenu: HTMLElement | null;
  likes: any;
}

export const handleError = async (response: Response) => {
  const errorsResponse: ErrorResponse = await response.json();

  if (response.status === 403 && errorsResponse.errors && errorsResponse.errors.length > 0) {
    toast.warning(errorsResponse.errors[0].info);
    return;
  }

  if (errorsResponse.errors && errorsResponse.errors.length > 0) {
    toast.warning(errorsResponse.errors[0].info);
  } else {
    toast.error("Something went wrong.");
  }
  return;
}

const Comment: React.FC<CommentProps> = (props) => {
  const userContext = React.useContext<UserContextProps>(UserContext);
  const dialogContext = React.useContext<DialogContextProps>(DialogContext);

  const context = React.useContext<QuestionItemContextProps>(QuestionItemContext);
  const [state, setState] = React.useState<CommentState>({
    commentsMenu: null,
    likes: props.comment.likes
  });

  const openReply = (commentId: string) => {
    dialogContext.setState({
      ...dialogContext.state,
      commentSelected: true,
      tabValue: 2,
      commentId: commentId
    })
  }

  const removeAnswer = async () => {
    const response = await QuestionService.removeAnswer(context.state.questionId, props.comment.id)
    if (response.status !== 200) {
      await handleError(response);
      return;
    }

    context.setState({ ...context.state, commentAnswer: null })
    setState({ ...state, commentsMenu: null })
  }

  const selectAnswer = async () => {
    const response = await QuestionService.selectAnswer(context.state.questionId, props.comment.id)
    if (response.status !== 200) {
      await handleError(response);
      return;
    }

    const likedByCurrentUser = state.likes.filter((l: any) => l.user.id === userContext.state.id).length > 0;
    context.setState({
      ...context.state,
      commentAnswer: {
        id: props.comment.id,
        text: props.comment.text,
        user: {
          fullName: props.comment.user.fullName,
          jobTitle: props.comment.user.jobTitle,
          department: props.comment.user.department,
          profileUrl: props.comment.user.profileUrl
        },
        likesCount: state.likes.length,
        likedByCurrentUser: likedByCurrentUser
      },
      answerSelectedBy: userContext.state,
    })

    setState({ ...state, commentsMenu: null })
  }


  const selectDecision = async () => {
    const response = await QuestionService.selectDecision(props.comment.id);
    
    if (response.status !== 200) {
      await handleError(response);
      return;
    }

    toast.success("Comment selected as decision succesfully.");

    setState({ ...state, commentsMenu: null });
    dialogContext.setState({...dialogContext.state, decision: {id: props.comment.id}});
    dialogContext.state.updateFeed()
  }


  const selectOutcome = async () => {
    const response = await QuestionService.selectOutcome(props.comment.id);
    
    if (response.status !== 200) {
      await handleError(response);
      return;
    }

    toast.success("Comment selected as outcome succesfully.");

    setState({ ...state, commentsMenu: null })
    dialogContext.setState({...dialogContext.state, outcome: {id: props.comment.id}});
    dialogContext.state.updateFeed()
  }

  const unselectDecision = async () => {
    const response = await QuestionService.unselectDecision(props.comment.id);
    
    if (response.status !== 200) {
      await handleError(response);
      return;
    }

    toast.success("Comment unselected as decision.");

    setState({ ...state, commentsMenu: null });
    dialogContext.setState({...dialogContext.state, decision: null});
    dialogContext.state.updateFeed()
  }


  const unselectOutcome = async () => {
    const response = await QuestionService.unselectOutcome(props.comment.id);
    
    if (response.status !== 200) {
      await handleError(response);
      return;
    }

    toast.success("Comment unselected as outcome.");

    setState({ ...state, commentsMenu: null })
    dialogContext.setState({...dialogContext.state, outcome: null});
    dialogContext.state.updateFeed()
  }

  const user = props.comment.user;
  const commentIsTheAnswer = context.state.commentAnswer !== null && context.state.commentAnswer !== undefined && context.state.commentAnswer.id === props.comment.id;
  const commentIsDecision = dialogContext.state.decision && dialogContext.state.decision.id === props.comment.id;
  const commentIsOutcome = dialogContext.state.outcome && dialogContext.state.outcome.id === props.comment.id;

  const getMenuOutcome = () => {
    return commentIsOutcome
      ? <MenuItem className="popover-menu-item" onClick={(e) => unselectOutcome()}>Remove Outcome</MenuItem>
      : <MenuItem className="popover-menu-item" onClick={(e) => selectOutcome()}>Select as Outcome</MenuItem>;
  }

  const getMenuDecision = () => {
    return commentIsDecision
      ? <MenuItem className="popover-menu-item" onClick={(e) => unselectDecision()}>Remove Decision</MenuItem>
      : <MenuItem className="popover-menu-item" onClick={(e) => selectDecision()}>Select as Decision</MenuItem>;
  }

  const getCommentClasses = () => {
    let _classes = "comments-list-item"
    _classes += `${commentIsTheAnswer ? ' item-answer' : ''}`
    _classes += `${commentIsDecision  ? ' decision-item' : ''}`
    _classes += `${commentIsOutcome   ? ' outcome-item' : ''}`
    return _classes;
  }

  return (
    <li className={getCommentClasses()} key={props.comment.id}>
      <div className="comment-main-content" >
        <img src={props.comment.user && props.comment.user.profileUrl} className="comments-item-image" alt="" />
        <div className="comments-item-content">

          <span className={`comments-item-infos ${(commentIsOutcome || commentIsTheAnswer || commentIsDecision) ? "comments-item-infos-white" : ""}`}>
            <span className="name">{user && user.fullName}</span> {user && user.jobTitle ? "· " + user.jobTitle : ""} {user && user.department ? "· " + user.department : ""}
          </span>
          <span className="comments-item-text">
            <span style={(commentIsTheAnswer || commentIsDecision || commentIsOutcome) ? { color: "#fff" } : {}}>{props.comment.text}</span>
            {props.showReply && (
              <Button className="reply-button" onClick={(e) => openReply(props.comment.id)} style={(commentIsTheAnswer || commentIsDecision || commentIsOutcome) ? { color: "#000" } : {}}>
                Reply
              </Button>
            )}
          </span>
        </div>
        {props.type === PostType.QUESTION &&
          (
            <>
              <Button className="comments-item-menu" aria-haspopup="true" onClick={(e) => setState({ ...state, commentsMenu: e.currentTarget })}>
                <img src={DotsMenuIcon} alt="" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={state.commentsMenu}
                open={Boolean(state.commentsMenu)}
                onClose={(e) => setState({ ...state, commentsMenu: null })}
                PopoverClasses={{ paper: 'popover-menu' }}
              >
                {commentIsTheAnswer ?
                  (<MenuItem className="popover-menu-item" onClick={(e) => removeAnswer()}>Remove Comment as Answer</MenuItem>) :
                  (<MenuItem className="popover-menu-item" onClick={(e) => selectAnswer()}>Select Comment as Answer</MenuItem>)}

              </Menu>
            </>
        )}
        {props.type === PostType.DATA_STORY &&
          (
            <div style={(commentIsTheAnswer || commentIsDecision || commentIsOutcome) ? { color: "#fff" } : {}}>
              <Button className="comments-item-menu" aria-haspopup="true" onClick={(e) => setState({ ...state, commentsMenu: e.currentTarget })}>
                <img src={DotsMenuIcon} alt="" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={state.commentsMenu}
                open={Boolean(state.commentsMenu)}
                onClose={(e) => setState({ ...state, commentsMenu: null })}
                PopoverClasses={{ paper: 'popover-menu' }}
              >
                { getMenuDecision() }
                { getMenuOutcome() }
              </Menu>
            </div>
        )}          


      </div>
      <CommentLike
        userId={+userContext.state.id}
        likes={state.likes}
        commentId={props.comment.id}
        type={props.type}
        setLikes={(likes) => { setState({ ...state, likes: likes }); }}
        isWhite={(commentIsTheAnswer || commentIsDecision || commentIsOutcome)}
      />

      <RepliesContent
        comment={props.comment}
        replies={props.replies}
        focusedComment={props.focusedComment}
        openReply={openReply} />

      {commentIsDecision ? (
          <span className="marked-text">Marked as Decision</span>
          ) : <></> }
      {commentIsOutcome ? (
        <span className="marked-text">Marked as Outcome</span>
        ) : <></> }
      {
        props.index === 0 && props.mostLikedShow && (state.likes.length > 0) && (
          <div className={`comments-most-liked ${commentIsTheAnswer ? 'item-answer' : ''}`}>
            { (commentIsDecision || commentIsOutcome || commentIsTheAnswer) ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 18.537 17.524" className="white-like liked" style={{paddingRight: "10px"}}>
                <g id="Like" transform="translate(0.5 0.699)">
                  <g id="Icon_ionic-md-thumbs-up" data-name="Icon ionic-md-thumbs-up">
                    <path id="Caminho_21" data-name="Caminho 21" d="M12.911,20.825h7.516a1.6,1.6,0,0,0,1.495-.995l2.5-5.756a1.588,1.588,0,0,0,.114-.6V11.838a1.892,1.892,0,0,0-1.652-1.9H17.669l.787-3.465.026-.255a1.215,1.215,0,0,0-.363-.863L17.237,4.5,11.731,9.912a1.614,1.614,0,0,0-.481,1.152v8.162A1.626,1.626,0,0,0,12.911,20.825Z" transform="translate(-6.993 -4.5)" strokeWidth="1" />
                    <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.808V25.8H3.375Z" transform="translate(-3.375 -9.472)" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                </g>
              </svg>  
            ): (
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 18.537 17.524" className="data-actions-like liked" style={{paddingRight: "10px"}}>
                <g id="Like" transform="translate(0.5 0.699)">
                  <g id="Icon_ionic-md-thumbs-up" data-name="Icon ionic-md-thumbs-up">
                    <path id="Caminho_21" data-name="Caminho 21" d="M12.911,20.825h7.516a1.6,1.6,0,0,0,1.495-.995l2.5-5.756a1.588,1.588,0,0,0,.114-.6V11.838a1.892,1.892,0,0,0-1.652-1.9H17.669l.787-3.465.026-.255a1.215,1.215,0,0,0-.363-.863L17.237,4.5,11.731,9.912a1.614,1.614,0,0,0-.481,1.152v8.162A1.626,1.626,0,0,0,12.911,20.825Z" transform="translate(-6.993 -4.5)" strokeWidth="1" />
                    <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.808V25.8H3.375Z" transform="translate(-3.375 -9.472)" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                </g>
              </svg>
            )}
            <span style={{paddingTop: "5px"}}>Most liked comment</span>
          </div>
        )
      }
    </li>
  )
}

export default Comment;