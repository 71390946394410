import { gql } from "@apollo/client";
import { getApolloClient } from "../../components/GraphQL/GraphQL";
import { Company, QueryResult } from "../DataStory/v1/DataStoryContracts";
import LoginService from "../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class AddUsersService {
    async invite(emails: string[]) {
        const url = `${BASE_URL}/api/User/invite`;
        const token = LoginService.getToken();
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                users: emails.map((email) => {
                    return {
                        email: email
                    }
                })
            })
        });
    }

    async getCompany(): Promise<Company> {
        const client = getApolloClient(LoginService.getToken());

        const result: QueryResult = await client.query({
            query: gql`
            query Ping {
                me{
                    company{
                        name
                        domain
                    }
                }
            }
            `
        });

        return result.data.me[0].company;
    }
}

export default new AddUsersService();