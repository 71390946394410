import React, { useEffect } from "react";
import './Question.scss';
import { FormControlLabel, Switch } from "@material-ui/core";
import { getStep, ObjectComponent } from "./Steps";

import { QuestionContext, QuestionContextProps } from "./QuestionProvider"
import QuestionService from "./QuestionService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface NavigationState {
  currentStep: ObjectComponent | null;
}

export type QuestionData = {
  id: string;
  text: string;
  extra: string;
  image: string;
  kpis: string[];
}

interface QuestionProps {
  handleClose: () => void;
  updateFeed: () => void;
  questionData?: QuestionData;
}

enum NavigationDirection {
  BACK,
  NEXT
}

const Question: React.FC<QuestionProps> = (props) => {
  const context = React.useContext<QuestionContextProps>(QuestionContext);

  const [disablePostButton, setDisablePostButton] = React.useState<boolean>(false)
  const [helpChecked, setHelpChecked] = React.useState<boolean>()
  const [state, setState] = React.useState<NavigationState>({
    currentStep: null
  });

  useEffect(() => {
    if(props.questionData) {
      context.setState({
        ...context.state, 
        text: props.questionData.text,
        extraContent: props.questionData.extra,
        kpis: props.questionData.kpis,
        image: props.questionData.image
      });
    }
    setState({ ...state, currentStep: getStep(1) })
  /* eslint-disable */
  }, []);
  /* eslint-enable */

  const helpHandleChange = () => {
    setHelpChecked(!helpChecked);
  };

  const handleNavigation = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.BACK) {
      if (context.state.step === 1) {
        props.handleClose()
        return;
      }

      context.setState({ ...context.state, step: context.state.step - 1 })
      setState({ ...state, currentStep: getStep(context.state.step - 1) ?? {} })
      return;
    }
    if (context.state.step > 2) {
      return;
    }

    if (context.state.step === 1 && context.state.text.length === 0) {
      context.setState({ ...context.state, inputError: true });
      return;
    }

    context.setState({ ...context.state, step: context.state.step + 1 })
    setState({ ...state, currentStep: getStep(context.state.step + 1) ?? {} });
  }

  const currentClass = (checkingStep: number) => {
    return checkingStep === context.state.step ? "footer-steps-current" : "footer-steps-remaining";
  }

  const postQuestion = async () => {
    setDisablePostButton(true)

    const response = await QuestionService.save({
      text: context.state.text,
      extraContent: context.state.extraContent,
      taggedUsers: context.state.taggedUsers,
      kpis: context.state.kpis,
      image: context.state.image!,
      department: context.state.department,
      importance: context.state.importance
    });

    if (response.status !== 200) {
      setDisablePostButton(false)
      return;
    }

    toast.success("Question posted successfully!");
    await props.updateFeed()
    setTimeout(() => {
      props.handleClose();
    }, 3000);
  }

  const changeStep = (step: number) => {
    if (context.state.step > step) {
      context.setState({ ...context.state, step: step });
      setState({ ...state, currentStep: getStep(step) });
    }
  }

  const editQuestion = async () => {
    setDisablePostButton(true)

    const response = await QuestionService.edit({
      text: context.state.text,
      extraContent: context.state.extraContent,
      taggedUsers: context.state.taggedUsers,
      kpis: context.state.kpis,
      image: context.state.image!,
      imageUrl: props.questionData!.image,
      department: context.state.department,
      importance: context.state.importance,
      id: props.questionData!.id,
      imageKeep: context.state.imageKeep
    });

    if (response.status !== 200) {
      setDisablePostButton(false);
      return;
    }

    toast.success("Question edited successfully!");
    await props.updateFeed()
    setTimeout(() => {
      props.handleClose();
    }, 3000);
  }

  return (
    <div className="question-modal-content">
      <div className="question-modal-header-background">
        {context.state.step <= 2 && (
            <div className="question-modal-header">
              <h2 className="question-modal-title">Step {context.state.step}: {state.currentStep && state.currentStep.title}</h2>

              {/* HELP ON TOOGLE */}
              <FormControlLabel
                className="question-help-toogle"
                control={
                  <Switch checked={helpChecked} onChange={helpHandleChange} name="showHelpText" color="primary" />}
                label="Help on"
                labelPlacement="start"
              />
            </div>
          )
        }

        {context.state.step === 3 && (
            <div className="question-modal-header">
              <h2 className="question-modal-title">Step {context.state.step}: {state.currentStep ? state.currentStep.title : ""}</h2>
            </div>
          )
        }

        {
          helpChecked && state.currentStep && state.currentStep.helpList
        }
      </div>


      <div className="question-modal-main-content">
        {state.currentStep && state.currentStep.component}
      </div>

      <div className="question-footer">
        <div className="question-footer-steps">
          <span className={`${currentClass(1)} step-label`} onClick={(e) => changeStep(1)}>1</span>
          <span className="footer-steps-remaining">/</span>
          <span className={`${currentClass(2)} step-label`} onClick={(e) => changeStep(2)}>2</span>
          <span className="footer-steps-remaining">/</span>
          <span className={`${currentClass(3)} step-label`} onClick={(e) => changeStep(3)}>3</span>
        </div>

        {context.state.step <= 2 ?
          (
            <div className="question-footer-buttons">
              <button className="btn-outlined-wipe" onClick={(e) => handleNavigation(NavigationDirection.BACK)}>
                Cancel
              </button>
              <button className="btn-contained-wipe" onClick={(e) => handleNavigation(NavigationDirection.NEXT)}>
                {context.state.step === 2 ? 'Preview' : 'Next'}
              </button>
            </div>
          )
          :
          (
            <div className="question-footer-buttons">
              {/* <button className="btn-outlined-wipe">
                Save Draft
              </button>
              <button className="btn-contained-wipe">
                Post Later
              </button> */}
              {
                (props.questionData && props.questionData.text.length > 0) ? (
                  <button className="btn-contained-wipe" onClick={(e) => editQuestion()} disabled={disablePostButton}>
                    Save
                  </button>
                ) : (
                  <button className="btn-contained-wipe" onClick={(e) => postQuestion()} disabled={disablePostButton}>
                    Post
                  </button>
                )
              }
              
            </div>
          )
        }

      </div>
      <ToastContainer />
    </div>
  )
}

export default Question;