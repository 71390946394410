import React, { useEffect, useState } from "react";
import './Analyst.scss';
import Header from '../../../components/Header/Header';
import { Container, Grid, Select, MenuItem, Tabs, Tab, OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { useLocation } from "react-router-dom";

import StoryItem from "../../Dashboard/Feed/StoryItem/StoryItem";
import QuestionItem from "../../Dashboard/Feed/QuestionItem/QuestionItem";
import DataStoryService from "../../DataStory/v1/DataStoryService";
import { StoryItemProvider } from "../../Dashboard/Feed/StoryItem/StoryItemProvider";
import { QuestionItemProvider } from "../../Dashboard/Feed/QuestionItem/QuestionItemProvider";
import { DialogContextProvider } from "../../Dashboard/Feed/QuestionItem/DialogProvider";
import { loadAppContext } from "../../../components/AppInsights";
import { UserContext, UserContextProps } from "../../../components/UserProvider";
import { FeedItem } from "../../Dashboard/Dashboard";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`analyst-tabpanel-${index}`}
      aria-labelledby={`analyst-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

export interface User {
  id: number;
  fullName: string;
  department?: string;
  jobTitle?: string;
  profileUrl?: string;
}

export interface CommentAnswer {
  id: string;
  text: string;
  user: User;
  likedByCurrentUser: Boolean;
  likesCount: number;
}

// interface FeedItem {
//   __typename: string;
//   id: string;
//   questionKpis?: Array<any>;
//   dataStoryKpis?: Array<any>;
//   // DataStory
//   title?: string;
//   subtitle?: string;
//   insight?: string;
//   author?: User;
//   commentsCount?: number;
//   peopleCommentsCount?: number;
//   likedByCurrentUser?: boolean;
//   likesCount?: number;
//   taggedUsers?: number[];
//   publishedDate: Date;
//   chartImageUrl: string;
//   addressedUserId: number;
//   hasDownloadableData: boolean;
//   // Question
//   text?: string;
//   image?: string;
//   extraContent?: string | null;
//   commentAnswerId?: string | null;
//   commentAnswer?: CommentAnswer | null;
//   answerSelectedBy?: User | null;
//   user?: User;
// }

const now = new Date();
const weekBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

const Analyst: React.FC = () => {
  const context = React.useContext<UserContextProps>(UserContext);
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const [feed, setFeed] = React.useState<FeedItem[]>([])
  /* eslint-disable */
  const [userId, setUserId] = useState("")
  const [selectedFilter, setSelectedFilter] = useState<Array<any>>([]);
  /* eslint-enable */

  useEffect(() => {
    loadAppContext(location, context);
    (async function () {
      await updateFeed()
    })()
  /* eslint-disable */
  }, [selectedFilter]);
  /* eslint-enable */
  const updateFeed = async () => {
    const feedPayload: any = await DataStoryService.getFeed(selectedFilter,
        weekBegin.toISOString().split('T')[0],
        todayEnd.toISOString().split('T')[0]
    );
    setFeed(feedPayload.data.feedV2);
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

  const removeFromFeed = (index: number) => null;

  return (
    <div className="library-container">
      <Header />

      <div className="library-subheader">
        <Container>
          Analyst Library
        </Container>
      </div>

      <div className="library-content-header">
        <Container>
          <Grid item md={11} sm={12} xs={12}>
            <Tabs className="library-header-tabs" value={value} onChange={handleChange} aria-label="Analyst Library Tabs">
              <Tab label="Data Stories" className="library-nav-item" />,
              <Tab label="Questions" className="library-nav-item" />,
            </Tabs>

            <div className="library-menu">
              <Select
                id="filter"
                variant="outlined"
                defaultValue="placeholder"
                className="library-menu-select"
              >
                <MenuItem value="placeholder" disabled>Filter by</MenuItem>
                <MenuItem value="kpi">KPI</MenuItem>
              </Select>

              <OutlinedInput
                id="search-input"
                className="library-menu-search"
                type="text"
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon className="admin-users-search-input-icon" />
                  </InputAdornment>
                }
              />
            </div>

            <div className="library-order-topics">
              <span className="topic topics-active">Likes</span>
              <span className="topic">Comments</span>
              {value === 0 && (
                <>
                  <span className="topic">Similar</span>
                  <span className="topic">Data Stories</span>
                  <span className="topic">Articles</span>
                </>
              )}
            </div>
          </Grid>
        </Container>
      </div>

      <Container className="library-content-container">
        <h4 className="library-content-title">Today</h4>

        <TabPanel value={value} index={0}>
          {feed.map((feedItem, index) => {
            if (feedItem.__typename === "DataStory") {
              let kpis = feedItem.dataStoryKpis!.map(kpi => capitalize(kpi.kpi.toLowerCase()));

              return (
                <StoryItemProvider
                  key={feedItem.id}
                  likedByCurrentUser={feedItem.likedByCurrentUser!}
                  likesCount={feedItem.likesCount!}>
                  <DialogContextProvider id={feedItem.id} key={feedItem.id}>
                    <StoryItem
                      key={feedItem.id}
                      story={{
                        ...feedItem,
                        tags: kpis,
                        date: new Date(feedItem.publishedDate),
                        storyRemoved: () => removeFromFeed(index),
                        pivotState: "",
                        userId: userId
                      }}
                      preview={false}
                    />
                  </DialogContextProvider>
                </StoryItemProvider>
              );
            } else {
              return <p>data story</p>
            }
          })
          }
        </TabPanel>

        <TabPanel value={value} index={1}>
          {feed.map((feedItem, index) => {
            if (feedItem.__typename === "Question") {
              let kpis = feedItem.questionKpis!.map(kpi => capitalize(kpi.kpi.toLowerCase()));
              return (
                <QuestionItemProvider key={feedItem.id}>
                  <DialogContextProvider id={feedItem.id} key={feedItem.id}>
                    <QuestionItem
                      key={feedItem.id}
                      id={feedItem.id}
                      author={feedItem.user!.fullName}
                      jobTitle={feedItem.user!.jobTitle!}
                      department={feedItem.user!.department!}
                      title={feedItem.text!}
                      image={feedItem.image!}
                      extraContent={feedItem.extraContent!}
                      answers={[]}
                      date={new Date(feedItem.publishedDate)}
                      isFirst={index === 0}
                      profileUrl={feedItem.user!.profileUrl!}
                      tags={kpis}
                      commentAnswerId={feedItem.commentAnswerId!}
                      commentAnswer={feedItem.commentAnswer as any}
                      likesCount={feedItem.likesCount!}
                      commentsCount={feedItem.commentsCount!}
                      peopleCommentsCount={feedItem.peopleCommentsCount!}
                      likedByCurrentUser={feedItem.likedByCurrentUser!}
                      answerSelectedBy={feedItem.answerSelectedBy!}
                      questionRemoved={() => null}
                    />
                  </DialogContextProvider>
                </QuestionItemProvider>
              );
            }
            return "";
          })
          }
        </TabPanel>
      </Container>
    </div>
  );
}

export default Analyst;