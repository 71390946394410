import { GeneratedText } from "../DataStoryContracts";
import { DataStoryProps } from "../DataStoryProvider";
import DataStoryService from "../DataStoryService";

export const generateText = async (props: DataStoryProps): Promise<GeneratedText | null> => {
  const response = await DataStoryService.generateText(props.state.id, {
    file: props.state.filename,
    cols: props.state.cols,
    rows: props.state.rows,
    columns: JSON.stringify(props.state.columns),
    data: "",
    pivotState: "",
    graphType: props.state.graphType,
    aggregatorName: props.state.aggregatorName,
    dictionaryGraphData: JSON.stringify(props.state.dictionaryGraphData)
  });

  if (response.status !== 200) {    
    return null;
  }

  const responseData: GeneratedText = await response.json();
  return responseData;
}

export const saveStory = async (props: any, freedomMode: boolean) => {
  const response = await DataStoryService.saveStory({
    id: props.state.id,
    addressedUserId: props.state.addressedUserId,
    kpis: props.state.kpis,
    title: props.state.title ?? "",
    taggedUsersIds: props.state.tagged.map((u: any) => u.id),
    pivotState: JSON.stringify(props.state.selectedData),
    storyContent: {
      anomalyReason: props.state.anomalyReason ?? "",
      impactExplanation: props.state.impactExplanation ?? "",
      insight: props.state.insight ?? "",
      suggestions: props.state.suggestions ?? ""
    }
  });

  if (response.status !== 200) {
    return;
  }

  props.setState({ ...props.state, step: 3 });
}
