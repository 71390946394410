import './Login.scss';
import Header from '../../components/Header/Header'
import LoginForm from './LoginForm';
import ForgottenPassword from './ResetPasswordFlow/ForgottenPassword';
import ResetPassword from './ResetPasswordFlow/ResetPassword';
import { Container, Grid, Hidden } from '@material-ui/core';
import { Redirect, useHistory, useLocation } from 'react-router';
import backgroundImage from '../../assets/images/login-background.png';
import { useState } from 'react';
import LoginService from './LoginService';
import { useEffect } from 'react';
import AppInsights, { loadAppContext } from '../../components/AppInsights';
import { UserRegisterStatus } from '../DataStory/v1/DataStoryContracts';

interface LocationState {
  email: string;
  emailConfirmation: boolean;
  resetPassword: boolean;
  userRegisterStatus?: UserRegisterStatus
}

const Login: React.FC = () => {
  const authenticated = LoginService.isUserAuthenticated();

  const history = useHistory();
  let location: any = useLocation();
  const _state: LocationState = location.state || {email: "", emailConfirmation: false, resetPassword: false};

  const path = window.location.pathname;

  const [state, setState] = useState({ mode: path.startsWith("/reset") ? 'reset-password' : 'login' });
  /* eslint-disable */
  const [passwordChanged, setPasswordChanged] = useState(false);
  /* eslint-enable */

  useEffect(() => {
    loadAppContext(location);
  /* eslint-disable */
  }, []);
  /* eslint-enable */

  return authenticated ? <Redirect to={{ pathname: "/dashboard" }} /> : (
    <div className="login-container">
      <Header />

      <Container className="login-container-content">
        <Grid container alignItems="center" className="login-container-content">
          <Grid item md={5} sm={12} xs={12}>
            {getFormByMode(state.mode, setState)}
          </Grid>

          <Grid item md={7}>
            <Hidden smDown>
              <img src={backgroundImage} alt="" className="login-container-image" />
            </Hidden>
          </Grid>

        </Grid>
      </Container>
    </div>
  );

  function getFormByMode(mode: string, setState: any) {
    if (mode === 'forgot-password') {
      return (<ForgottenPassword setParentState={setState} />)
    }

    if(mode === 'reset-password'){
      
      return (
      <ResetPassword 
        setParentState={setState} 
        setPasswordChanged={setPasswordChanged}
        history={history}
      />
      );
    }
  
    return (
    <LoginForm 
      setParentState={setState} 
      email={_state.email} 
      emailConfirmation={_state.emailConfirmation} 
      resetPassword={_state.resetPassword}
      userRegisterStatus={_state.userRegisterStatus}
      />
    );
  }
}

export default AppInsights(Login);