import React from "react";

import { MenuItem, Select } from "@material-ui/core";

import { DataStoryContext, DataStoryProps } from "../../DataStoryProvider";

import { default as kpis }  from "./ContextKpis";
import LoginService from "../../../../Login/LoginService";
import {useLocation} from "react-router-dom";

interface FirstProps {
    style?: any;
}

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const First: React.FC<FirstProps> = (props) => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);
    const location = useLocation();

    React.useEffect(() => {
        const url = `${BASE_URL}/api/google-analytics/targets`
        const token = LoginService.getToken();

        if(location.state) { return; }

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
            },
        }).then(response => {
            if(response.status === 200) {
                response.json()
                    .then(targets => {
                        context.setState({
                            ...context.state,
                            targets: targets.targets
                        });
                    });
            }
        });
    /* eslint-disable */
    }, [])
    /* eslint-enable */

    return (
        <div style={props.style ? props.style : {}}>
            <h1>Let's get started shall we</h1>
            <p>Pick a KPI</p>

            <Select
                id="select-kpi-dropdown"
                variant="outlined"
                fullWidth
                required
                style={{marginTop: "10px"}}
                value={context.state.context.kpi.length > 0 ? context.state.context.kpi : "placeholder" }
                onChange={(e) => {
                    const kpi = e.target.value as string; 
                    const _kpis = kpis as any;

                    let targetValue = 0;

                    switch (kpi) {
                        case "Acquisition":
                            targetValue = context.state.targets.traffic;
                            break;
                        case "Conversion":
                            targetValue = context.state.targets.conversion;
                            break;
                        case "Revenue":
                            targetValue = context.state.targets.revenue;
                            break;
                    }

                    context.setState({...context.state, 
                        context: { 
                            ...context.state.context, 
                            kpi: kpi,
                            questions: _kpis[kpi].questions,
                            actions: _kpis[kpi].actions,
                            kpiDescription: _kpis[kpi].description,
                            keyMetrics: _kpis[kpi].keyMetrics,
                            targetMetric: _kpis[kpi].targetMetric,
                            targetValue: targetValue
                        },
                        subStep: 2 
                    })
                }}>
                <MenuItem value="placeholder" disabled>pick a KPI</MenuItem>
                { Object.keys(kpis).map(kpi => (
                    <MenuItem value={kpi}>{kpi}</MenuItem>
                ))}
            </Select> 
        </div>
    );
}

export default First;