import React, { useEffect } from 'react'
import './DataStory.scss';
import Header from '../../../components/Header/Header';
import dataStoryIcon from '../../../assets/images/dataStory-icon.svg';
import { Grid, Container, FormControlLabel, Switch } from '@material-ui/core';
import { toast } from "react-toastify";
import { getSteps, Step } from './Step';
import { DataStoryContext, DataStoryProps } from './DataStoryProvider';
import { UserContext, UserContextProps, UserType } from '../../../components/UserProvider';
import { useHistory, useLocation } from 'react-router-dom';

const DataStory: React.FC = () => {
  const history = useHistory();
  const context = React.useContext<DataStoryProps>(DataStoryContext);
  const [steps, setSteps] = React.useState<any>(getSteps(context.state, context.setState));
  const [stepKeys, setStepKeys] = React.useState<string[]>(Object.keys(steps));
  const userContext = React.useContext<UserContextProps>(UserContext);

  const location = useLocation();

  useEffect(() => {
    if (userContext.state.type !== UserType.ANALYST) {
      toast.warn('Creating a datastory is only available for Storytellers');
      history.push('/');
    }

    if(location.state) {
      const editState: any = location.state; 
      
      context.setState({
        ...context.state, 
        id: editState.id,
        title: editState.title,
        subtitle: editState.subtitle,
        insight: editState.insight,
        anomalyReason: editState.anomalyReason,
        impactExplanation: editState.impactExplanation,
        suggestions: editState.suggestions,
        kpis: editState.tags,
        freedomMode: editState.freedomFlow,
        addressedUserId: editState.addressedUserId,
        dataChartImageUrl: editState.dataChartImageUrl,
        editMode: true, 
        step: 2,
      })
    }
  /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const _steps = getSteps(context.state, context.setState, context.state.freedomMode);
    setSteps(_steps);
    setStepKeys(Object.keys(_steps).sort((a: any, b: any) => a[0] - b[0]));
  /* eslint-disable */
  }, [context.state.freedomMode]);
  /* eslint-enable */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    context.setState({ ...context.state, [event.target.name]: event.target.checked });
  };

  const getStep = (steps: any, current: number) => {
    if (steps[current]) {
      const step: Step = steps[current];
      return step.component;
    }
    return <></>;
  }

  const getHelp = (steps: any, current: number) => {
    if (steps[current]) {
      const step: Step = steps[current];
      return step.help;
    }
    return <></>;
  }

  const getActiveClass = (step: number) => {
    return isActive(step) ? "steps-list-item active" : "steps-list-item";
  }

  const getIcon = (step: number) => {
    return isActive(step) ? <span>&#10003;</span> : <span />;
  }

  const getStepName = (steps: any, current: number) => {
    if (steps[current]) {
      const step: Step = steps[current];
      return step.step;
    }
    return "";
  }

  const getStepListName = (steps: any, number: number) => {
    if (steps[number]) {
      const step: Step = steps[number];
      return step.stepListName;
    }
    return "";
  }

  const isActive = (step: number) => {
    return step <= context.state.step;
  }

  return (
    <div className="data-story-create">
      <Header />

      {/* Step header */}
      <div className="data-step-header">
        <Container>
          <Grid item md={2} className="step-subtitle">
            <img src={dataStoryIcon} alt="" />
            Adding data story
          </Grid>

          <Grid item md={10}>
            <div className="step-title-help">
              <h1 className="step-title">Step {context.state.step} of {stepKeys.length}:&nbsp;
                <span className="step-description">{getStepName(steps, context.state.step)}</span>
              </h1>

              {/* HELP ON TOOGLE */}
              <FormControlLabel
                className="step-help-toogle"
                control={
                  <Switch checked={context.state.showHelpText} onChange={handleChange} name="showHelpText" color="primary" />}
                label="Help on"
                labelPlacement="start"
              />
            </div>
            {/* HELP TEXT */}
            {context.state.showHelpText && getHelp(steps, context.state.step)}
          </Grid>
        </Container>
      </div>

      {/* Step's container */}
      <div className="data-step-container">
        <Container>
          <Grid item md={2} className="data-steps-list">
            <ul>
              {stepKeys.map((number: any) => {
                if (isActive(number)) {
                  return (
                    <li
                      className={getActiveClass(number)}
                      key={number.toString()}
                      onClick={() => context.setState({ ...context.state, step: number })}
                    >
                      <span className="check-icon">{getIcon(number)}</span>
                      Step {number}:&nbsp;<span className="step-description">{getStepListName(steps, number)}</span>
                    </li>
                  );
                }
                return (
                  <li className={getActiveClass(number)} key={number.toString()}>
                    <span className="check-icon">{getIcon(number)}</span>
                    Step {number}:&nbsp;<span className="step-description">{getStepListName(steps, number)}</span>
                  </li>
                );
              })}
            </ul>
          </Grid>

          <Grid item md={10} className="data-step-content">
            {getStep(steps, context.state.step)}
          </Grid>
        </Container>
      </div>
    </div >
  )
}

export default DataStory;