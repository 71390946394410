import React from "react";

export type QuestionContextProps =  {
    state: QuestionState;
    setState: CallableFunction;
} 

export const QuestionContext = React.createContext<QuestionContextProps>({
    state: {
        step: 1,
        text:'',
        extraContent: "",
        image: null,
        importance: "",
        department: "",
        fullName: "",
        inputError: false,
        imageKeep: true,
        kpis: [],
        taggedUsers: []
    },
    setState: () => null
})

interface QuestionState {
    step: number;
    text: string;
    extraContent: string;
    importance: string;
    department: string;
    fullName: string;
    taggedUsers: number[];
    inputError: boolean;
    kpis: string[];
    imageKeep: boolean;
    image: File | null;
}

export const QuestionProvider = (props: any) => {
    const [state, setState] = React.useState<QuestionState>({
        step: 1,
        text: "",
        extraContent: "",
        image: null,
        importance: "",
        department: "",
        fullName: "",
        imageKeep: true,
        inputError: false,
        kpis: [],
        taggedUsers: []
    });

    return (
        <QuestionContext.Provider value={{ state, setState: setState }}>
            {props.children}
        </QuestionContext.Provider>
    )
}