import React from "react";
import './Likes.scss';
import { Button } from "@material-ui/core";
import LikesModalEmpty from '../../../../../assets/images/likes-modal-empty.svg';
import { Like } from "../../../../DataStory/v1/DataStoryContracts";
import QuestionService from "../QuestionService";
import { PostType } from "../Comments/Comments";
import DataStoryService from "../../../../DataStory/v1/DataStoryService";

interface LikeProps {
  dataStoryId: string;
  likes: Like[];
  liked: boolean;
  type: PostType;
}

const Likes: React.FC<LikeProps> = (props) => {
  const [like, setLike] = React.useState<boolean>(props.liked);

  const [likes, setLikes] = React.useState<Like[]>([]);

  const likeStory = () => {
    (async function () {
      if (props.type === PostType.QUESTION)
        await QuestionService.likeQuestion(props.dataStoryId);
      else
        await DataStoryService.likeStory(props.dataStoryId);
      setLike(!like)
    })()
  }

  React.useEffect(() => {
    (async function () {
      let response: any;
      if (props.type === PostType.QUESTION)
        response = await QuestionService.getLikes(props.dataStoryId)
      else
        response = await DataStoryService.getLikes(props.dataStoryId)

      setLikes(response)
    })()
  /* eslint-disable */
  }, []);
  /* eslint-enable */

  return (
    <div>
      {/* likes list */}
      {likes.length > 0 ? (
        <ul className="likes-list">
          {
            likes.map(like => (
              <li className="likes-list-item" key={like.user.id}>
                <img src={like.user.profileUrl} className="likes-item-image" alt="" />
                <span className="likes-item-name">
                  <span className="name">{like.user.fullName}</span> · {like.user.jobTitle} · {like.user.department}
                </span>
              </li>
            ))
          }
        </ul>
      ) : (
        <div className="likes-empty">
          <img src={LikesModalEmpty} className="likes-empty-icon" alt="" />
          <p className="likes-empty-text">
            It starts with a like, it ends with a<br /> whole new acquisition strategy.
          </p>
        </div>
      )}


      <div className="likes-footer">
        <Button className="likes-like-button" onClick={likeStory}>
          {
            like ?
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 18.537 17.524" className="data-actions-like liked">
                <g id="Like" transform="translate(0.5 0.699)">
                  <g id="Icon_ionic-md-thumbs-up" data-name="Icon ionic-md-thumbs-up">
                    <path id="Caminho_21" data-name="Caminho 21" d="M12.911,20.825h7.516a1.6,1.6,0,0,0,1.495-.995l2.5-5.756a1.588,1.588,0,0,0,.114-.6V11.838a1.892,1.892,0,0,0-1.652-1.9H17.669l.787-3.465.026-.255a1.215,1.215,0,0,0-.363-.863L17.237,4.5,11.731,9.912a1.614,1.614,0,0,0-.481,1.152v8.162A1.626,1.626,0,0,0,12.911,20.825Z" transform="translate(-6.993 -4.5)" strokeWidth="1" />
                    <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.808V25.8H3.375Z" transform="translate(-3.375 -9.472)" strokeLinejoin="round" strokeWidth="1" />
                  </g>
                </g>
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 14.928 14.165" className="data-actions-like">
                <g id="Like" transform="translate(0.5 0.699)">
                  <path id="Caminho_21" data-name="Caminho 21"
                    d="M12.569,17.465h5.97a1.273,1.273,0,0,0,1.187-.79L21.707,12.1a1.261,1.261,0,0,0,.09-.473v-1.3a1.5,1.5,0,0,0-1.312-1.506H16.348l.625-2.752.021-.2a.965.965,0,0,0-.288-.685L16,4.5,11.632,8.8a1.282,1.282,0,0,0-.382.915V16.2A1.292,1.292,0,0,0,12.569,17.465Z"
                    transform="translate(-7.869 -4.5)" strokeWidth="1" />
                  <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.307v7.979H3.375Z"
                    transform="translate(-3.375 -10.764)" strokeLinejoin="round" strokeWidth="1" />
                </g>
              </svg>
          }
          Like
        </Button>
      </div>
    </div >
  )
}

export default Likes;