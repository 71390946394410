import React from "react";
import Header from "../../../../../components/Header/Header";
import { OnboardingContext, OnboardingContextProps } from "../../OnboardingProvider";
import { CircularProgress, Grid, TextField } from "@material-ui/core";
import LoginService from "../../../../Login/LoginService";
import { toast } from "react-toastify";

import NumberFormat from 'react-number-format';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;


const calculateTargetRevenue = (traffic: number, conversion: number, averageOrderValue: number) => {
    return (traffic * (conversion / 100) * averageOrderValue);
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<number> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function generateFormatCustom(prefix: string, suffix: string, isValid: CallableFunction | null = null) {
    return (props: NumberFormatCustomProps) => {
        const { inputRef, onChange, ...other } = props;

        if (isValid == null) {
            return (
                <NumberFormat
                    {...other}
                    getInputRef={inputRef}
                    onValueChange={(values) => {
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value,
                            },
                        });
                    }}
                    thousandSeparator
                    isNumericString
                    prefix={prefix}
                    suffix={suffix}
                />
            );
        }

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isAllowed={(props: any) => isValid(props)}
                isNumericString
                prefix={prefix}
                suffix={suffix}
            />
        );
    }
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    return generateFormatCustom("", "")(props);
}

function CurrencyFormatCustom(props: NumberFormatCustomProps) {
    return generateFormatCustom("£ ", "")(props);
}

const MAX_VAL = 100;
const withValueLimit = (args: any) => args.floatValue <= MAX_VAL;

function PercentageFormatCustom(props: NumberFormatCustomProps) {
    return generateFormatCustom("", "%", withValueLimit)(props);
}

const Targets = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);
    const [loadTargets, setLoadTargets] = React.useState<boolean>(true);
    const CURRENT_YEAR = new Date().getFullYear();

    React.useEffect(() => {
        if (context.state.targetTraffic !== 0 ||
            context.state.targetConversion !== 0 ||
            context.state.targetAverageOrderValue !== 0) {
            setLoadTargets(false);
            return;
        }

        if (context.state.viewId) {
            const url = `${BASE_URL}/api/google-analytics/targets?viewId=${context.state.viewId}`
            const token = LoginService.getToken();

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token ? 'Bearer ' + token : "",
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json()
                        .then(targets => {

                            const targetTraffic = Math.round(targets.previousYear.traffic * 1.15);
                            let targetAverageOrderValue = targets.previousYear.averageOrderValue * 1.15;
                            targetAverageOrderValue = Math.round((targetAverageOrderValue + Number.EPSILON) * 100) / 100;

                            let targetConversion = (targets.previousYear.conversion * 1.15);
                            targetConversion = Math.round((targetConversion + Number.EPSILON) * 100) / 100;

                            context.setState({
                                ...context.state,
                                traffic: targets.previousYear.traffic,
                                averageOrderValue: targets.previousYear.averageOrderValue,
                                conversion: targets.previousYear.conversion,
                                revenue: targets.previousYear.revenue,
                                targetTraffic: targetTraffic,
                                targetAverageOrderValue:targetAverageOrderValue,
                                targetConversion: targetConversion,
                                targetRevenue: calculateTargetRevenue(targetTraffic, targetConversion, targetAverageOrderValue)
                            });
                            setLoadTargets(false);
                        });
                }
            }).catch(() => {
                toast.error("Error while fetching last years targets")
            })
        }
        /* eslint-disable */
    }, [])
    /* eslint-enable */


    return (
        <div className="onboarding-container">
            <Header />
            <div className="onboarding-container-content">
                <div className="onboarding-step-container">
                    <h1 className="onboarding-step-first-text">
                        These metrics will determine your revenue.<br /><br />
                        Inputting your goals for these metrics will help calculate the expected revenue and a focus on these targets is crucial
                    </h1>

                    {!loadTargets && (<div>
                        <Grid container style={{ fontSize: "22px", fontWeight: 400 }}>
                            <Grid item md={4} />
                            <Grid item md={4}>
                                Last year ({CURRENT_YEAR - 1})
                            </Grid>
                            <Grid item md={4}>
                                This year ({CURRENT_YEAR})
                            </Grid>

                            <Grid item md={4} style={{ marginTop: "10px" }}>
                                Traffic (total sessions)
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.traffic.toFixed(0)}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                    }}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.targetTraffic.toFixed(0)}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                    }}
                                    onChange={(e) => {
                                        const _targetTraffic = parseFloat(e.target.value);
                                        if (isNaN(_targetTraffic)) { return; }
                                        context.setState({
                                            ...context.state,
                                            targetTraffic: _targetTraffic,
                                            targetRevenue: calculateTargetRevenue(_targetTraffic, context.state.targetConversion, context.state.targetAverageOrderValue)
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item md={4} style={{ marginTop: "10px" }}>
                                Average Conversion Rate
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.conversion.toFixed(2)}
                                    InputProps={{
                                        inputComponent: PercentageFormatCustom as any,
                                    }}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.targetConversion.toFixed(2)}
                                    InputProps={{
                                        inputComponent: PercentageFormatCustom as any,
                                    }}
                                    onChange={(e) => {
                                        const _targetConversion = parseFloat(e.target.value);
                                        if (isNaN(_targetConversion)) { return; }
                                        context.setState({
                                            ...context.state,
                                            targetConversion: _targetConversion,
                                            targetRevenue: calculateTargetRevenue(context.state.targetTraffic, _targetConversion, context.state.targetAverageOrderValue)
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item md={4} style={{ marginTop: "10px" }}>
                                Average Order Value
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.averageOrderValue.toFixed(2)}
                                    InputProps={{
                                        inputComponent: CurrencyFormatCustom as any,
                                    }}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.targetAverageOrderValue.toFixed(2)}
                                    InputProps={{
                                        inputComponent: CurrencyFormatCustom as any,
                                    }}
                                    onChange={e => {
                                        let _targetAOV = parseFloat(e.target.value.replace(",", ""));
                                        if (isNaN(_targetAOV)) { return; }
                                        context.setState({
                                            ...context.state,
                                            targetAverageOrderValue: _targetAOV,
                                            targetRevenue: calculateTargetRevenue(context.state.targetTraffic, context.state.targetConversion, _targetAOV)
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item md={4} style={{ marginTop: "10px" }}>
                                Revenue
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.revenue.toFixed(2)}
                                    disabled
                                    InputProps={{
                                        inputComponent: CurrencyFormatCustom as any,
                                    }}
                                    style={{ marginRight: "10px" }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    value={context.state.targetRevenue.toFixed(2) ?? 0}
                                    disabled
                                    InputProps={{
                                        inputComponent: CurrencyFormatCustom as any,
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} style={{ textAlign: "center", marginTop: "30px" }}>
                            </Grid>
                        </Grid>


                        <Grid container style={{ marginTop: '40px' }}>
                            <Grid item md={10} />
                            <Grid item md={2}>
                                <div className="onboarding-content-footer">
                                    <div className="onboarding-buttons">
                                        <button className="btn-contained-wipe" onClick={() => context.setState({ ...context.state, step: context.state.step + 1 })}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    )}
                    {loadTargets && <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress style={{ color: 'white' }} />
                    </div>}

                </div>
            </div>
        </div>
    )
}

export default Targets;