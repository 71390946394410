import React from "react"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import AppInsights, { loadAppContext } from "../../../components/AppInsights"
import { UserContext, UserContextProps } from "../../../components/UserProvider"
import DataStory from "./DataStory"
import { DataStoryProvider } from "./DataStoryProvider"

const _DataStoryConnector: React.FC = () => {
    const location = useLocation();
    const userContext = React.useContext<UserContextProps>(UserContext);

    useEffect(() => {
        loadAppContext(location, userContext);
    /* eslint-disable */
    }, []);
    /* eslint-enable */

    return (
        <DataStoryProvider>
            <DataStory />
        </DataStoryProvider>    
    )
}

export const DataStoryConnector = AppInsights(_DataStoryConnector);