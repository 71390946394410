import React, { useState } from 'react';
import './GoogleSignIn.scss';
import logo from './btn_google_signin_light_normal_web.png'; // with import
import logoFocus from './btn_google_signin_light_focus_web.png'; // with import
import logoClick from './btn_google_signin_light_pressed_web.png'; // with import

interface GoogleSignInProps {
  onClick(): void;
}

const GoogleSignIn: React.FC<GoogleSignInProps> = (props) => {

  const [src, setSrc] = useState(logo);
  return (
    <img id="google-sign-in" src={src}
      onMouseOver={() => setSrc(logoFocus)}
      onMouseOut={() => setSrc(logo)}
      onClick={() => {
        setSrc(logoClick) 
        setTimeout(() => setSrc(logo), 3000)
        props.onClick();
      }}
     alt=""
    />
  )
}

export default GoogleSignIn;