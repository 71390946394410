import React from "react";
import "./DataStory.scss";

import {Container, FormControlLabel, Grid, Switch} from "@material-ui/core";

import Header from "../../../components/Header/Header";
import StepList from "./StepList";
import StepMenu from "./StepMenu";
import Step from "./Step";
import { DataStoryContext, DataStoryProps } from "./DataStoryProvider";
import dataStoryIcon from "../../../assets/images/dataStory-icon.svg";
import { useLocation } from "react-router-dom";

const DataStory : React.FC = () => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);
    const location = useLocation();
    const steps: Step[] = StepList;

    const goBack = () => {
        const subStep = steps[context.state.step - 1].getSubStep()
        if(subStep === context.state.subStep) {
            if(context.state.step === 1 && (context.state.subStep - 1) === 1) {
                context.setState({...context.state, subStep: context.state.subStep - 1, context: { ...context.state.context, kpi: "placeholder" }});
                return;
            }
            context.setState({...context.state, subStep: context.state.subStep - 1});
            return;
        } 
        if(context.state.step > 0) {
            context.setState({...context.state, step: context.state.step - 1 });
        }
    }

    const goNext = () => {
        const subStep = steps[context.state.step - 1].getSubStep()
        if(subStep < context.state.subStep) {
            context.setState({...context.state, subStep: context.state.subStep + 1});
            return;
        } 
        if(context.state.step < steps.length) {
            context.setState({...context.state, step: context.state.step + 1, subStep: 1 });
        }
    }

    React.useEffect(() => {
        const _state = location.state as any;
        if(location.state) {
            context.setState({
                ...context.state,
                id: _state.id,
                step: 2,
                subStep: _state.impactExplanation.length === 0 && _state.suggestions.length === 0 ? 4 : 3,
                context: {
                    ...context.state.context,
                    kpi: _state.tags.length > 0 ? _state.tags[0] : ""
                },
                draftStory: {
                    ...context.state.draftStory,
                    title: _state.title,
                    subtitle: _state.subtitle,
                    insight: _state.insight,
                    anomalyReason: _state.anomalyReason,
                    impactExplanation: _state.impactExplanation,
                    suggestions: _state.suggestions
                },
                editMode: true
            })
        }
    /* eslint-disable */
    }, [])
    /* eslint-enable */

    return (
        <div className="data-story-create">
            <Header />
            <div className="data-step-header">
                <Container>
                    <Grid item md={2} className="step-subtitle">
                        <img src={dataStoryIcon} alt="" />
                        Adding data story
                    </Grid>

                    <Grid item md={10}>
                        <div className="step-title-help">
                            <h1 className="step-title">Step {context.state.step} of {steps.length}:&nbsp;
                                <span className="step-description">{ steps[context.state.step - 1].getHelpText() }</span>
                            </h1>

                            {/* HELP ON TOOGLE */}
                            <FormControlLabel
                                className="step-help-toogle"
                                control={
                                    <Switch checked={context.state.showHelpText} onChange={() => {
                                        context.setState({...context.state, showHelpText: !context.state.showHelpText })
                                    }} name="showHelpText" color="primary" />}
                                label="Help on"
                                labelPlacement="start"
                            />
                        </div>
                        {/* HELP TEXT */}

                        { context.state.showHelpText && steps[context.state.step - 1].getHelp() }
                    </Grid>
                </Container>
            </div>

            <div className="data-step-container">
                <Container>
                    <Grid item md={2} className="data-steps-list">
                        <StepMenu 
                            steps={steps}
                        />
                    </Grid>
                    <Grid item md={10} className={`data-step-content ${context.state.step === 2 ? "no-padding" : ""}`}>
                        { steps[context.state.step - 1].getComponent() }
                        
                        { 
                            context.state.step === 1 && context.state.subStep !== 1 && (
                                <div className="data-story-buttons">
                                    <button className="btn-outlined-wipe" onClick={() => goBack()}>
                                    Cancel
                                    </button>

                                    <button className="btn-contained-wipe" onClick={() => goNext()}>
                                    Next
                                    </button>
                                </div>
                            )
                        } 
                    </Grid>
                </Container>
            </div>
        </div>
    );
}

export default DataStory;