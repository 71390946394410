import React from 'react';
import './LoginForm.scss';
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {Link as ReactLink} from 'react-router-dom'
import LoginService from './LoginService'
import {useLocation} from 'react-router';
import {UserRegisterStatus} from '../DataStory/v1/DataStoryContracts';

interface LoginFormState {
  password: string;
  showPassword: boolean;
  email: string;
  invalidCredentials: boolean;
  loggingLock: boolean;
  pendingAdminApproval: boolean
}

interface SuccessResponse {
  token: string,
  expiresIn: number
}

interface ErrorsResponse {
  errors: ResponseError[]
}

interface ResponseError {
  code: string,
  info: string
}

interface LoginFormProps {
  setParentState: CallableFunction;
  email: string;
  emailConfirmation: boolean;
  resetPassword: boolean;
  userRegisterStatus?: UserRegisterStatus
}

const getConfirmationEmailMessage = (isAdminApproval: boolean) => {
  return !isAdminApproval
    ? "We confirmed your e-mail. Enter your password to login."
    : (
      "We confirmed your e-mail. We just need your admin to rubber stamp you joining.<br/>We’ve dropped your company admin a line to check you’re not a robot. As soon as we have the all clear, you’ll get an email. (Feel free to give them a nudge.)"
    );
}

const getFreeTrialMessage = () => "We are almost there, just log in and enjoy your free trial.";

export const emailConfirmationHeader = (emailConfirmation: any, isAdminApproval: boolean, isFreeTrial: boolean) => {
  if(!emailConfirmation) return <></>;
  
  if(isFreeTrial) {
    return (
      <span className='email-confirmation-head' dangerouslySetInnerHTML={{__html: getFreeTrialMessage()}}/>
    );
  }

  return (
    <span className='email-confirmation-head' dangerouslySetInnerHTML={{__html: getConfirmationEmailMessage(isAdminApproval)}} />
  );
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const location = useLocation();

  const [state, setState] = React.useState<LoginFormState>({
    password: '',
    showPassword: false,
    email: props.email,
    invalidCredentials: false,
    loggingLock: false,
    pendingAdminApproval: false
  });

  const isAdminApproval = props.userRegisterStatus === UserRegisterStatus.PENDING_ADMIN_APPROVAL;

  const handleChange = (prop: keyof LoginFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [prop]: event.target.value });
  };  

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setState({ ...state, invalidCredentials: false, loggingLock: true });
    
    try {
      const response = await LoginService.login(state.email, state.password);
  
      if (response.status !== 200) {
        const errorsResponse: ErrorsResponse = await response.json();
  
        const pendingAdminApproval = errorsResponse.errors.some(x => x.code === "UserPendingAdminApproval");
        if (pendingAdminApproval) {
          setState({ ...state, invalidCredentials: false, loggingLock: false, pendingAdminApproval: pendingAdminApproval });
          return;
        }
        setState({ ...state, invalidCredentials: true, loggingLock: false, pendingAdminApproval: pendingAdminApproval });
        return;
      }
  
      const success: SuccessResponse = (await response.json()).result;
      await LoginService.persistLoginToken(success.token, success.expiresIn);
      
    
      const callbackUrl = new URLSearchParams(location.search).get("callbackUrl");
      window.location.href = callbackUrl ?? "/";
    } catch(err) {  
      setState({ ...state, invalidCredentials: false, loggingLock: false  });
    }
  }

  const isFreeTrial = () => {
    const params = new URLSearchParams(window.location.search);
    return !!params.get('trial');
  }

  return (
    <Grid container>
      <Card className="login-content-card">
        <CardContent>
          <h1 className="login-container-title">Welcome to Decidable</h1>
          <form action="#" onSubmit={(e) => handleLogin(e)}>
            {state.invalidCredentials && <span className='invalid-credentials-head'>Oops! That didn't work</span>}
            
            { emailConfirmationHeader(props.emailConfirmation, isAdminApproval, isFreeTrial()) }
            
            {props.resetPassword && <span className='email-confirmation-head'>We updated your password. Enter your email and password to continue.</span>}
            {state.pendingAdminApproval && <span className='pending-confirmation-head'>You're keen. We just need your admin to rubber stamp you joining.</span>}
            <Grid item>
              <InputLabel className="login-input-label" htmlFor="login-username">
                Username (Your work email)
              </InputLabel>
              <TextField
                id="login-username"
                fullWidth
                type="email"
                margin="normal"
                placeholder="Username"
                variant="outlined"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                error={state.invalidCredentials}
                helperText={state.invalidCredentials && "Sorry we can't find an account with this email address. Do you want to check it and try again?"}
                required
              />
            </Grid>

            <Grid item className="login-input-password">
              <InputLabel className="login-input-label" htmlFor="login-password">Password</InputLabel>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <OutlinedInput
                  id="login-password"
                  placeholder="Password"
                  type={state.showPassword ? 'text' : 'password'}
                  value={state.password}
                  onChange={handleChange('password')}
                  error={state.invalidCredentials}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setState({ ...state, showPassword: !state.showPassword })}
                        onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormHelperText
                error={state.invalidCredentials}
                style={{ paddingLeft: "15px", paddingRight: "15px", marginTop: "-5px" }}
              >{state.invalidCredentials && "That doesn't seem to be right. Do you want to check it and try again?"}</FormHelperText>
            </Grid>

            <Grid item className="authentication-link" onClick={() => props.setParentState({ mode: 'forgot-password' })}>
              <Link>
                Help, I've forgotten my password
              </Link>
            </Grid>

            <Grid item className="login-buttons">
              <ReactLink to="/register">
                <button className="btn-outlined-wipe" type="button">
                  Sign up
                </button>
              </ReactLink>

              <button className="btn-contained-wipe" type="submit" disabled={state.loggingLock}>
                Log in
              </button>
            </Grid>
          </form>
        </CardContent>
      </Card >
    </Grid >
  );
}

export default LoginForm;