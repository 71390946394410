import Welcome from './Welcome/Welcome';
import Password from './Password/Password';
import Verification from './Verification/Verification';
import TermsConditions from './TermsConditions/TermsConditions';

import { RegisterState } from "./Register";

export const getStep = (step: number, state: RegisterState, setState: CallableFunction, register: CallableFunction): JSX.Element => {
    const steps: any = {
        1: <Welcome
            state={state}
            setState={setState} />,
        2: <Password
            state={state}
            setState={setState}
            register={register}
        />,
        2.5: <TermsConditions
            setStep={(step: number) => setState({ ...state, step: step })}
            isRegistration={true} />,
        3: <Verification 
            email={state.email} 
            adminName={state.adminName} 
            status={state.status}
            isFreeTrial={state.freeTrialToken.length > 0} />
    }

    return steps[step] ? steps[step] : <></>;
}
 