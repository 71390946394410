import React, { Component } from 'react';
import './ResetPassword.scss';
import { Grid, Card, CardContent, InputLabel, FormControl, OutlinedInput, IconButton, InputAdornment, FormHelperText } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ForgottenPasswordService from './ForgottenPasswordService';

type ResetPasswordProps = {
  setParentState: (o: any) => void;
  setPasswordChanged: CallableFunction;
  history?: any;
}

interface ChangePasswordState {
  email: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
  invalidPassword: boolean;
  showFirstPassword: boolean;
  showConfirmPassword: boolean;
  passwordError: string;
}

export default class ResetPassword extends Component<ResetPasswordProps, ChangePasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('email') ?? '';
    let token = params.get('token') ?? '';

    this.state = {
      email: email,
      token: token,
      newPassword: '',
      confirmPassword: '',
      invalidPassword: false,
      showFirstPassword: false,
      showConfirmPassword: false,
      passwordError: ''
    }
  }

  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (this.validPassword(this.state.newPassword) === false) {
      this.setState({ ...this.setState, invalidPassword: true });
      return;
    }

    this.setState({ ...this.setState, invalidPassword: false });

    const response = await ForgottenPasswordService.changePassword(this.state);

    if (response.status !== 200) {
      this.props.history.push("/", { resetPassword: false });
      this.props.setParentState({ mode: 'login' });
      return;
    }

    this.props.history.push("/", { resetPassword: true });
    this.props.setParentState({ mode: 'login' });
  }

  setPasswordState(e: any, name: string) {
    this.setState({ ...this.state, [name]: e.target.value });
  }

  validPassword(value: string) {
    if (!this.passwordConstraint(value)) {
      this.setState({ ...this.setState, passwordError: 'The password does not pass the security criteria' });
      return false;
    }
    if (this.state.newPassword !== this.state.confirmPassword){
      this.setState({ ...this.setState, passwordError: 'The passwords don\'t match.' });
      return false;
    }
    this.setState({ ...this.setState, passwordError: '' });
    return true; 
  }

  passwordConstraint(password: string) {
    return /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,16}$/.test(password);
  }

  render() {
    return (
      <Grid container>
        <Card className="login-content-card">
          <CardContent>
            <h2 className="login-container-title reset-password-title">Right, let's sort out a new one for you</h2>
            <p className="reset-password-text">
              Make sure it’s at least 6 characters long and uses a mix of upper and lowercase letters, numbers and at least one non alphanumeric symbol.
            </p>

            <form action="#" onSubmit={(e) => this.handleSubmit(e)}>
              <Grid item>
                <InputLabel className="login-input-label" htmlFor="new-password">
                  Enter a new password
                </InputLabel>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <OutlinedInput
                    id="new-password"
                    placeholder="password"
                    type={this.state.showFirstPassword ? 'text' : 'password'}
                    required
                    onChange={(e) => this.setPasswordState(e, "newPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ ...this.setState, showFirstPassword: !this.state.showFirstPassword })}
                          onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                          edge="end"
                        >
                          {this.state.showFirstPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item className="login-input-password">
                <InputLabel className="login-input-label" htmlFor="repeat-new-password">
                  One more time please
                </InputLabel>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <OutlinedInput
                    id="repeat-new-password"
                    placeholder="repeat password"
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    required
                    onChange={(e) => this.setPasswordState(e, "confirmPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ ...this.setState, showConfirmPassword: !this.state.showConfirmPassword })}
                          onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                          edge="end"
                        >
                          {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormHelperText
                  error={this.state.invalidPassword}
                  style={{ paddingLeft: "15px", paddingRight: "15px", marginTop: "-5px" }}
                >
                  {this.state.invalidPassword && this.state.passwordError}
                </FormHelperText>
              </Grid>

              <Grid item className="login-buttons">
                <button className="btn-outlined-wipe" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.props.setParentState({ mode: "login" })}>
                  Back
                </button>

                <button className="btn-contained-wipe reset-password-button" type="submit">
                  Save & Login
                </button>
              </Grid>
            </form>
          </CardContent>
        </Card >
      </Grid >
    );
  }
}