type KpiElement = {
    description: string;
    keyMetrics: string;
    targetMetric: string;
    questions: string[];
    actions: string[];
}

interface Kpi {
    Acquisition: KpiElement;
    Conversion: KpiElement;
    Engagement: KpiElement;
}

const ContextKpis: Kpi = {
    Acquisition: {
        description: "It is vital to have clear context for a great story. This KPI is about bringing in a large number of prospects at the right cost, who will use the product to its fullest potential. It provides an answer to the question: How many prospects (new visitors) are our marketing campaigns and efforts driving to our website?",
        keyMetrics: "Prospects, Traffic, visitors, users, sessions, shoppers, buyers",
        targetMetric: "Traffic",
        questions: [
            "How much traffic are we driving to the platform from each channel?",
            "How does the traffic behave on the platform?",
            "How much do they cost to acquire?",
            "What is the impact on revenues?"
        ],
        actions: [
            "Run a new campaign",
            "Improve landing pages",
            "Optimise source traffic channels",
            "Reduce or increase Ad spend"
        ]
    },
    Conversion: {
        description: "It is vital to have clear context for a great story. This KPI is about how many visitors or sessions on your platform go on to complete the desired action.  Often this KPI provides an answer to the question: How many prospects (new visitors) went on to buy something from our online store.",
        keyMetrics: "% of people who buy, sign up to email, share on social media.",
        targetMetric: "the % of users that purchase",
        questions: [
            "What is the overall conversion rate?",
            "Which traffic channels are driving conversion?",
            "Which part of our conversion funnel do we have fix?",
            "What is the impact on revenues?"
        ],
        actions: [
            "Understand which products convert better",
            "Improve pages in the conversion flow (including cart abandonment)",
            "Optimise source traffic channels for better conversion",
            "Assess key words used to drive traffic and conversion"
        ]
    },
    Engagement: {
        description: "It is vital to have clear context for a great story. This KPI is about the total amount of money that is being received by the company after trading its products or service with its customers. It provides an answer to the question: How effectively are we monetizing our hard earned traffic?",
        keyMetrics: "Revenues, Average order values, total number of orders",
        targetMetric: "Ecommerce Revenue",
        questions: [
            "What is the key driver for the revenues (acquisition, conversion, average order value)?",
            "How does the traffic behave on the platform?",
            "How much do they cost to acquire?"
        ],
        actions: [
            "Are there any demographic or geographic segments to focus on?",
            "Target the right channels to improve revenue by traffic source",
            "Scale traffic through better conversion rates",
            "Identity cross-sell and upsell opportunities"
        ]
    }
}

export default ContextKpis;