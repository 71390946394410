import { DataStoryState } from "../DataStoryContracts";
import DataStoryService from "../DataStoryService";

export const selectUser = (state: DataStoryState, setState: CallableFunction, selected: any, setSelected: CallableFunction) => {
    if (!state.currentSelected) {
      if (selected[0]) {
        setState({
          ...state,
          currentSelected: selected[0],
          addressedUserId: selected[0].id,
          name: selected[0].label,
          jobTitle: selected[0].jobTitle,
          department: selected[0].department,
          description: selected[0].description
        });
      }
      return;
    }
    if (selected.length >= 1) {
      setSelected([...selected.filter((item: any) => item.value !== state.currentSelected.value)]);
      setState({
        ...state,
        currentSelected: selected[0],
        addressedUserId: selected[0].id,
        name: selected[0].label,
        jobTitle: selected[0].jobTitle,
        department: selected[0].department,
        description: selected[0].description
      });
      return;
    }
    if (state.currentSelected) {
      setState({
        ...state,
        name: state.currentSelected.label,
        addressedUserId: state.currentSelected.id,
        jobTitle: state.currentSelected.jobTitle,
        department: state.currentSelected.department,
        description: state.currentSelected.description
      });
    }
}

export const configureKpis = (kpis: string[]) => {
    const labels: Array<HTMLLabelElement> = Array.from(document.querySelectorAll(".form-check-label"));

    kpis.forEach(item => {
        labels.forEach(label => {
        if (item === label.innerText) {
            const input: any = document.getElementById(label.htmlFor);
            input.checked = true;
        }
        });
    });
}

export const getOptions = async (setOptions: CallableFunction, callback?: CallableFunction): Promise<void> => {
    const response = await DataStoryService.getUsers();
    const options = response.map(user => {
        return {
            id: user.id,
            label: user.fullName,
            value: user.fullName,
            jobTitle: user.jobTitle,
            department: user.department,
            description: user.description,
            profileUrl: user.profileUrl,
            type: user.type,
            kpis: user.userKpis.map(kpi => kpi.kpi)
        };
    });
    setOptions(options);

    callback && callback(options);
}
