import React from "react";
import { DataStoryContext, DataStoryProps } from "../DataStoryProvider";

import DraftMenu from "./Substeps/DraftMenu/DraftMenu";
import Scribey from "./Substeps/Scribey/Scribey";
import BasicGuide from "./Substeps/BasicGuide/BasicGuide";
import FreedomMode from "./Substeps/FreedomMode/FreedomMode";

const DraftStory: React.FC = () => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);

    switch(context.state.subStep) {
        case 1:
            return <DraftMenu />;
        case 2:
            return <Scribey />;
        case 3:
            return <BasicGuide />;
        case 4:
            return <FreedomMode />;
        default:
            return <></>;
    }
}

export default DraftStory;