import React from "react";
import './Comments.scss';
import { Button, TextField } from "@material-ui/core";

import LikeGreenIcon from '../../../../../assets/images/like-green-icon.svg';
import LikeGreenFilledIcon from '../../../../../assets/images/like-green-filled-icon.svg';

import { Comment as IComment } from "../../../../DataStory/v1/DataStoryContracts";
import Comment from "./Comment";
import { handleComment, handleStoryComment } from "./CommentMethods";
import { PostType } from "./Comments";
import { DialogContext, DialogContextProps } from "../DialogProvider";

interface FocusedCommentProps {
  dataStoryId: string;
  author: string;
  comments: IComment[];
  liked: boolean;
  type: PostType
}

interface FocusedCommentState {
  like: boolean;
  comment: string;
}

const FocusedComment: React.FC<FocusedCommentProps> = (props) => {

  const dialogContext = React.useContext<DialogContextProps>(DialogContext);
  const [state, setState] = React.useState<FocusedCommentState>({
    like: props.liked,
    comment: ""
  });

  const comment = async () => {
    let comments: any;

    if (props.type === PostType.QUESTION)
      comments = await handleComment(state.comment, props.dataStoryId, dialogContext.state.commentId);
    else
      comments = await handleStoryComment(state.comment, props.dataStoryId, dialogContext.state.commentId);
    dialogContext.setState({ ...dialogContext, comments: comments, comment: "" });
  }

  const replies = dialogContext.state.comments.filter((item) => {
    return item.parentCommentId === dialogContext.state.commentId
  });
  return (
    <>
      {/* comments list */}
      <ul className="comments-list">
        {
          dialogContext.state.comments.filter((item) => {
            return item.id === dialogContext.state.commentId
          })
            .map((item, index) => (
              <Comment
                comment={item} index={index} mostLikedShow={false} key={item.id} showReply={false} replies={replies}
                focusedComment={true}
                type={props.type} />
            ))
        }
      </ul>


      {/* footer */}
      <div className="comments-footer">
        <Button className="footer-like-button" onClick={() => setState({ ...state, like: !state.like })}>
          {
            state.like ? <img src={LikeGreenFilledIcon} className="like-icon" alt=""/> : <img src={LikeGreenIcon} alt="" className="like-icon" />
          }
        </Button>

        <div className="footer-input-group">
          <div className="footer-input-label">
            {state.comment.length > 0 && (
              <>
                <span className="comments-label">Reply to</span>
                &nbsp;
                <span className="comments-author">{props.author}’s Question</span>
              </>
            )}
          </div>
          <TextField
            id="comment-input"
            className="footer-input"
            placeholder="Add a comment..."
            fullWidth
            autoFocus
            value={state.comment}
            onChange={(e) => setState({ ...state, comment: e.target.value })}
            onKeyUp={(e) => e.key === "Enter" && comment()}
          />
        </div>

        <button
          className="btn-contained-wipe footer-post-button"
          onClick={() => state.comment.length > 0 && comment()}
          type="button"
          disabled={state.comment.length === 0}
        >
          Post
        </button>
      </div>
    </>
  )
}

export default FocusedComment;