import React from 'react'
import './AddVisual.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataStoryContext, DataStoryProps } from '../DataStoryProvider';
import DataStoryService from '../../v1/DataStoryService';
import { saveStory } from '../DraftStory/Substeps/Scribey/Wizard/ScribeyStory/StoryMethods';
// import { saveStory } from '../Story/StoryMethods';
// import DataStoryService from '../DataStoryService';

const AddVisual: React.FC = () => {
  const context = React.useContext<DataStoryProps>(DataStoryContext);

  const [image, setImage] = React.useState<File | null>(null);

  // React.useEffect(() => {
  //   if(context.state.editMode) {
  //     loadImage(context.state.dataChartImageUrl, document.getElementById('visual-image'));
  //   }
  // }, [])

  React.useEffect(() => {
    if(context.state.draftStory.storyImage) {
      loadImage(context.state.draftStory.storyImage, document.getElementById('visual-image'));
    }
  /* eslint-disable */
  }, []);
  /* eslint-enable */

  const fileUpload = (e: any) => {
    const fileName = e.target.value;
    const fileType = fileName.lastIndexOf(".") + 1;
    const slicedFileType = fileName.slice(fileType);

    // image validation regex
    const hasImageFile = /(image\/(png|jpg|jpeg))/gi;

    if (!hasImageFile.test(e.target.files[0].type) && !hasImageFile.test(slicedFileType)) {
      toast.warning("Sorry we can only upload a .JPG or .PNG file");
    }

    const file = e.target.files ? e.target.files[0] : null;
    setImage(file);
    context.setState({ ...context.state, 
      draftStory: {
        ...context.state.draftStory,
        storyImage: file
      },
      step: 3 
    });
    const image: any = document.getElementById('visual-image')
    loadImage(file, image);
  }

  const loadImage = (file: any, elImage: any) => {

    const reader = new FileReader();

    reader.addEventListener("load", function () {
      const img = reader.result as any
      elImage.src = img;
      context.setState({
        ...context.state,  
        draftStory: {
          ...context.state.draftStory,
          storyImage: img
        },
      })
    });

    if(typeof file === "string") {
      elImage.src = file;
      return;
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const save = async () => {
    await saveStory(context);

    if(image) {
      context.setState({...context.state, 
        draftStory: {
          ...context.state.draftStory,
          storyImage: image
        },
      });
      await DataStoryService.saveStoryImage(context.state.id, image);
    }
  }

  return (
    <div style={{ minHeight: '60vh' }}>
      <h3 className="data-container-title">
        Add your image<br />
        Visuals bring a story to life, they add realism.  Pick the right image that clearly brings the story to life, remember, it doenst have to be a graph. If talking about sales it could be your top selling item, if channels it could simply be the facebook logo.   Be smart about the image you pick.
      </h3>

      <div className="data-visual-input-file">
        <input type="file" name="input-image" id="input-image" accept="image/png, image/jpg, image/jpeg"
          onChange={(e) => fileUpload(e)}
        />
        <label htmlFor="input-image">
          Drop your image here or click to select a file
          <svg xmlns="http://www.w3.org/2000/svg" width="21.241" height="26.301" viewBox="0 0 21.241 26.301">
            <text id="_2mb" data-name="2mb" transform="translate(4.5 18.801)" fill="#fff" font-size="6" font-family="ProximaNova-Regular, Proxima Nova"><tspan x="0" y="0">2mb</tspan></text>
            <g id="Icon_feather-file" data-name="Icon feather-file" transform="translate(-5.5 -2.5)">
              <path id="Caminho_1022" data-name="Caminho 1022" d="M17.385,3H8.53A2.53,2.53,0,0,0,6,5.53V25.771A2.53,2.53,0,0,0,8.53,28.3h15.18a2.53,2.53,0,0,0,2.53-2.53V11.855Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
              <path id="Caminho_1023" data-name="Caminho 1023" d="M19.5,3v8.855h8.855" transform="translate(-2.115 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            </g>
          </svg>
        </label>
      </div>

      <img crossOrigin="anonymous" src="" alt="" className="data-visual-image" id="visual-image-canvas" />
      <img src="" alt="" className="data-visual-image" id="visual-image" />

      <canvas id="myCanvas"></canvas>

      <div className="data-story-buttons">
        <button className="btn-outlined-wipe" onClick={(e: any) => {
          context.setState({ ...context.state, step: 2, subStep: 1 });
        }}>
          Cancel
        </button>

        <button className="btn-contained-wipe" onClick={(e) => {
          (async function() {
            await save();
            context.setState({ ...context.state, step: 4 });
          })()
        }}>
          Next
        </button>

      </div>
    </div>
  )
}

export default AddVisual;
