import Header from "../../../../components/Header/Header";
import React from "react";
import {UserContext, UserContextProps} from "../../../../components/UserProvider";
import {finishOnboarding} from "../Onboard";
import {OnboardingContext, OnboardingContextProps} from "../OnboardingProvider";
import {Grid} from "@material-ui/core";

const BusinessUser = () => {
    const context = React.useContext<OnboardingContextProps>(OnboardingContext);
    const userContext = React.useContext<UserContextProps>(UserContext);

    return (
        <div className="onboarding-container">
            <Header />
            <div className="onboarding-container-content">
                <h1 className="onboarding-step-first-text">
                    Your data has a story to tell. We help generate that story, enable you to discuss it with your colleagues, and make real decisions with it. <br/><br/><br/>
                    Start reading
                </h1>


                <Grid container style={{marginTop: '40px'}}>
                    <Grid item md={10} />
                    <Grid item md={2}>
                        <div className="onboarding-content-footer">
                            <div className="onboarding-buttons">
                                <button className="btn-contained-wipe" onClick={() => finishOnboarding(userContext, context)}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default BusinessUser;