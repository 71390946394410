import gql from "graphql-tag";
import { getApolloClient } from "../../../../components/GraphQL/GraphQL";
import LoginService from "../../../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

type QuestionArgs = {
    questionId: string;
    comment: string;
    parentId: string | null;
}

class QuestionService {
    async likeQuestion(questionId: string) {
        const url = `${BASE_URL}/api/Question/${questionId}/like`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: ""
        });
    }

    async commentQuestion({
        questionId, comment, parentId
    }: QuestionArgs) {
        const url = `${BASE_URL}/question-comment`;

        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: JSON.stringify({
                "comment": comment,
                "questionId": questionId,
                "parentCommentId": parentId
            })
        });
    }

    async getComments(questionId: string): Promise<any[]> {
        const client = getApolloClient(LoginService.getToken());

        const result = await client.query({
            query: gql`
            query Ping {
                question(id: "${questionId}") {
                    id
                    comments {
                        id
                        text
                        parentCommentId
                        user {
                            id
                            fullName
                            department
                            jobTitle
                            profileUrl
                        }
                        likes{
                            commentId
                            user {
                                id
                                fullName
                            }
                        }
                    }
                }
            }
            `
        });


        return result.data.question.comments;;
    }

    async getLikes(questionId: string) {
        const client = getApolloClient(LoginService.getToken());

        const result = await client.query({
            query: gql`
            query Ping {
                question(id: "${questionId}") {
                    id
                    likes {
                        user {
                            id
                            fullName
                            department
                            jobTitle
                            profileUrl
                        }
                    }
                }
            }
            `
        });

        return result.data.question.likes;
    }

    async selectAnswer(questionId: string, commentId: string) {
        const url = `${BASE_URL}/api/Question/${questionId}/select-comment-as-answer/${commentId}`;
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }

    async selectDecision(commentId: string) {
        const url = `${BASE_URL}/story-comment/${commentId}/select-comment-as-decision/`;
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }

    async selectOutcome(commentId: string) {
        const url = `${BASE_URL}/story-comment/${commentId}/select-comment-as-outcome/`;
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }

    async unselectDecision(commentId: string) {
        const url = `${BASE_URL}/story-comment/${commentId}/unselect-comment-as-decision/`;
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }

    async unselectOutcome(commentId: string) {
        const url = `${BASE_URL}/story-comment/${commentId}/unselect-comment-as-outcome/`;
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }


    async removeAnswer(questionId: string, commentId: string) {
        const url = `${BASE_URL}/api/Question/${questionId}/unselect-comment-as-answer/${commentId}`;
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }

    async deleteQuestion(questionId: string) {
        const url = `${BASE_URL}/api/Question/remove/${questionId}`
        
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            }
        });
    }

    async refreshQuestion(questionId: string) {
        const client = getApolloClient(LoginService.getToken());

        const result = await client.query({
            query: gql`
            query Ping {
                question(id: "${questionId}") {
                    id
                    text
                    image
                    extraContent
                    commentAnswerId
                    answerSelectedBy {
                        id
                        fullName
                        department
                        jobTitle
                        profileUrl
                    }
                    commentAnswer {
                        id
                        text
                        user {
                            id
                            fullName
                            department
                            jobTitle
                            profileUrl
                        }
                        likesCount
                        likedByCurrentUser
                    }
                    user {
                        id
                        fullName
                        jobTitle
                        department
                        profileUrl
                    }
                    questionKpis{
                        kpi
                    }  
                    publishedDate
                    likedByCurrentUser
                    commentsCount
                    peopleCommentsCount
                    likesCount
                    likedByCurrentUser
                    likesCount
                    commentsCount
                    peopleCommentsCount
                }
            }
            `
        });

        
        return result.data.question;
    }
}

export interface QuestionRefresh{
    likedByCurrentUser: boolean
    likesCount: number
    commentsCount: number
    peopleCommentsCount: number
}

export default new QuestionService();