export enum Colour {
    ORANGE = "orange",
    GREEN = "green",
    BLUE = "blue",
    PURPLE = "purple"
}

class Colourway {
    change(colour: Colour) {    
        const styleSheet = document.documentElement.style;    
        styleSheet.setProperty('--colour-400', `var(--${colour}-400)`);
        styleSheet.setProperty('--colour-300', `var(--${colour}-300)`);
        styleSheet.setProperty('--colour-200', `var(--${colour}-200)`);
        styleSheet.setProperty('--colour-100', `var(--${colour}-100)`);
    }
}

export default new Colourway();