import React from 'react';
import '../Onboarding.scss';
import { MenuItem, Select, TextField, Checkbox, ListItemText } from "@material-ui/core";
import { UserPreferences } from '../Onboarding';

interface YourStoryProps {
  preferences: UserPreferences;
  name: string;
  changeUserPreferences: CallableFunction
}

const SecondStep: React.FC<YourStoryProps> = (props) => {
  const departments = [
    'Production',
    'Product',
    'Research and Development',
    'Marketing',
    'Sales',
    'Humans Resources',
    'Accounting and Finance',
    'IT',
    'Technology',
    'Data',
    'Software Development',
    'Ecommerce',
    'Design',
    'Fraud',
    'Legal',
    'Public Relations',
    'Social Media'
  ];

  return (
    <div className="onboarding-step-container">
      <h1 className="onboarding-step-title">
        Nice to meet you {props.name}, <br />
        why don’t you tell us your story?
      </h1>

      <div className="onboarding-step-text">
        I create insights for
        <Select
          className="onboarding-step-select"
          id="department"
          variant="outlined"
          defaultValue="placeholder"
          multiple
          value={props.preferences.departments}
          renderValue={(selected) => (selected as string[]).join(', ')}
          onChange={(e: any) => {
            props.changeUserPreferences({ ...props.preferences, departments: e.target.value })
          }}
        >
          <MenuItem value="placeholder" disabled>Department</MenuItem>
          {departments.map((department) => (
            <MenuItem key={department} value={department}>
              <Checkbox checked={props.preferences.departments.indexOf(department) > -1} color="primary" />
              <ListItemText primary={department} />
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="onboarding-step-text">
        my line manager is &nbsp;
        <TextField
          id="business-manager"
          className="onboarding-step-input"
          value={props.preferences.lineManager}
          onChange={(e) => props.changeUserPreferences({ ...props.preferences, lineManager: e.target.value })}
        />
      </div>

      <div className="onboarding-step-text">
        and I want my colourway to be
        <Select
          className="onboarding-step-select"
          id="colourway"
          variant="outlined"
          defaultValue="placeholder"
          value={props.preferences.preferredColour}
          onChange={(e) => props.changeUserPreferences({ ...props.preferences, preferredColour: e.target.value })}
        >
          <MenuItem value="placeholder" disabled>Pick</MenuItem>
          <MenuItem value="green">Green</MenuItem>
          <MenuItem value="purple">Purple</MenuItem>
          <MenuItem value="blue">Blue</MenuItem>
          <MenuItem value="orange">Orange</MenuItem>
        </Select>
      </div>
    </div>
  );
}

export default SecondStep;