import React, { useEffect, useState } from 'react';
import './GoogleAnalytics.scss';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import LoginService from '../Login/LoginService';
import moment from 'moment';
import { toast } from 'react-toastify';


interface ScheduledStoryProps {
  version: number
}

interface ScheduledStory {
  id: number,
  name: string,
  frequency: string,
  lastWritten: Date,
  scheduledTime: string,
  reportType: string
}

const ScheduledStories: React.FC<ScheduledStoryProps> = (props) => {
  const token = LoginService.getToken();

  const [scheduledStories, setScheduledStories] = useState<ScheduledStory[]>([]);

  const refreshStories = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/scheduled-story`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': token ? 'Bearer ' + token : "",
      },
    }).then(async (tokenResponse: any) => {
      setScheduledStories(await tokenResponse.json());
    });
  }

  useEffect(() => {
    refreshStories();
  /* eslint-disable */
  }, [props.version])
  /* eslint-enable */

  const remove = (id: number) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/scheduled-story/${id}`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': token ? 'Bearer ' + token : "",
      },
    }).then(async (response: Response) => {
      if(response.status === 200){
        toast.success("Scheduled story removed")
        refreshStories();
        return;
      }

      toast.warn("Something went wrong")
    });
  }

  return (
    <div className="scheduled-stories">
      <h2>Scheduled stories</h2>

      {scheduledStories.length > 0 && <TableContainer className="preview-table-container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="table-cell-border">
                View
              </TableCell>
              <TableCell className="table-cell-border">
                Frequency
              </TableCell>
              <TableCell className="table-cell-border">
                Story type
              </TableCell>
              <TableCell className="table-cell-border">
                Last written
              </TableCell>
             
              <TableCell className="table-cell-border">
                Next Scheduled
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scheduledStories.map((x) => <TableRow key={x.id} hover role="checkbox">
              <TableCell className="table-cell-border">
                {x.name}
              </TableCell>
              <TableCell className="table-cell-border">
                {x.frequency}
              </TableCell>
              <TableCell className="table-cell-border">
                {x.reportType}
              </TableCell>
              <TableCell className="table-cell-border">
                {x.lastWritten ? moment(x.lastWritten).format("DD-MM-YYYY hh:mm") : ""}
              </TableCell>
              <TableCell className="table-cell-border">
                {moment(x.scheduledTime).format("DD-MM-YYYY hh:mm")}
              </TableCell>
              <TableCell className="table-cell-border">
                <button className="btn-contained-wipe" onClick={() => { remove(x.id) }}>Remove</button>
              </TableCell>
            </TableRow>)}

          </TableBody>
        </Table>
      </TableContainer>}
      {scheduledStories.length <= 0 && <div>There are no stories configured yet, you can add them above</div>}

    </div >
  );
}

export default ScheduledStories;