import React from 'react'
import './Story.scss';

import Grid from '@material-ui/core/Grid';
import { InputBase, TextareaAutosize, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { saveStory } from './StoryMethods';
import { DataStoryContext, DataStoryProps } from '../../../../DataStoryProvider';

const tooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: "#fff",
    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
    color: '#000',
    fontSize: '12px',
    lineHeight: '20px'
  }
});

const Story: React.FC = () => {
  const context = React.useContext<DataStoryProps>(DataStoryContext);
  const classes = tooltipStyles();

  const save = async () => {
    await saveStory(context);
    context.setState({
      ...context.state,
      step: 3,
      subStep: 1
    });
  }

  return (
    <div className="data-story-container">
      <div style={{ minHeight: '60vh' }}>
        <h3 className="data-container-title">Your story - click to edit any text</h3>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={3}>
            <label className="data-story-label">Title</label>
          </Grid>
          <Grid item xs={12} md={9}>
            <InputBase
              value={
                context.state.draftStory.title
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, 
                draftStory: {
                  ...context.state.draftStory,
                  title: e.target.value
                }
              })}
              placeholder="Type here"
              inputProps={{ 'aria-label': 'naked', maxLength: 150 }}
              className="data-story-input"
            />
          </Grid>
        </Grid>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={5}>
            <label className="data-story-label">
              <b>The setting and your general discoveries</b><br />
              Give some context. Not loads. Just enough background to set the stage for the data. Identify any key things you’ve looked at. Or discovered. But keep your powder dry. Don’t give your insight yet. Just tease the problem or opportunity.<br />
              <Tooltip
                title="As you can see, the total online sales follow a cyclical pattern each quarter. This year’s sales have been trending above the previous year’s results in every period until recently. For some reason, the current quarter’s results took an unexpected downward turn. Most product categories are performing as normal, however there are two product categories that are worth digging into."
                classes={{ tooltip: classes.tooltip }}
              >
                <span className="data-story-label-example">Example</span>
              </Tooltip>
            </label>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextareaAutosize placeholder="Type here" className="data-story-textarea" name="" id="freedom-mode"
              value={
                context.state.draftStory.anomalyReason
              }
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => context.setState({ ...context.state, 
                draftStory: {
                  ...context.state.draftStory,
                  anomalyReason: e.target.value
                }
              })}
              maxLength={1000}
            />
          </Grid>
        </Grid>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={5}>
            <label className="data-story-label">
              <b>The aha moment</b><br />
              This is your big reveal. Where you share your main insight. Explain what it is. The impact that it has on your KPIs or goals. If you can, say why it happened. If you can’t, say why too. This could be a good follow up action.<br />
              <Tooltip
                title="Looking deeper into the two product categories that didn’t perform as well, we noticed that a cluster of products from brand X performed unusually poorly. Unless the brand X issue can be resolved, the e-commerce team will miss their quarterly sales target by 38%. However, it’s not clear why brand X has performed below expectations."
                classes={{ tooltip: classes.tooltip }}
              >
                <span className="data-story-label-example">Example</span>
              </Tooltip>
            </label>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextareaAutosize placeholder="Type here" className="data-story-textarea" name="" id="freedom-mode"
              value={
                context.state.draftStory.impactExplanation
              }
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => context.setState({ ...context.state, 
                draftStory: {
                  ...context.state.draftStory,
                  impactExplanation: e.target.value
                }
              })}
              maxLength={1000}
            ></TextareaAutosize>
          </Grid>
        </Grid>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={5}>
            <label className="data-story-label">
              <b>The solution and next steps</b><br />
              Pitch your ideas for actions. Give some options. It’ll kickstart the discussion about how to act on your insight.<br />
              <Tooltip
                title="It’s worth taking a deeper look at those specific brands to try and work out what the issue was. Perhaps the tech team could see if there is an issue on the search or discovery of the brands? It’d be good to rule out any tech-related issues."
                classes={{ tooltip: classes.tooltip }}
              >
                <span className="data-story-label-example">Example</span>
              </Tooltip>
            </label>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextareaAutosize placeholder="Type here" className="data-story-textarea" name="" id="freedom-mode"
              value={
                context.state.draftStory.suggestions
              }
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => context.setState({ ...context.state, 
                draftStory: {
                  ...context.state.draftStory,
                  suggestions: e.target.value
                }
              })}
              maxLength={1000}
            ></TextareaAutosize>
          </Grid>
        </Grid>

      </div>

      <div className="data-story-buttons">
        <button className="btn-outlined-wipe" onClick={(e: any) => {
          context.setState({ ...context.state, 
            step: 2,
            subStep: 1
          })
        }}>
          Cancel
        </button>

        <button className="btn-contained-wipe" onClick={(e: any) => save()}>
          Add Visual
        </button>
      </div>
    </div >
  );
}

export default Story;