import gql from "graphql-tag";
import { getApolloClient } from "../../../components/GraphQL/GraphQL";
import LoginService from "../../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

interface QuestionRequest {
    id?: string;
    text: string;
    extraContent: string;
    image: File | null;
    imageUrl?: string;
    kpis: string[];
    taggedUsers: number[];
    department: string;
    importance: string;
    imageKeep?: boolean;
}

export interface ActiveUser {
    id: any;
    fullName: string;
    profileUrl: string;
    department: string;
    jobTitle: string;
    description: string;
}

interface QueryResult {
    data: ResponsePayload
}

interface ResponsePayload {
    me: User[]
}
interface User {
    fullName: string;
    company: Company;
}

interface Company {
    activeUsers: ActiveUser[];
}


const QuestionService = {
    async save(question: QuestionRequest) {
        const url = `${BASE_URL}/api/question`
        var token = LoginService.getToken();

        const data = new FormData();
        data.append('text', question.text)
        data.append('extraContent', question.extraContent);
        if(question.image){
            data.append('image', question.image, question.image.name);
        }
        data.append('departament', question.department);
        data.append('importance', question.importance);
        
        for(const kpi of question.kpis) {
            data.append('kpis[]', kpi);
        }

        for(const taggedUser of question.taggedUsers) {
            data.append('taggedUsers[]', taggedUser.toString());
        }
        
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: data
        });
    },
    async edit(question: QuestionRequest) {
        const url = `${BASE_URL}/api/question`
        var token = LoginService.getToken();

        const data = new FormData();
        data.append('Text', question.text)
        data.append('ExtraContent', question.extraContent);

        if(question.image){
            if(typeof question.image === "string") {
                if(question.imageKeep) {
                    data.append('ImageUrl', question.imageUrl!);        
                } else {
                    data.append('ImageUrl', '');        
                }
            } else {
                data.append('Image', question.image, question.image.name);
                data.append('ImageUrl', '');
            }  
        } 

        data.append('Departament', question.department);
        data.append('Importance', question.importance);
        
        for(const kpi of question.kpis) {
            data.append('Kpis[]', kpi);
        }

        for(const taggedUser of question.taggedUsers) {
            data.append('TaggedUsers[]', taggedUser.toString());
        }

        data.append('Id', question.id!);
        
        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: data
        });
    },

    async getUsers(): Promise<ActiveUser[]> {    
        const client = getApolloClient(LoginService.getToken());
    
        const result: QueryResult = await client.query({
            query: gql`
            query Ping {
                me{
                    fullName
                    company{
                        name
                        activeUsers{
                            id
                            fullName
                            profileUrl
                            department
                            jobTitle
                            description
                        }
                    }
                }
            }
            `
        });
    
        return result.data.me[0].company.activeUsers;
    }
};

export default QuestionService;