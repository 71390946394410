import React from "react";
import { User } from "../pages/Login/LoginContracts";
import { Colour } from "./Colourway/Colourway";


export type OnboardUser = User & {
  onboarded: boolean;
  type: UserType;
  company: Company;
  trialEnabled: boolean;
  description: string;
  preferredColour: string; 
  userKpis: any[];
  kpis: string[];
  profilePhoto: File | null;
  joyride: JSX.Element | null;
  setJoyride: (joyride: JSX.Element | null) => void;
}

interface Company {
  subscriptions: Subscription[] | null;
  activeSubscritpion: Subscription | null;
  isTrialAvailable: boolean;
}

export interface Subscription {
  id: string;
  planType: string;
  startDate: string;
  invoiceValue: number;
  paymentPeriodType: string;
  invoiceCreditCardNumber: string;
  invoiceEmail: string; 
  endDate: string;
  cancelledAt: string;
  status: string;
}


export enum UserType {
  BUSINESS = "BUSINESS_USER",
  ANALYST = "ANALYST"
}

export type UserContextProps = {
  state: OnboardUser,
  setState: CallableFunction
}

export const UserContext = React.createContext<UserContextProps>({
  state: {
    id: "",
    email: "",
    fullName: "",
    profileUrl: "",
    jobTitle: "",
    department: "",
    userKpis: [],
    kpis: [],
    authenticated: false,
    onboarded: false,
    profilePhoto: null,
    type: UserType.BUSINESS,
    preferredColour: Colour.GREEN,
    description: "",
    joyride: null,
    setJoyride: () => null,
    company: {
      subscriptions: null,
      activeSubscritpion: null,
      isTrialAvailable: false
    },
    trialEnabled: false
  },
  setState: () => null
});

interface UserProviderState {
  joyride: JSX.Element | null;
  setJoyride: (joyride: JSX.Element | null) => void;
}

export const UserProvider: React.FC<UserProviderState> = (props) => {
  const [state, setState] = React.useState<OnboardUser>({
    id: "",
    email: "",
    fullName: "",
    profileUrl: "",
    jobTitle: "",
    department: "",
    description: "",
    userKpis: [],
    kpis: [],
    profilePhoto: null,
    authenticated: false,
    onboarded: false,
    type: UserType.BUSINESS,
    preferredColour: Colour.GREEN,
    joyride: props.joyride,
    setJoyride: props.setJoyride,
    company: {
      subscriptions: null,
      activeSubscritpion: null,
      isTrialAvailable: false
    },
    trialEnabled: false
  });

  return (
      <UserContext.Provider value={{ state, setState: setState }}>
          {props.children}
      </UserContext.Provider>
  )
}