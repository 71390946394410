import React from 'react';
import { StepBuilder, Step } from "../Step"
import Preview from "./Preview"

export const PreviewStep = (): Step => {
	return new StepBuilder()
				.component(<Preview />)
				.step("Share")
				.stepListName("Share")
				.build();
}