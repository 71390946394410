import DataStoryService from "../../../../DataStory/v1/DataStoryService";
import QuestionService from "../QuestionService";

export const handleComment = async (comment: string, questionId: string, parentId?: string) => {
  const id = questionId;

  const response = await QuestionService.commentQuestion({
    comment: comment.trim(),
    questionId: id,
    parentId: parentId ?? null
  });

  if (response.status !== 200) {
    return;
  }

  const responseComments = await QuestionService.getComments(questionId);
  return responseComments;
}

export const handleStoryComment = async (comment: string, dataStoryId: string, parentCommentId?: string) => {
  const id = dataStoryId;

  const response = await DataStoryService.commentStory({
    comment: comment.trim(),
    dataStoryId: id,
    parentCommentId: parentCommentId ? parentCommentId : null
  });

  if (response.status !== 200) {
    return;
  }

  const responseComments = await DataStoryService.getComments(dataStoryId);
  return responseComments;
}