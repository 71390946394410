import { v4 as uuid } from "uuid";
import React from "react";
import { Option } from "react-multi-select-component";

export interface DataStoryProps {
    state: DataStoryState,
    setState: React.Dispatch<React.SetStateAction<DataStoryState>>
}

export const DataStoryContext = React.createContext<DataStoryProps>({
    state: {
        id: "",
        step: 1,
        subStep: 1,
        showQuestions: false,
        showActions: false,
        editMode: false,
        showHelpText: false,
        targets: {
            traffic: 0,
            averageOrderValue: 0,
            conversion: 0,
            revenue: 0
        },
        context: {
            kpi: "",
            keyMetrics: "",
            targetMetric: "",
            kpiDescription: "",
            targetValue: 0,
            questions: [],
            actions: [],
        },
        draftStory: {
            storyImage: null,
            wizardAnswers: [],
            AIAnswers: [],
            tagged: [],
            showStory: false,
            addressedUserId: null,
            priority: "",
            department: "",
            title: "",
            subtitle: "",
            insight: "",
            anomalyReason: "",
            impactExplanation: "",
            suggestions: "",
        }
    },
    setState: () => null
});

interface ContextState {
    kpi: string;
    kpiDescription: string;
    keyMetrics: string;
    targetMetric: string;
    targetValue: number;
    questions: string[];
    actions: string[];
}

interface DraftStoryState {
    wizardAnswers: string[];
    AIAnswers: string[];
    showStory: boolean;
    title: string;
    subtitle: string;
    insight: string;
    anomalyReason: string;
    impactExplanation: string;
    suggestions: string;
    addressedUserId: number | null;
    priority: string;
    department: string;
    tagged: Option[];
    storyImage: File | string | null;
}

interface Targets {
    traffic: number;
    conversion: number;
    revenue: number;
    averageOrderValue: number;
}

interface DataStoryState {
    id: string;
    step: number;
    subStep: number;
    showQuestions: boolean;
    showActions: boolean;
    editMode: boolean;
    showHelpText: boolean;
    targets: Targets;
    context: ContextState;
    draftStory: DraftStoryState;
}

export const DataStoryProvider: React.FC = (props) => {
    const [state, setState] = React.useState<DataStoryState>({
        id: uuid(),
        step: 1,
        subStep: 1,
        showQuestions: false,
        showActions: false,
        editMode: false,
        showHelpText: false,
        targets: {
            traffic: 0,
            averageOrderValue: 0,
            conversion: 0,
            revenue: 0
        },
        context: {
            kpi: "",
            keyMetrics: "",
            targetMetric: "",
            targetValue: 0,
            kpiDescription: "",
            questions: [],
            actions: [],
        },
        draftStory: {
            wizardAnswers: [],
            AIAnswers: [],
            showStory: false,
            addressedUserId: null,
            priority: "",
            department: "",
            title: "",
            subtitle: "",
            insight: "",
            anomalyReason: "",
            impactExplanation: "",
            suggestions: "",
            tagged: [],
            storyImage: null
        }
    });

    return (
        <DataStoryContext.Provider value={{ state, setState: setState }}>
            {props.children}
        </DataStoryContext.Provider>
    )
}
