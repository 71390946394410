import React, { useState } from 'react';
import './GoogleAnalytics.scss';
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from '../../components/Header/Header';
import { CircularProgress, Container, Grid, MenuItem, TextField } from '@material-ui/core';
import { UserContext, UserContextProps } from '../../components/UserProvider';
import { useEffect } from 'react';
import { loadAppContext } from '../../components/AppInsights';
import LoginService from '../Login/LoginService';
import AdminMenu from '../../components/AdminMenu/AdminMenu';
import ScheduledStories from './ScheduledStories';
import { toast } from 'react-toastify';
import SubscribedToGa from '../../components/SubscribedToGa';
import GoogleSignIn from '../../components/GoogleSignIn/GoogleSIgnIn';

interface GoogleAnalyticsProfile {
  id: string,
  name: string
}

interface WebProperty {
  id: string,
  name: string

  profiles: GoogleAnalyticsProfile[]
}

interface AccountSummary {
  id: string,
  name: string,
  webProperties: WebProperty[]
}

interface UserSelection {
  account?: string,
  view?: string,
  frequency: string
  reportType: string
}

const GoogleAnalytics: React.FC = () => {
  const location = useLocation();
  const userContext = React.useContext<UserContextProps>(UserContext);
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const [errorCredentials, setErrorCredentials] = useState(false);
  const [code, setCode] = useState(params.get("code"));
  const [accounts, setAccounts] = useState<AccountSummary[]>([]);
  const [scope, setScope] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [selection, setSelection] = useState<UserSelection>({
    frequency: "Daily",
    reportType: "Acquisition"
  });
  const [version, setVersion] = useState(0);

  const hasGoogleAnalyticsAccess = SubscribedToGa(userContext);

  useEffect(() => {
    const token = LoginService.getToken();

    if (code || accounts.length > 0) {
      return;
    }
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/google-analytics/credentials`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': token ? 'Bearer ' + token : "",
      },
    }).then(async (tokenResponse: any) => {
      const body = await tokenResponse.json();
      setScope(body.scope);
      setLoggedIn(body.loggedIn);
      if (body.accounts.length > 0)
        setAccounts(body.accounts);
    }).catch(x => {
      setErrorCredentials(true);
    });
    /* eslint-disable */
  }, [accounts]);
  /* eslint-enable */

  useEffect(() => {

    if (!code)
      return;

    if (localStorage.getItem("isOnboarding")) {
      localStorage.removeItem("isOnboarding");

      history.push("/google-analytics/setup", {
        code: code
      });
      return;
    }

    const token = LoginService.getToken();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/google-analytics/exchange-access-code?accessCode=${code}`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': token ? 'Bearer ' + token : "",
      },
    }).then(async (tokenResponse: any) => {
      debugger;
      if (tokenResponse.status === 200) {
        const body = await tokenResponse.json();
        setAccounts(body.accounts ?? []);
        setLoggedIn(body.loggedIn);
        setScope(body.scope);
        history.push('/google-analytics');
      }
      else if (tokenResponse.status === 403) {
        toast.error("Your company is not subscribed to the GoogleAnalytics or a free trial plan, please upgrade your plan to use this functionality.");
        history.push('/google-analytics');
      }
      else {
        toast.error("Something went wrong with your access code, please refresh the page to try again");
        history.push('/google-analytics');
      }
    }).catch(() => {
      toast.error("Something went wrong with your access code, please refresh the page to try again");
      history.push('/google-analytics');
    });
    /* eslint-disable */
  }, [code])
  /* eslint-enable */


  const authorise = () => {
    window.location.href = process.env.REACT_APP_API_ENDPOINT + '/api/google-analytics/authorize'
  };

  const selectedAccount = accounts.filter(x => x.id === selection.account);
  const views = selectedAccount.length > 0 ? selectedAccount[0].webProperties
    .map(x => x.profiles)
    .reduce(function (a, b) {
      return a.concat(b)
    }, []) : [];

  const add = () => {
    if (loading) {
      return;
    }

    if (!selection.account || !selection.view) {
      toast.warn("Please select the account and the view.")
      return;
    }
    setLoading(true);

    const token = LoginService.getToken();
    const accountName = selectedAccount[0].name;
    const viewName = views.filter(x => x.id === selection.view)[0].name;

    const scheduledStory = {
      name: `${accountName} - ${viewName}`,
      viewId: selection.view,
      frequency: selection.frequency,
      reportType: selection.reportType
    }
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/scheduled-story`;
    try {
      fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': token ? 'Bearer ' + token : "",
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(scheduledStory)
      }).then(async (tokenResponse: Response) => {
        setLoading(false);
        if (tokenResponse.status === 200) {
          toast.success("Story scheduled successfully. Our AI's just creating your story. Imagine you can hear a really fast typewriter.")
          setVersion(version + 1);
        }
      });
    }
    catch
    {
      setLoading(false);
    }
  }

  const logout = () => {
    const token = LoginService.getToken();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/google-analytics/credentials`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': token ? 'Bearer ' + token : "",
      },
    }).then(async (response: Response) => {
      setErrorCredentials(false);
      if (response.status === 200) {
        toast.success("You have been logged out")
        setAccounts([]);
        history.push('/google-analytics');
        setVersion(version + 1);
        setCode(null);
        
        return;
      }

      toast.error("Something went wrong durint the logout process. Please remove all the scheduled stories that are associate with this Google Analytics account first")
    });

  }

  useEffect(() => {
    loadAppContext(location, userContext);
    /* eslint-disable */
  }, [])
  /* eslint-enable */

  return (
    <div className="admin-container">
      <Header />

      <Container className="admin-container-content">
        <AdminMenu path={"/google-analytics"} />
        {!hasGoogleAnalyticsAccess && <Grid item md={10} className="admin-selected-content">
          <div className="decidable-alert">
            Your company is not subscribed into our Google analytics plan, please upgrade your subscription <Link to="/cart">here</Link> to get access.
          </div>
        </Grid>}
        {hasGoogleAnalyticsAccess && <Grid item md={10} className="admin-selected-content">
          {!code && accounts && accounts.length <= 0 &&
            <div>

              {errorCredentials &&
                <div className="decidable-alert">
                  We found errors with your Google Analytics account, click here to: <button onClick={logout} className="btn-contained-wipe logout-button-error" >Log out</button>
                </div>}

              <h2>Google Analytics</h2>
              <p className="googleSignInParagraph">Simply plug in your Google Analytics and let our AI work its magic:  </p>
              <GoogleSignIn onClick={authorise} />

            </div>
          }

          {loggedIn && scope && scope.length <= 0 && <React.Fragment><div className='missing-scope-warning'>
            We managed to connect you in, but we still don't have access to everything we need.
            <button onClick={logout} className="btn-contained-wipe logout-button" >Log out</button>
          </div>
          </React.Fragment>}


          {accounts.length > 0 &&
            <React.Fragment>
              <div className='success-connection'>
                Congratulations! We are now connected with your google analytics account.
                <br />
                Please select the account, and the Google analytics property you want reports for below.

                <button onClick={logout} className="btn-contained-wipe logout-button" >Log out</button>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12} md={3} className="data-tag-select">
                  <h3 className="data-container-title">Google analytics account</h3>
                  <TextField
                    id="account"
                    variant="outlined"
                    defaultValue="placeholder"
                    fullWidth
                    value={selection.account}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelection({ ...selection, account: e.target.value })}
                    select
                  >
                    {accounts.map(x =>
                      <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} className="data-tag-select">
                  <h3 className="data-container-title">Property</h3>
                  <TextField
                    id="view"
                    variant="outlined"
                    defaultValue="placeholder"
                    fullWidth
                    value={selection.view}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelection({ ...selection, view: e.target.value })}
                    select
                  >
                    {views.map(x =>
                      <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                    )}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={2} className="data-tag-select">
                  <h3 className="data-container-title">Story Frequency</h3>
                  <TextField
                    id="frequency"
                    variant="outlined"
                    defaultValue="placeholder"
                    fullWidth
                    value={selection.frequency}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelection({ ...selection, frequency: e.target.value })}
                    select
                  >
                    <MenuItem value="Weekly">Weekly</MenuItem>
                  </TextField>


                </Grid>

                <Grid item xs={12} md={2} className="data-tag-select">
                  <h3 className="data-container-title">Story Type</h3>
                  <TextField
                    id="frequency"
                    variant="outlined"
                    defaultValue="placeholder"
                    fullWidth
                    value={selection.reportType}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelection({ ...selection, reportType: e.target.value })}
                    select
                  >
                    <MenuItem value="Acquisition">Acquisition</MenuItem>
                    <MenuItem value="Conversion">Conversion</MenuItem>
                    <MenuItem value="Engagement">Engagement</MenuItem>
                    <MenuItem value="Revenue">Revenue</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2} className="data-tag-select" justify="flex-end">
                  {loading &&
                    <div className="overlay-loading">
                      <CircularProgress style={{ 'color': 'white' }} />
                    </div>
                  }
                  <button className="btn-contained-wipe add-button" onClick={add}>
                    Add
                  </button>
                </Grid>
              </Grid>
            </React.Fragment>
          }

          <ScheduledStories version={version} />
        </Grid>}

      </Container>

    </div >
  );
}

export default GoogleAnalytics;