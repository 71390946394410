import { Container, Grid, InputBase, TextField } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import AdminMenu from '../../components/AdminMenu/AdminMenu';
import Header from '../../components/Header/Header';
import { DialogContextProvider } from '../Dashboard/Feed/QuestionItem/DialogProvider';
import StoryItem from '../Dashboard/Feed/StoryItem/StoryItem';
import { StoryItemProvider } from '../Dashboard/Feed/StoryItem/StoryItemProvider';
import LoginService from '../Login/LoginService';

interface StoryTestPageState {
  view?: string;
  variables?: string,
  storyContent?: string,

}

const StoryTestPage: React.FC = () => {

  const [state, setState] = useState<StoryTestPageState>({});


  const renderStory = async () => {
    const token = LoginService.getToken();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/story-test`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': token ? 'Bearer ' + token : "",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        view: state.view,
        variables: JSON.parse(state.variables ?? "{}")
      })
    })

    if (response.status === 200) {
      const res = await response.json();
      setState({ ...state, storyContent: res.storyContent })
    }
    else {
      toast.error("Something went wrong")
    }
  }

  const feedItem = {
    id: '',
    author: {
      profileUrl: "https://app.decidable.com/profile-images/decidable.png",
      fullName: "Decidable Global",
      jobTitle: "Storyteller",
      department: "All"
    },
    chartImageUrl: "/data-story-icons/bars-chart.png",
    commentsCount: 0,
    title: "Story generated",
    insight: state.storyContent

  }
  return (
    <div className="admin-container">
      <Header />

      <Container className="admin-container-content">
        <AdminMenu path={"/company/users"} />

        <Grid item md={10} className="admin-selected-content">
          <div className="admin-container">
            <div className="scheduled-stories">
              <h2>Test stories</h2>
              <Grid container item >
                <TextField
                  value={state.variables}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, variables: e.target.value })}
                  placeholder="Type here"
                  multiline
                  rows={10}
                  variant="outlined"
                  label="Variables"
                  fullWidth={true}
                  inputProps={{ 'aria-label': 'naked' }}
                />
              </Grid>
              <div className='story-test'>
                <Grid container item >
                  <TextField
                    style={{ marginTop: "30px" }}
                    value={state.view}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, view: e.target.value })}
                    placeholder="Type here"
                    multiline
                    rows={10}
                    variant="outlined"
                    label="View"
                    fullWidth={true}
                    inputProps={{ 'aria-label': 'naked' }}
                  />
                </Grid>
                <Grid container item >
                  <button onClick={renderStory} style={{ marginTop: "30px" }} className="btn-contained-wipe" >Render</button>
                </Grid>
                <Grid container item md={10}  style={{ marginTop: "30px" }}  >
                  <StoryItemProvider
                    key={feedItem.id}
                    likedByCurrentUser={false}
                    likedByCurrentUserDecision={false}
                    likedByCurrentUserOutcome={false}
                    likesCount={0}
                    likesCountDecision={0}
                    likesCountOutcome={0}
                  >
                    <DialogContextProvider
                      id={feedItem.id}
                      key={feedItem.id}
                      decision={null}
                      outcome={null}
                      updateFeed={() => { }}
                    >
                      {
                        <div style={{ width: "650px" }}>
                          <StoryItem
                            story={{
                              ...feedItem,
                              userId: 0,
                              tags: [],
                              date: new Date(),
                              storyRemoved: () => { },
                              isFirst: false
                            }}
                            preview={true}
                          />
                        </div>
                      }
                    </DialogContextProvider>
                  </StoryItemProvider>

                </Grid>
              </div>
            </div >

          </div >
        </Grid>
      </Container>
    </div >

  )
}

export default StoryTestPage;