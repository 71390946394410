import React from 'react';
import { UserType } from '../../../components/UserProvider';
import './Onboarding.scss';

export interface NavigationProps {
  type: UserType;
  step: number;
  changeStep: CallableFunction
  saveOnboardDetails: CallableFunction
  validateKpiSelection(): boolean;
  validateUserPreferences(): boolean;
}

enum NavigationDirection {
  BACK,
  NEXT
}

const OnboardingNavigation: React.FC<NavigationProps> = (props) => {
  const currentClass = (checkingStep: number) => {
    if (checkingStep > props.step)
      return "disabled-step";
    return checkingStep === props.step ? "current-step" : "remaining-step";
  }

  const handleNavigation = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.BACK) {
      props.changeStep(props.step - 1);
      return;
    }

    if (props.step === 2 && !props.validateUserPreferences()) {
      return;
    }

    if (props.step === 3 && !props.validateKpiSelection()) {
      return;
    }

    props.changeStep(props.step + 1);
  }

  const navigateToStep = (newStep: number) => {
    if (newStep <= props.step)
      props.changeStep(newStep);
  }

  return (

    <div className="onboarding-content-footer">
      <div className="onboarding-steps">
        <span className={currentClass(1)} onClick={(e) => navigateToStep(1)}>1</span>
        <span className="remaining-step">/</span>
        <span className={currentClass(2)} onClick={(e) => navigateToStep(2)}>2</span>
        <span className="remaining-step">/</span>
        <span className={currentClass(3)} onClick={(e) => navigateToStep(3)}>3</span>
        <span className="remaining-step">/</span>
        <span className={currentClass(4)} onClick={(e) => navigateToStep(4)}>4</span>
      </div>

      <div className="onboarding-buttons">
        {props.step !== 1 &&
          (
            <button className="btn-outlined-wipe" onClick={(e) => handleNavigation(NavigationDirection.BACK)}>
              Back
            </button>
          )
        }

        {props.step === 4 ?
          <button className="btn-contained-wipe" onClick={(e) => props.saveOnboardDetails()}>
            Save
          </button>
          :
          <button className="btn-contained-wipe" onClick={(e) => handleNavigation(NavigationDirection.NEXT)}>
            Next
          </button>
        }
      </div>
    </div >
  );
}



export default OnboardingNavigation;