import React from 'react';
import './Welcome.scss';
import { Container, Grid, InputLabel, TextField, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { RegisterState } from '../Register';
import { getEmailErrorHelper, invalidEmail, nextScreen, requireError } from './WelcomeMethods';
import RegisterService from '../RegisterService';

export interface WelcomeProps {
  state: RegisterState;
  setState: CallableFunction;
}

export interface WelcomeState {
  nameRequired: boolean;
  emailRequired: boolean;
  companyRequired: boolean;
  jobTitleRequired: boolean;
  emailInvalid: boolean;
}

const Welcome: React.FC<WelcomeProps> = (props) => {
  const [state, setState] = React.useState<WelcomeState>({
    nameRequired: false,
    emailRequired: false,
    companyRequired: false,
    jobTitleRequired: false,
    emailInvalid: false
  });

  const history = useHistory();
  const [errorEmail, setErrorEmail] = React.useState<string>("");

  return (
    <Container className="register-card-content">
      <h1 className="register-content-title">Welcome to Decidable</h1>
      <h2 className="register-content-subtitle">Let's get you started</h2>

      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} className="register-inputs-container">
          <Grid item className="register-inputs-container-margin">
            <InputLabel className="register-input-label" htmlFor="register-name">
              Who am I talking to?
            </InputLabel>
            <TextField
              id="register-name"
              fullWidth
              margin="normal"
              placeholder="my name"
              variant="outlined"
              name="name"
              autoComplete="on"
              value={props.state.name}
              error={requireError(state.nameRequired, props.state.name)}
              helperText={requireError(state.nameRequired, props.state.name) && "Please tell us your name."}
              onChange={(e) => props.setState({ ...props.state, name: e.target.value })}
              inputProps={{ tabIndex: 1 }}
            />
          </Grid>

          <Grid item className="register-inputs-container-margin">
            <InputLabel className="register-input-label" htmlFor="register-email">
              and how can I contact you?
            </InputLabel>
            <TextField
              id="register-email"
              fullWidth
              margin="normal"
              placeholder="email@company.com"
              variant="outlined"
              value={props.state.email}
              error={
                requireError(state.emailRequired, props.state.email) 
                || (state.emailRequired && invalidEmail(props.state.email))
                || errorEmail.length > 0}
              helperText={getEmailErrorHelper(state, props) || errorEmail}
              onChange={(e) => props.setState({...props.state, email: e.target.value})}
              inputProps={{ tabIndex: 3 }}
              name="email"
              type="email"
              autoComplete="on"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className="register-inputs-container">
          <Grid item className="register-inputs-container-margin">
            <InputLabel className="register-input-label" htmlFor="company-name">
              I work for
            </InputLabel>
            <TextField
              id="company-name"
              fullWidth
              margin="normal"
              placeholder="company"
              variant="outlined"
              name="company"
              value={props.state.company}
              error={requireError(state.companyRequired, props.state.company)}
              helperText={requireError(state.companyRequired, props.state.company) && "Please tell us your company."}
              onChange={(e) => props.setState({ ...props.state, company: e.target.value })}
              inputProps={{ tabIndex: 2 }}
            />
          </Grid>

          <Grid item className="register-inputs-container-margin">
            <InputLabel className="register-input-label" htmlFor="job-title">
              I want to use decidable as
            </InputLabel>
            <TextField
              id="job-title"
              fullWidth
              margin="normal"
              placeholder="Job title"
              variant="outlined"
              value={props.state.jobTitle}
              error={requireError(state.jobTitleRequired, props.state.jobTitle)}
              helperText={requireError(state.jobTitleRequired, props.state.jobTitle) && "Please tell us your job title."}
              onChange={(e) => props.setState({ ...props.state, jobTitle: e.target.value })}
              inputProps={{ tabIndex: 4 }}
              select>
              <MenuItem value="Storyteller">an analyst that creates data stories</MenuItem>
              <MenuItem value="Story Reader">a user who uses data stories for decisions</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className="register-button">
      <button className="btn-contained-wipe" onClick={(e) => { 
            RegisterService.validate({email: props.state.email})
            .then(response => { 
              if(response.status !== 200) {
                response.json().then(resp => { if(resp?.errors && resp.errors.length > 0)
                  {
                  setErrorEmail(resp.errors[0].info)
                  }
                })
                return; 
              }
              nextScreen(state, props, setState, history)
            })
            .catch(error => {
              console.log(error)
            });
          }
        }
         >
          Next
        </button>
      </Grid>

      <Grid item className="welcome-login-link">
        already signed up <Link to="/" className="link">Log in here</Link>
      </Grid>
    </Container>
  );
}

export default Welcome;