import './StoryItem.scss';
import { Button, Menu, MenuItem, Collapse } from "@material-ui/core";

import DotsMenuIcon from '../../../../assets/images/dotsMenu-icon.svg';
import React from "react";
import DataStoryService from "../../../DataStory/v1/DataStoryService";
import { StoryItemContext, StoryItemContextProps } from "./StoryItemProvider";
import { StoryItemImageProps, StoryState } from "./StoryItemContracts";
import { PostType } from "../QuestionItem/Comments/Comments";
import CommentsLikesDialog from "./CommentsLikesDialog";
import { DialogContext, DialogContextProps } from "../QuestionItem/DialogProvider";
import DecidableChart from "../../../DataStory/v1/PickGraph/DecidableChart";
import { GraphSelection } from "../../../DataStory/v1/PickGraph/PickAGraphService";
import { toast } from "react-toastify";
import swal from "sweetalert";


import Editor from "rich-markdown-editor";

import { useHistory } from "react-router";
import Outcome from "./Outcome/Outcome";
import Decision from "./Decision/Decision";
import { UserContext, UserContextProps } from "../../../../components/UserProvider";
import OnboardingService from "../../../Onboarding/v1/OnboardingService";
import { GuideStatus, GuideType, mapGuideToApplicable } from "../../../Onboarding/v1/Onboarding";
import { STATUS } from "react-joyride";
import { JoyrideStep, runJoyride } from '../../../../components/Joyride/Joyride';
import { handleError } from '../QuestionItem/Comments/Comment';

interface GraphRenderState {
    data: any[];
    graphSelection?: GraphSelection
}

export const getKpiColor = (kpi: string) => {
    if (kpi === "Conversion") return "var(--green-400)";
    if (kpi === "Acquisition") return "var(--orange-400)";
    if (kpi === "Revenue") return "var(--purple-400)";
    if (kpi === "Engagement") return "var(--blue-400)";
    return "";
}

export const getKpiStyle = (kpi: string) => {
    const color = getKpiColor(kpi);
    if (color) {
        return { backgroundColor: color };
    }
    return {};
}

export const getKpiLineColor = (kpi: string) => {
    if (kpi === "Conversion") return "green-line";
    if (kpi === "Acquisition") return "orange-line";
    if (kpi === "Engagement") return "blue-line";
    if (kpi === "Revenue") return "purple-line";
    return "";
}

const StoryItem: React.FC<StoryItemImageProps> = (props) => {
    const context = React.useContext<StoryItemContextProps>(StoryItemContext);
    const userContext = React.useContext<UserContextProps>(UserContext)
    const dialogContext = React.useContext<DialogContextProps>(DialogContext);

    const history = useHistory();

    const kpis = [
        { name: 'Acquisition', description: 'Casting your net to capture as many customers as possible.' },
        { name: 'Conversion', description: 'Transforming prospective customers into actual customers.' },
        { name: 'Engagement', description: "Maximise the visitor's experience of your site" },
        { name: 'Revenue', description: 'Maximise the amount that your site earns.' },
        { name: 'Payoff', description: 'Reaping the rewards of landing your customers.' },
        { name: 'Referral', description: 'The customers you’ve landed spreading the word.' }
      ];

    const day = props.story.date.getDate();
    const month = props.story.date.toLocaleDateString('en-US', { month: "short" });
    const year = props.story.date.getFullYear();

    const [pivotState, setPivotState] = React.useState<GraphRenderState>({
        data: [],
        graphSelection: undefined
    })

    const [state, setState] = React.useState<StoryState>({
        subtitle: "",
        insight: props.story.insight,
        anomalyReason: "",
        impactExplanation: "",
        suggestions: "",
    })

    const handleOpenModal = async (tabValue: number) => {
        if (props.preview)
            return;
        dialogContext.setState({
            ...dialogContext.state,
            tabValue: tabValue,
            open: true,
            id: props.story.id
        });
    };

    const updateStory = async () => {
        const refreshResult = await DataStoryService.refreshStory(props.story.id);

        context.setState({
            ...context.state,
            liked: refreshResult.likedByCurrentUser,
            like: refreshResult.likedByCurrentUser,
            numberLikes: refreshResult.likesCount,
            commentsCount: refreshResult.commentsCount,
            peopleCommentsCount: refreshResult.peopleCommentsCount
        });
    }

    const likeStory = () => {
        (async function () {
            await DataStoryService.likeStory(props.story.id);
            if (context.state.like) {
                context.setState({ ...context.state, numberLikes: context.state.numberLikes - 1, like: !context.state.like });
                return;
            }
            context.setState({ ...context.state, numberLikes: context.state.numberLikes + 1, like: !context.state.like });
        })()
    }

    const handleStoryContent = async () => {
        if (props.preview) {
            context.setState({ ...context.state, openStoryContent: !context.state.openStoryContent });
            return;
        }

        if (!context.state.openStoryContent) {
            const response = await DataStoryService.readMore(props.story.id);
            try {
                const pivotState = JSON.parse(response.data.story.pivotState);

                const elImage: any = document.getElementById(`graphImage_${props.story.id}`)!;

                if (props.story.dataChartImageUrl) {
                    elImage.src = props.story.dataChartImageUrl;
                    elImage.style.display = "block";
                } else {
                    elImage.style.display = "none";

                    delete pivotState.aggregators;
                    delete pivotState.derivedAttributes;

                    if (pivotState) {
                        setPivotState({
                            ...pivotState,
                            data: pivotState.data,
                            aggregatorName: pivotState.aggregatorName,
                            cols: pivotState.cols,
                            rows: pivotState.rows,
                            colOrder: pivotState.colOrder,
                            rowOrder: pivotState.rowOrder,
                            vals: pivotState.vals
                        });
                    }
                }
            } catch (err) {
            }
            setState({
                ...state,
                insight: response.data.story.insight,
                subtitle: response.data.story.subtitle,
                anomalyReason: response.data.story.anomalyReason,
                impactExplanation: response.data.story.impactExplanation,
                suggestions: response.data.story.suggestions,
            });
        }
        context.setState({ ...context.state, openStoryContent: !context.state.openStoryContent });
    };

    const getCommentInfo = (answerNumber: number) => {
        if (answerNumber === 1) return 'Someone commented on this story';
        return `${answerNumber} people commented on this story`
    }

    React.useEffect(() => {
        let _state = { ...context.state, commentsCount: props.story.commentsCount };
        if (context.state.commentSelected) {
            _state = { ..._state, tabValue: 2 };
        }
        context.setState(_state);

        if (props.story.isOpen) {
            handleStoryContent();
        }

        if (props.story.isFirst) {
            const steps: JoyrideStep[] = [
                {
                    target: '#feed-story-item-1',
                    content: 'This is a data story to help you take decisions.',
                    disableBeacon: true
                },
                {
                    target: '#read-more-1',
                    content: 'Click here to read the full data story',
                    disableBeacon: true
                },
                {
                    target: "#story-container-footer-1",
                    content: "Here you can like and comment the data story you feel strong about, this will help your colleagues find this item and give the appreciation the story teller deserves..",
                    disableBeacon: true
                }
            ]

            OnboardingService.getGuideStatus()
                .then(async (status: any) => {
                    if (status.feedReadGuide === GuideStatus.PendingGuide) {
                        runJoyride(userContext, steps, (data: any) => {
                            const { status } = data;

                            if ([STATUS.FINISHED].includes(status)) {
                                (async function () {
                                    await OnboardingService.saveGuide(
                                        mapGuideToApplicable(GuideStatus.UserGuided),
                                        GuideType.FeedReadGuide
                                    );
                                })()
                                return;
                            }

                            if ([STATUS.SKIPPED].includes(status)) {
                                (async function () {
                                    await OnboardingService.saveGuide(
                                        mapGuideToApplicable(GuideStatus.UserRefused),
                                        GuideType.FeedReadGuide
                                    );
                                })()
                                return;
                            }
                        });
                    }
                })
        }

        /* eslint-disable */
    }, []);
    /* eslint-enable */

    const downloadData = async (): Promise<void> => {
        const response = await DataStoryService.downloadData(props.story.id);
        if (response.status === 200) {
            const a = document.createElement("a");
            a.setAttribute("style", "display: none;")
            document.body.appendChild(a);

            const blob = await response.blob();
            a.href = URL.createObjectURL(blob);
            a.download = 'data.csv'
            a.click();

            context.setState({ ...context.state, storyMenu: false })
        }
        else {
            toast.error("Something went wrong while downloading your data");
        }
    };


    const removePost = (): void => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this story!",
            icon: "warning",
            buttons: [true, true],
            dangerMode: true,
        })
            .then(async (willDelete: boolean) => {
                if (willDelete) {
                    const response = await DataStoryService.unpublishStory(props.story.id);
                    if (response.status === 200) {
                        props.story.storyRemoved();
                        context.setState({ ...context.state, storyMenu: false })
                        toast.success("Poof! Your story has been deleted");
                    }
                    else if (response.status === 403) {
                        handleError(response);
                    }
                    else {
                        toast.error("Something went wrong while deleting the story");
                    }
                }
            });
    }

    const editPost = () => {
        history.push("/v2/data-story", {
            id: props.story.id,
            userId: props.story.userId,
            title: props.story.title,
            subtitle: props.story.subtitle,
            insight: props.story.insight,
            tags: props.story.tags,
            addressedUserId: props.story.addressedUserId,
            anomalyReason: props.story.anomalyReason,
            impactExplanation: props.story.impactExplanation,
            suggestions: props.story.suggestions,
            dataChartImageUrl: props.story.dataChartImageUrl,
            freedomFlow: (
                props.story.impactExplanation.length === 0 && props.story.suggestions.length === 0
            )
        });
    }

    const getCustomStyle = () => {
        if (hasDecision() || hasOutcome()) return { borderRadius: "15px 15px 0px 0px" }
        return {}
    }

    const hasDecision = () => {
        return props.story.decision !== null && props.story.decision !== undefined;
    }

    const hasOutcome = () => {
        return props.story.outcome !== null && props.story.outcome !== undefined;
    }

    return (
        <div key={props.story.id} className="feed-story-item" id={`feed-story-item-${props.story.isFirst ? "1" : props.story.id}`}>
            <div className="story-header">
                <div>
                    <span className="datetime">
                        {props.story.date.toLocaleTimeString()} · {day} {month} {year}&nbsp;
                        {/* <span className="feed-reference">·  You’re seeing this as you liked XXXXXX’s post</span> */}
                    </span>
                    {props.story.peopleCommentsCount > 0
                        ? (
                            <>
                                &nbsp;{getCommentInfo(props.story.peopleCommentsCount)}
                            </>
                        )
                        : (
                            <span className="bold-text">Be the first to comment on this story</span>
                        )
                    }
                </div>

                {props.story.tags.length > 0 && (
                    <div className="tags-list">
                        {props.story.tags.map((item: string) => (
                            <span className="tags-item" key={item} style={getKpiStyle(item)}>
                                <span className={getKpiLineColor(item)}>{item}</span>
                                <div className="tags-item-hover">
                                    <h5 className="tags-hover-title">{item}</h5>
                                    <p className="tags-hover-description">
                                        {kpis.map(kpi => kpi.name === item ? kpi.description : "")}
                                    </p>
                                </div>
                            </span>
                        ))}
                    </div>
                )}
            </div>
            <div className="story-container" style={getCustomStyle()}>
                <div className={`story-container-header ${getKpiLineColor(props.story.tags[0])}`}>
                    <div className="story-container-contents">
                        <img className="container-header-avatar" src={props.story.author.profileUrl} alt="" />

                        <div className="container-header-name">
                            <span className="name">{props.story.author.fullName}</span>
                            <span className="role">
                                {props.story.author.jobTitle} {props.story.author.department === '' ? '' : `· ${props.story.author.department}`}
                            </span>
                        </div>
                    </div>

                    <div className="story-container-contents">
                        <img src={props.story.chartImageUrl} className="container-header-chart" alt="" />

                        <Button className="story-menu-button" onClick={(e) => context.setState({ ...context.state, storyMenu: e.currentTarget })}>
                            <img src={DotsMenuIcon} alt="" />
                        </Button>

                        {!props.preview &&
                            <Menu
                                anchorEl={context.state.storyMenu}
                                open={Boolean(context.state.storyMenu)}
                                onClose={() => context.setState({ ...context.state, storyMenu: null })}
                                PopoverClasses={{ paper: 'popover-menu' }}
                            >
                                {!props.preview && props.story.hasDownloadableData && <MenuItem onClick={() => downloadData()} className="popover-menu-item">Download Data</MenuItem>}
                                {!props.preview && <MenuItem onClick={() => editPost()} className="popover-menu-item">Edit Post</MenuItem>}
                                {!props.preview && <MenuItem onClick={() => removePost()} className="popover-menu-item">Remove Post</MenuItem>}
                            </Menu>
                        }
                    </div>
                </div>

                <div className="story-container-title">
                    <div className="texts-contents" onClick={() => handleStoryContent()}>
                        <h5 className="card-type" style={{ color: getKpiColor(props.story.tags[0]) }}>Data Story</h5>
                        <h3 className="title" data-label="story-feed-item">{props.story.title}</h3>
                        {
                            context.state.openStoryContent ? '' : (
                                <Editor className="text-intro decidable-markdown-feed" value={[
                                    props.story.insight,
                                    props.story.anomalyReason].join("\n")
                                } readOnly disableExtensions={[]} />
                            )
                        }

                        <span className="read-more" id={`read-more-${props.story.isFirst ? "1" : props.story.id}`} style={{ color: getKpiColor(props.story.tags[0]) }} >
                            {
                                (context.state.openStoryContent || props.story.isOpen) ? 'Hide content' : 'Read more...'
                            }
                        </span>
                    </div>
                </div>

                <Collapse in={context.state.openStoryContent} timeout="auto" className="collapse-content">
                    <div className="collapse-text-content">
                        {/*<h4 className="collapse-subtitle">{props.story.subtitle}</h4>*/}
                        <Editor value={[
                            state.insight,
                            state.anomalyReason,
                            state.impactExplanation,
                            state.suggestions].join("\n")
                        }
                            className="decidable-markdown-feed"
                            readOnly disableExtensions={[]} />
                    </div>
                    <div className="collapse-content-chart">
                        {pivotState.data && pivotState.graphSelection && <DecidableChart data={pivotState.data} graphSelection={pivotState.graphSelection!} />}
                        <img id={`graphImage_${props.story.id}`} style={{ width: "100%", display: "none" }} alt="" />
                    </div>
                </Collapse>

                <div className="story-container-footer" id={`story-container-footer-${props.story.isFirst ? "1" : props.story.id}`}>
                    <Button className="data-actions" onClick={() => !props.preview && likeStory()}>
                        {
                            context.state.like ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 18.537 17.524" className="data-actions-icon data-actions-like liked">
                                    <g id="Like" transform="translate(0.5 0.699)">
                                        <g id="Icon_ionic-md-thumbs-up" data-name="Icon ionic-md-thumbs-up">
                                            <path id="Caminho_21" data-name="Caminho 21" d="M12.911,20.825h7.516a1.6,1.6,0,0,0,1.495-.995l2.5-5.756a1.588,1.588,0,0,0,.114-.6V11.838a1.892,1.892,0,0,0-1.652-1.9H17.669l.787-3.465.026-.255a1.215,1.215,0,0,0-.363-.863L17.237,4.5,11.731,9.912a1.614,1.614,0,0,0-.481,1.152v8.162A1.626,1.626,0,0,0,12.911,20.825Z" transform="translate(-6.993 -4.5)" strokeWidth="1" />
                                            <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.808V25.8H3.375Z" transform="translate(-3.375 -9.472)" strokeLinejoin="round" strokeWidth="1" />
                                        </g>
                                    </g>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 14.928 14.165" className="data-actions-icon data-actions-like">
                                    <g id="Like" transform="translate(0.5 0.699)">
                                        <path id="Caminho_21" data-name="Caminho 21"
                                            d="M12.569,17.465h5.97a1.273,1.273,0,0,0,1.187-.79L21.707,12.1a1.261,1.261,0,0,0,.09-.473v-1.3a1.5,1.5,0,0,0-1.312-1.506H16.348l.625-2.752.021-.2a.965.965,0,0,0-.288-.685L16,4.5,11.632,8.8a1.282,1.282,0,0,0-.382.915V16.2A1.292,1.292,0,0,0,12.569,17.465Z"
                                            transform="translate(-7.869 -4.5)" strokeWidth="1" />
                                        <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.307v7.979H3.375Z"
                                            transform="translate(-3.375 -10.764)" strokeLinejoin="round" strokeWidth="1" />
                                    </g>
                                </svg>
                        }
                    </Button>


                    <Button className="data-actions" onClick={() => handleOpenModal(0)}>
                        {context.state.numberLikes > 9 ? context.state.numberLikes : `0${context.state.numberLikes}`}
                    </Button>
                    <Button className="data-actions" onClick={() => handleOpenModal(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 13.625 13.347" className="data-actions-icon">
                            <g id="Comment" transform="translate(-4.5 -1.8)">
                                <g id="Comment_Icon" data-name="Comment Icon" transform="translate(5 2.3)">
                                    <path d="M-521.2,544.632h-8.884a1.957,1.957,0,0,0-1.871,2.033v5.884a1.957,1.957,0,0,0,1.871,2.033h1.406V556.8a.154.154,0,0,0,.256.126l2.472-2.345h4.75a1.957,1.957,0,0,0,1.87-2.033v-5.884A1.957,1.957,0,0,0-521.2,544.632Z"
                                        transform="translate(531.954 -544.632)" strokeLinejoin="round" strokeWidth="1" />
                                </g>
                            </g>
                        </svg>
                        {!props.preview && <div>
                            {context.state.commentsCount > 9 ? context.state.commentsCount : `0${context.state.commentsCount}`}
                        </div>}

                        {props.preview && "00"}
                    </Button>
                </div>

            </div>

            <CommentsLikesDialog author={props.story.author.fullName}
                likes={context.state.likes}
                liked={context.state.like}
                comments={context.state.comments}
                type={PostType.DATA_STORY}
                key={props.story.id}
                onClose={updateStory}/>

            {
                props.story.decision && (
                    <Decision story={{
                        ...props.story.decision,
                        userId: props.story.id,
                        author: props.story.decision.user,
                        insight: props.story.decision.text,
                        outcome: props.story.outcome !== null && props.story.outcome !== undefined,
                        tags: [],
                        date: new Date(),
                        storyRemoved: () => null
                    }}
                        preview={false}
                    />
                )
            }
            {
                props.story.outcome && (
                    <Outcome story={{
                        ...props.story.outcome,
                        userId: props.story.id,
                        author: props.story.outcome.user,
                        insight: props.story.outcome.text,
                        tags: [],
                        date: new Date(),
                        storyRemoved: () => null
                    }}
                        preview={false}
                    />
                )
            }
        </div >
    )
}

export default StoryItem;

