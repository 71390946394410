import React from "react";

export type OnboardingContextProps = {
  state: OnboardingState;
  setState: CallableFunction;
}

export const OnboardingContext = React.createContext<OnboardingContextProps>({
  state: {
    step: 1,
    type: '',
  },
  setState: () => null
})

interface OnboardingState {
  step: number;
  type: string;
}

export const OnboardingProvider = (props: any) => {
  const [state, setState] = React.useState<OnboardingState>({
    step: 1,
    type: "USER",
  });

  return (
    <OnboardingContext.Provider value={{ state, setState: setState }}>
      {props.children}
    </OnboardingContext.Provider>
  )
}