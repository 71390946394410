
const WordHighlighter = {
    getText(originalText: string, newText: string) {
        var originalSplit = originalText.split('. ');
        var newTextSplit = newText.split('. ');

        var highlights = [];
        for (var i in newTextSplit) {
            var originalPhrase = originalSplit[i];
            var currentPhrase = newTextSplit[i];
            highlights.push(this.getHighlight(originalPhrase, currentPhrase));
        }

        return highlights.join('. ')
    },
    getHighlight(original: string, current: string) {

        // noinspection DuplicatedCode
        const words: string[] =original ? original
        .replace(/\n/g, '')
        .replace(/\./g, '')
        .replace(/,/g, '')
        .replace(/!/g, '')
        .replace(/\?/g, '')
        .replace(/-/g, '')
        .split(" ") : [];

        const hashtable: any = {}
        for (const word of words) {
            if (hashtable[word]) {
                hashtable[word] += 1;
                continue;
            }
            hashtable[word] = 1;
        }
        return current.split(" ").map(item => {
            const _word = item
                .replace(/\./g, '')
                .replace(/,/g, '')
                .replace(/!/g, '')
                .replace(/\?/g, '')
                .replace(/-/g, '')
            if (hashtable[_word]) return item;
            return `<span class="highlight-text">${item}</span>`;
        }).join(" ")
    }


}
export default WordHighlighter