import React from "react";
import './Comments.scss';
import { TextField } from "@material-ui/core";

import { Comment as IComment } from "../../../../DataStory/v1/DataStoryContracts";

import { handleComment, handleStoryComment } from "./CommentMethods";
import QuestionService from "../QuestionService";
import LikeButton from "../../../../../components/Likes/LikeButton";
import { PostType } from "./Comments";
import DataStoryService from "../../../../DataStory/v1/DataStoryService";

interface CommentsFooterProps {
  dataStoryId: string;
  author: string;
  liked: boolean;
  type: PostType;
  updateCommentsList(newComments: IComment[]): void;
}

interface CommentsFooterState {
  liked: boolean;
  comment: string;
}

const CommentsFooter: React.FC<CommentsFooterProps> = (props) => {
  const [state, setState] = React.useState<CommentsFooterState>({
    liked: props.liked,
    comment: ""
  });

  const comment = async () => {
    let comments: any;

    if(props.type === PostType.QUESTION)
      comments = await handleComment(state.comment, props.dataStoryId);
    else
      comments = await handleStoryComment(state.comment, props.dataStoryId);
    setState({ ...state, comment: "" })

    props.updateCommentsList(comments)
  }

  const likeQuestion = async () => {

    if(props.type === PostType.QUESTION)
      await QuestionService.likeQuestion(props.dataStoryId);
    else
      await DataStoryService.likeStory(props.dataStoryId);

    setState({ ...state, liked: !state.liked });
  }

  return (
    <div className="comments-footer">
      <LikeButton className="footer-like-button" liked={state.liked} onClick={() => likeQuestion()} />
      <div className="footer-input-group">
        <div className="footer-input-label">
          {state.comment.length > 0 && (
            <>
              <span className="comments-label">Commenting on</span>
              &nbsp;
              <span className="comments-author">{props.author}’s {props.type === PostType.QUESTION ? "Question" : "Data Story"}</span>
            </>
          )}

        </div>
        <TextField
          id="comment-input"
          className="footer-input"
          placeholder="Add a comment..."
          fullWidth
          autoFocus
          autoComplete="off"
          value={state.comment}
          onChange={(e) => setState({ ...state, comment: e.target.value })}
          onKeyUp={(e) => e.key === "Enter" && comment()}
        />
      </div>

      <button
        className="btn-contained-wipe footer-post-button"
        onClick={() => state.comment.length > 0 && comment()}
        type="button"
        disabled={state.comment.length === 0}
      >
        Post
      </button>
    </div>
  )
}

export default CommentsFooter;