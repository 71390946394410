import { Button, Collapse } from '@material-ui/core';
import React from 'react';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import showReplies from '../../../../../assets/images/show-comments-icon.svg';
import hideReplies from '../../../../../assets/images/hide-comments-icon.svg';

interface RepliesContentProps {
    comment: any;
    replies?: Array<any>;
    focusedComment: boolean;
    openReply(commentId: string) : void;
}

interface RepliesContentPropsState {
    likes: any;
    openReplies: boolean;
}

const RepliesContent: React.FC<RepliesContentProps> = (props) => {
    const [state, setState] = React.useState<RepliesContentPropsState>({
        openReplies: props.focusedComment,
        likes: props.comment.likes
    }); 

    return (
        <div className="replies-content">
            <Collapse in={state.openReplies} timeout="auto">
                <ul className="comments-answers-list">
                    {
                        props.replies && props.replies.map((reply: any) => (<li key={reply.id} className="answers-list-item comments-item-content">
                            <div className="answer-main-content">
                                <SubdirectoryArrowRightIcon color="primary" className="answers-item-arrow-icon" />
                                <img src={reply.user.profileUrl} className="answers-item-image" alt="" />
                                <span className="comments-item-text">
                                    <span className="name">{reply.user.fullName}:</span> {reply.text}
                                    <Button className="reply-button" onClick={() => props.openReply(props.comment.id)}>
                                        Reply
                                    </Button>
                                </span>
                            </div>
                        </li>))
                    }
                </ul>
            </Collapse>

            {props.replies && props.replies.length > 0 && (
                <>
                    {!state.openReplies && <div className="replies-collapsed-content" onClick={() => setState({ ...state, openReplies: !state.openReplies })}>
                        <SubdirectoryArrowRightIcon color="primary" className="replies-collapsed-icon" />
                        <span>{props.replies.length} more replies</span>
                    </div>}
                    {!props.focusedComment && (
                        <button onClick={() => setState({ ...state, openReplies: !state.openReplies })} className="replies-collapse-button">
                            {state.openReplies ? <img src={hideReplies} alt="" /> : <img src={showReplies} alt="" />}
                        </button>
                    )
                    }
                </>
            )}
        </div>);
}

export default RepliesContent;