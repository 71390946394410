import React from 'react';
import '../Onboarding.scss';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
interface ThirdStepProps {
  intentionList: string[];
  intentionChanged: CallableFunction;
}

const ThirdStep: React.FC<ThirdStepProps> = (props) => {
  const options = [
    { name: 'have more time to wrangle data and add value. Or Save time producing valued insights', value: 'wrangle-data' },
    { name: 'encourage more people to engage with my insights', value: 'encourage' },
    { name: 'avoid being asked the same questions all the time', value: 'avoid-questions' },
    { name: 'get people to trust my insights more than their guts', value: 'trust-insights' },
  ];

  return (
    <div className="onboarding-step-container">
      <h1 className="onboarding-step-title">
        I’m interested in Decidable, as it’ll help me:
      </h1>

      <FormControl className="onboarding-checkbox-group">
        <FormGroup>
          {options.map((intention, index) => (
            <FormControlLabel
              className="onboarding-checkbox-label"
              control={<Checkbox name={intention.name}
                onChange={(e, isInputChecked) => props.intentionChanged(e.target.name, isInputChecked)}
                icon={<UncheckedIcon />}
                checked={props.intentionList.indexOf(intention.name) >= 0}
                checkedIcon={<CheckCircleIcon />} />}
              label={intention.name}
              key={index}
            />
          ))}
        </FormGroup>
      </FormControl>

      <div className="onboarding-checkbox-alert">
        <span>Pick as many as you want.</span>
      </div>
    </div>
  );
}

export default ThirdStep;