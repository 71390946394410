import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Container } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './Header.scss';
import decidableLogo from '../../assets/images/logo.svg';
import Sidemenu from '../Sidemenu/Sidemenu';
import LoginService from '../../pages/Login/LoginService'
import { OnboardUser, UserContext, UserContextProps } from '../UserProvider';
import Colourway, { Colour } from '../Colourway/Colourway';

const scheduledStories = process.env.REACT_APP_SCHEDULED_STORIES === "true";

const Header: React.FC = () => {
  const context = React.useContext<UserContextProps>(UserContext);
  const history = useHistory();
  const authenticated = LoginService.isUserAuthenticated();

  useEffect(() => {
    if (authenticated) {
      LoginService.getUserName(() => { }).then((user: OnboardUser) => {
        context.setState({ 
          ...context.state, 
          ...user, 
          authenticated: true, 
          trialEnabled: user.company.isTrialAvailable
        })

        if(Object.values(Colour).includes(user.preferredColour as Colour)) {
          Colourway.change(user.preferredColour as Colour);
        } else {
          Colourway.change(Colour.GREEN);
        }

        if(!user.company.activeSubscritpion) {
          history.push("/cart");
          return;
        }

        if (!user.onboarded) {
          const url = scheduledStories ? "/v2/onboarding" : "/onboarding";
          history.push(url);
        }
      })
    }
  /* eslint-disable */
  }, [authenticated])
  /* eslint-enable */

  const [menuStatus, setMenuStatus] = useState<boolean>(false)

  const openMenu = () => {
    let hamburguerMenu = document.querySelector('.hamburger-menu')
    hamburguerMenu?.classList.toggle('animate')
    setMenuStatus(!menuStatus)
  }

  const handleClickAway = () => {
    if (menuStatus) {
      let hamburguerMenu = document.querySelector('.hamburger-menu')
      hamburguerMenu?.classList.toggle('animate')
    }
    setMenuStatus(false)
  };

  const toProfilePage = () => {
    history.push("/user/profile");
  }

  return (
    <header className="header">
      <Container className="header-content">
        <Link to="/" className="header-logo-link">
          <img src={decidableLogo} className="header-logo-image" alt="" />
          <span className="header-logo-beta">beta</span>
        </Link>

        {
          LoginService.isUserAuthenticated()
            ?
            <div className="header-content-infos">
              <div className="user-infos" onClick={toProfilePage}>
                <span className="user-name">
                  {context.state.fullName ? context.state.fullName : ""}
                </span>
                <span className="user-picture">
                  <img src={context.state.profileUrl ? context.state.profileUrl : ""} alt="" />
                </span>
              </div>

              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <div className="menu-wrapper" onClick={openMenu}>
                    <div className="hamburger-menu"/>
                  </div>
                  <Sidemenu openMenu={menuStatus} />
                </div>
              </ClickAwayListener>
            </div>
            : <></>
        }
      </Container>
    </header>
  );
}

export default Header;