import React from 'react';
import '../Onboarding.scss';
import { Dialog } from '@material-ui/core';

const FirstStep: React.FC = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="onboarding-step-container">
      <h1 className="onboarding-step-first-text">
        Your pain is why we created Decidable.<br />
        Your data has stories to tell.<br />
        We make sure yours are heard.<br />
        And more importantly, acted on.
      </h1>
      <h2 className="onboarding-step-first-text">
        Too good to be true?<br />
        Take a look at our <span className="video" onClick={handleOpenModal}>video</span><br />
        or even better give it a test drive and see.
      </h2>

      <Dialog onClose={handleCloseModal} aria-labelledby="simple-dialog-title" open={openModal}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/shV4DPsjzS0"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </Dialog>
    </div>
  );
}

export default FirstStep;