import AddVisual from "./AddVisual/AddVisual";
import Context from "./Context/Context";
import DraftStory from "./DraftStory/DraftStory";
import Preview from "./Preview/Preview";
import Step from "./Step";
import React from "react";

const StepList: Step[] = [
    new Step("Context")
        .setComponent(<Context />)
        .setSubStep(2)
        .setHelp(
            <ul className="step-help-text">
                <li>Always tailor your insights to the reader.</li>
                <li>It’s about what they need to hear.</li>
                <li>Not what you want to say.</li>
            </ul>
        )
        .setHelpText("Context is king"),
    new Step("Draft Story")
        .setComponent(<DraftStory />)
        .setSubStep(4)
        .setHelp(
            <ul className="step-help-text">
                <li>People want answers, not workings out.</li>
                <li>So just give the data that tells the story.</li>
            </ul>
        )
        .setHelpText("Draft your narrative story"),
    new Step("Add Visual")
        .setComponent(<AddVisual />)
        .setHelpText("Add your visual"),
    new Step("Share")
        .setComponent(<Preview />)
        .setHelpText("Share")
];

export default StepList;