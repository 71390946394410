import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { UserContextProps } from './UserProvider';


const browserHistory = createBrowserHistory({ });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY!,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

appInsights.loadAppInsights();

export interface Location {
  pathname: string;
  search: any;
  state: any;
  hash: any;
  key?: any;
}

const loadAppContext = (location: Location, userContext?: UserContextProps) => {
  if(userContext && userContext.state && userContext.state.email) {
    appInsights.setAuthenticatedUserContext(userContext.state.email);
  }
  appInsights.trackPageView({ name: location.pathname });
}

export { loadAppContext };

const TrackingComponent = (Component: any) => withAITracking(reactPlugin, Component)

export default TrackingComponent;
export { reactPlugin, appInsights };