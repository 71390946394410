import React, {useMemo} from 'react'
import './PickGraph.scss';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {GraphSelection} from './PickAGraphService';

export interface DecidableChartProps {
    data: any[];
    graphSelection: GraphSelection
}
const DecidableChart: React.FC<DecidableChartProps> = (props) => {
    const { by, aggregator, aggregatorSubject, chartType } = props.graphSelection;
    const COLORS = useMemo(() => { return ['#23AA4B', '#2C94B5', '#5C1477', '#F98423', '#53CB83', '#50ACC9', '#A757D8', '#F2B070', '#87D1A6', '#8AC3D6', '#D7AAF4', '#FABA84'] }, []);
    const yDataKey = aggregator === "Count" ? "count" : aggregatorSubject;
    return useMemo(() => {


        if (chartType === "Pie") {
            return (<PieChart width={600} height={500}>
                <Pie
                    data={props.data}
                    cx="50%"
                    cy="50%"
                    fill="#8884d8"
                    dataKey={yDataKey!}
                    nameKey={by}
                    label={(entry) => entry.name + ": " + entry.value.toLocaleString(undefined)}

                >
                    {props.data.map((entry, index) => (
                        <Cell key={entry[by]} fill={COLORS[index % COLORS.length]}/>
                    ))}
                    <Tooltip/>
                </Pie>
            </PieChart>);
        }

        if (chartType === "Bar") {
            return (<BarChart
                width={600}
                height={500}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={by}/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey={yDataKey!} fill={COLORS[1 % COLORS.length]}/>
            </BarChart>);
        }

        return (
            <LineChart
                width={600}
                height={500}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={by}/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Line type="monotone" dataKey={yDataKey} stroke={COLORS[1 % COLORS.length]} activeDot={{r: 8}}/>
            </LineChart>);
    /* eslint-disable */
    }, [by, aggregator, aggregatorSubject, chartType]);
    /* eslint-enable */
}

export default DecidableChart;