import React, { ChangeEvent, useEffect } from 'react'
import './ScribeyStory.scss';

import Grid from '@material-ui/core/Grid';
import { InputBase} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import Editor from "rich-markdown-editor";
import { saveStory } from './StoryMethods';
import WordHighlighter from './WordHighlighter';
import { DataStoryContext, DataStoryProps } from '../../../../../DataStoryProvider';
import DataStoryService from '../../../../../../v1/DataStoryService';
import {UserContext, UserContextProps} from "../../../../../../../../components/UserProvider";
import {Colour} from "../../../../../../../../components/Colourway/Colourway";

makeStyles({
  tooltip: {
    backgroundColor: "#fff",
    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
    color: '#000',
    fontSize: '12px',
    lineHeight: '20px'
  }
});

const ScribeyStory: React.FC = () => {
  const userContext = React.useContext<UserContextProps>(UserContext);
  const context = React.useContext<DataStoryProps>(DataStoryContext);
  const [showDraftText, setShowDraftText] = React.useState(true);
  const [answerIndex, setAnswerIndex] = React.useState<number>(0);
  const [originalAnswers, setOriginalAnswers]: any = React.useState<string>('');

  const save = async () => {
    const saved = await saveStory(context);

    if(saved) {
      context.setState({...context.state, step: 3, subStep: 1});
    }
  }

  const isActive = (_index: number, current: number) => _index === current ? "active" : "";

  useEffect(() => {
    const words: string = context
      .state
      .draftStory
      .wizardAnswers
      .filter((x) => x)
      .join(' ');

    setOriginalAnswers(words);
  /* eslint-disable */
  }, [])
  /* eslint-enable */

  return (
    <div className="data-story-container">

      
      <h3 className="data-container-title">Scribey will take what you have drafted and transform it into an engaging data story</h3>
      
      <div style={{ minHeight: '60vh' }}>
      <div className="data-story-drafted">
        <div className="data-drafted-header">
            <h4>Here are the key points that you drafted</h4>

            <button className="data-drafted-button" onClick={() => setShowDraftText(!showDraftText)}>
              {showDraftText ? (
                <VisibilityOutlinedIcon className="visibility-icon" />
              ) : (
                <VisibilityOffOutlinedIcon className="visibility-icon" />
              )}
            </button>
          </div>

          {showDraftText && (
            <p className="data-drafted-text" dangerouslySetInnerHTML={{ __html: context.state.draftStory.wizardAnswers.filter((x) => x).join(". <br>") }}>
            </p>
          )}
        </div>

        <div className={`data-story-scribey ${(userContext.state.preferredColour === Colour.PURPLE) && "light-purple"}`}>
          <h4>Here are some options that Scribey has come up with</h4>
          <p className="data-scribey-text"
            dangerouslySetInnerHTML={{
              __html: WordHighlighter.getText(originalAnswers, context.state.draftStory.AIAnswers[answerIndex])
            }}
          >
          </p>
          <div className="data-scribey-footer">
            <div className="data-scribey-footer-pagination">
              {context.state.draftStory.AIAnswers.map((value, index) => (
                <span className={`footer-pagination ${isActive(index, answerIndex)}`}
                  onClick={() => { setAnswerIndex(index) }}
                >{index + 1}</span>
              ))}
            </div>

            <div className="data-scribey-footer-buttons">
              {/* <button className="btn-outlined-wipe"
                  onClick={(e) => {
                    let newIndex = 0;
                    if(answerIndex + 1 < context.state.AIAnswers.length) {
                      newIndex = answerIndex + 1;
                    }
                    setAnswerIndex(newIndex);
                  }}
                >
                  Load more
                </button> */}

              <button className="btn-contained-wipe"
                onClick={() => {
                  context.setState({ ...context.state, draftStory: {...context.state.draftStory, anomalyReason: context.state.draftStory.AIAnswers[answerIndex] + "\n" + context.state.draftStory.anomalyReason }})
                }}
              >
                Add to Story
              </button>
            </div>
          </div>
        </div>

        <h3 className="data-container-title">Your story - click to edit any text</h3>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={3}>
            <label className="data-story-label">Title</label>
          </Grid>
          <Grid item xs={12} md={9}>
            <InputBase
              value={context.state.draftStory.title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, draftStory: { ...context.state.draftStory, title: e.target.value }  })}
              placeholder="Type here"
              inputProps={{ 'aria-label': 'naked', maxLength: 150 }}
              className="data-story-input"
            />
          </Grid>
        </Grid>

        <Grid container className="data-story-topic">
          <Grid item xs={12} md={3}>
            <label className="data-story-label">
              The narrative that you will publish
            </label>
          </Grid>
          <Grid item xs={12} md={9}>
              <Editor
                key={context.state.draftStory.anomalyReason}
                onChange={(newValue) => {
                  context.setState({...context.state, draftStory: { ...context.state.draftStory, insight: "", anomalyReason: newValue(), impactExplanation: "", suggestions: "" }})
                }}
                className="decidable-markdown data-story-textarea"
                uploadImage={async (file: File) => {
                  const response = await DataStoryService.saveGenericImage(context.state.id, file)
                  if(response.status !== 200) return;
                  const payload = await response.json(); 
                  return payload.imageUrl;
                }}
                disableExtensions={[]}
                placeholder="Type your story here"
                defaultValue={[
                  context.state.draftStory.insight, 
                  context.state.draftStory.anomalyReason,
                  context.state.draftStory.impactExplanation,
                  context.state.draftStory.suggestions,
                ].filter(x => x).join("\\")}
              />
          </Grid>
        </Grid>
      </div>

      <div className="data-story-buttons">
        <button className="btn-outlined-wipe" onClick={() => {
          context.setState({ ...context.state, draftStory: { ...context.state.draftStory, showStory: false } })
        }}>
          Cancel
        </button>

        <button className="btn-contained-wipe" onClick={() => save()}>
          Add Visual
        </button>
      </div>
    </div >
  );
}

export default ScribeyStory;