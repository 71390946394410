import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    HttpLink,
    concat,
    NormalizedCacheObject
} from "@apollo/client";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getApolloClient = (token: string): ApolloClient<NormalizedCacheObject> => {
    const httpLink = new HttpLink({ uri: BASE_URL + '/graphql', });
    
    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers

        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                authorization: token ? 'Bearer ' + token : "",
            }
        }));

        return forward(operation);
    });
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: concat(authMiddleware, httpLink),
    });
}