import {
    gql
} from "@apollo/client";
import { getApolloClient } from "../../components/GraphQL/GraphQL";
import { OnboardUser } from "../../components/UserProvider";
import { QueryResult } from "./LoginContracts";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const LoginService = {
    login: async function (email: string, password: string): Promise<Response> {
        const url = `${BASE_URL}/api/user/login`
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                'email': email.trim(),
                'password': password.trim()
            })
        });
    },
    isUserAuthenticated: () => localStorage.getItem("_token") ? true : false,
    persistLoginToken: async (token: string, expiresIn: number) => {
        localStorage.setItem("_token", token);

        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);

        localStorage.setItem("_tokenExpiration", expirationDate.toString());
    },
    logout: () => {
        localStorage.removeItem("_token");
        localStorage.removeItem("_tokenExpiration");
        window.location.reload();
    },
    getToken: (): string => {
        const tokenExpiration = localStorage.getItem("_tokenExpiration");
        if (!tokenExpiration)
            return '';

        const expiration = new Date(tokenExpiration);
        if (new Date() > expiration) {
            LoginService.logout();
            return '';
        }

        return localStorage.getItem("_token") || "";
    },
    freeTrialSub: async () => {
        const url = `${BASE_URL}/api/create-free-trial-subscription`
        var token = LoginService.getToken();
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer'
        });
    },
    getUserName: async (setName: CallableFunction): Promise<OnboardUser> => {    
        const client = getApolloClient(LoginService.getToken());
        const result: QueryResult = await client.query({
            query: gql`
            query Ping {
                me {
                    id
                    fullName
                    profileUrl
                    email
                    jobTitle
                    department
                    onboarded
                    type
                    description
                    preferredColour
                    userKpis {
                        kpi
                    }
                    company {
                        activeSubscritpion {
                            id
                            planType
                            startDate
                            invoiceValue
                            paymentPeriodType
                            invoiceCreditCardNumber
                            invoiceEmail
                            endDate
                            cancelledAt
                            status
                        }
                    }
                }
            }
            `
        });
    
        setName(result.data.me[0].fullName);

        return result.data.me[0] as OnboardUser;
    }
};

export default LoginService;