import React from 'react';
import './App.scss';
import { theme } from './theme/theme';

import Routes from './Routes';
import { UserProvider } from './UserProvider';
import { ToastContainer } from 'react-toastify';



import './AppInsights';

const App: React.FC = () => {
  const [joyride, setJoyride] = React.useState<JSX.Element | null>(null)

  return (
    <UserProvider joyride={joyride} setJoyride={setJoyride}>
      { joyride }
      <Routes
        theme={theme}
      />
      <ToastContainer />
    </UserProvider>
  );
}

export default App;