import { Container, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import './Checkout.scss';
import { useHistory, useLocation } from "react-router";
import AppInsights, { loadAppContext } from "../../components/AppInsights";
import Header from "../../components/Header/Header";

const Cancelled: React.FC = () => {
    const history = useHistory();
    const location: any = useLocation();

    useEffect(() => {
        loadAppContext(location);
    /* eslint-disable */
    }, []);
    /* eslint-enable */

    const next = () => {
        history.push("/cart");
    }

    return (
        <div className="checkout">
            <Header />
            <Container className="checkout-container">
                <h1 className="checkout-container-title">Subscription payment cancelled.</h1>

                <Grid container direction="row-reverse">
                    <Grid item>
                        <button className="btn-contained-wipe" onClick={() => next()}>
                            Back to cart
                        </button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default AppInsights(Cancelled);