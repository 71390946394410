import React from "react";
import './Cart.scss';
import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Header from "../../components/Header/Header";
import CartService, { PlanType } from "./CartService";

const Cart: React.FC = () => {
    const createCheckout = async (planType: PlanType) => {
        const response = await CartService.createCheckout({
            planType: planType
        })
        if (response.status !== 200) {
            return;
        }
        window.location.href = await response.text();
    }

    const startingPlan = async () => {
        await createCheckout(PlanType.StarterPlan);
    }

    const googleAnalyticsPlan = async () => {
        await createCheckout(PlanType.GoogleAnalytics);

    }

    const goToCheckout = async () => {
        const plans: Array<any> = Array.from(document.getElementsByName("seletectedPlan"));

        let selectedPlan = null;

        for (const plan of plans) {
            if (plan.checked) {
                selectedPlan = plan.value;
            }
        }

        if (!selectedPlan) return;

        if (selectedPlan === "starting") {
            startingPlan();
        }

        if (selectedPlan === "googleAnalytics") {
            googleAnalyticsPlan();
        }
    }

    return (
        <div className="cart">
            <Header />
            <Container className="cart-container">
                <h1 className="cart-container-title">Stories worth telling are worth sharing.</h1>

                <p className="cart-container-text">
                    Get automated google analytics stories on a weekly basis. Our clients are unearthing between 25% and 30% additional revenue every week.
                </p>

                <h2 className="cart-container-subtitle">Our Plans</h2>

                <Grid item xs={12}>
                    <TableContainer className="cart-container-table">
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ visibility: 'hidden' }}></TableCell>
                                    {/* <TableCell align="center">The getting<br />started plan</TableCell> */}
                                    <TableCell align="center">Automated Google Analytics <br />insights as stories</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Seats
                                    </TableCell>
                                    {/* <TableCell align="center" className="started-plan">
                                        11
                                    </TableCell> */}
                                    <TableCell align="center">
                                        3
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Automated Google Analytics stories
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        –
                                    </TableCell> */}
                                    <TableCell align="center">
                                        Included
                                    </TableCell>
                                </TableRow>
                                <TableRow className="row-cost">
                                    <TableCell component="th" scope="row">
                                        &#40;Introductory Offer&#41;
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <span className="highlight-price">£95</span> per month
                                    </TableCell> */}
                                    <TableCell align="center">
                                        <span className="highlight-price">£99</span> per month
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ visibility: "hidden", borderBottom: "none" }}>
                                    </TableCell>
                                    {/* <TableCell align="center" className="cart-table-radio">
                                        <input type="radio" name="seletectedPlan" value="starting" id="starting" /><br />
                                        <span className="radio-check"></span>
                                        <label htmlFor="starting" className="cart-table-label">Select plan</label>
                                    </TableCell> */}
                                    <TableCell align="center" className="cart-table-radio">
                                        <input type="radio" name="seletectedPlan" value="googleAnalytics" id="googleAnalytics" /><br />
                                        <span className="radio-check"></span>
                                        <label htmlFor="goToCheckout" className="cart-table-label">Select plan</label>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid container direction="row-reverse">
                    <Grid item>
                        <button className="btn-contained-wipe" onClick={() => goToCheckout()}>
                            Next
                        </button>
                    </Grid>
                </Grid>
                <label htmlFor="bespoken" className="cart-vat-label">* Prices are exclusive of VAT</label>
            </Container>
        </div>
    );
}

export default Cart;