import React from 'react';
import "./StoryQuotes.scss"

interface Quote {
    text: string,
    author: string
}


function getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
}

const StoryQuotes: React.FC = () => {
    const ANIMATION_DURATION = 1000;
    const CHANGE_QUOTE_INTERVAL = 10000;

    const quotes: Quote[] = React.useMemo<Quote[]>(() => [{
        text: "Data stories are more memorable than statistics alone. 5% remember stats, 68% remember stories.",
        author: "Nancy Duarte"
    },
    {
        text: "The brain lights up when a story is told, imagine the power of using storytelling to help your audience understand your Data",
        author: "Decidable"
    },
    {
        text: "Storytelling is the essential human activity. The harder the situation, the more essential it is.",
        author: "Tim O’Brien"
    },
    {
        text: "“Good story” means something worth telling that the world wants to hear. Finding this is your lonely task. . . . Your goal must be a good story well told.",
        author: "Robert McKee, screenwriting expert and author"
    },
    {
        text: "Stories change people while statistics give them something to argue about.",
        author: "Bernie Siegel, author and surgeon"
    },
    {
        text: "Maybe stories are just data with a soul.",
        author: "Brene Brown, research professor and author"
    },
    {
        text: "Numbers have an important story to tell. They rely on you to give them a clear and convincing voice.",
        author: "Stephen Few, data visualization expert"
    },
    {
        text: "I’m a storyteller; that’s what exploration really is all about. Going to places where others haven’t been and returning to tell a story they haven’t heard before.",
        author: "James Cameron, filmmaker and underwater explorer"
    },
    {
        text: "A lost coin is found by means of a candle; the deepest truth is found by means of a simple story.",
        author: "Anthony De Mello, author"
    },
    {
        text: "For me, and for many of us, our first waking thought of the day is 'I didn’t get enough sleep.' The next one is 'I don’t have enough time.'",
        author: "@BreneBrown"
    },
    {
        text: "Storytelling is the essential human activity. The harder the situation, the more essential it is.",
        author: "Tim O’Brien, author"
    },
    {
        text: "'I have no special talent. I am only passionately curious.",
        author: "Einstein"
    }], []);

    const startingQuote = quotes[getRndInteger(0, quotes.length)];
    const [quote, setQuote] = React.useState<Quote>(startingQuote);

    const timeoutQuote = () => {
        setTimeout(() => {
            setTimeout(() => {
                setQuote(quotes[getRndInteger(0, quotes.length)]);
            }, ANIMATION_DURATION)
            timeoutQuote();
        }, CHANGE_QUOTE_INTERVAL);
    }

    React.useEffect(() => {
        timeoutQuote();
        /* eslint-disable */
    }, [])
    /* eslint-enable */

    return (
        <>
            <div className="story-quote">
                <div className="content">
                    "{quote.text}"
                    <br />
                    <span className="author">{quote.author}</span>
                    <div className="story-quote-button">
                        <button className="btn-outlined-wipe"
                            onClick={() => {
                                setQuote(quotes[getRndInteger(0, quotes.length)])
                            }}
                            style={{ marginRight: '5px' }}
                        >See another quote</button>
                    </div>
                </div>
            </div>

        </>
    );
}
export default StoryQuotes;