import React from "react";
import { User } from "../../Login/LoginContracts";
import { UserPreferences } from "./Onboarding";

class UserStoryGenerator {
    generateAnalystStory(intentions: string[], fullName: string, departments: string[]): string {
        return `My name is ${fullName} and I want to ${this.grammarJoin(intentions)}. I produce stories for ${this.grammarJoin(departments)}.`
    }

    generateUserStory(userPreferences: UserPreferences, user: User, kpis: string[]): string {
        return `My name is ${user.fullName}, I work in ${userPreferences.department} as a ${userPreferences.jobTitle}. You can contact me at ${user.email}. Some of the KPIs I’m interested in are ${this.grammarJoin(kpis)}.`;
    }

    grammarJoin(values: string[]): string {
        if (values.length === 1)
            return values[0];

        if (values.length === 2)
            return `${values[0]} and ${values[1]}`;

        const leftSideIntentionList = values.slice(0, values.length - 1);

        const leftSide = leftSideIntentionList.join(', ');
        return `${leftSide} and ${values[values.length - 1]}`;
    }

    grammarJoinElements(values: JSX.Element[]): JSX.Element {
        if (values.length === 1)
            return values[0];

        if (values.length === 2)
            return (<React.Fragment>
                {values[0]} and {values[1]}
            </React.Fragment>)

        const leftSideIntentionList = values.slice(0, values.length - 1);
        return (<React.Fragment>{leftSideIntentionList
            .map<React.ReactNode>(t => <span>{t}</span>)
            .reduce((prev, curr) => [prev, ', ', curr])} and {values[values.length - 1]}
        </React.Fragment>)
    }
}

export default new UserStoryGenerator();