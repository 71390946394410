import React from "react";
import { CommentAnswer, User } from "../../Dashboard";
export type QuestionItemContextProps = {
    state: QuestionItemState,
    setState: CallableFunction
}

export const QuestionItemContext = React.createContext<QuestionItemContextProps>({
    state: {
        commentId: "",
        numberLikes: 0,
        questionId: "",
        questionMenu: null,
        commentAnswer: null,
        answerSelectedBy: null
    },
    setState: () => null
});

interface QuestionItemState {
    commentId: string;
    numberLikes: number;
    questionId: string;
    questionMenu: Element | null;
    commentAnswer: CommentAnswer | null;
    answerSelectedBy: User | null;
}

export const QuestionItemProvider: React.FC = (props) => {
    const [state, setState] = React.useState<QuestionItemState>({
        commentId: "",
        numberLikes: 0,
        questionId: "",
        questionMenu: null,
        commentAnswer: null,
        answerSelectedBy: null
    })
    return (
        <QuestionItemContext.Provider value={{ state, setState: setState }}>
            {props.children}
        </QuestionItemContext.Provider>
    )
}