import LoginService from "../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const EmailConfirmationService = {
    async confirm(email: string, token: string): Promise<Response> {
        const url = `${BASE_URL}/api/user/confirm-email`
        
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LoginService.getToken()}`,
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                'email': email.trim(),
                'token': token.trim()
            })
        });
    }
}

export default EmailConfirmationService;