import React from 'react';
import '../Onboarding.scss';
import { MenuItem, Select, TextField } from "@material-ui/core";
import { UserPreferences } from '../Onboarding';

interface YourStoryProps {
  preferences: UserPreferences;
  name: string;
  changeUserPreferences: CallableFunction
}

const SecondStep: React.FC<YourStoryProps> = (props) => {
  return (
    <div className="onboarding-step-container">
      <h1 className="onboarding-step-title">
        Nice to meet you {props.name}, <br />
        why don’t you tell us your story?
      </h1>

      <div className="onboarding-step-text">
        I work in
        <Select
          className="onboarding-step-select"
          id="department"
          variant="outlined"
          defaultValue="placeholder"
          value={props.preferences.department}
          onChange={(e) => props.changeUserPreferences({ ...props.preferences, department: e.target.value })}
        >
          <MenuItem value="placeholder" disabled>Department</MenuItem>
          <MenuItem value="Production">Production</MenuItem>
          <MenuItem value="Product">Product</MenuItem>
          <MenuItem value="Research and Development">Research and Development</MenuItem>
          <MenuItem value="Marketing">Marketing</MenuItem>
          <MenuItem value="Sales">Sales</MenuItem>
          <MenuItem value="Humans Resources">Humans Resources</MenuItem>
          <MenuItem value="Accounting and Finance">Accounting and Finance</MenuItem>
          <MenuItem value="IT">IT</MenuItem>
          <MenuItem value="Technology">Technology</MenuItem>
          <MenuItem value="Data">Data</MenuItem>
          <MenuItem value="Software Development">Software Development</MenuItem>
          <MenuItem value="Ecommerce">Ecommerce</MenuItem>
          <MenuItem value="Design">Design</MenuItem>
          <MenuItem value="Fraud">Fraud</MenuItem>
          <MenuItem value="Legal">Legal</MenuItem>
          <MenuItem value="Public Relations">Public Relations</MenuItem>
          <MenuItem value="Social Media">Social Media</MenuItem>
        </Select>
        as a &nbsp;
        <TextField
          value={props.preferences.jobTitle}
          onChange={(e) => props.changeUserPreferences({ ...props.preferences, jobTitle: e.target.value })}
          id="business-jobtitle"
          className="onboarding-step-input job-title"
        />
      </div>

      <div className="onboarding-step-text">
        my line manager is &nbsp;
        <TextField
          value={props.preferences.lineManager}
          onChange={(e) => props.changeUserPreferences({ ...props.preferences, lineManager: e.target.value })}
          id="business-manager"
          className="onboarding-step-input"
        />
      </div>

      <div className="onboarding-step-text">
        and I want my colourway to be
        <Select
          className="onboarding-step-select"
          id="colourway"
          variant="outlined"
          defaultValue="placeholder"
          value={props.preferences.preferredColour}
          onChange={(e) => props.changeUserPreferences({ ...props.preferences, preferredColour: e.target.value })}
        >
          <MenuItem value="placeholder" disabled>Pick</MenuItem>
          <MenuItem value="green">Green</MenuItem>
          <MenuItem value="purple">Purple</MenuItem>
          <MenuItem value="blue">Blue</MenuItem>
          <MenuItem value="orange">Orange</MenuItem>
        </Select>
      </div>
    </div>
  );
}

export default SecondStep;