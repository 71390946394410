import React, { ChangeEvent } from 'react'
import './FreedomMode.scss';
import { DataStoryContext, DataStoryProps } from '../DataStoryProvider';
import { Grid, InputBase } from '@material-ui/core';

import Editor from "rich-markdown-editor";
import DataStoryService from '../DataStoryService';

const FreedomMode: React.FC = () => {
  const context = React.useContext<DataStoryProps>(DataStoryContext);

  return (
    <div>
      <h3 className="data-container-title">
        Freedom mode<br />
        All you have to do is write. This is the meat of your story, it makes your analytics readable by anyone.
      </h3>
      <Grid container className="data-story-topic">
          <Grid item xs={12} md={5}>
            <label className="data-story-label">Title</label>
          </Grid>
          <Grid item xs={12} md={7}>
            <InputBase
              value={context.state.title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, title: e.target.value })}
              placeholder="Type here"
              inputProps={{ 'aria-label': 'naked', maxLength: 150 }}
              className="data-story-input"
            />
          </Grid>
        </Grid>

        <Grid container className="data-story-topic" style={{marginBottom: "50px"}}>
          <Grid item xs={12} md={5}>
            <label className="data-story-label">Subtitle</label>
          </Grid>
          <Grid item xs={12} md={7}>
            <InputBase
              value={context.state.subtitle}
              onChange={(e: ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, subtitle: e.target.value })}
              placeholder="Type here"
              inputProps={{ 'aria-label': 'naked', maxLength: 150 }}
              className="data-story-input"
            />
          </Grid>
        </Grid>

      <Editor
        id="freedom-mode"
        onChange={(newValue) => {
          context.setState({...context.state, insight: newValue(), anomalyReason: "", impactExplanation: "", suggestions: "" })
        }}
        className="decidable-markdown"
        uploadImage={async file => {
          const response = await DataStoryService.saveGenericImage(context.state.id, file)
          if(response.status !== 200) return;
          const payload = await response.json(); 
          return payload.imageUrl;
        }}
        disableExtensions={[]}
        placeholder="Type your story here"
        defaultValue={[
          context.state.insight, 
          context.state.anomalyReason,
          context.state.impactExplanation,
          context.state.suggestions,
        ].filter(x => x).join("\\")}
      />
      <div className="data-story-buttons">
        <button className="btn-outlined-wipe" onClick={(e: any) => {
          context.setState({ ...context.state, storyMode: "" })
        }}>
          Cancel
        </button>

        <button className="btn-contained-wipe" onClick={(e) => {
          context.setState({ ...context.state, step: 3 })
        }}>
          Next
        </button>

      </div>
    </div>
  )
}

export default FreedomMode;
