import React from "react";

import "./Context.scss";
import { DataStoryContext, DataStoryProps } from "../DataStoryProvider";
import First from "./Substeps/First";
import Second from "./Substeps/Second";

const Context: React.FC = () => {
    const context = React.useContext<DataStoryProps>(DataStoryContext);

    switch(context.state.subStep) {
        case 1:
            return <First />;
        case 2:
            return (
                <>
                    <First style={{marginBottom: "30px"}}/>
                    <Second />
                </>
            );
        default:
            return <></>;
    }
}

export default Context;