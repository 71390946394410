import React, { useState } from 'react';
import './BillingDetails.scss';
import { useHistory } from "react-router-dom";
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { UserRegisterStatus } from '../../DataStory/v1/DataStoryContracts';
import { OnboardUser, Subscription, UserContext, UserContextProps } from '../../../components/UserProvider';
import { toast } from 'react-toastify';
import LoginService from '../../Login/LoginService';

export interface Row {
  id: number;
  email: string;
  name: string;
  role: string;
  userType: string;
  userRegisterStatus: UserRegisterStatus;
}
const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const BillingService = {
  cancel: async function (): Promise<Response> {
    var token = LoginService.getToken();
    const url = `${BASE_URL}/subscription/cancel`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? 'Bearer ' + token : "",

      },
      referrerPolicy: 'no-referrer'
    });
  }
};

const BillingDetails: React.FC = () => {
  const context = React.useContext<UserContextProps>(UserContext);

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const cancelSubscription = async () => {
    if (window.confirm("Are you sure you want to cancel?")) {
      const response = await BillingService.cancel();
      if (response.status === 200) {
        toast.success("Your subscription has been cancelled");

        setLoading(true);
        setTimeout(function () {
          LoginService.getUserName(() => { }).then((user: OnboardUser) => {
            setLoading(false);
            context.setState({
              ...context.state,
              ...user,
              authenticated: true,
              trialEnabled: user.company.isTrialAvailable
            })
          }).catch(() => {
            setLoading(false)
          });
        }, 5000)
      } else if (response.status === 401) {
        toast.error("Only the company admin can cancel subscriptions");
      } else {
        toast.error("Something went wrong cancelling your subscription");
      }

    }
  }
  
  const changePlan =() => {
    history.push('/cart');
  }

  const GBPFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

  const renderSubscriptionStatus = (subscription: Subscription | null) => {

    if (!subscription) {
      return <span>
        No subscription active
      </span>;
    }

    if (subscription.planType === "FreeTrial") {
      return <span>
        Your company is on a {subscription.planType} plan. 
        Your plan will expire on {subscription! && new Date(subscription!.endDate).toLocaleDateString()} <br /> <br />
        If you wish to subscribe to Decidable   <button className="btn-outlined-wipe" style={{ marginLeft: "5px" }} onClick={changePlan}>Click here</button >
      </span>;
    }

    if (subscription.status === "canceled") {
      return <span>

        Your company {subscription.planType} plan was cancelled on {new Date(subscription.cancelledAt).toLocaleDateString()}. 
        Your company plan is valid until {new Date(subscription.endDate).toLocaleDateString()}.<br /><br />
        If you wish to re-subscribe 
        <button className="btn-outlined-wipe" style={{ marginLeft: "5px" }} onClick={changePlan}>Click here</button >
      </span >;
    }

    return (<div>
      <span>Your account is on the {subscription.planType}, paying every {subscription.paymentPeriodType}. Your plan will renew on {new Date(subscription!.endDate).toLocaleDateString()} for {GBPFormatter.format(subscription.invoiceValue)}.</span><br /> <br /> 
      {/* <button className="btn-outlined-wipe" style={{ marginLeft: "5px" }} onClick={changePlan}>Change Plan</button > */}
      Alternatively, If you wish to cancel
      < button className="btn-outlined-wipe btn-error" style={{ marginLeft: "5px" }} onClick={cancelSubscription}>Click here</button >
    </div >)

  }
  return (
    <>
      <div className="billing-selected-content-header">
        <h1 className="billing-selected-content-title">Overview</h1>
      </div>

      <div className="billing-selected-content-header" style={{ padding: "30px 0px" }}>
        {!loading && renderSubscriptionStatus(context.state.company.activeSubscritpion)}
        {loading && <CircularProgress />}
      </div >
      {
        context.state.company.activeSubscritpion && (
          context.state.company.activeSubscritpion!.planType !== "FreeTrial" && (
            <div className="billing-selected-content-header">
              <Grid container>
                <Grid item xs={4} style={{ padding: "30px 0px" }}>
                  Payments
                </Grid>
                <Grid item xs={4} style={{ padding: "30px 0px" }}>
                  Currently, the default payment method is the card ending in {context.state.company.activeSubscritpion.invoiceCreditCardNumber}.<br />
                  All billings related emails are sent to {context.state.company.activeSubscritpion.invoiceEmail}.
                </Grid>
                <Grid item xs={4} style={{ padding: "30px 0px" }}>
                  <Button>Manage</Button>
                </Grid>
              </Grid>
            </div>
          )
        )
      }
    </>
  );
}

export default BillingDetails;