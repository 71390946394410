import React from 'react';
import { UserPreferences } from '../Onboarding';
import '../Onboarding.scss';
import UserStoryGenerator from '../UserStoryGenerator';
interface YourStoryProps {
  preferences: UserPreferences;
  name: string;
  changeUserPreferences: CallableFunction;
  intentionList: string[];
}

const FourthStep: React.FC<YourStoryProps> = (props) => {

  const boldedIntentionList = props.intentionList.map(x => (<span className="highlight">{x}</span>));
  const boldedDepartmentLists = props.preferences.departments.map(x => (<span className="highlight">{x}</span>));

  return (
    <div className="onboarding-step-container">
      <h1 className="onboarding-step-title">
        So this is your story:
      </h1>

      <h2 className="onboarding-step-story">
        My name is <span className="highlight">{props.name}</span> and I want to&nbsp;
        {UserStoryGenerator.grammarJoinElements(boldedIntentionList)}.
        I produce stories for {UserStoryGenerator.grammarJoinElements(boldedDepartmentLists)}.
      </h2>
    </div>
  );
}

export default FourthStep;