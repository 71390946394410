import './Outcome.scss';
import { Button, Menu, MenuItem} from "@material-ui/core";

import DotsMenuIcon from '../../../../../assets/images/dotsMenu-icon.svg';
import React from "react";
import { StoryItemContext, StoryItemContextProps } from "../StoryItemProvider";
import { StoryItemImageProps} from "../StoryItemContracts";
import { DialogContext, DialogContextProps } from "../../QuestionItem/DialogProvider";
import { toast } from "react-toastify";


import Editor from "rich-markdown-editor";

import QuestionService from "../../QuestionItem/QuestionService";
import { handleError } from "../../QuestionItem/Comments/Comment";
import CommentService from "../../QuestionItem/Comments/CommentService";
import { LikeWhiteButton } from '../../../../../components/Likes/LikeButton';

const Outcome: React.FC<StoryItemImageProps> = (props) => {
    const context = React.useContext<StoryItemContextProps>(StoryItemContext);
    const dialogContext = React.useContext<DialogContextProps>(DialogContext);

    const likeOutcome = () => {
        (async function () {
            await CommentService.likeComment(props.story.id);
            if (context.state.likeOutcome) {
                context.setState({ ...context.state, numberLikesOutcome: context.state.numberLikesOutcome - 1, likeOutcome: !context.state.likeOutcome });
                return;
            }
            context.setState({ ...context.state, numberLikesOutcome: context.state.numberLikesOutcome + 1, likeOutcome: !context.state.likeOutcome });
        })()
    }    
    
    const unselectOutcome = async (id: string) => {
        const response = await QuestionService.unselectOutcome(props.story.id);
        
        if (response.status !== 200) {
          handleError(response);
          return;
        }
    
        toast.success("Comment unselected as outcome.");
        context.setState({...context.state, outcomeMenu: null, });
        dialogContext.setState({...dialogContext.state, outcome: null});
        dialogContext.state.updateFeed();
    }

    return (
        <div key={props.story.id} className="feed-outcome-item">
            <div className="outcome-container"
                style={{ background: "#2c94b5", borderRadius: "0px 0px 15px 15px" }}
            >
                <div className="outcome-container-header">
                    <div className="outcome-container-contents">
                        <img className="container-header-avatar" src={props.story.author.profileUrl} alt="" />

                        <div className="container-header-name container-header-name-white">
                            <span className="name">{props.story.author.fullName}</span>
                            <span className="role">
                                {props.story.author.jobTitle} {props.story.author.department === '' ? '' : `· ${props.story.author.department}`}
                            </span>
                        </div>
                    </div>

                    <div className="outcome-container-contents">
                        <img src={props.story.chartImageUrl} className="container-header-chart" alt="" />
                                   
                        <Button className="outcome-menu-button" onClick={(e) => context.setState({ ...context.state, outcomeMenu: e.currentTarget })}>
                            <img src={DotsMenuIcon} alt="" />
                        </Button>
                        <Menu
                            anchorEl={context.state.outcomeMenu}
                            open={Boolean(context.state.outcomeMenu)}
                            onClose={() => context.setState({ ...context.state, outcomeMenu: null })}
                            PopoverClasses={{ paper: 'popover-menu' }}
                        >
                        <MenuItem onClick={(e) => unselectOutcome(props.story.id)} className="popover-menu-item">Remove Outcome</MenuItem>
                        </Menu>
                    </div>
                </div>

                <div className="outcome-container-title">
                    <div className="texts-contents">
                        <h5 className="card-type" style={{color: '#fff'}}>Marked as Outcome</h5>
                        <Editor className="text-intro decidable-markdown-feed white-outcome" value={[
                            props.story.insight,
                            props.story.anomalyReason].join("\n")
                        } readOnly disableExtensions={[]} />
                    </div>
                </div>

                <div className="outcome-container-footer">
                    <LikeWhiteButton liked={context.state.likeOutcome} onClick={() => likeOutcome() } />   
                    <span className="like-counter-outcome">{context.state.numberLikesOutcome.toString().length === 1 ? "0" : ""}{context.state.numberLikesOutcome}</span> 
                </div>

            </div>
        </div>
    )
}

export default Outcome;

