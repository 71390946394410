import React from 'react';
import './Verification.scss';
import { Container, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router';

interface VerificationProps {
  email: string,
  adminName?: string
  isFreeTrial: boolean;
  status: string
}

const Verification: React.FC<VerificationProps> = (props) => {
  const history = useHistory();

  const freeTrialText = () => props.isFreeTrial ? "We are almost there. After that you can enjoy your free trial.": ""

  return (
    <Container className="register-card-content verification-content">
      <Grid item className='verification-back-button'>
        <Button variant="outlined" onClick={(e: React.MouseEvent<HTMLButtonElement>) => history.push("/")}>
          {"x"}
        </Button>
      </Grid>
      <h1 className="register-content-title">
        {!props.adminName ?
          `To be super safe, we’ve sent you an email to ${props.email} to complete your registration. ${freeTrialText()}`
          :
          (
            <>
              Great minds think alike, your company is already using Decidable.
              <br /><br />
              To be super safe, we’ve sent you an email to ${props.email} to complete your registration.
            </>
          )
        }
      </h1>

      {!props.adminName ?
        <Grid item className="verification-buttons">
          <button className="btn-outlined-wipe invite-users">
            Send email again
          </button>

          <button className="btn-contained-wipe" onClick={(e) => history.push("/")}>
            Login & get busy
          </button>
        </Grid>
        :
        ''
      }
    </Container>
  );
}

export default Verification;