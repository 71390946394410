import React from 'react';
import './ResendEmail.scss';
import { Grid, CardContent } from '@material-ui/core';

interface ResendEmailProps {
  email: string;
  submit: CallableFunction;
  disableSendAgain: boolean;
  setParentState: CallableFunction;
}

export default function ResendEmail(props: ResendEmailProps) {
  return (
    <CardContent>
      <h1 className="login-container-title">Fear not, help is on the way. We've sent a recovery link to {props.email}</h1>
      {
        props.disableSendAgain
          ? (
            <button className="btn-contained-wipe" type="button">
              Send Again
            </button>
          )
          : (
            <Grid item className="login-buttons">
              <button className="btn-contained-wipe" type="button" onClick={async (e) => await props.submit()}>
                Send Again
              </button>
            </Grid>
          )
      }
      <Grid item className="login-buttons">
        <button className="btn-outlined-wipe" type="button" onClick={() => props.setParentState({ mode: "login" })}>
          Back
        </button>
      </Grid>
    </CardContent >
  );
}