class Step {
    private name: string;
    private component: JSX.Element;
    private subStep: number;
    private helpComponent: JSX.Element;
    private helpText: string;

    constructor(name: string) {
        this.name = name;
        this.component = <>{ this.name }</>;
        this.subStep = 1;
        this.helpComponent = <></>;
        this.helpText = "";
    }

    setComponent(component: JSX.Element) {
        this.component = component;
        return this;
    }

    setSubStep(subStep: number) {
        this.subStep = subStep;
        return this;
    }

    setHelp(help: JSX.Element) {
        this.helpComponent = help;
        return this;
    }

    setHelpText(helpText: string) {
        this.helpText = helpText;
        return this;
    }

    getSubStep() { return this.subStep; }
    getName() { return this.name; }
    getHelp() { return this.helpComponent; }
    getHelpText() { return this.helpText; }
    getComponent() { return this.component; }
}

export default Step;