import LoginService from "../Login/LoginService";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export enum PlanType {
    StarterPlan = "StarterPlan",
    GoogleAnalytics = "GoogleAnalytics"
}
export interface Cart {
    planType: PlanType
}

class CartService {
    async createCheckout(cart: Cart) {
        const url = `${BASE_URL}/payment/create-checkout-session`;

        const token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            body: JSON.stringify(cart)
        });
    }
    
}

export default new CartService();