import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import EmailConfirmation from "../pages/EmailConfirmation/EmailConfirmation";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import ProtectedRoute from "./ProtectedRoute";
import { OnboardingConnector } from '../pages/Onboarding/v1/OnboardingConnector';

import { OnboardingConnector as OnboardingConnectorV2 } from "../pages/Onboarding/v2/OnboardingProvider";

import AddUsers from '../pages/AddUsers/AddUsers';
import Admin from '../pages/Admin/Admin';
import AnalystLibrary from '../pages/Library/Analyst/Analyst';
import React from 'react';
import LoginService from "../pages/Login/LoginService";
import { DataStoryConnector } from "../pages/DataStory/v1/DataStoryConnector";
import Cart from "../pages/Cart/Cart";

import { default as CheckoutSuccess } from "../pages/Checkout/Success";
import { default as CheckoutCancelled } from "../pages/Checkout/Cancelled";

import { default as FocusedQuestion } from "../pages/Focused/Question";
import { default as FocusedStory } from "../pages/Focused/Story";
import Billing from "../pages/Billing/Billing";
import GoogleAnalytics from "../pages/GoogleAnalytics/GoogleAnalytics";
import AdminFreeTrial from "../pages/AdminFreeTrial/AdminFreeTrial";
import UserProfile from "../pages/UserProfile/UserProfile";
import { default as DataStoryConnectorV2 } from "../pages/DataStory/v2/DataStoryConnector";
import GoogleAnalyticsSetup from "../pages/GoogleAnalytics/GoogleAnalyticsSetup";
import StoryTestPage from "../pages/StoryTestPage/StoryTestPage";

interface RoutesProps {
  theme: any;
}

const Routes: React.FC<RoutesProps> = (props) => {
  const authenticated = LoginService.isUserAuthenticated();
  return (
    <BrowserRouter>
      <ThemeProvider theme={props.theme}>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" exact component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/confirm-email" component={EmailConfirmation} />
          <Route exact path="/reset-password" component={Login} />
          <ProtectedRoute exact path="/user/profile" isAuthenticated={authenticated} component={UserProfile} />

          <ProtectedRoute exact path="/onboarding" isAuthenticated={authenticated} component={OnboardingConnector} />
          <ProtectedRoute exact path="/v2/onboarding" isAuthenticated={authenticated} component={OnboardingConnectorV2} />

          <ProtectedRoute exact path="/dashboard" isAuthenticated={authenticated} component={Dashboard} />
          
          <ProtectedRoute exact path="/data-story" isAuthenticated={authenticated} component={DataStoryConnector} />
          <ProtectedRoute exact path="/v2/data-story" isAuthenticated={authenticated} component={DataStoryConnectorV2} />

          <ProtectedRoute exact path="/add-users" isAuthenticated={authenticated} component={AddUsers} />
          <ProtectedRoute exact path="/company/users" isAuthenticated={authenticated} component={Admin} />
          <ProtectedRoute exact path="/free-trial" isAuthenticated={authenticated} component={AdminFreeTrial} />
          <ProtectedRoute exact path="/billing" isAuthenticated={authenticated} component={Billing} />
          <ProtectedRoute exact path="/library/analyst" isAuthenticated={authenticated} component={AnalystLibrary} />
          <ProtectedRoute exact path="/cart" isAuthenticated={authenticated} component={Cart} />
          <ProtectedRoute exact path="/checkout/success" isAuthenticated={authenticated} component={CheckoutSuccess} />
          <ProtectedRoute exact path="/checkout/cancelled" isAuthenticated={authenticated} component={CheckoutCancelled} />
          <ProtectedRoute exact path="/question/:id" isAuthenticated={authenticated} component={FocusedQuestion} />
          <ProtectedRoute exact path="/story/:id" isAuthenticated={authenticated} component={FocusedStory} />
          <ProtectedRoute exact path="/google-analytics" isAuthenticated={authenticated} component={GoogleAnalytics} />
          <ProtectedRoute exact path="/google-analytics/code" isAuthenticated={authenticated} component={GoogleAnalytics} />
          <ProtectedRoute exact path="/google-analytics/setup" isAuthenticated={authenticated} component={GoogleAnalyticsSetup} />
          <ProtectedRoute exact path="/story-test" isAuthenticated={authenticated} component={StoryTestPage} />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default Routes;