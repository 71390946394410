import React, { useEffect } from 'react'
import './Preview.scss';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postStory } from './PreviewMethods';
import { UserContext, UserContextProps } from '../../../../components/UserProvider';
import { DataStoryContext, DataStoryProps } from '../DataStoryProvider';
import DecidableChart from '../PickGraph/DecidableChart';
import { MultiSelect } from 'react-multi-select-component';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { getOptions, selectUser } from '../Context/ContextMethods';


import Editor from "rich-markdown-editor";


const Preview: React.FC = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.toLocaleDateString('en-US', { month: "short" });
  const year = date.getFullYear();
  const history = useHistory();
  const userContext = React.useContext<UserContextProps>(UserContext);
  const context = React.useContext<DataStoryProps>(DataStoryContext);

  /* eslint-disable */
  const [selected, setSelected] = React.useState<any>([]);
  /* eslint-enable */
  const [options, setOptions] = React.useState<Array<any>>([]);

  const [image, setImage] = React.useState<string>("")
  const images = [
    "/data-story-icons/area-chart-02.png",
    "/data-story-icons/area-chart.png",
    "/data-story-icons/bars-chart.png",
    "/data-story-icons/circular-bars-chart.png",
    "/data-story-icons/circular-chart.png",
    "/data-story-icons/pyramid-chart.png"
  ]

  const loadImage = () => {
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      const elImage: any = document.getElementById("graphImage")!;
      elImage.src = reader.result!;
      elImage.style.display = "block";
      context.setState({ ...context.state, freedomB64Image: elImage.src });
    });

    if (context.state.freedomGraphImage) {
      reader.readAsDataURL(context.state.freedomGraphImage)
    }

    if (context.state.dataChartImageUrl) {
      const elImage: any = document.getElementById("graphImage")!;
      elImage.style.display = "block";
      elImage.src = context.state.dataChartImageUrl;
    }
  }
  useEffect(() => {
    const index = Math.floor(Math.random() * images.length)
    setImage(images[index]);

    if (context.state.dataChartImageUrl) {
      loadImage();
    }

    getOptions(setOptions, (_options: any) => {
      context.setState(context.state);
      selectUser(context.state, context.setState, [], setSelected);
    }).then(r => r);
   /* eslint-disable */
   }, []);
  /* eslint-enable */

  return (
    <div className="data-preview-container">
      <h3 className="data-container-title">Ta da!</h3>

      <div className="data-preview-content" style={{ minHeight: '60vh' }}>
        <div className="preview-header">
          <div>
            <span className="datetime">{date.toLocaleTimeString()} · {day} {month} {year}</span>
            <span className="bold-text">Be the first to comment on this story</span>
          </div>

          <div className="tags-list">
            {context.state.kpis.map(kpi => (
              <span className="tags-item">{kpi}</span>
            ))}
          </div>
        </div>

        <div className="preview-container">
          <div className="preview-container-header">
            <div className="story-container-contents">
              <img className="container-header-avatar" src={userContext.state.profileUrl} alt="" />

              <div className="container-header-name">
                <span className="name">{userContext.state.fullName}</span>
                <span className="role">
                  {userContext.state.jobTitle} {userContext.state.department ? `· ${userContext.state.department}` : ''}
                </span>
              </div>

            </div>
            <div className="story-container-contents">
              <img src={image} className="container-header-chart" alt="" />
              {/* <div className="container-title-header">
              <img src={DotsMenuIcon} alt="" />
            </div> */}
            </div>
          </div>

          <div className="preview-container-title">
            <h5 className="card-type">Data Story</h5>
            <h3 className="title">{context.state.title} </h3>
            <h4 className="subtitle">{context.state.subtitle}</h4>
          </div>

          <div className="preview-container-graph">
            <p className="graph-text">{""}</p> {/* Where insight used to be */}

            <Editor value={[
                context.state.insight, 
                context.state.anomalyReason, 
                context.state.impactExplanation, 
                context.state.suggestions].join("\n")
            } readOnly 
            className="decidable-markdown-feed"
            disableExtensions={[]}/>

            <div className="graph-image">
              {context.state.pivotTable &&
                context.state.pivotTable.data &&
                !context.state.dataChartImageUrl &&
                <DecidableChart data={context.state.pivotTable!.data} graphSelection={context.state.pivotTable.graphSelection} />}
              {context.state.dataChartImageUrl && <img id="graphImage" src={context.state.dataChartImageUrl} style={{ width: "100%", display: 'block' }} alt="" />}
            </div>
          </div>

          <div className="container-buttons-footer">
            <span className="data-actions">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 14.928 14.165" className="data-actions-icon data-actions-like">
                <g id="Like" transform="translate(0.5 0.699)">
                  <path id="Caminho_21" data-name="Caminho 21"
                    d="M12.569,17.465h5.97a1.273,1.273,0,0,0,1.187-.79L21.707,12.1a1.261,1.261,0,0,0,.09-.473v-1.3a1.5,1.5,0,0,0-1.312-1.506H16.348l.625-2.752.021-.2a.965.965,0,0,0-.288-.685L16,4.5,11.632,8.8a1.282,1.282,0,0,0-.382.915V16.2A1.292,1.292,0,0,0,12.569,17.465Z"
                    transform="translate(-7.869 -4.5)" stroke-width="1" />
                  <path id="Caminho_22" data-name="Caminho 22" d="M3.375,15.75H5.307v7.979H3.375Z"
                    transform="translate(-3.375 -10.764)" stroke-linejoin="round" stroke-width="1" />
                </g>
              </svg>
            </span>
            <span className="data-actions">
              00 Likes
            </span>
            <span className="data-actions">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 13.625 13.347" className="data-actions-icon">
                <g id="Comment" transform="translate(-4.5 -1.8)">
                  <g id="Comment_Icon" data-name="Comment Icon" transform="translate(5 2.3)">
                    <path d="M-521.2,544.632h-8.884a1.957,1.957,0,0,0-1.871,2.033v5.884a1.957,1.957,0,0,0,1.871,2.033h1.406V556.8a.154.154,0,0,0,.256.126l2.472-2.345h4.75a1.957,1.957,0,0,0,1.87-2.033v-5.884A1.957,1.957,0,0,0-521.2,544.632Z"
                      transform="translate(531.954 -544.632)" stroke-linejoin="round" stroke-width="1" />
                  </g>
                </g>
              </svg>
              00 Comments
            </span>
          </div>
        </div>
      </div>

      <h3 className="data-container-title" style={{marginTop: '75px'}}>Tag the people and departments that would be interested.</h3>

        <div className="data-user-selection">
          <MultiSelect
            options={options}
            value={context.state.tagged}
            onChange={(tagged: any) => context.setState({ ...context.state, tagged: tagged })}
            labelledBy="Select user(s)"
            className="user-input"
            overrideStrings={{
              selectSomeItems: "Select user(s)",
              allItemsAreSelected: "All users are selected"
            }}
          />
          {/* SELECTED USER */}
          {context.state.tagged.map((user: any) => (
            <div className="selected-user" key={user.value}>
              <span className="user-photo">
                <img src={user.profileUrl} style={{ width: '100%' }} alt="" />
              </span>
              <span className="user-name">{user.label}</span>

              <Button className="user-delete" variant="outlined" onClick={(e: React.MouseEvent) => {
                context.setState({ ...context.state, tagged: [] })
                // props.setState({...props.state, tagged: [...props.state.tagged.filter((item: any) => item.label != user.label)]})
              }}>
                x
              </Button>
            </div>
          ))}
        </div>
        <h3 className="data-container-title">Tags</h3>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4} className="data-tag-select">
            <TextField
              id="departament"
              variant="outlined"
              defaultValue="placeholder"
              fullWidth
              value={context.state.choosenDepartment.length > 0 ? context.state.choosenDepartment : "placeholder"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, choosenDepartment: e.target.value })}
              select
            >
              <MenuItem value="placeholder" disabled>Department</MenuItem>
              <MenuItem value="Production">Production</MenuItem>
              <MenuItem value="Product">Product</MenuItem>
              <MenuItem value="Research and Development">Research and Development</MenuItem>
              <MenuItem value="Marketing">Marketing</MenuItem>
              <MenuItem value="Sales">Sales</MenuItem>
              <MenuItem value="Humans Resources">Humans Resources</MenuItem>
              <MenuItem value="Accounting and Finance">Accounting and Finance</MenuItem>
              <MenuItem value="IT">IT</MenuItem>
              <MenuItem value="Technology">Technology</MenuItem>
              <MenuItem value="Data">Data</MenuItem>
              <MenuItem value="Software Development">Software Development</MenuItem>
              <MenuItem value="Ecommerce">Ecommerce</MenuItem>
              <MenuItem value="Design">Design</MenuItem>
              <MenuItem value="Fraud">Fraud</MenuItem>
              <MenuItem value="Legal">Legal</MenuItem>
              <MenuItem value="Public Relations">Public Relations</MenuItem>
              <MenuItem value="Social Media">Social Media</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="data-tag-select">
            <TextField
              id="kpi"
              variant="outlined"
              defaultValue="placeholder"
              fullWidth
              value={context.state.choosenKpi.length > 0 ? context.state.choosenKpi : "placeholder"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const choosenKpi = e.target.value;
                const _k = `${choosenKpi[0].toUpperCase()}${choosenKpi.slice(1).toLowerCase()}`
                context.setState({ ...context.state, choosenKpi: choosenKpi, kpis: [_k] });
              }}
              select
            >
              <MenuItem value="placeholder" disabled>KPI</MenuItem>
              <MenuItem value="Acquisition">Acquisition</MenuItem>
              <MenuItem value="Payoff">Payoff</MenuItem>
              <MenuItem value="Retention">Retention</MenuItem>
              <MenuItem value="Virality">Virality</MenuItem>
              <MenuItem value="Operations">Operations</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="data-tag-select">
            <TextField
              id="importance"
              variant="outlined"
              defaultValue="placeholder"
              fullWidth
              value={context.state.choosenPriority.length > 0 ? context.state.choosenPriority : "placeholder"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => context.setState({ ...context.state, choosenPriority: e.target.value })}
              select
            >
              <MenuItem value="placeholder" disabled>Importance</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Normal">Normal</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </TextField>
          </Grid>
        </Grid>

      <div className="data-story-buttons">
        {/* <button className="btn-outlined-wipe" onClick={(e: React.MouseEvent<HTMLButtonElement>) => saveDraft(context.state)}>
          Save Draft
        </button> */}

        {context.state.editMode ? (
          <button className="btn-contained-wipe" onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            postStory(context.state.id, context.state, history)
          }}>
            Edit
          </button>
        ) : (
          <button className="btn-contained-wipe" onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            postStory(context.state.id, context.state, history)
          }}>
            Post
          </button>
        )}


      </div>
      <ToastContainer />
    </div >
  )
}

export default Preview;