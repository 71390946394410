import React from "react";
import LoginService from "../Login/LoginService";
import {toast} from "react-toastify";
import {useHistory, useLocation} from "react-router-dom";
import {OnboardingUserType} from "../Onboarding/v2/OnboardingProvider";
import Header from "../../components/Header/Header";

interface GASetupNavState {
    code: string;
}

const GoogleAnalyticsSetup = () => {
    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        const state = location.state as GASetupNavState;
        const code = state.code;

        if (!code)
            return;

        const token = LoginService.getToken();
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/google-analytics/exchange-access-code?accessCode=${code}`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
            },
        }).then(async (tokenResponse: any) => {
            if (tokenResponse.status === 200) {
                const body = await tokenResponse.json();
                history.push('/v2/onboarding', {
                    step: 2,
                    type: OnboardingUserType.ANALYST,
                    GAPayload: {
                        accounts: body.accounts ?? [],
                        loggedIn: body.loggedIn,
                        scope: body.scope
                    }
                });
            }else if(tokenResponse.status === 403){
                toast.error("Your company is not subscribed to the GoogleAnalytics or a free trial plan, please upgrade your plan to use this functionality.");
                history.push('/v2/onboarding', {
                    step: 1,
                    type: OnboardingUserType.ANALYST
                });
            } else {
                toast.error("Something went wrong with your access code, please refresh the page to try again");
                history.push('/v2/onboarding', {
                    step: 1,
                    type: OnboardingUserType.ANALYST
                });
            }
        });
    /* eslint-disable */
    }, [])
    /* eslint-enable */

    return (
        <div className="onboarding-container">
            <Header />
            <div className="onboarding-container-content">
                <div className="onboarding-step-container">
                    <h1 className="onboarding-step-first-text">
                        Almost done. You are going to be redirected in a couple seconds.
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default GoogleAnalyticsSetup;