import React from 'react';
import { StepBuilder, Step } from "../Step";
import AddVisual from "./AddVisual";

export const AddVisualStep = (): Step => {
  return new StepBuilder()
    .component(<AddVisual />)
    .step("Add your visual")
    .stepListName("Add your visual")
    .build();
}