import React from "react";
import { Button, Menu, MenuItem } from '@material-ui/core';
import { CommentAnswer, User } from '../../Dashboard';
import DotsMenuAnswerIcon from '../../../../assets/images/dotsMenu-white-icon.svg';
import { LikeWhiteButton } from '../../../../components/Likes/LikeButton';

interface CommentAnswerProps {
    answer: CommentAnswer,
    answerSelectedBy: User | null,
    removeAnswer: CallableFunction,
    likeAnswerComment: CallableFunction
}

interface CommentAnswerState {
    commentsMenu: HTMLElement | null;
}

const QuestionAnswerComponent: React.FC<CommentAnswerProps> = (props) => {
    const [state, setState] = React.useState<CommentAnswerState>({
        commentsMenu: null
    })
    const [openContent, setOpenContent] = React.useState(false);
    const [showMoreContentIcon, setShowMoreContentIcon] = React.useState(false);

    const user = props.answer.user;

    const openAnswerContent = () => {
        let text = document.querySelector('.answer-text')
        setOpenContent(!openContent);

        text?.classList.toggle('show-text')
    }

    React.useEffect(() => {
        let answerText = document.querySelector('.answer-text')
        if (answerText) {
            if (answerText.clientHeight > 30) {
                setShowMoreContentIcon(true)
            }
        }
    /* eslint-disable */
    }, [])
    /* eslint-enable */

    return (
        <div className="question-answer">
            <div className="answer-header">
                <img src={props.answer.user.profileUrl} className="author-image" alt="" />
                <div className="answer-author">
                    <span className="name">{user && user.fullName}</span>
                    <span className="role">{user && user.jobTitle ? user.jobTitle : ""} {user && user.department ? "· " + user.department : ""}</span>
                </div>
                <Button className="answer-menu-button" onClick={(e) => setState({ ...state, commentsMenu: e.currentTarget })}>
                    <img src={DotsMenuAnswerIcon} alt="" />
                </Button>
            </div>

            <div className="answer-text-content" onClick={openAnswerContent}>
                <span className="card-type">Marked as Answer</span>
                <p className="answer-text" style={{color: "#fff", fontSize: "16px"}}>{props.answer.text}</p>
                <span className="answer-selected">
                    Answer selected by {props.answerSelectedBy?.fullName} {props.answerSelectedBy?.jobTitle ? "· " + props.answerSelectedBy?.jobTitle : ""}
                </span>
                {showMoreContentIcon ? (
                    <span className="read-more answer">
                        {openContent ? 'Hide content...' : 'Read more...'}
                    </span>
                ) : ''}
            </div>

            <div className="answer-likes">
                <LikeWhiteButton className="answer-likes-button" liked={props.answer.likedByCurrentUser} onClick={() => props.likeAnswerComment()} />
                <Button className="answer-likes-button">
                    <span className="answer-likes-count">{props.answer.likesCount ?? 0}</span>
                </Button>

                {/* {showMoreContentIcon ? (
                    <Button className="answer-open-text" onClick={openAnswerContent}>
                        {openContent ? (
                            <VisibilityOffOutlinedIcon className="open-text-icon hide-text" />
                        ) : (
                            <VisibilityOutlinedIcon className="open-text-icon" />
                        )}
                    </Button>
                ) : ''} */}
            </div>

            <Menu
                id="simple-menu"
                anchorEl={state.commentsMenu}
                open={Boolean(state.commentsMenu)}
                onClose={(e) => setState({ ...state, commentsMenu: null })}
                PopoverClasses={{ paper: 'popover-menu' }}>
                <MenuItem className="popover-menu-item" onClick={() => props.removeAnswer()}>Remove Comment as Answer</MenuItem>
            </Menu>
        </div>)
}
export default QuestionAnswerComponent;