import gql from "graphql-tag";
import { getApolloClient } from "../../../components/GraphQL/GraphQL";
import { QueryResult } from "../../Login/LoginContracts";
import LoginService from "../../Login/LoginService";
import { UserPreferences, GuideType } from "./Onboarding";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class OnboardingService {
    async saveOnboarding(userPreferences: UserPreferences, kpis: string[], userDescription: string): Promise<Response> {
        const url = `${BASE_URL}/api/user/on-board`
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                ...userPreferences,
                userName: userPreferences.userName ?? "",
                kpis: kpis,
                description: userDescription
            })
        });
    }

    async changeUserPhoto(photo: File) {
        const url = `${BASE_URL}/api/user/change-user-picture`
        var token = LoginService.getToken();

        const data = new FormData()
        data.append("UserPictureFile", photo);


        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': token ? 'Bearer ' + token : "",
        },
        referrerPolicy: 'no-referrer',
            body: data
        });
    }

    async getGuideStatus() {
        const client = getApolloClient(LoginService.getToken());
        const result: QueryResult = await client.query({
            query: gql`
                query Ping {
                    me {
                        askQuestionGuide
                        feedReadGuide
                        writeDataStoryGuide  
                    }
                }
                `
        });

        return result.data.me[0];
    }

    async saveGuide(status: any, type: GuideType) {
        const url = `${BASE_URL}/api/User/guide`
        var token = LoginService.getToken();

        return await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : "",
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                guideStatus: status,
                guideType: type
            })
        });
    }
}
export default new OnboardingService();