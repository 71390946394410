import React from "react";

import "./Scribey.scss";
import StoryHelper from "../StoryHelper";
import Wizard from "./Wizard/Wizard";

const Scribey: React.FC = () => {
    return (
        <>
            <div>
                <StoryHelper />
                
                <div style={{marginTop: "35px"}}>
                    <Wizard />
                </div>            
            </div>
        </>
    )
}

export default Scribey;